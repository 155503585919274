export const giftPack = {
  data() {
    return {
      carouselTimer: 0,
      carouselNumber: 0,
      animate: true,
      time: {
        h: '00',
        m: '00',
        s: '00',
        ms: '000',
      },
      countTimer: 0,
      stop: false
    };
  },
  methods: {
    carousel(number) {
      const maxPx = -number * this.listData.length;
      this.carouselTimer = setInterval(() => {
        this.carouselNumber -= number;
        if (this.carouselNumber === maxPx) {
          this.animate = false;
          this.carouselNumber = 0;
        } else {
          this.animate = true;
        }
      }, 2e3);
    },
    countTime(dateTime, cb) {
      const date = new Date();
      const now = date.getTime();
      const endDate = new Date(dateTime); //设置截止时间
      const end = endDate.getTime();
      const leftTime = end - now; //时间差
      let h, m, s, ms;
      if (leftTime >= 0) {
        h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        m = Math.floor((leftTime / 1000 / 60) % 60);
        s = Math.floor((leftTime / 1000) % 60);
        ms = Math.floor(leftTime % 1000);
        if (ms < 100) {
          ms = '0' + ms;
        }
        if (s < 10) {
          s = '0' + s;
        }
        if (m < 10) {
          m = '0' + m;
        }
        if (h < 10) {
          h = '0' + h;
        }
      } else {
        console.log('已截止');
        this.stop = true;
        cb && cb();
      }
      this.time = {
        h,
        m,
        s,
        ms,
      };

      if (this.stop) {
        this.stop = false;
        return;
      }
      //递归每秒调用countTime方法，显示动态时间效果
      this.countTimer = setTimeout(() => {
        this.countTime(dateTime, cb)
      }, 50);
    },
  },
};
