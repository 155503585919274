<template>
  <div>
    <transition name="modal1">
      <lt-modal
        v-model="visible"
        simple
        :footer-hide="true"
      >
        <div
          class="preview-upload"
          :style="{
            width: wrapWidth + 'px',
            height: wrapHeight + 'px',
          }"
        >
          <div :class="`preview-img-wrap ${ fileInfo.base64 ? '' : 'animation' }`" :style="{ width: wrapWidth - mainWidth - 80 + 'px' }">
            <div
              v-if="fileInfo.base64"
              :style="{
                width: wrapWidth - mainWidth - 80 - 64 + 'px',
                backgroundImage: `url(${fileInfo.base64})`
              }"
              class="preview-img"
            />
          </div>
          <div class="upload" :style="{ width: mainWidth + 'px', alignItems: status === 'uploadSuccess' ? 'flex-start' : 'center' }">
            <div class="uploading" v-if="status === 'uploading'">
              <div class="upload-title">正在上传你的图片 <span>{{ uploadProcess }}%</span></div>
              <div class="upload-process">
                <div class="upload-process-bar" :style="{ width: uploadProcess + '%' }"></div>
              </div>
              <div class="upload-cancel" @click="cancelUpload">取消上传</div>
            </div>
            <div class="upload-fail" v-if="status === 'uploadFail'">
              <div class="fail-msg">
                <img class="fail-msg-icon" :src="require('./img/error.svg')" alt="错误">
                {{ +errorCode === 87014 ? '图片违规' : '图片上传失败' }}
              </div>
              <div class="fail-desc">{{ errTip[errorCode] }}</div>
              <div class="fail-btn" v-if="[87014, 0, 100, 101].includes(+errorCode)" @click="reUploadFile">重新上传</div>
              <div class="fail-btn" v-if="+errorCode === -431 && +teamInfo.adminUserVipLevel === 0" @click="buyNow">扩充存储权限</div>
            </div>
            <div class="upload-success" v-if="status === 'uploadSuccess'">
              <div class="file-name">{{ fileInfo.name }}</div>
              <div class="file-size" v-if="fileInfo.rect"> {{ fileInfo.rect.w }} * {{ fileInfo.rect.h }} px</div>
              <div class="file-edit" @click="openCreateImage">上传并编辑图片</div>
              <QuickScenes :mid="mid" :teamId="teamId">
                <div class="file-design">在设计中使用</div>
              </QuickScenes>
            </div>
          </div>
        </div>
      </lt-modal>
    </transition>
    <UploadImgFunc ref="uploadImgFuncRef" @upload-success="uploadSuccess" @upload-fail="uploadFail" />
  </div>
</template>

<script>
import QuickScenes from "./quick-scenes.vue";
import UploadImgFunc from "./uploadImgFunc.vue";
import {mapActions} from "vuex";
import pageNameEnum from "../../pageNameEnum";

const vipNameObj = {
  0: '普通用户',
  1: '企业VIP-基础版',
  2: '协作会员',
  3: '高级团队VIP',
  4: '个人版',
  5: '企业VIP-标准团队版',
  6: '企业专业版VIP',
  7: '企业VIP-加强版',
  8: '企业VIP-基础团队版',
  9: '企业VIP-全用途版',
  100: '基础版VIP',
  105: '全用途版VIP',
  110: '标准团队版VIP',
  115: '高级团队版VIP',
  120: '企业专业版',
  125: '新媒体商户版',
  130: '全用途商户版',
  135: '团队协作版',
  199: '标准版（个人）',
  200: '标准版（企业）',
  201: '个人高级版',
  202: '企业高级版',
  203: '高级版',
  205: '协作版',
  220: '旗舰版',
  211: "通用版（个人）",
  212: "通用版（企业）",
}

export default {
  name: "preview-modal",
  inject: ['header'],
  components: {
    QuickScenes,
    UploadImgFunc
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
      default() {
        return {
          nickname: '',
          email: '',
          userHeadImgUrl: '',
          urnCount: 0,
        };
      },
    },
    teamInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    teamId: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      wrapWidth: 1366,
      wrapHeight: 560,
      mainWidth: 360,
      status: 'uploadFail', // uploading上传中 uploadFail上传失败 uploadSuccess 上传成功
      errorCode: -110,
      errTip: {
        '-431': '您的个人空间素材存储空间不足，请清理空间后再试',
        '-110': '权限不足，无法上传',
        '87014': '图片涉嫌违规，禁止上传',
        '0': '当前网络异常，请您稍后再试',
        '100': '当前图片文件过大，请调整尺寸后重试',
        '101': '当前资源格式不合规，请调整格式后重新上传',
      },
      uploadProcess: 0,
      timer: -1,
      fileInfo: {},
      visible: false,
      mid: '',
    }
  },
  mounted() {
    this.rect();
    window.addEventListener('resize', this.rect)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    visible() {
      if (this.visible) {
        this.reportPopupShow(true)
      }
      if (!this.visible) {
        this.fileInfo = {};
      }
    }
  },
  methods: {
    ...mapActions({
      setLogregShow: 'setLogregShow',
      setIsLogin: 'setIsLogin',
    }),
    reportPopupShow(is_login) {
      try {
        window.gio('track', 'popup_show', {
          page_name: pageNameEnum[this.$route.path] || '',
          popup_name: '上传并编辑图片弹窗',
          page_url: location.href,
          is_login: is_login ? '是' : '否',
        })
      } catch (err) {
        console.error(err)
      }
    },
    reportImgHandle(is_login, button_name) {
      try {
        window.gio('track', 'button_click', {
          page_name: '上传并编辑图片',
          module_name: '图片处理弹窗',
          button_name,
          class_id: null,
          page_url: location.href,
          is_login: is_login ? '是' : '否',
        })
      } catch (err) {
        console.error(err)
      }
    },
    reportFileUpload({ file_id, is_success, error_type, error_info }) {
      try {
        window.gio('track', 'file_upload', {
          forward_page_name: '创建设计弹窗',
          forward_module_name: '上传并编辑图片',
          file_type: '图片',
          file_format: this.fileInfo.type,
          file_size: this.fileInfo.size,
          file_id,
          design_id: null,
          design_scene_id: null,
          is_success,
          error_type,
          error_info,
          user_id: this.userInfo.userId,
          user_vip: this.userInfo.vipLevelName,
          team_id: this.teamId,
          team_name: decodeURI(this.teamInfo.teamName),
          team_vip: vipNameObj[this.teamInfo.adminUserVipLevel],
          business_lines:1,
          product_name:1,
          platform:1
        })
      } catch (err) {
        console.error(err)
      }
    },
    rect() {
      const clientWidth = document.body.clientWidth
      const clientHeight = document.body.clientHeight
      this.wrapWidth = clientWidth - 160 > 1366 ? 1366 : clientWidth - 160 < 960 ? 960 : clientWidth - 160
      this.wrapHeight = clientHeight - 160 > 560 ? 560 : clientHeight - 160 < 400 ? 400 : clientHeight - 160
      this.mainWidth = clientWidth > 1280 ? 360 : 280
    },
    reUploadFile() {
      this.reportImgHandle(true, '重新上传')
      this.uploadFile();
    },
    async uploadFile() {
      const input = document.createElement('input');
      input.accept = '.png,.jpg,.jpeg,.svg,.gif,.bmp';
      input.type = 'file';
      input.multiple = false;
      input.click();
      input.onchange = async () => {
        this.visible = true;
        this.uploadStart();
        const file = input.files[0];
        this.fileInfo = file ? {
          type: file.type,
          name: file.name,
          size: file.size,
        } : {};
        if (!['image/gif', 'image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'image/svg+xml'].includes(this.fileInfo.type)) {
          this.uploadFail({ code: 101 })
          return;
        }
        if (
          this.fileInfo.size > 1024 * 1024 * 20 ||
          (file.type === 'image/gif' && this.fileInfo.size >  1024 * 1024 * 5)
        ) {
          this.uploadFail({ code: 100 })
          return;
        }
        try {
          const imgInfo = await this.getFileInfo(file);
          this.fileInfo = {...this.fileInfo, ...imgInfo}
          if (Math.max(this.fileInfo.rect.w, this.fileInfo.rect.h) > 16384) {
            this.uploadFail({ code: 100 })
            return;
          }
          this.open(file);
        } catch (err) {
          this.errorCode = 0;
          this.status = 'uploadFail'
          console.error(err);
        }
      }
    },
    open(file) {
      if (file) {
        this.$nextTick(() => {
          this.$refs.uploadImgFuncRef.UploadImg(file);
        })
      }
    },
    uploadStart() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.uploadProcess = 2;
      this.status = 'uploading';
      this.timer = setInterval(() => {
        if (this.uploadProcess < 98) {
          this.uploadProcess += 1;
        } else if (this.status === 'uploadSuccess' && this.uploadProcess > 95) {
          this.uploadProcess = 100;
          clearInterval(this.timer)
        }
      }, 100)
    },
    uploadSuccess({ mid }) {
      this.mid = mid;
      this.reportFileUpload({
        file_id: mid,
        is_success: '是',
        error_type: null,
        error_info: null
      })
      this.status = 'uploadSuccess';
      if (this.uploadProcess > 95) {
        this.uploadProcess = 100;
        clearInterval(this.timer)
      }
    },
    uploadFail({ code, err }) {
      if (code === -3) {
        this.reportFileUpload({
          file_id: null,
          is_success: '否',
          error_type: '登录态失效',
          error_info: '登录态失效'
        })
        this.visible = false;
        this.setIsLogin(false)
        this.setLogregShow(1);
        return;
      }
      this.reportFileUpload({
        file_id: null,
        is_success: '否',
        error_type: this.errTip[code] || '',
        error_info: this.errTip[code] || err.toString() || ''
      })
      this.status = 'uploadFail';
      this.errorCode = this.errTip[code] ? code : 0;
      this.$message.error(this.errTip[code]);
      clearInterval(this.timer)
    },
    buyNow() {
      this.reportImgHandle(true, '扩充存储权限')
      let level = this.userInfo.vipLevel;
      level = !level ? 4 : level;
      this.header.buyNow(34, level);
    },
    openCreateImage() {
      this.reportImgHandle(true, '上传并编辑图片')
      if (!['image/png', 'image/jpg', 'image/jpeg', 'image/bmp'].includes(this.fileInfo.type)) {
        return this.$message.error('图片编辑暂时仅支持png、jpg、bmp资源类型')
      }
      if (this.mid) {
        window.open(`/pic/createImage?d=${this.mid}`)
      } else {
        console.error('打开编辑图片失败', this.mid)
      }
    },
    getFileInfo(file) {
      return new Promise((resolve, reject) => {
        let fr = new FileReader()
        fr.readAsDataURL(file)
        fr.onload = e => {
          let base64 = e.target.result
          let img = new Image()
          img.src = base64
          img.onload = () => {
            resolve({
              base64,
              rect: { w: img.width, h: img.height },
            })
          }
          img.onerror = (err) => {
            console.log('解析图片失败')
            reject('解析图片失败')
          }
        }
      })
    },
    cancelUpload() {
      this.reportImgHandle(true, '取消上传')
      this.visible = false
    }
  }
}
</script>

<style scoped lang="less">
@keyframes skeleton-loading {
  0% {
    transform: translateX(-37.5%)
  }
  100% {
    transform: translateX(37.5%)
  }
}
.preview-upload {
  display: flex;
  .preview-img-wrap {
    height: 100%;
    padding: 32px;
    background-color: #F8F8FB;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    &.animation::after {
      position: absolute;
      top: 0;
      bottom: 0;
      inset-inline-end: -150%;
      inset-inline-start: -150%;
      background: linear-gradient(90deg,rgba(0,0,0,0.036) 25%,rgba(0,0,0,0.09) 37%,rgba(0,0,0,0.036) 63%);
      animation-name: skeleton-loading;
      animation-duration: 1.4s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
      content: '';
    }
  }
  .preview-img {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    object-fit: contain;
  }
  .upload {
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    .uploading {
      flex: 1;
      .upload-title {
        white-space: nowrap;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #505A71;
        margin-bottom: 16px;
        & > span {
          color: #0773FC;
        }
      }
      .upload-process {
        height: 10px;
        background: #F3F4F9;
        border-radius: 5px;
        margin-bottom: 48px;
        .upload-process-bar {
          transition: width 0.2s;
          height: 10px;
          width: 0;
          border-radius: 5px;
          background: linear-gradient(89.98deg, #00C2FF 1.09%, #0773FC 99.99%);;
        }
      }
      .upload-cancel {
        height: 44px;
        background: #F8F8FB;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        color: #505A71;
        cursor: pointer;
        &:hover {
          background: #F1F3F9;
        }
      }
    }
    .upload-fail {
      flex: 1;
      .fail-msg {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        font-weight: 600;
        font-size: 20px;
        color: #505A71;
        margin-bottom: 16px;
        &-icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
      .fail-desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #505A71;
        margin-bottom: 48px;
      }
      .fail-btn {
        height: 44px;
        background: #0773FC;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: #0667E2;
        }
      }
    }
    .upload-success {
      padding: 32px 0;
      flex: 1;
      .file-name {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #1B2337;
        margin-bottom: 8px;
        word-break: break-all;
      }
      .file-size {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #8693AB;
        margin-bottom: 16px;
      }
      .file-edit {
        height: 44px;
        background: #0773FC;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin-bottom: 12px;
        cursor: pointer;
        &:hover {
          background: #0667E2;
        }
      }
      .file-design {
        height: 44px;
        background: #F8F8FB;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #505A71;
        cursor: pointer;
        &:hover {
          background: #F1F3F9;
        }
      }
    }
  }
}
</style>