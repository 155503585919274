<template>
  <footer :class="`activecenter-footer ${theme}`">
    <slot></slot>
    <div ref="containerRef" class="activecenter-footer-line2">
      <a-popover
        trigger="hover"
        :getPopupContainer="triggerNode => triggerNode"
        :align="{
          offset: [0, 2],
        }"
      >
        <template #content>
          <div class="more-info" ref="moreContainerRef">
            <template v-for="(item, index) in list.slice(lastIndex)">
              <a
                :key="item.title"
                v-if="item.href"
                class="link"
                :href="item.href"
                :target="item.target"
              >
                {{ item.title }}
              </a>
              <span v-else-if="isVersion(item.title)" :key="index" class="text">
                版本号：iOS 4.7.7&nbsp;&nbsp;&nbsp;Android：4.8.1
              </span>
              <span v-else :key="index" class="text">{{ item.title }}</span>
            </template>
          </div>
        </template>
        <span :class="`more ${theme}`" ref="moreButtonRef">
          更多
          <my-icon type="icon-xiala" class="arrow-icon"></my-icon>
        </span>
      </a-popover>
    </div>
  </footer>
</template>
<script>
export default {
  name: "footerCopyright",
  data() {
    return {
      list: [
        {
          title: "Copyright©北京艺源酷科技有限公司",
        },
        {
          suffixIcon: require("./img/put_on_file_icon.png"),
          href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049358",
          target: "_blank",
          title: "京公网安备 11010502049358号",
        },
        {
          href: "https://beian.miit.gov.cn/",
          target: "_blank",
          title: "京ICP备14056892号-1",
        },
        {
          href: "https://beian.cac.gov.cn/#/index",
          target: "_blank",
          title: "网信算备110108349315101240027号",
        },
        {
          href: "https://beian.cac.gov.cn/#/index",
          target: "_blank",
          title: "网信算备110108349315101240019号",
        },
        {
          title: "开发者：北京艺源酷科技有限公司",
        },
        {title: "版本号：iOS 4.7.7 Android：4.8.1"},
        {
          title: "高新技术企业证书 GR202111007765",
        },
        {
          title: "广播电视节目制作经营许可证（京）字第24572号",
        },
        {
          title: "京网文〔2024〕0428-026号",
        },
      ],
      lastIndex: -1,
    };
  },
  props: {
    theme: {
      type: String,
      default: "light",
    },
  },
  mounted() {
    this.addItem();
    window.addEventListener("resize", this.adjustList);
    this.adjustList();
  },
  methods: {
    isVersion(title) {
      return title.includes("版本号：");
    },
    addItem() {
      const container = this.$refs.containerRef;
      const moreButton = this.$refs.moreButtonRef;

      let newContent = "";

      this.list.forEach((item, index) => {
        let el = "";
        if (item.href) {
          el = `<a class="link" href="${item.href}" target="${item.target}">
            ${
            item.suffixIcon
              ? `<img
              width="16"
              height="16"
              src="${item.suffixIcon}"
              alt="icon"
              />`
              : ""
          }
            ${item.title}
          </a>`;
        } else {
          el = this.isVersion(item.title)
            ? `<span class="text">版本号：iOS 4.7.7&nbsp;&nbsp;&nbsp;Android：4.8.1</span>`
            : `<span class="text">${item.title}</span>`;
        }
        newContent += el;
      });
      moreButton.insertAdjacentHTML("beforebegin", newContent);

      const listItems = Array.from(container.children);
      listItems.forEach((item, index) => {
        if (this.list[index]) {
          this.list[index].width = item.offsetWidth;
        }
      });
    },
    adjustList() {
      const container = this.$refs.containerRef;
      const moreButton = this.$refs.moreButtonRef;

      const containerWidth = container.offsetWidth;
      const moreButtonWidth = moreButton.offsetWidth;
      const listItems = Array.from(container.children);
      let totalWidth = 12 + 64;
      let lastIndex = -1;

      this.list.forEach((item, index) => {
        totalWidth += item.width + 12;
        if (totalWidth + moreButtonWidth > containerWidth && !~lastIndex) {
          lastIndex = index;
        }
      });

      this.lastIndex = lastIndex;

      listItems.forEach((item, index) => {
        if (
          (~lastIndex && index >= lastIndex && item !== moreButton) ||
          (!~lastIndex && item === moreButton)
        ) {
          item.style.display = "none";
        } else {
          item.style.removeProperty("display");
        }
      });
    },
  },
};
</script>
<style lang="less">
.activecenter-footer {
  width: 100%;
  text-align: center;
  //border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-top: 9px;

  .activecenter-footer-line2 {
    margin: 0;
    height: 36px;
    line-height: 36px;
    width: 100%;
    text-align: center;

    span {
      font-size: 12px;
      color: #8693ab;
      line-height: 20px;
      margin-right: 12px;
      white-space: nowrap;
    }

    a {
      font-size: 12px;
      color: #8693ab;
      line-height: 20px;
      margin-right: 12px;
      white-space: nowrap;

      & > img {
        vertical-align: text-top;
      }

      &:hover {
        color: #0773fc;
        text-decoration: underline;
      }
    }

    .arrow-icon {
      color: #8693ab;
      font-size: 10px;
    }

    .more {
      cursor: pointer;

      &.light:hover {
        color: #1b2337;

        .arrow-icon {
          color: #505a71;
        }
      }

      &.dark:hover {
        color: #0773fc;

        .arrow-icon {
          color: #0773fc;
        }
      }

      &-info {
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 9px 32px 8px rgba(42, 49, 67, 0.06),
        0px 16px 32px 0px rgba(42, 49, 67, 0.06),
        0px 6px 24px 0px rgba(42, 49, 67, 0.1);
        white-space: nowrap;

        a,
        span {
          margin-bottom: 4px;
          text-align: left;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-inner {
        box-shadow: none;
        background-color: transparent;
      }

      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
}
</style>
