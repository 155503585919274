<template>
  <div class="search-bar" :style="{ width: !isHeader ? width : '' }">
    <div
      class="search-wrap"
      ref="searchWarp"
      @resize="handleResize"
      v-click-outside="clickOutSide"
      :class="{ focus: isFocus }"
      :style="{ width: !isHeader ? '100%' : '' }"
    >
      <div
        class="icon-list icon-list-left"
        :style="{
          borderLeft: getBorderStyle,
          borderTop: getBorderStyle,
          borderBottom: getBorderStyle,
        }"
      >
        <!-- 搜索按钮 -->
        <span
          v-if="!aiDesignState"
          @click.stop="searchTemplate(searchValue, 'search')"
        ></span>
        <div
          v-show="aiDesignState"
          ref="lavContainer"
          class="ai_icon"
          @click.stop="searchTemplate(searchValue, 'search')"
        ></div>
      </div>
      <!-- 防止浏览器自动填充 -->
      <input type="text" style="display: none" />
      <input type="password" style="display: none" />

      <!-- 搜索框 -->
      <input
        v-model="searchValue"
        id="search"
        ref="input"
        accesskey="/"
        maxlength="32"
        autocomplete="off"
        class="search-input"
        :style="{ borderTop: getBorderStyle, borderBottom: getBorderStyle }"
        :placeholder="placeholder"
        @focus="focus"
        @keyup="filterSearchValue"
        @keyup.enter="searchTemplate(searchValue, 'search')"
        @input="associativeWordSearch()"
      />
      <div
        class="icon-list"
        :style="{
          borderRight: getBorderStyle,
          borderTop: getBorderStyle,
          borderBottom: getBorderStyle,
        }"
      >
        <!-- 搜图片按钮 -->
        <div class="camera-icon" v-show="!searchValue">
          <span @click.stop="handleCameraClick()"></span>
          <div class="tips">上传图片搜索相似模板</div>
        </div>
        <div
          v-show="searchValue"
          class="delete-icon"
          @click.stop="clearInput"
        ></div>
        <!-- 点击搜索按钮 -->
        <div
          class="search-button"
          v-show="focusHotShow || !isHeader"
          :style="{ width: isHeader ? '60px' : '76px', marginRight: '2px' }"
          @click.stop="searchTemplate(searchValue, 'search')"
        >
          搜索
        </div>
      </div>

      <!-- 搜索历史&&热搜词 -->
      <div v-show="focusHotShow" class="focusHotSearch">
        <ul class="search-module-box">
          <li
            v-for="(item, index) in searchModuleData"
            :key="index"
            class="module-list"
            :class="{ active: searchModuleType === index }"
            @mousedown.stop="chooseSearchMoudle(index)"
          >
            <span>{{ item }}</span>
          </li>
        </ul>
        <!-- empty -->
        <div
          class="empty-material"
          v-if="searchModuleType === 1 && historySearch.length === 0"
        >
          <img src="./img/empty.png" />
          <span>暂无搜索记录</span>
        </div>
        <div class="search-scroll-box">
          <!-- 模版和素材的搜索 -->
          <template v-if="searchModuleType === 0">
            <template v-if="!showAssociateView || searchValue === ''">
              <AiDesignModule
                v-if="focusHotShow && showAiState"
                :preset-words="presetWords"
                :search-default-arr="searchDefaultArr"
                :search-warp-width="searchWarpWidth"
                :is-login="isLogin"
                :team-id="teamId"
                :ai-template-list="aiTemplateList"
                @setLogin="setLogregShow(1)"
                @reportDesigntrack="
                  aiDesigntrack('ad_content_click', 'dropList')
                "
              />
              <template v-if="historySearch.length !== 0">
                <div class="focusHotSearch_title">
                  <span>历史搜索</span>
                  <div class="icon" @mousedown.stop="clearHistorySearch">
                    <i></i>
                  </div>
                </div>
                <div class="historySearch">
                  <div
                    v-for="(item, index) in historySearch"
                    :key="index"
                    @click="historyClick(item)"
                    class="historySearchCon"
                  >
                    <span class="historySearchKey">{{ item.keyword }}</span>
                    <span
                      class="del-search"
                      @click.stop="clearHistorySearch(item)"
                    ></span>
                  </div>
                </div>
              </template>
              <div class="focusHotSearch_title">热门搜索</div>
              <div class="focusHotSearch_box">
                <div
                  v-for="(item, index) in hotSearch"
                  :key="index"
                  class="focusHotSearch_hotword"
                  @mousedown="searchTemplate(item.keyword, 'hotSearch')"
                >
                  <div class="content-keyword">
                    <span :class="['keyword-icon', `icon-${index}`]">
                      {{ index + 1 }}
                    </span>
                    {{ item.keyword }}
                  </div>
                  <!-- <span class="content-number"
                    >{{ item.templateCount || 6 }}结果</span
                  > -->
                </div>
              </div>
              <div class="focusHotSearch_title" v-if="senceItemList.length">
                <div class="senceSearch_title">按场景搜索</div>
                <div class="more-button" @click="handleSenceMoreClick()">
                  <span class="more-button-title">全部场景</span>
                  <span class="more-icon"></span>
                </div>
              </div>
              <div class="senceSearch_box" v-if="senceItemList.length">
                <div
                  v-for="(item, index) in senceItemList"
                  :key="index"
                  class="senceSearch_item"
                  @click="handleSenceListItemClick(item)"
                >
                  <div
                    class="content-keyword"
                    :title="
                      item.kindTitle && item.kindTitle.length > 7
                        ? item.kindTitle
                        : null
                    "
                  >
                    {{ senceSearchEllipsis(item.kindTitle) }}
                  </div>
                </div>
              </div>
              <div
                class="focusHotSearch_title"
                v-if="searchTuijianAdList.length"
              >
                精选推荐
              </div>
              <div
                class="recommendations_box"
                v-if="searchTuijianAdList.length"
              >
                <ScrollSlider
                  :listLength="searchTuijianAdList.length"
                  :rowLength="searchTuijianAdList.length"
                  :width="170"
                  :isResize="true"
                  :moreShow="false"
                  :isHeader="true"
                >
                  <div
                    v-for="(item, index) in searchTuijianAdList"
                    :key="index"
                    class="recommendations_item"
                    ref="header_recommendations"
                    :style="{ backgroundImage: `url(${item.picUrl})` }"
                    @click="handleRecommendationsClick(item, index)"
                  >
                  <img class="goto_recommendation" :src="item.picUrl"></img>
                  </div>
                </ScrollSlider>
              </div>
            </template>
            <!-- 联想搜索 -->
            <template v-else>
              <!-- 解决方案联想 -->
              <template v-if="solutionWordList.length">
                <div
                  class="focusHotSolutionKw_box"
                  :style="
                    associativeWordList.length == 0 &&
                    templateSenceList.length == 0 &&
                    associativeTopicList.length == 0
                      ? 'margin-bottom: 0;'
                      : 'margin-bottom: -14px;'
                  "
                >
                  <div
                    v-for="item in solutionWordList"
                    :key="item.keyword"
                    class="focusHotSolutionSearch_content"
                    @mousedown="searchTemplate(item.title, '', item.id, true)"
                  >
                    <div class="solution_title">
                      <img
                        :src="`https:${item.mobileBgImageUrl}`"
                        class="solution_img"
                        v-if="item.mobileBgImageUrl"
                      />
                      <div class="solution_cont">
                        <span class="content-keyword" v-html="item.htmlKeyword">
                        </span>
                        <span class="solution_desc">{{
                          item.description
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="associativeWordList.length">
                <div class="focusHotSearchKw_box">
                  <div
                    v-for="item in associativeWordList"
                    :key="item.keyword"
                    class="focusHotSearch_content"
                    @mousedown="searchTemplate(item.keyword, 'assoSearch')"
                  >
                    <span class="content-keyword" v-html="item.htmlKeyword">
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="templateSenceList.length">
                <div class="focusHotSearch_title">场景</div>
                <div
                  v-for="item in templateSenceList"
                  :key="item.id"
                  class="focusHotSearch_content"
                  @mousedown="
                    toSearchSence(
                      item.parentKindId,
                      item.id,
                      item.title,
                      item.kindType,
                    )
                  "
                >
                  <img
                    class="ai_icon"
                    v-if="item.kindType === 1"
                    src="./img/ai_icon.svg"
                  />
                  <span class="content-keyword" v-html="item.htmlKeyword">
                  </span>
                  <span class="size" v-if="item.kindType === 0"
                    >{{ item.width }} ×{{ item.height }}</span
                  >
                  <span class="more_ai" v-if="item.kindType === 1"
                    >一键生成</span
                  >
                </div>
              </template>
              <template v-if="associativeTopicList.length">
                <div class="focusHotSearch_title">专题</div>
                <div
                  v-for="item in associativeTopicList"
                  :key="item.id"
                  class="focusHotSearch_content"
                  @mousedown="toSolution({ id: item.id, keyword: item.title })"
                >
                  <span class="content-keyword">
                    <span class="spec-icon"></span>
                    <span v-html="item.htmlKeyword"></span>
                  </span>
                </div>
              </template>
            </template>
          </template>
          <!-- 选中素材搜索 -->
          <template v-else-if="searchModuleType === 1">
            <template v-if="meterialTopicList.length > 0 && searchValue !== ''">
              <div class="focusHotSearch_title">专题</div>
              <div
                v-for="item in meterialTopicList"
                :key="item.id"
                class="focusHotSearch_content"
                @mousedown="toMeterialSolution(item)"
              >
                <span class="content-keyword">
                  <span class="spec-icon"></span>
                  <span v-html="item.htmlKeyword"></span>
                </span>
              </div>
            </template>
            <template v-else>
              <div
                class="focusHotSearch_title"
                v-if="historySearch.length !== 0"
              >
                <span>历史搜索</span>
                <div class="icon" @mousedown.stop="clearHistorySearch">
                  <i></i>
                </div>
              </div>
              <div class="historySearch">
                <div
                  v-for="(item, index) in historySearch"
                  :key="index"
                  @click="historyClick(item)"
                  class="historySearchCon"
                >
                  <span class="historySearchKey">{{ item.keyword }}</span>
                  <span
                    class="del-search"
                    @click.stop="clearHistorySearch(item)"
                  ></span>
                </div>
              </div>
            </template>
          </template>
          <!-- 我的设计搜索 -->
          <template v-else>
            <!-- 存在我的设计或者创建了设计文件夹 且 初始化的时候出现历史记录 -->
            <template
              v-if="
                historySearch.length !== 0 &&
                searchDesignFolders.length === 0 &&
                searchDesiginList.length === 0 &&
                allDesignFolders.length !== 0 &&
                allDesignList.length !== 0
              "
            >
              <div class="focusHotSearch_title">
                <span>历史搜索</span>
                <div class="icon" @mousedown.stop="clearHistorySearch">
                  <i></i>
                </div>
              </div>
              <div class="historySearch">
                <div
                  v-for="(item, index) in historySearch"
                  :key="index"
                  @click="historyClick(item)"
                  class="historySearchCon"
                >
                  <span class="historySearchKey">{{ item.keyword }}</span>
                  <span
                    class="del-search"
                    @click.stop="clearHistorySearch(item)"
                  ></span>
                </div>
              </div>
            </template>
            <!-- 我的设计模块 -->
            <!-- 存在设计和文件夹 -->
            <div class="myDesignSearch_content">
              <template
                v-if="designFolders.length > 0 || designList.length > 0"
              >
                <template v-if="designList.length > 0">
                  <p class="myDesignSearch_content_title">设计</p>
                  <ul class="myDesignSearch_content_design">
                    <li
                      v-for="(item, index) in designList"
                      :key="index"
                      class="myDesignSearch_content_design_list"
                      @mousedown="goDesignPage(item.designId)"
                    >
                      <div class="img">
                        <img :src="item.imgUrl" alt="" />
                      </div>
                      <div class="content">
                        <div class="name" v-html="item.htmlKeyword"></div>

                        <div class="size">
                          {{ parseInt(item.design_width) }} ×
                          {{ parseInt(item.design_height) }} px
                        </div>
                      </div>
                    </li>
                  </ul>
                </template>
                <template v-if="designFolders.length > 0">
                  <p class="myDesignSearch_content_title">文件夹</p>
                  <ul class="myDesignSearch_content_design">
                    <li
                      v-for="(item, index) in designFolders"
                      :key="index"
                      class="myDesignSearch_content_design_list"
                      @mousedown="goDesignPage()"
                    >
                      <div class="img">
                        <img src="./img/folder.svg" alt="" />
                      </div>
                      <span class="name" v-html="item.htmlKeyword"></span>
                    </li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <div class="guide">
                  您还没有创建设计或文件夹，点击任意场景开始设计
                </div>
                <ul v-if="recommendedScenes.length" class="quick-scenes">
                  <li
                    v-for="(item, index) in recommendedScenes"
                    :key="index"
                    class="quick-scenes-item"
                    @click="goDesign(item)"
                  >
                    <div class="quick-scenes-item-name">{{ item.title }}</div>
                    <div class="quick-scenes-item-desc">
                      {{ item.width }} × {{ item.height }}
                    </div>
                  </li>
                </ul>
              </template>
            </div>
          </template>
        </div>
        <div class="padding20"></div>
        <img
          :src="activityBanner[0].picUrl"
          alt=""
          class="search-adver"
          v-if="activityEntryShow"
          @click="goAdverPage(activityBanner[0].activityJumpUrl)"
        />
      </div>
    </div>
    <CameraSearch v-model="cameraModalVisible" />
  </div>
</template>

<script>
  import Xss from 'xss';
  import Lottie from 'lottie-web';
  import { mapActions } from 'vuex';
  import routeConvert from './route-conversion';
  import RouterConversionIndustry from '@/utils/route-conversion-industry';
  import CameraSearch from './cameraSearch.vue';
  import AiDesignModule from './aiDesignModule.vue';
  import { mapGetters } from 'vuex';
  import ScrollSlider from './scrollSlider.vue';
  import pageNameEnum from '../chuangkit-components-logreg/pageNameEnum';
  import tracker from '@/utils/tracker';
  import { getKeywordMatchRule } from "@/api";

  const historySearchType = {
    template: 0,
    topic: 1,
  };

  const options = {
    whiteList: {
      span: ['class'],
    },
  };
  const MyXss = new Xss.FilterXSS(options);

  const solutionTypeMap = {
    0: 'color',
    1: 'style',
    2: 'use',
    3: 'industry',
  };

  export default {
    name: 'SearchBar',
    inject: ['app'],
    components: {
      CameraSearch,
      AiDesignModule,
      ScrollSlider,
    },
    data() {
      return {
        isShowSearch: false,
        isFocus: false,
        // 搜索关键词
        searchValue: '',
        // 热搜下拉列表
        focusHotShow: false,
        // 热搜词
        hotSearch: [],
        // 搜索历史
        historySearch: [],
        // 默认搜索关键词
        searchDefaultWord: '',
        // 一级分类搜索
        showFirstkindList: false,
        designType: [],
        firstkindId: 0,
        firstkindName: '全站',
        clearHistoryShow: false,
        cameraModalVisible: false,
        solutionId: '', // 解决方案id
        solutionWordList: [], // 解决方案联想列表
        associativeWordList: [], // 联想词列表
        associativeTopicList: [], // 联想专题列表
        templateSenceList: [], // 联想场景列表
        meterialTopicList: [], // 素材的专题列表
        searchModuleType: 0,
        searchModuleData: ['模板', '素材', '我的设计'],
        searchModuleClick: false,
        recommendedScenes: [],
        allDesignList: [],
        allDesignFolders: [],
        searchDesiginList: [],
        searchDesignFolders: [],
        activityEntryShow: false, // 搜索框配置广告位
        activityBanner: [],
        searchDefaultArr: [],
        timer: null, //轮播切换
        searchWordIndex: 0,
        searchWarpWidth: 0,
        aiDesignState: false, //是否开启智能设计
        showAiState: false, // 是否开下拉列表
        aiTemplateList: [], // AI下拉列表
        aiSubject: [], // AI下拉列表
        presetWords: '', // 预置词
        animationUrl: require('./searchIcon.json'),
        aiMatchWords: 3, //默认3个及以上关键词才会触发智能设计
        aiMatchWordsLength: 10, //默认10个及以上关键词才会触发智能设计
        senceItemList: [], //常用场景列表
        searchTuijianAdList: [], //精选推荐广告位
      };
    },
    props: {
      route: {
        type: Object,
      },
      isLogin: {
        type: Boolean,
      },
      width: {
        type: [Number, String],
        default: 580,
      },
      isHeader: {
        type: Boolean,
        default: true,
      },
      tagType: {
        type: [Number, String],
      },
      tagId: {
        type: [Number, String],
      },
      userInfo: {
        type: Object,
        default() {
          return {};
        },
      },
      isPoint: {
        type: Boolean,
        default: false,
      },
      from: {
        type: String,
      },
    },
    computed: {
      ...mapGetters({
        teamId: 'getTeamId',
        functionServiceList: 'getTeamFunctionServiceList',
      }),
      // 搜索框默认文字
      placeholder() {
        if (this.searchModuleType === 0) {
          return this.defaultSearchItem.name || '搜索你想要的模板';
        } else if (this.searchModuleType === 1) {
          return '搜索你想要的素材';
        } else {
          return '搜索我的设计';
        }
      },
      defaultSearchItem() {
        return this.searchDefaultArr[this.searchWordIndex] || {};
      },
      isPageSolution() {
        return this.$route.path === '/designtools/solution';
      },
      showAssociateView() {
        return (
          this.solutionWordList.length ||
          this.associativeWordList.length ||
          this.associativeTopicList.length ||
          this.templateSenceList.length
        );
      },
      designList() {
        return this.searchDesignFolders.length > 0 ||
          this.searchDesiginList.length > 0
          ? this.searchDesiginList
          : this.allDesignList;
      },
      designFolders() {
        return this.searchDesignFolders.length > 0 ||
          this.searchDesiginList.length > 0
          ? this.searchDesignFolders
          : this.allDesignFolders;
      },
      getBorderStyle() {
        return this.focusHotShow
          ? '1px solid rgba(7, 115, 252,1)'
          : '1px solid rgba(7, 115, 252, 0.5)';
      },
      getImageUrl() {
        return this.defaultSearchItem?.picUrl
          ? `url(https:${this.defaultSearchItem.picUrl})`
          : "url('./img/camera-icon-new.svg')";
      },
    },
    watch: {
      teamId() {
        this.getUserDesigns();
      },
      searchModuleType: {
        handler(v) {
          if (this.searchValue !== '') {
            this.associativeWordSearch(this.searchValue);
          }
          if (v === 0) {
            // 获取模版相关初始化 轮播热词数据、热门搜索数据
            // this.getBannerInfo(100)
            // this.getRecommendKeyword();
          } else if (v === 2) {
            // 获取我的设计初始化数据 推荐场景、我的设计、我的文件夹
            this.getRecommendedScenes();
            this.getUserDesigns();
          }
        },
        immediate: true,
      },
      route: {
        handler(v) {
          if (v.fullPath.includes('/designtools/selfSearch/searchResult')) {
            this.searchValue = v.query.k;
            this.searchModuleType = 2;
          }
        },
        immediate: true,
      },
      searchValue(v) {
        // this.getAIRecommendKeyword(v)
        this.associativeWordSearch(v);
      },
      errorScanModalVisible(bol) {
        if (!bol) {
          this.handleReScan();
        }
      },
      scanModalVisible(bol) {
        if (!bol) {
          if (this.pollQueryTimer) {
            clearInterval(this.pollQueryTimer);
          }
        }
      },
      focusHotShow(val) {
        try {
          if (val) {
            const params = {
              popup_name: '预搜索弹窗',
              page_name: '推荐页',
              is_login: this.isLogin ? '是' : '否',
              user_group: 'new-recommend',
              C_RGBCode: USER_INDENTI,
            };
            window.gio('track', 'popup_show', params);
          }
        } catch (error) {
          console.log('error', error);
        }
      },
      functionServiceList: {
        handler(v) {
          if (
            v.length > 0 &&
            !v.some(
              (item) => item.functionName === 'aidesign' && item.status === 2,
            )
          ) {
            // 获取AI智能设计
            this.getActiveBanner('web_search_inputbox_ad');
            // 获取AI下拉列表数据
            this.getActiveBanner('web_search_droplist_ad');
          } else {
            this.aiDesignState = false;
            this.searchDefaultArr = [];
          }
        },
        immediate: true,
      },
    },
    mounted() {
      // 获取广告位图片
      this.getActiveBanner('web_search_ads');
      //获取常用场景
      this.getSenceList();
      if (!this.isLogin) {
        // 获取AI智能设计
        this.getActiveBanner('web_search_inputbox_ad');
        // 获取AI下拉列表数据
        this.getActiveBanner('web_search_droplist_ad');
      }
      //获取热门搜索
      this.getHotSearch();
      //获取精选推荐广告位
      this.getActiveBanner('web_searchpop_tuijian');
      window.addEventListener('resize', this.handleResize);
      this.$nextTick(this.handleResize);
      this.loadLottie();
    },
    beforeDestroy() {
      if (this.pollQueryTimer) {
        clearInterval(this.pollQueryTimer);
      }
    },
    methods: {
      ...mapActions({
        setLogregShow: 'setLogregShow',
        setDesignTypeList: 'setDesignTypeList',
      }),
      clickOutSide() {
        this.focusHotShow = false;
      },
      handleResize() {
        // 使用$refs来获取元素的引用
        this.searchWarpWidth = this.$refs.searchWarp.clientWidth;
      },
      goDesignPage(designId = '') {
        if (designId) {
          window.location.href = `/odyssey/design?d=${designId}`;
          return;
        }
        window.location.href = `/designtools/self/design`;
      },
      getActiveBanner(alias) {
        try {
          this.$http
            .get('/hatchery/adposition', {
              data: {
                gw: true,
                requestMethod: 'GET',
                template: true,
                nottrans: true,
              },
              params: { platform: 0, alias, gw: true },
            })
            .then((res) => {
              if (res.body.code === 200 && alias == 'web_search_ads') {
                this.activityEntryShow =
                  typeof res.body.data !== 'undefined' && res.body.data.length;
                this.activityBanner = res.body.data;
              }
              if (res.body.code === 200 && alias == 'web_search_inputbox_ad') {
                if (
                  typeof res.body.data !== 'undefined' &&
                  res.body.data.length
                ) {
                  let newAdList = res.body.data;
                  newAdList.map((v) => {
                    const wordsList = v.description.split('&');
                    this.aiMatchWords = wordsList[1].split('=')[1];
                    this.aiMatchWordsLength = wordsList[2].split('=')[1];
                    v.name = wordsList[0];
                  });
                  this.searchDefaultArr = newAdList || [];
                  this.aiDesignState = true;
                  this.aiDesigntrack('ad_content_show');
                } else {
                  // 获取轮播词
                  this.getRecommendKeyword();
                }
              }
              if (res.body.code === 200 && alias === 'web_search_droplist_ad') {
                this.showAiState = !!(
                  res.body.data !== 'undefined' && res.body.data.length
                );
                if (this.showAiState) {
                  this.getAiTemplateResulut();
                }
              }
              if (res.body.code !== 200 && alias == 'web_search_inputbox_ad') {
                // 获取轮播词
                this.getRecommendKeyword();
              }
              if (res.body.code !== 200 && alias === 'web_search_inputbox_ad') {
                // 获取轮播词
                this.getRecommendKeyword();
              }
              if (res.body.code === 200 && alias == 'web_searchpop_tuijian') {
                //web_searchpop_tuijian广告位曝光埋点
                if (
                  typeof res.body.data !== 'undefined' &&
                  res.body.data.length
                ) {
                  this.searchTuijianAdList = res.body.data;
                  this.$nextTick(() => {
                    this.searchTuijianAdList.forEach((item, index) => {
                      const itemRef = this.$refs.header_recommendations;
                      this.recommendationsShowTrack(itemRef, item, index);
                    });
                  });
                }
              }
            });
        } catch (e) {
          console.error('error \n' + e);
        }
      },
      // lottie动画触发
      loadLottie() {
        this.animation = Lottie.loadAnimation({
          container: this.$refs.lavContainer,
          name: 'myAnimation',
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: this.animationUrl,
        });
      },
      getAiTemplateResulut() {
        this.$http
          .get('/tools/intelligence/design/preset/text/result', {
            data: {
              gw: true,
              requestMethod: 'GET',
              template: true,
              nottrans: true,
              nodo: true,
            },
            params: { gw: true },
          })
          .then((response) => {
            if (response.body.code === 200) {
              this.presetWords = response.body.data?.presetWords;
              this.aiTemplateList = response.body.data?.cacheList.splice(0, 6);
            }
          });
      },
      aiDesigntrack(trackName, type = '') {
        //智能设计广告位开启曝光埋点
        try {
          let ABtestInfo =
        (sessionStorage.getItem("burientInfoAb") &&
          JSON.parse(sessionStorage.getItem("burientInfoAb")))||{
            ABTest_id:'',
            ABTest_type:'',
          }
          const params = {
            ad_name: this.searchDefaultArr[0]?.name,
            ad_order: 1,
            adsense_name:
              type === '' ? 'web_搜索框_预置词广告' : 'Web搜案下拉框广告位',
            adsense_alias:
              type === '' ? 'web_search_inputbox_ad' : 'web_search_droplist_ad',
              ...ABtestInfo
          };
          window.gio('track', trackName, params);
        } catch (error) {
          console.log('error', error);
        }
      },
      goAdverPage(url) {
        if (url) window.location.href = url;
      },
      chooseSearchMoudle(index) {
        if (index === 2 && !this.isLogin) {
          this.setLogregShow(1);
          return;
        }
        this.searchModuleType = index;
        this.searchModuleClick = true;
      },
      historyClick(item) {
        if (
          item.type === historySearchType.template ||
          this.searchModuleType !== 0
        ) {
          this.searchTemplate(item.keyword, 'historySearch');
        } else {
          this.toSolution({ id: item.id, keyword: item.keyword });
        }
      },
      toSolution(item) {
        setHistory({ type: 1, keyword: item.keyword, id: item.id });
        try {
          const params = {
            popup_name: '预搜索弹窗',
            page_name: '推荐页',
            input_keyword: key,
            is_login: this.isLogin ? '是' : '否',
            user_group: 'new-recommend',
            C_RGBCode: USER_INDENTI,
          };
          window.gio('track', 'associa_content_click', params);
        } catch (error) {
          console.log('error', error);
        }
        //热搜词埋点
        const hotSearchWords = {
          search_type: this.searchModuleType == 0 ? '模板' : '素材',
          keywords: item.keyword,
          search_source: item.type === 1 ? '历史搜索' : '联想关键词搜索',
          target_page: '专题页',
          target_id: item.id,
          business_lines: 1,
          product_name: 1,
          platform: 1,
        };
        this.reportHotSearch(hotSearchWords);
        window.location.href = `/solutiontopics?id=${item.id}`;
      },
      toMeterialSolution(item) {
        setHistory({ type: 0, keyword: item.themeName, id: item.id });
        try {
          const params = {
            popup_name: '预搜索弹窗',
            page_name: '推荐页',
            input_keyword: key,
            is_login: this.isLogin ? '是' : '否',
            user_group: 'new-recommend',
            C_RGBCode: USER_INDENTI,
          };
          window.gio('track', 'associa_content_click', params);
        } catch (error) {
          console.log('error', error);
        }
        //热搜词埋点
        const hotSearchWords = {
          search_type: '素材',
          keywords: item.themeName,
          search_source: '联想关键词搜索',
          target_page: '专题页',
          target_id: item.id,
          business_lines: 1,
          product_name: 1,
          platform: 1,
        };
        this.reportHotSearch(hotSearchWords);
        //  copyrightType专题内容类型, 0素材  1内容
        window.location.href = `/${
          item.copyrightType === 0 ? 'material' : 'image'
        }/topic?id=${item.id}&title=${item.themeName}`;
      },
      clearInput() {
        this.searchValue = '';
        this.clearHistoryShow = true;
      },
      associativeWordSearch: debounce(async function (keyword) {
        try {
          var objExp = /(^\s*)|(\s*$)/g;
          keyword = keyword && keyword.replace(objExp, '');
          if (this.searchModuleType === 0 && keyword !== '') {
            this.getAssociateSolutionList(keyword), this.getSearchKind(keyword);
            const fields = ['associativeWordList', 'associativeTopicList'];
            const resFields = ['keyword', 'title'];
            if (!keyword) {
              for (let i = 0; i < fields.length; i++) {
                this[fields[i]] = [];
              }
              return;
            }
            const res = await Promise.all([
              this.getTemplateKwSuggest(keyword),
              this.getThemeKwSuggest(keyword),
            ]);
            // 1、专题名称后台设置了最多14个字，全显示出来就好了，正常标蓝
            // 2、关键词如果词超过10个字被……了 就不标蓝了
            for (let index = 0; index < fields.length; index++) {
              this[fields[index]] = res[index].body.data.map((item) => {
                item.htmlKeyword = MyXss.process(
                  index === historySearchType.template
                    ? ellipsis(item[resFields[index]], keyword)
                    : generateHtml(item[resFields[index]], keyword),
                );
                return item;
              });
            }
            if (this.solutionWordList && this[fields[0]]) {
              this[fields[0]].map((v, index) => {
                if (
                  v.keyword ==
                  (this.solutionWordList[index] &&
                    this.solutionWordList[index].title)
                ) {
                  this[fields[0]].splice(index, 1);
                }
              });
            }
          } else if (this.searchModuleType === 2) {
            this.getUserDesigns(keyword);
          } else if (this.searchModuleType === 1) {
            // 专题搜索联想。
            this.getMaterialTopic(keyword);
          }
        } catch (error) {
          console.log(error);
        }
        try {
          const params = {
            popup_name: '预搜索弹窗',
            page_name: '推荐页',
            input_keyword: keyword,
            is_login: this.isLogin ? '是' : '否',
            user_group: 'new-recommend',
            C_RGBCode: USER_INDENTI,
          };
          window.gio('track', 'input_seach_keyword', params);
        } catch (error) {
          console.log('error', error);
        }
      }, 300),
      getTemplateKwSuggest(keyword) {
        return this.$http.get('/team/templateInfo/getTemplateKwSuggest', {
          params: {
            gw: true,
            keyword,
          },
          data: {
            gw: true,
            requestMethod: 'GET',
            template: true,
            nottrans: true,
          },
        });
      },
      getThemeKwSuggest(keyword) {
        return this.$http.get('/team/templateInfo/getThemeKwSuggest', {
          params: {
            gw: true,
            keyword,
            pageNum: 5,
            solutionSubjectId: 1,
            type: 1,
            device: 1,
          },
          data: {
            gw: true,
            requestMethod: 'GET',
            template: true,
            nottrans: true,
          },
        });
      },
      focus() {
        this.focusHotShow = true;
        this.isFocus = true;
        this.historySearch = getHistory();
        this.associativeWordSearch(this.searchValue);
        this.searchWarpWidth = this.$refs.searchWarp.clientWidth;
        if (this.showAiState) {
          this.aiDesigntrack('ad_content_show', 'dropList');
        }
      },
      filterSearchValue() {
        this.searchValue = this.searchValue.replace(/%/gi, '');
      },
      // 获取推荐搜索关键字
      getRecommendKeyword() {
        this.$http
          .get('/contentcopyright/homepage/config/query', {
            params: {
              moduleCode: 'carouselWords',
            },
            data: { gw: true, requestMethod: 'GET' },
          })
          .then((response) => {
            const dataArr = response.body.data;
            this.searchDefaultArr = dataArr || [];
            this.timer = setInterval(() => {
              if (this.searchWordIndex === dataArr.length - 1) {
                this.searchWordIndex = 0;
              } else {
                this.searchWordIndex++;
              }
            }, 5000); // 每3秒切换一次
          });
      },
      //获取热门搜索
      getHotSearch() {
        let data = {
          gw: true,
          from: 0, // 请求来源（0:pc；1:小程序）
        };
        if (this.userInfo.userId) {
          data.userId = this.userInfo.userId;
        } else {
          data.userId = this.app.getCktUuid(10);
        }
        this.$http
          .post('/contentcopyright/template/aiGetRecommendKeywordV2', data)
          .then((response) => {
            this.hotSearch = response.body.data.recommendWord.slice(0, 6);
          });
      },
      beforeDestroy() {
        clearInterval(this.timer);
      },
      // 点击相机
      handleCameraClick() {
        this.cameraModalVisible = true;
      },
      toSearchSence(parentKindId, id, key, kindType) {
        // setHistory({ type: 0, keyword: key });
        // try {
        //   const params = {
        //     popup_name: '预搜索弹窗',
        //     page_name: '推荐页',
        //     input_keyword: key,
        //     is_login: this.isLogin ? '是' : '否',
        //     user_group: 'new-recommend',
        //     C_RGBCode: USER_INDENTI,
        //   };
        //   window.gio('track', 'associa_content_click', params);
        // } catch (error) {
        //   console.log('error', error);
        // }
        // const hotSearchWords = {
        //   search_type: '模板',
        //   keywords: key,
        //   target_page: '场景页',
        //   target_id: id,
        //   search_source: '联想关键词搜索',
        //   business_lines: 1,
        //   product_name: 1,
        //   platform: 1,
        // };
        // this.reportHotSearch(hotSearchWords);
        //智能场景
        if (kindType == 1) {
          const aiParams = {
            forward_module_name: "搜索场景联想",
            forward_page_name: "搜索下拉框",
            design_scene_id: id,
            design_id_name: key,
            design_scene_type: "智能设计",
            click_type: "前往智能设计详情页",
            is_login: this.isLogin ? "是" : "否",
            ...tracker.baseParams,
          };
          window.gio("track", "design_scene_click", aiParams);
          if (!this.isLogin) {
            this.setLogregShow(1);
            return;
          }
          return window.open(`/ai-design/${id}?source=web-bigsearch`);
        }
        window.location.href = `/sj-pi${parentKindId}-si${id}-or0-pt0-bt1-pn1.html`;
      },
      goAIDesign() {
        window.location.href = `/ai-design/session`;
      },
      // 搜索操作
      searchTemplate(
        key = this.searchValue,
        frontType = '',
        solutionId = 0,
        isGoSolution = false,
      ) {
        var objExp = /(^\s*)|(\s*$)/g;
        key = key.replace(objExp, '');
        let forward_module_name = '';
        // 选中素材搜索我的设计搜索
        if (this.searchModuleType === 1 || this.searchModuleType === 2) {
          this.searchMaterialOrMyDesign(key, frontType);
          return;
        }
        const query = {}
        query.keywords = key
        //智能设计匹配三个及以上关键词
        const regex = /(?:[\u4e00-\u9fa5\w]+(?:[,\s，]\s*|$)){3,}/g;
        const matches = key.match(regex) || key.length >= 10;
        if (matches && this.aiDesignState) {
          const aiDesignSearchWords = {
            search_type: '模板',
            keywords: key,
            target_page: '搜索结果页（含智能设计）',
            target_id: '无',
            search_source:
              frontType == 'search'
                ? '直接搜索'
                : frontType == 'assoSearch'
                ? '联想关键词搜索'
                : frontType == 'hotSearch'
                ? '热门搜索'
                : '历史搜索',
            business_lines: 1,
            product_name: 1,
            platform: 1,
          };
          // 智能设计场景生成埋点
          window.gio('track', 'ai_design_scene_generate', {
            forward_page_name: '搜索框',
            versions_type: '会话版',
            user_id: this.userInfo?.userId || '',
            user_vip: this.userInfo?.vipLevelName || '',
          });
          this.reportHotSearch(aiDesignSearchWords);
        }
        // 如果输入框内value为空 说明是搜索默认轮播次
        if (!key) {
          window.location.href =
            this.searchDefaultArr[this.searchWordIndex]?.jumpUrl ||
            `${location.origin}/templatecenter`;
          setHistory({
            type: 0,
            keyword: this.searchDefaultArr[this.searchWordIndex].name,
          });
        } else {
          setHistory({ type: 0, keyword: key });
          //不为空则是自定义搜索
          try {
            const params = {
              popup_name: '预搜索弹窗',
              page_name: '推荐页',
              input_keyword: key,
              is_login: this.isLogin ? '是' : '否',
              user_group: 'new-recommend',
              C_RGBCode: USER_INDENTI,
            };
            window.gio('track', 'associa_content_click', params);
          } catch (error) {
            console.log('error', error);
          }
          if (this.searchModuleType === 0 && !isGoSolution) {
            let query = {};
            query.keywords = key;
            let solutionData = this.getAssociateSolutionList(key, true);
            solutionData.then(async (response) => {
              const { code, data = {} } = response.body || {};
              if (frontType == 'search') {
                forward_module_name = '直接搜索';
              }
              if (frontType == 'assoSearch') {
                forward_module_name = '联想关键词搜索';
              }
              if (frontType == 'hotSearch') {
                forward_module_name = '热门搜索';
              }
              if (frontType == 'historySearch') {
                forward_module_name = '历史搜索';
              }
              sessionStorage.setItem(
                'solutionParam',
                JSON.stringify({ forward_module_name }),
              );
              if (code === 200) {
                if (data.parentSolutionId > 0) {
                  //热搜词埋点
                  const hotSearchWords = {
                    search_type: '模板',
                    keywords: key,
                    target_page: '解决方案页',
                    target_id: data.solutionId,
                    search_source:
                      frontType == 'search'
                        ? '直接搜索'
                        : frontType == 'assoSearch'
                        ? '联想关键词搜索'
                        : frontType == 'hotSearch'
                        ? '热门搜索'
                        : '历史搜索',
                    business_lines: 1,
                    product_name: 1,
                    platform: 1,
                  };
                  this.reportHotSearch(hotSearchWords);
                  window.location.href = `/solution/${data.parentSolutionId}/f${data.solutionId}-p1`;
                } else if (data.parentSolutionId == 0) {
                  //热搜词埋点
                  const hotSearchWords = {
                    search_type: '模板',
                    keywords: key,
                    target_page: '解决方案页',
                    target_id: data.solutionId,
                    search_source:
                      frontType == 'search'
                        ? '直接搜索'
                        : frontType == 'assoSearch'
                        ? '联想关键词搜索'
                        : frontType == 'hotSearch'
                        ? '热门搜索'
                        : '历史搜索',
                    business_lines: 1,
                    product_name: 1,
                    platform: 1,
                  };
                  this.reportHotSearch(hotSearchWords);
                  window.location.href = `/solution/${data.solutionId}`;
                } else {
                  //热搜词埋点
                  const hotSearchWords = {
                    search_type: '模板',
                    keywords: key,
                    target_page: '搜索结果页',
                    target_id: '无',
                    search_source:
                      frontType == 'search'
                        ? '直接搜索'
                        : frontType == 'assoSearch'
                        ? '联想关键词搜索'
                        : frontType == 'hotSearch'
                        ? '热门搜索'
                        : '历史搜索',
                    business_lines: 1,
                    product_name: 1,
                    platform: 1,
                  };
                  this.reportHotSearch(hotSearchWords);
                  // 分词逻辑
                  const isAnalySuccess = await this.getAnalysisResult(query)
                  if (isAnalySuccess) return
                  this.goToWeb(query);
                }
              }
            });
            return;
          }
        }

        if (solutionId && frontType == '') {
          try {
            const params = {
              popup_name: '预搜索弹窗',
              page_name: '推荐页',
              input_keyword: key,
              is_login: this.isLogin ? '是' : '否',
              user_group: 'new-recommend',
              C_RGBCode: USER_INDENTI,
            };
            window.gio('track', 'associa_content_click', params);
          } catch (error) {
            console.log('error', error);
          }
          //热搜词埋点
          const hotSearchWords = {
            search_type: '模板',
            keywords: key,
            target_page: '解决方案页',
            target_id: solutionId,
            search_source: '联想关键词搜索',
            business_lines: 1,
            product_name: 1,
            platform: 1,
          };
          this.reportHotSearch(hotSearchWords);
          sessionStorage.setItem(
            'solutionParam',
            JSON.stringify({ forward_module_name: '解决方案页联想搜索' }),
          );
          window.location.href = `/solution/${solutionId}`;
          return;
        }
        this.goToWeb(query);
      },
      getAnalysisResult(query){
        return new Promise((resolve,reject) => {
          getKeywordMatchRule({ keyword: query.keywords })
            .then((response) => {
              const data = response.body?.data;
              const code = response.body.code;
              if (code === 200 && data) {
                const href = `sj${data.firstKindId ? '-pi' + data.firstKindId : ''}${data.secondKindId ? '-si' + data.secondKindId : ''}-or0-pt0-bt1${
                  query.keywords ? '-ke' +  query.keywords : ''
              }-pn1.html`
                const params = {
                  firstKindId: data.firstKindId? data.firstKindId: -1,
                  secondKindId: data.secondKindId? data.secondKindId: -1,
                }; 
                localStorage.setItem('mathWordData',JSON.stringify(params))
                document.cookie = `mathWordData=${encodeURIComponent(JSON.stringify(params))}`;
                if (data.firstKindId || data.secondKindId){
                  window.location.href = `${location.origin}/${href}`
                  return
                }
                resolve(false); // 没有返回分类id和场景id
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              console.error("获取关键词匹配规则时出错：", error);
              reject(error); // 将错误传递出去
            });
      });
      },
      goToWeb(query) {
        const _route = new routeConvert(this);
        const obj = _route.parse();
        query.secondKindId = obj && obj.secondKindId;
        query.parentKindId = obj && obj.parentKindId;
        if (this.firstkindId > 0) {
          query.parentKindId = this.firstkindId;
        }
        if (this.firstkindId === 0 || this.firstkindId === 99) {
          query.parentKindId = undefined;
        }
        if (this.isPageSolution) {
          query.solutionKeywordId = this.$route.query.id;
          query[solutionTypeMap[this.tagType]] = this.tagId;
        }
        sessionStorage.setItem('parentName', this.firstkindName);
        sessionStorage.setItem('searchShowQuery', JSON.stringify(query));
        sessionStorage.setItem('firstSearchState', '1');
        // 移除搜索下您是不是想找数据
        sessionStorage.removeItem('recommendsInfo');
        try {
          window.gio('track', 'recommendpage_click', {
            click_position: '工具首页_banner模块',
            click_content: query.keywords,
            user_group: 'new-recommend',
            C_RGBCode: USER_INDENTI,
          });
        } catch (error) {
          console.error('gio error \n' + error);
        }
        if (this.isPageSolution) {
          let route = '';
          const _route = new RouterConversionIndustry(this);
          route =
            location.origin +
            `/industry/search/${this.$route.query.id}/` +
            _route.compute({
              pageNo: 1,
              keywords: key,
            });
          location.href = route;
        } else {
          location.href =
            location.origin + '/templatecenter/search/' + query.keywords;
          // _route.set('/sj', query)
        }
        setTimeout(() => {
          // this.clearHistoryShow = false
          this.focusHotShow = false;
          // this.$refs.input.blur()
        }, 100);
      },
      selectFirstkind(item) {
        this.firstkindName = item.pKindName;
        this.firstkindId = item.firstKindId;
      },
      // 收起一级分类搜索列表
      closeList() {
        this.showFirstkindList = false;
      },
      clearHistorySearch(item) {
        try {
          if (item.keyword) {
            const arr = JSON.parse(
              window.localStorage.getItem('historySearch'),
            );
            const newList = arr.filter((cur) => cur.keyword !== item.keyword);
            this.historySearch = newList;
            window.localStorage.setItem(
              'historySearch',
              JSON.stringify(newList),
            );
          } else {
            this.clearHistoryShow = true;
            this.historySearch = [];
            window.localStorage.removeItem('historySearch');
          }
        } catch (e) {
          this.clearHistoryShow = false;
          console.log(e);
        }
      },
      // 选中素材 我的设计 点击回车 或者搜索按钮
      searchMaterialOrMyDesign(key, frontType) {
        if (!key) return;
        // this.$router.push(
        //   `/${this.materialStationType}/relevant?k=${this.searchValue}`
        // )
        setHistory({ type: 0, keyword: key });
        this.$emit('set-search-keywords', key);
        if (this.searchModuleType === 1) {
          //热搜词埋点
          const hotSearchWords = {
            search_type: '素材',
            keywords: key,
            search_source:
              frontType == 'search'
                ? '直接搜索'
                : frontType == 'assoSearch'
                ? '联想关键词搜索'
                : '历史搜索',
            target_page: '搜索结果页',
            target_id: '无',
            business_lines: 1,
            product_name: 1,
            platform: 1,
          };
          this.reportHotSearch(hotSearchWords);
          window.location.href = `/image/relevant?k=${key}`;
        } else {
          window.location.href = `/designtools/selfSearch/searchResult?k=${key}`;
        }
        // this.clearHistoryShow = false
        setTimeout(() => {
          // this.clearHistoryShow = false
          this.focusHotShow = false;
          // this.$refs.input.blur()
        }, 100);
        // this.$refs.input.blur()
      },
      //热搜词上报埋点
      reportHotSearch(params) {
        try {
          window.gio('track', 'keywords', params);
          const newParam = {
            event: 'keywords',
            event_name: '搜索关键词',
            params,
            trigger_timing: '搜索时打点（前端打点）',
          };
          window.cktTracker.report(newParam);
        } catch (error) {
          console.log('error', error);
        }
      },
      // 获取推荐场景
      getRecommendedScenes() {
        try {
          this.$http
            .post('/team/distribution/template/listSecondScenes', { gw: true })
            .then((response) => {
              const { code, data = [] } = response.body || {};
              if (code === 200) {
                this.recommendedScenes = data;
              }
            });
        } catch (error) {
          console.error(error);
        }
      },
      //（暂时先接解决方案，其他先不聚合）
      getAssociateSolutionList(search = '', isReturnAll) {
        if (isReturnAll) {
          return this.$http.get(
            '/team/distribution/solution/getAssociateSolutionList',
            {
              data: {
                requestMethod: 'GET',
                template: true,
                nottrans: true,
                gw: true,
              },
              params: {
                gw: true,
                keyword: search,
                limit: 5,
              },
            },
          );
        } else {
          if (search === '') return;
          try {
            this.$http
              .get('/team/distribution/solution/getAssociateSolutionList', {
                data: {
                  requestMethod: 'GET',
                  template: true,
                  nottrans: true,
                  gw: true,
                },
                params: {
                  gw: true,
                  keyword: search,
                  limit: 5,
                },
              })
              .then((response) => {
                const { code, data = {} } = response.body || {};
                if (code === 200) {
                  this.solutionWordList =
                    data.solutions.length > 0 &&
                    data.solutions.map((item) => {
                      item.htmlKeyword = generateHtml(
                        item.title || '未命名',
                        search,
                      );
                      return item;
                    });
                }
              });
          } catch (error) {
            this.solutionWordList = [];
            console.error(error);
          }
        }
      },
      // 获取模版的搜索场景联想
      getSearchKind(search = '') {
        if (search === '') return;
        try {
          this.$http
            .post('/team/distribution/template/getSecondScenes', {
              gw: true,
              keyword: search,
            })
            .then((response) => {
              const { code, data = [] } = response.body || {};
              if (code === 200) {
                this.templateSenceList = data.slice(0, 5).map((item) => {
                  item.htmlKeyword = generateHtml(
                    item.title || '未命名',
                    search,
                  );
                  return item;
                });
              }
            });
        } catch (error) {
          this.templateSenceList = [];
          console.error(error);
        }
      },
      // 获取搜索素材专题联想
      getMaterialTopic(search = '') {
        if (search === '') return;
        try {
          this.$http
            .post('/team/materialInfo/searchThemes', {
              gw: true,
              keyword: search,
              limit: 5,
            })
            .then((response) => {
              const { code, data = [] } = response.body || {};
              if (code === 200) {
                this.meterialTopicList = data.map((item) => {
                  item.htmlKeyword = generateHtml(
                    item.themeName || '未命名',
                    search,
                  );
                  return item;
                });
              }
            });
        } catch (error) {
          this.meterialTopicList = [];
          console.error(error);
        }
      },
      // 获取我的设计
      getUserDesigns(search = '') {
        const isFuseNewTeam = this.getIsFuseNewTeam(this.teamId);
        isFuseNewTeam.then(resolve => {
        if (resolve) {
          this.$http
            .post("/dam/store/listStoreSimpleData", { gw: true, type: 2 })
            .then(res => {
              if (res.body.code === 200) {
                const storeId = res.body.data?.[0]?.storeId || 0;
                const data = {
                  pageNo: 1,
                  pageSize: 3,
                  storeId,
                  resourceTypeList: [0],
                  keyWord:search,
                  sortType: 1,
                  useState: 1,
                  nottrans: true 
                };
                this.$http
                  .post(
                    "/dam/store/resource/listPersonalStore",
                    { gw: true, ...data },
                  )
                  .then(res => {
                    const code = res.body.code;
                    if (code === 200) {
                      const newList = res.body.data.result;
                      newList.map(v => {
                        v.coverUrl = v.thumbFilePath;
                        v.imgUrl = v.thumbFilePath;
                        v.design_width = v.detail?.width;
                        v.design_height = v.detail?.height;
                        v.kindId = v.detail?.kindId;
                        v.designId = v.detail?.designId;
                        v.designTitle = v.name;
                        v.htmlKeyword = generateHtml(
                          v.name || "未命名",
                          search,
                        );
                        v.designType =
                          v.detail?.kindId === -88 || v.detail?.kindId === -1
                            ? 2
                            : 1;
                        v.designPageCount = v.detail?.designPageCount;
                        v.isWxSmallAppShow = v.detail?.isWxSmallAppShow;
                        v.designPublishPaperwork =
                          v.detail?.designPublishPaperwork;
                        v.designPublishState =
                          v.detail?.contentAttachId === -1 ? 1 : 0;
                        v.updateTime = v.detail?.updateTime;
                        v.hideOption = true;
                        v.thumbnailsState = 2;
                        v.lastThumbTimeV2 = new Date().getTime();
                      });
                      this.searchDesiginList = newList;
                    } else {
                      console.warn(`数据查询失败，错误码：${code}`);
                    }
                  });
              }
            });
        } else {
          const params = {
          folder_id: '',
          page_no: 1,
          page_size: 3,
          tid: this.teamId,
          apidomainv2: true,
          search,
        };
        this.$http.post('/design/queryUserDesigns', params).then((res) => {
          const code = res.body.code;
          if (code === 200) {
            let designList = res.body.data.list.map((item) => {
              item.htmlKeyword = generateHtml(
                item.designTitle || '未命名',
                search,
              );
              return item;
            });
            if (search === '') {
              this.searchDesiginList = [];
              this.allDesignList = designList;
            } else {
              this.searchDesiginList = designList;
            }
            this.getUserFolders(search);
          } else {
            console.warn(`数据查询失败，错误码：${code}`);
          }
        });
        } });
      },
      async getIsFuseNewTeam(id) {
        try {
          const {
            body: { code, data },
          } = await this.$http.post("/team/fuse/team/isFuseNewTeam", {
            gw: true,
            teamId: id,
          });
          if (code === 200) {
            return Promise.resolve(data);
          }
        } catch (error) {
          console.log(error)
        }
      },
      // 获取创建文件夹
      getUserFolders(search = '') {
        const params = {
          type: 0,
          team_id: this.teamId,
          check_team: 0,
          apidomainv2: true,
          search,
        };
        this.$http.post('/design/folder/getUserFolders', params).then((res) => {
          const code = res.body.code;
          if (code === 200) {
            let designFolders = res.body.data.splice(0, 2).map((item) => {
              item.htmlKeyword = generateHtml(item.folderName, search);
              return item;
            });
            if (search === '') {
              this.searchDesignFolders = [];
              this.allDesignFolders = designFolders;
            } else {
              this.searchDesignFolders = designFolders;
            }
          } else {
            console.warn(`数据查询失败，错误码：${code}`);
          }
        });
      },
      //获取常用场景
      async getSenceList() {
        try {
          this.$http
            .post('/team/distribution/template/listFirstKinds', { gw: true })
            .then((res) => {
              const code = res.body.code;
              if (code === 200) {
                const data = res.body.data;
                if (Array.isArray(data)) {
                  this.senceItemList = data[0].designKind.slice(0, 20);
                }
              } else {
                console.warn(`数据查询失败，错误码：${code}`);
              }
            });
        } catch (error) {
          console.log(error);
        }
      },
      handleSenceListItemClick(item) {
        let parentKindId =
          item.tempCenterFirstKindId ||
          item.pKindId ||
          item.pkindId ||
          item.firstKindId;
        let kindId = item.kindId || design_kind.kindId;
        try {
          window.gio('track', 'design_scene_click', {
            forward_module_name: '搜索下拉框_按场景搜索',
            forward_page_name: pageNameEnum[this.$route.path] || '模版中心',
            design_scene_id: item.firstKindId,
            design_id_name: item.kindTitle,
            design_scene_type: '平台场景',
            click_type: '搜索模板/浏览模板',
            is_login: this.isLogin ? '是' : '否',
          });
        } catch (error) {
          console.log('error', error);
        }
        let href = `sj-pi${parentKindId}-si${kindId}-or0-pt0-bt1${
          this.searchValue ? '-ke' + this.searchValue : ''
        }-pn1.html`;
        window.location.href = `${location.origin}/${href}`;
      },
      handleSenceMoreClick() {
        let url = 'templatecenter';
        if (this.searchValue) {
          url = `sj-or0-pt0-bt1-ke${this.searchValue}-pn1.html`;
        }
        window.location.href = `${location.origin}/${url}`;
      },
      //精选推荐广告位点击
      handleRecommendationsClick(item, index) {
        try {
          const params = {
            forward_page_name: pageNameEnum[this.$route.path] || '模版中心',
            forward_module_name: `搜索下拉框_精选推荐_${item.name}`,
            ad_name: item.name,
            ad_alias: item.alias,
            ad_order: index + 1,
            ads_type: null,
            ad_id: null,
            adsense_id: null,
            adsense_name: '精选推荐广告位',
            adsense_alias: 'web_searchpop_tuijian',
            is_login: this.isLogin,
            target_page_url: item.jumpUrl,
          };
          window.gio('track', 'ad_content_click', params);
          if (!item.jumpUrl) {
            return;
          }
          if (item.needLogin && !this.isLogin) {
            this.$message('请先登录');
            sessionStorage.setItem(
              'forward',
              JSON.stringify({
                forward_module_name: `搜索下拉框_精选推荐_${item.name}`,
                forward_page_name: pageNameEnum[this.$route.path] || '模版中心',
              }),
            );
            this.setLogregShow(1);
            return;
          }
          window.open(item.jumpUrl);
        } catch (error) {
          console.log('error', error);
        }
      },
      recommendationsShowTrack(ref, item, index) {
        try {
          const callback = (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting && entry.intersectionRatio) {
              let ABtestInfo =
        (sessionStorage.getItem("burientInfoAb") &&
          JSON.parse(sessionStorage.getItem("burientInfoAb")))||{
            ABTest_id:'',
            ABTest_type:'',
          }
                const params = {
                  forward_page_name:
                    pageNameEnum[this.$route.path] || '模版中心',
                  forward_module_name: `搜索下拉框_精选推荐_${item.name}`,
                  ad_name: item.name,
                  ad_alias: item.alias,
                  ad_order: index + 1,
                  ads_type: null,
                  ad_id: null,
                  adsense_id: null,
                  adsense_name: '精选推荐广告位',
                  adsense_alias: 'web_searchpop_tuijian',
                  is_login: this.isLogin,
                  target_page_url: item.jumpUrl,
                  ...ABtestInfo
                };
                window.gio('track', 'ad_content_show', params);
              }
            });
          };
          const observer = new IntersectionObserver(callback, {
            root: document.querySelector('.recommendations_box'),
            threshold: [0.1],
          });
          observer.observe(ref[index]);
        } catch (error) {
          console.log('error', error);
        }
      },
      senceSearchEllipsis(name){
        if(name&&name.length>7){
          return name.slice(0,7)+'...'
        }
        return name
      },
    },
  };
  function setHistory(item) {
    let history = getHistory();
    for (let i = 0; i < history.length; i++) {
      if (history[i].keyword === item.keyword) return;
    }
    if (history.length >= 6) {
      history = history.slice(0, 5);
    }
    history.unshift(item);
    window.localStorage.setItem('historySearch', JSON.stringify(history));
  }

  function getHistory() {
    const historySearch = window.localStorage.getItem('historySearch');
    try {
      return JSON.parse(historySearch) || [];
    } catch (error) {
      window.localStorage.setItem('historySearch', JSON.stringify([]));
      return [];
    }
  }
  function debounce(fn, wait, immediate) {
    let timer;
    return function () {
      if (timer) clearTimeout(timer);
      if (immediate) {
        // 如果已经执行过，不再执行
        const callNow = !timer;
        timer = setTimeout(() => {
          timer = null;
        }, wait);
        if (callNow) {
          fn.apply(this, arguments);
        }
      } else {
        timer = setTimeout(() => {
          fn.apply(this, arguments);
        }, wait);
      }
    };
  }

  function ellipsis(result, keyword) {
    if (result.length > 10) {
      result = result.slice(0, 10) + '...';
    }
    return generateHtml(result, keyword);
  }

  function generateHtml(result, keyword) {
    return result.replace(keyword, `<span class="colorful">${keyword}</span>`);
  }
</script>

<style lang="less" scoped>
  @import './searchBar.less';
</style>
