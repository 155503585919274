<template>
  <div class="page-wrap">
    <h1>仅需两步，就可获得更精准的模板推荐</h1>
    <p class="quest">您的{{progress==1?'行业':'职业'}}是?</p>
    <div class="answer">{{progress == 2?industrySelect.industryName:''}}</div>
    <!-- 第一步选择用户行业 -->
    <template v-if="progress == 1">
      <div class="industry-container">
        <div class="industry" v-for="(item, index) in industries" :key="index">
          <div class="industry-item" :style="`background-position: ${item.icon}`" :class="{active: industrySelectIndex === index}" @click="select(item, index)">{{item.industryName}}</div>
          <div v-if="industrySelect.industryName == '其他' && item.industryName == '其他'" class="userInput">
            <input type="text" ref="industryInput" maxlength="20" v-model="industryInput" placeholder='请补充（选填）'/>
          </div>
        </div>
      </div>
      <a @click="chooseJob(2)" class="progress-btn" :class="{' last-step': !industrySelect.industryName}">下一步</a>
    </template>
    <!-- 第二步选择用户职业 -->
    <template v-if="progress == 2">
      <div class="industry-container">
        <div class="industry" style="width:20%" v-for="(item, index) in showJobs" :key="index">
          <div class="industry-item job-item" :style="`background-position: ${item.icon}`" :class="{active: jobSelectIndex === index}" @click="select(item, index, false)">{{item.JobName}}</div>
          <div v-if="jobSelect.JobName == '其他' && item.JobName == '其他'" class="userInput">
            <input type="text" ref="jobInput" v-model="jobInput" maxlength="20" placeholder='请补充（选填）'/>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <a @click="chooseJob(1)" class="progress-btn last-step">上一步</a>
        <a @click="chooseJob(3)" class="progress-btn" :class="{' last-step': !jobSelect.JobName}">下一步</a>
      </div>
    </template>
    <div class="submit-success" v-if="showSuccess">
      <img src="./img/job-success.png" />
      <p class="title">提交成功!</p>
      <p class="info">创客贴将为您提供更精确的内容推荐。</p>
      <a @click="closeModal" class="progress-btn">关闭</a>
    </div>
    <!-- 插图 -->
    <template v-if="progress == 1">
      <img class="ill illustration1" src="./img/onboarding_guide1.png" />
      <img class="ill illustration2" src="./img/onboarding_guide2.png" />
    </template>
    <template v-else>
      <img class="ill illustration3" src="./img/onboarding_guide3.png" />
      <img class="ill illustration4" src="./img/onboarding_guide4.png" />
    </template>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {jobs, industries} from './comp/data.js';
  export default {
    data () {
      return {
        JobIndex: 0,
        progress: 1,
        industrySelect: {},
        industrySelectIndex: '',
        industryInput: '',
        jobSelect: {},
        jobSelectIndex: '',
        jobInput: '',
        jobs,
        showJobs: [],
        industries,
        showSuccess: false,
        diySizeShow: false
      }
    },
    methods: {
      ...mapActions({
        setLogregShow: 'setLogregShow'
      }),
      // 行业职业选择
      select(val, index, isJob=true) {
        if (isJob) {
          this.industrySelectIndex = index
          this.industrySelect = val
          this.$nextTick(() => {
            if (this.$refs.industryInput[0]) {
              this.$refs.industryInput[0].focus()
            }
          })
        } else {
          this.jobSelectIndex = index
          this.jobSelect = val
          this.$nextTick(() => {
            if (this.$refs.jobInput[0]) {
              this.$refs.jobInput[0].focus()
            }
          })
        }
      },
      // 点击跳转上一步，下一步
      chooseJob(val) {
        // 1：获取行业，2：获取职业，3：场景推荐
        switch (val) {
          case 1:
            this.progress = val
            break;
          case 2:
            if (this.industrySelect.industryName) {
              this.progress = val
              this.jobSelectIndex = ''
              this.showJobs = []
              // 筛选并排序行业下对应的职业
              let jobsMap = {}
              this.showJobs = []
              this.jobs.map((item) => {
                if (this.industrySelect.jobIds.indexOf(item.jobId) >= 0) {
                  jobsMap[item.jobId] = item
                }
              })
              const order = this.industrySelect.jobIds
              for (let i=0; i<order.length;i++) {
                this.showJobs.push(jobsMap[order[i]])
              }
              this.jobSelect = {}
            } else {
              this.$emit('promptShow', { promptText: '请选择行业标签', promptKind: 'error' })
            }
            break;
          case 3:
            if (this.jobSelect.JobName) {
              // this.progress = val
              this.addInfo()
            } else {
              this.$emit('promptShow', { promptText: '请选择职业标签', promptKind: 'error' })
            }
            break;
        }
      },
      // 将行业职业信息添加至用户信息中
      addInfo() {
        if (this.industrySelect.industryName !== '其他') {
          this.industryInput = ''
        }
        if (this.jobSelect.JobName !== '其他') {
          this.jobInput = ''
        }
        const params = {
          trade_profession: this.industrySelect.industryName,
          profession: this.jobSelect.JobName,
          other_trade: this.industryInput,
          other_profession: this.jobInput,
          gw: true
        }
        this.$http.post('/accountcenter/user/insertUserLabel', params).then((res) => {
          // 200:成功;未登录：-1;参数错误：-2
          switch (res.body.code) {
            case 200:
              // GrowingIO 统计
              try {
                gio('app.set', 'user_industry', this.industrySelect.industryName)
                gio('app.set', 'user_occupation', this.jobSelect.JobName)
              } catch (err) {
                throw err
              }
              this.showSuccess = true
              this.$store.commit('setJobSubmitState', true)
              break
            case -1:
              this.login()
              break
            case -2:
              this.$emit('promptShow', { promptText: '请稍候再试', promptKind: 'error' })
              break
          }
        })
      },
      // 登录
      login(v) {
        if (!v){
          this.$emit('getUserInfo')
          this.$emit('promptShow', { promptText: '请先登录', promptKind: 'error' })
          this.setLogregShow(1)
        }
      },
      // 自定义尺寸
      diySize () {
        this.diySizeShow = true
      },
      // 获得全部场景
      getScenes() {
        this.$http.post('/contentcopyright/designkind/getOccupationRecommendedKindInfo', {gw: true}).then(res => {
          let data = res.body.data
          this.jobs = data.jobs
          this.industries = data.industries
        })
      },
      closeModal() {
        this.$emit('closeModal')
      }
    },
    created() {
      this.getScenes()
    }
  }
</script>

<style lang="less" scoped>
  .page-wrap {
    width: 100%;
    height: 100%;
    padding-top: 42px;
    background: #f6f6f6;
    position: relative;
    overflow: hidden;
    h1 {
      text-align: center;
      font-size: 24px;
      line-height: 30px;
      animation: gridItem .5s .1s backwards;
      color: #333;
    }
    .quest{
      font-size: 18px;
      color: #333333;
      text-align: center;
      line-height: 18px;
      margin-top: 18px;
    }
    .answer{
      line-height: 56px;
      height: 56px;
      font-size: 16px;
      color: #666666;
      text-align: center;
    }
    .progress-btn{
      background: #20A4FF;
      border-radius: 4px;
      color: #FAFAFA;
      line-height: 27px;
      width: 80px;
      display: block;
      margin: 0 auto;
      text-align: center;
      box-sizing: border-box;
      animation: gridItem .5s  backwards;
      &:hover {
        transform: scale(1.05);
        opacity: .9;
      }
    }
    .last-step{
      border: 1px solid #666;
      background: #f6f6f6;
      color: #666
    }
    .btn-container{
      width: 348px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto
    }
    .industry-container{
      width: 100%;
      margin: 0 auto 32px;
      padding: 0 56px;
      overflow: hidden;
      position: relative;
      z-index: 100;
      .industry{
        width: 16.66666667%;
        float: left;
        text-align: center;
        position: relative;
        .industry-item{
          font-size: 14px;
          color: #666666;
          line-height: 16px;
          display: inline-block;
          width: 94px;
          height: 94px;
          border-radius: 4px;
          background-color: #fff;
          margin-bottom: 18px;
          padding: 60px 0px 16px;
          box-sizing: border-box;
          cursor: pointer;
          transition: all 200ms ease;
          border: 1px solid transparent;
          background: url('./img/guide-industries.png') #fff no-repeat;
          &.job-item{
            background: url('./img/guide-jobs.png') #fff no-repeat;
            &.active{
              background:  url('./img/guide-jobs-hover.png') #20A4FF no-repeat;
            }
          }
          &:hover{
            border-color: #20A4FF;
          }
          &.active{
            background:  url('./img/guide-industries-hover.png') #20A4FF no-repeat;
            color: #fff;
            border-color: #20A4FF;
          }
        }
        .userInput{
          position: absolute;
          bottom: 16px;
          right: 0;
          transform: translateX(106%);
          font-size: 12px;
          color: #666;
          width: 125px;
          height: 30px;
          border-bottom: 1px solid #929292;
          box-sizing: border-box;
          transition: all 300ms ease;
          /* &:focus, &:hover{
            border-bottom: 2px solid #20a4ff;
          } */
          input{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border: none;
            outline: none;
            height: 30px;
            line-height: 30px;
            background-color: transparent;
            &::-webkit-input-placeholder{
              color: #666;
              font-size: 12px;
            }
          }
        }
      }
    }
    @keyframes gridItem {
      0% {
        opacity: 0;
        margin-top: -20px;
      }
      100% {
        opacity: 1;
        margin-top: 0;
      }
    }
    @keyframes ill1 {
      0% {transform: translateY(100%)}
      100% {transform: translateY(0)}
    }
    @keyframes ill2 {
      0% {transform: translate(100%,100%)}
      100% {transform: translate(0,0)}
    }
    @keyframes ill3 {
      0% {transform: translateY(100%)}
      100% {transform: translateY(0)}
    }
    @keyframes ill4 {
      0% {transform: translate(100%,100%)}
      100% {transform: translate(0,0)}
    }
    @keyframes ill5 {
      0% {transform: translateY(100%)}
      100% {transform: translateY(0)}
    }
    @keyframes ill6 {
      0% {transform: translate(100%,100%)}
      100% {transform: translate(0,0)}
    }
    .ill{
      position: absolute;
      bottom: 0;
      z-index: 1;
      animation-duration: 800ms;
      animation-fill-mode: forwards;
      &.illustration1, &.illustration3, &.illustration5{
        left: 4px;
        transform: translateY(100%);
      }
      &.illustration2, &.illustration4, &.illustration6{
        right: 12px;
        transform: translate(100%,100%);
      }
      &.illustration1{
        animation-name: ill1;
        width: 60px;
      }
      &.illustration2{
        animation-name: ill2;
        width: 106px;
      }
      &.illustration3{
        animation-name: ill3;
        width: 70px;
      }
      &.illustration4{
        animation-name: ill4;
        width: 105px;
      }
      &.illustration5{
        animation-name: ill5;
        animation-delay: 1s;
      }
      &.illustration6{
        animation-name: ill6;
        animation-delay: 1s;
      }
    }
    .submit-success{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 200;
      padding-top: 80px;
      background: #F6F6F6;
      text-align: center;
      img{
        margin-bottom: 42px;
      }
      .title{
        font-size: 24px;
        color: #333333;
        text-align: center;
        line-height: 30px;
      }
      .info{
        font-size: 16px;
        color: #666666;
        text-align: center;
        line-height: 17px;
        padding: 24px 0 52px;
      }
    }
  }
</style>
