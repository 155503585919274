<template>
  <div class="bind-phone">
    <div class="title">
      <img src="../img/success.svg" alt="" />
      {{ tipType ? '登录成功' : '恭喜你获得1天试用会员' }}
    </div>
    <div class="tips">
      <template v-if="tipType !== 2"
        >绑定手机号<span class="focus">免费领取试用会员</span></template
      >
      <template v-else>
        · 绑定手机号可获得更高级的安全保护<br/>
        · 可直接使用手机号进行多平台登录，实现号账号互通
      </template>
    </div>
    <div class="carousel" v-if="tipType !== 2">
      <ul
        class="user-list"
        :class="{ animate: animate }"
        :style="{ transform: `translateY(${carouselNumber}px)` }"
      >
        <li class="user" v-for="(item, index) in listData" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="form-container">
      <LtForm ref="bindForm" :model="bindForm" :rules="bindRule">
        <LtFormItem prop="phone">
          <LtInput
            v-model="bindForm.phone"
            placeholder="输入手机号"
            maxlength="11"
            :maxlengthShow="false"
          />
        </LtFormItem>
        <div class="pop-dxyzm">
          <LtFormItem prop="sms">
            <LtInput v-model="bindForm.sms" placeholder="输入验证码" />
          </LtFormItem>
          <div class="get-vcode" @click="getCheckNum">
            <span v-if="countdown === 0">获取验证码</span>
            <span v-else style="cursor: wait">{{ countdown }}s</span>
          </div>
        </div>
        <LtFormItem>
          <LtButton
            :class="!canClick ? 'cantclick' : ''"
            long
            type="primary"
            style="height: 42px; font-size: 14px;"
            @click="throttlerecivePackBindPhone"
            >{{ tipType === 2 ? '立即绑定' : '立即领取' }}</LtButton
          >
        </LtFormItem>
      </LtForm>
    </div>
    <div class="count-down" v-if="tipType !== 2">
      <!-- <img src="../img/clock2.svg" alt="" srcset="" /> -->
      剩余领取时间&nbsp;<span class="time"
        >{{ time.h }}:&nbsp;{{ time.m }}:&nbsp;{{ time.s }}</span
      >
    </div>
  </div>
</template>

<script>
  import dayJs from 'dayjs';
  import listData from './mockUser'
  import { giftPack } from './mixin';
  export default {
    name: 'bindphone',
    props: {
      visibile: {
        require: true,
        type: Boolean,
        default: () => false,
      },
      // tipType 区分提示类型, 0 恭喜  1 登录成功 2 敦促用户绑定手机号
      tipType: {
        require: true,
        type: Number,
        default: () => 1,
      },
    },
    mixins: [giftPack],
    watch: {
      countdown() {
        if (this.countdown > 0) {
          this.timer = setTimeout(() => {
            this.countdown -= 1;
          }, 1000);
        }
      },
      visibile: {
        handler(val) {
          try {
            if (val && this.tipType !== 2) {
            this.getUserList();
            this.countTime(dayJs().format('YYYY/MM/DD') + ' 23:59:59', () => {
              this.$emit('autoCloseBindPhone');
            });
            if (document) {
              document.addEventListener(
                'visibilitychange',
                this.carouselBPhoneEventListener,
                false,
              );
            }
          }
          if (!val) {
            if (document) {
              document.removeEventListener(
                'visibilitychange',
                this.carouselBPhoneEventListener,
              );
            }
          }
          } catch (error) {
            console.log(error);
          }
        },
        immediate: true,
      },
    },
    data() {
      return {
        bindForm: {
          phone: '',
          sms: '',
        },
        bindRule: {
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            {
              pattern: /(^1\d{10}$)/,
              message: '请输入正确的手机号',
              trigger: 'blur',
            },
          ],
          sms: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        },
        countdown: 0,
        captcha_ticket: '',
        throttleStatus: true,
        throttlebindStatus: true,
        phoneBinderInstance: null,
        listData
      };
    },
    computed: {
      canClick() {
        return this.bindForm.phone && this.bindForm.sms;
      },
    },
    methods: {
      async getUserList() {
        // /hatchery/vipCoupon/task/receivedVip
        try {
            const {
            body: { code, data },
          } = await this.$http.get('/vip/vipCoupon/task/receivedVip', {
            data: {
              gw: true,
              requestMethod: 'GET',
              template: true,
              nottrans: true,
            },
            params: { visitSiteId: 1, gw: true },
            nottrans: true,
          });
          if (code === 200) {
            this.listData = data.concat(data.slice(0, 1));
            this.carousel(36);
          }
          } catch (error) {
            this.carousel(36);
            console.log(error);
          }
      },
      carouselBPhoneEventListener() {
        if (document.visibilityState === 'visible') {
          if (this.visibile && this.tipType !== 2) {
            this.carousel(36);
          }
        } else {
          clearInterval(this.carouselTimer);
        }
      },
      throttlerecivePackBindPhone() {
        this.$emit('gio', this.tipType === 2 ? '立即绑定' : '立即领取');
        if (!this.canClick) {
          return;
        }
        if (!this.throttlebindStatus) return;
        this.throttlebindStatus = false;
        setTimeout(() => {
          this.bindPhone();
          this.throttlebindStatus = true;
        }, 200);
      },
      async getReward(taskId) {
        const res = await this.$http.get('/hatchery/activity/task/receive', {
          params: { taskId, gw: true, nottrans: true },
          data: {
            gw: true,
            requestMethod: 'GET',
            template: true,
            nottrans: true,
          },
        });
        let data;
        // 如果是chuangkit-saas 项目
        if (
          this.$route.path.includes('/designtools') ||
          this.$route.path === '/'
        ) {
          data = res.body && res.body.data;
        } else {
          data = res.data && res.data.body && res.data.body.data;
        }
        if (data) {
          try {
            // 绑定手机号成功上报
            window.gio('track', 'bind_phone', {
              bind_position: '新用户送试用会员福利',
            });
            this.$emit('bindSuccess');
          } catch (error) {
            console.log(error);
          }
        }
      },
      // 绑定手机号
      async bindPhone() {
        this.$refs.bindForm.validate(async (valid) => {
          if (!valid) return;

          const { code, msg } = await this.phoneBinderInstance.bindPhone(
            this.bindForm.phone,
            this.bindForm.sms,
            this.captcha_ticket,
            0,
            2,
          );

          if (code === 200) {
            if (this.tipType === 2) {
              this.$message('绑定成功');
              this.$emit('pureBindPhoneSuccess');
              return;
            }
            await this.getReward(1);
          } else {
            if (code === -30203){
              this.$emit('pureBindPhoneFail',this.bindForm.phone);
              return
            }
            this.$message(`${msg}`);
          }
        });
      },
      // 此接口基建停止使用
      // checkPhoneReg() {
      //   return new Promise((resolve, reject) => {
      //     try {
      //       this.$http
      //         .post('/user/checkPhoneReg', { pn: this.bindForm.phone })
      //         .then((res) => {
      //           // 1 允许注册；-1 手机格式不正确；-2手机号已注册
      //           switch (res.body.code) {
      //             case 1:
      //               break;
      //             case -2:
      //               this.$message('手机号已被其他账号绑定');
      //               break;
      //             default:
      //               this.$message(`手机号码校验失败`);
      //           }
      //           resolve(res.body.code === 1);
      //         });
      //     } catch (error) {
      //       resolve(false);
      //       console.log(error);
      //     }
      //   });
      // },
      // 获取短信验证码
      async getCheckNum() {
        this.$emit('gio', '获取验证码');
        let isPass = true;
        if (this.countdown > 0) return;
        // 手机号码格式校验
        this.$refs.bindForm.validateField('phone', (error) => {
          if (error) {
            isPass = false;
          }
        });
        if (!isPass) return;
        // 手机号码是否已被绑定校验
        // const isRight = await this.checkPhoneReg();
        // if (!isRight) return;
        const {
          code,
          msg,
          data,
        } = await this.phoneBinderInstance.sendSmsCaptcha(
          this.bindForm.phone,
          0,
        );

        if (code === 200) {
          this.captcha_ticket = data;
          this.countdown = 60;
          this.$message('验证码已发送');
        } else if (code === -20204) {
          // 手机号已被绑定
          this.$emit('pureBindPhoneFail',this.bindForm.phone);
        } else {
          this.$message(`${msg}`);
        }
      },
    },
    created() {
      if (!window.$PhoneBinder) {
        const script = document.createElement('script');
        const host =
          location.host === 'local.chuangkit.com'
            ? 'https://local-www.chuangkit.com'
            : '';
        script.src = host + '/accountcenter/sdk/phoneBinder.js';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
          this.phoneBinderInstance =
            window.$PhoneBinder && new window.$PhoneBinder();
        });
      }
    },
    mounted() {
      if (!this.phoneBinderInstance) {
        this.phoneBinderInstance =
          window.$PhoneBinder && new window.$PhoneBinder();
      }
    },
  };
</script>

<style lang="less" scoped>
  .bind-phone {
    width: 484px;
    height: 500px;
    font-family: PingFang SC;
    box-sizing: border-box;
    padding: 48px 72px 24px;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      color: #1b2337;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 32px;
        height: 32px;
        padding-right: 7px;
      }
    }

    .tips {
      font-size: 14px;
      line-height: 26px;
      color: #505a71;
      padding: 16px 0 32px;

      .focus {
        color: #000000;
        font-weight: 600;
      }
    }

    .carousel {
      position: relative;
      width: 246px;
      height: 36px;
      background: #f8f8fb;
      border-radius: 53px;
      overflow: hidden;

      .animate {
        transition: transform 1s;
      }

      .user-list {
        position: absolute;
        top: 0;
        .user {
          width: 246px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #505a71;
        }
      }
    }
    .form-container {
      .pop-dxyzm {
        display: flex;
        .get-vcode {
          display: flex;
          flex-shrink: 0;
          width: 130px;
          height: 40px;
          font-size: 14px;
          background-color: #ffffff;
          color:#1b2337;
          margin-left: 8px;
          cursor: pointer;
          border: 1px solid #EBEEF5;
          border-radius: 8px;
          justify-content: center;
          align-items: center;
        }
        .get-vcode:hover {
            color: #0773FC;
            border: 1px solid var(---, #0773FC);
        }
      }

      .cantclick {
        opacity: 0.2;
        cursor: not-allowed;
      }
      ::v-deep {
        .lt-input{
          height: 40px;
          border-radius: 8px;
        }
        .lt-input:hover{
          border-color: rgba(0,0,0,.1);
        }
        .lt-input:focus{
          box-shadow:none;
        }
        .lt-form-item-error .lt-input:hover{
          border-color: #fa2323;
        }
      }
      
    }
    ::v-deep {
      .lt-btn{
        border-radius: 8px;
        font-weight: 500;
      }
    }
    .count-down {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #505a71;
      justify-content: center;
      margin-top: -16px;

      // img {
      //   padding-right: 10px;
      // }
    }
  }
</style>
