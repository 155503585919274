const seoData = [
  {
    title: '海报设计',
    link: '/haibaosheji.html'
  },
  {
    title: '名片设计',
    link: '/mingpiansheji.html'
  },
  {
    title: '折页设计',
    link: '/zheyesheji.html'
  },
  {
    title: '宣传单设计',
    link: '/xuanchuandansheji.html'
  },
  {
    title: '社交图片',
    link: '/shejiaotupian.html'
  },
  {
    title: '微博图片',
    link: '/weibosheji.html'
  },
  {
    title: '公众号图片',
    link: '/gongzhonghaosheji.html'
  },
  {
    title: '邀请函设计',
    link: '/yaoqinghansheji.html'
  },
  {
    title: '易拉宝设计',
    link: '/yilabaosheji.html'
  },
  {
    title: '会员卡设计',
    link: '/huiyuankasheji.html'
  },
  {
    title: '门票设计',
    link: '/menpiaosheji.html'
  },
  {
    title: '搞笑表情图',
    link: '/gaoxiaobiaoqing.html'
  },
  {
    title: '封面设计',
    link: '/fengmiansheji.html'
  },
  {
    title: 'PPT模板设计',
    link: '/pptsheji.html'
  },
  {
    title: '明信片设计',
    link: '/mingxinpiansheji.html'
  },
  {
    title: '展架设计',
    link: '/zhanjiasheji.html'
  },
  {
    title: '工作证设计',
    link: '/gongzuozhengsheji.html'
  },
  {
    title: '优惠券设计',
    link: '/youhuiquansheji.html'
  },
  {
    title: '不干胶设计',
    link: '/buganjiaosheji.html'
  },
  {
    title: '简历设计',
    link: '/jianlisheji.html'
  },
  {
    title: '录取通知书',
    link: '/luqutongzhishu.html'
  },
  {
    title: '信纸设计',
    link: '/xinzhisheji.html'
  },
  {
    title: '贺卡设计',
    link: '/hekasheji.html'
  },
  {
    title: '停车卡设计',
    link: '/tingchekasheji.html'
  },
  {
    title: '画册设计',
    link: '/huacesheji.html'
  },
  {
    title: '挂画设计',
    link: '/guahuasheji.html'
  },
  {
    title: '台历设计',
    link: '/tailisheji.html'
  },
  {
    title: '授权书设计',
    link: '/shouquanshusheji.html'
  },
  {
    title: '新闻稿设计',
    link: '/xinwengaosheji.html'
  },
  {
    title: '信息图设计',
    link: '/xinxitusheji.html'
  },
  {
    title: '插画设计',
    link: '/chahuasheji.html'
  },
  {
    title: '电脑桌面壁纸',
    link: '/zhuomiansheji.html'
  },
  {
    title: '手机壁纸',
    link: '/shoujibizhi.html'
  },
  {
    title: '相册书设计',
    link: '/xiangceshusheji.html'
  },
  {
    title: 'banner设计',
    link: '/banner.html'
  },
  {
    title: '淘宝店铺装修',
    link: '/taobaosheji.html'
  },
  {
    title: '手提袋设计',
    link: '/shoutidaisheji.html'
  },
  {
    title: '书签设计',
    link: '/shuqiansheji.html'
  },
  {
    title: '菜单制作',
    link: '/caidansheji.html'
  },
  {
    title: '展板制作',
    link: '/zhanbansheji.html'
  },
  {
    title: '酒水单设计',
    link: '/jiushuidansheji.html'
  },
  {
    title: '公众号素材',
    link: '/weixinsucai.html'
  },
  {
    title: '长图片制作',
    link: '/changtusheji.html'
  },
  {
    title: 'Logo设计',
    link: '/shejiimg/logosheji.html'
  }
]

export default seoData
