<template>
  <div class="retain-users">
    <img
      class="retain-users-img"
      src="../img/retain_users.png"
      alt="挽留用户"
    />
    <p class="tips">
      进入任务中心可继续做任务领取福利！免费畅享40w+模板
      ，高清无水印下载，80%的用户已领取全部福利。
    </p>
    <div class="btns">
      <div class="keep-on" @click="$emit('keepUser')">继续领福利</div>
      <div class="reject" @click="$emit('reject')">狠心放弃</div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="less" scoped>
  .retain-users {
    width: 416px;
    height: 466px;
    box-sizing: border-box;
    padding: 24px;
    background: #ffffff;
    border-radius: 4px;

    .retain-users-img {
      display: block;
      width: 368px;
      height: 248px;
      border-radius: 4px;
      overflow: hidden;
    }

    .tips {
      font-size: 14px;
      line-height: 22px;
      color: #505a71;
      margin-top: 16px;
      margin-bottom: 24px;
    }

    .btns {
      width: 100%;

      .keep-on {
        width: 368px;
        height: 40px;
        background: #0773fc;
        border-radius: 4px;
        text-align: center;
        line-height: 40px;
        color: #ffffff;
        margin-bottom: 8px;
        cursor: pointer;
      }

      .reject {
        color: #505a71;
        width: 368px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
      }
    }
  }
</style>
