export function onNeedLogin(cb) {
  try {
    window._WUFA_API_.dataBridge.onData("needLogin", cb);
  } catch (e) {
    console.log(e)
  }
}

export function sendTeamInfo(v) {
  try {
    window._WUFA_API_.dataBridge.sendData("teamInfo", v);
  } catch (e) {
    console.log(e)
  }
}