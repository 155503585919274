import { axios } from '@/config/httpInterceptors';

export function clickSignInBubble(params) {
  return axios.post('/team/activity/clickSignInBubble', {
    ...params,
    gw: true,
  });
}

export function getSignInDetailToday() {
  return axios.post('/team/activity/getSignInDetailToday', { gw: true });
}

export function queryActivityList(params) {
  return axios.post('/team/activity/list', { ...params, gw: true });
}

export function queryActivityListAllSignInAwarding() {
  return axios.post('/team/activity/listAllSignInAwarding', { gw: true });
}

export function queryActivityListUserSignInAwarding(params) {
  return axios.post('/team/activity/listUserSignInAwarding', {
    ...params,
    gw: true,
  });
}

export function reportUploadMaterial(params) {
  return axios.post('/team/material/reportUploadMaterial', {
    ...params,
    gw: true,
  });
}

export function getBannerByTypeV2(params) {
  return axios.post('/team/banner/getBannerByTypeV2', {
    ...params,
    gw: true,
  });
}

/** 上传前或者进入设计页前（比如通过模板中心，创建设计按钮），是否允许上传或者进入设计页 **/
export function judgeCapacity() {
  return axios.post('/dam/capacity/judgeCapacity', {
    gw: true,
  });
}
// 分词匹配规则
export function getKeywordMatchRule(params) {
  return axios.get("/contentcopyright/contentSearchJudge/getMatchJudge", {
    params: { ...params, gw: true },
    data: { gw: true, template: true, nottrans: true },
  });
}
