/**
 * Created by wangshuanpeng on 2021/06/18.
 */

const defaultShortSort = {
  f: 'secondKindId',
  s: 'style',
  c: 'color',
  u: 'use',
  i: 'industry',
  p: 'pageNo',
  o: 'order',
  t: 'priceType'
}

export default class {
  constructor(that, route) {
    this._this = that
    this.route = route
  }

  parse() {
    const { _this, route } = this
    let $route = _this.$route
    if (route) {
      $route = route
    }
    let object = {}
    const path = decodeURIComponent($route.path).split('.html')[0]
    const isSearch = path.includes('/search/')
    let ids = path
    ids = ids
      .split(isSearch ? '/industry/search/' : '/industry/')[1]
      .split('/')[0]
    object.id = ids.split('-')[0]
    if (ids.includes('-')) {
      object.shortName = ids.split('-')[1]
    }
    let array = path.split('/')
    array = array[array.length - 1].split('-')
    array.forEach((el) => {
      const key = el.substring(0, 1).toLocaleLowerCase()
      const con = el.substring(1)
      if (con) object[defaultShortSort[key]] = Number(con)
    })
    object = { ...object, ...$route.query }
    object.keywords = object.k
    if (!object.keywords) {
      delete object.keywords
    }
    delete object.k

    return typeof object === 'object' ? object : null
  }

  /**
   *
   * @param p path
   * @param obj params
   * @param op query
   */
  set(p, obj, q) {
    const { _this } = this
    const array = []
    let query = ''
    if (obj) obj = this.transform(obj)
    Object.keys(defaultShortSort).forEach((k) => {
      if (obj[k] !== undefined && obj[k] !== null) array.push(k + '' + obj[k])
    })
    query = array.join('-')
    _this.$router.push({ path: p + '/' + query, query: q })
  }

  compute(obj) {
    const array = []
    const keywords = obj.keywords
    obj = this.transform(obj)
    Object.keys(defaultShortSort).forEach((k) => {
      if (obj[k] !== undefined && obj[k] !== null) array.push(k + '' + obj[k])
    })
    return `${array.join('-')}${!keywords ? '' : `?k=${keywords}`}`
  }

  transform(obj) {
    const newObj = {}
    Object.keys(defaultShortSort).forEach((k) => {
      for (const i in obj) {
        if (defaultShortSort[k] === i) {
          newObj[k] = obj[i]
        }
      }
    })
    return newObj
  }
}
