let u = navigator.userAgent
// let u = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1 Safari/605.1.15'
let ua = u.toLowerCase()
// console.log(u)
// console.log(u.replace(/^.*Chrome\/([\d.]+).*$/, '$1').replace(/^.*CriOS\/([\d.]+).*$/, '$1'))
// console.log(u.replace(/^.*Chrome\/([\d.]+).*$/, '$1').replace(/^.*CriOS\/([\d.]+).*$/, '$1').split('.')[0])
// console.log(u.replace(/^.*Chrome\/([\d.]+).*$/, '$1').replace(/^.*CriOS\/([\d.]+).*$/, '$1').split('.')[0] >= 49)
function checkChrome () {
  try {
    if (u.indexOf('Chrome') > -1 && (u.replace(/^.*Chrome\/([\d.]+).*$/, '$1').replace(/^.*CriOS\/([\d.]+).*$/, '$1').split('.')[0] >= 49)) {
      return true
    } else {
      return false
    }
  }
  catch (err) {
    console.log(err)
    return false
  }
}
function checkFirefox () {
  try {
    if (u.indexOf('Firefox') > -1 || u.indexOf('FxiOS') > -1) {
      return true
    } else {
      return false
    }
  }
  catch (err) {
    console.log(err)
    return false
  }
}
function checkSafari () {
  try {
    if (u.indexOf('Safari') > -1 && u.replace(/^.*Version\/([\d.]+).*$/, '$1') >= 10) {
      return true
    } else {
      return false
    }
  }
  catch (err) {
    console.log(err)
    return false
  }
}
function checkWechat () {
  try {
    if (u.indexOf('MicroMessenger') > -1) {
      return true
    } else {
      return false
    }
  }
  catch (err) {
    console.log(err)
    return false
  }
}
function checkProxy () {
  try {
    new Proxy({}, {})
    return true
  }
  catch (err){
    console.log(err)
    return false
  }
}
function checkCanvas () {
  try {
    document.createElement('canvas').getContext
    return true
  }
  catch (err) {
    console.log(err)
    return false
  }
}
function other () {
  //判断用户的浏览器信息是否提示浏览器兼容性
  // autoUpdate()
  let maySafari = ua.indexOf('safari'),
    mayLowIe = ua.indexOf('msie'),
    mayIe11 = ua.indexOf('trident'),
    mayEdge = ua.indexOf('edge'),
    mayQQ = ua.indexOf('qqbrowser'),
    mayChrome = ua.indexOf('chrome'),
    maySouGou = ua.indexOf('se 2.x'),
    mayLieBao = ua.indexOf('lbbrowser'),
    canUse = ua.search(/(2345explorer|ubrowser|coolnovo|theworld|qbrowser|metasr|bidubrowser|maxthon|lbbrowser)/i),
    //chrome浏览器
    vision
  if (mayChrome > 0 && maySafari > 0 && mayEdge < 0) {
    vision = u.replace(/^.*Chrome\/([\d.]+).*$/, '$1').replace(/^.*CriOS\/([\d.]+).*$/, '$1').split('.')[0]
    if (vision <= 45) {
      return true
    }
  } else if (mayQQ > 0) {
    //qq浏览器
    vision = ua.match(/qqbrowser\/([\d.]+)/)
    vision = vision[1].substring(0, 1)
    if (vision <= 8) {
      return true
    }
  } else if (mayLowIe > 0) {
    //低版本ie
    return true
  } else if (mayIe11 > 0) {
    //ie
    return true
  } else if (mayEdge > 0) {
    // Edge
    // vision = ua.match(/edge\/([\d.]+)/)
    // vision = vision[1].substring(0, 5)
    // if (vision <= 40.15) {
    return true
    // }
  } else if (maySouGou > 0) {
    //搜狗浏览器
    vision = ua.match(/chrome\/([\d.]+)/)
    vision = vision[1].substring(0, 2)
    if (vision < 35) {
      return true
    }
  } else if (mayLieBao > 0) {
    //猎豹
    vision = ua.match(/chrome\/([\d.]+)/)
    vision = vision[1].substring(0, 2)
    if (vision < 35) {
      return true
    }
  } else {
    return false
  }
  return false
}
let support = {
  chrome: checkChrome(),
  firefox: checkFirefox(),
  safari: checkSafari(),
  wechat: checkWechat(),
  proxy: checkProxy(),
  canvas: checkCanvas(),
  other: other()
}
// let support = {
// 	chrome: true,
// 	safari: true,
// 	wechat: true,
// 	proxy: true,
// 	canvas: true,
// 	other: false
// }
export default support
