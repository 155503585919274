<template>
  <div class="change-password-box">
    <div class="change-password">
      <div class="change-password-title">修改密码</div>
      <div class="change-password-tip">{{ tip }}</div>
      <div class="change-password-item">
        <div class="change-password-item-title">原密码</div>
        <div class="change-password-item-input">
          <input
            v-model="oldPassword"
            autocomplete="off"
            type="password"
            class="a-input password-input"
            placeholder="请输入原密码"
            :max-length="18"
          />
        </div>
      </div>
      <div class="change-password-item">
        <div class="change-password-item-title">新密码</div>
        <div
          class="change-password-item-input"
          :class="{ 'has-error': invalid && password && !isFocus }"
        >
          <input
            v-model="password"
            autocomplete="new-password"
            type="password"
            class="a-input password-input"
            :placeholder="`请输入${strongStatus ? 8 : 6}-18位新密码`"
            :max-length="18"
            @focus="isFocus = true"
            @blur="isFocus = false"
          />
          <div
            class="password-error-tip"
            :style="{
              opacity: invalid && password && !isFocus ? 1 : 0,
            }"
          >
            {{
              strongStatus
                ? "请按照密码要求重新设置密码"
                : "密码不符合要求，请重新输入"
            }}
          </div>
          <div
            v-if="strongStatus"
            type="icon-tishi"
            class="password-tip-icon"
            @mouseenter.stop="tipShow = true"
            @mouseleave.stop="tipShow = false"
          />
          <PasswordTip
            v-if="strongStatus"
            :style="{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 'calc(100% + 12px)',
              transition: 'all 0.2s',
              opacity: tipShow || isFocus ? 1 : 0,
            }"
            :errors="errors"
          ></PasswordTip>
        </div>
      </div>
      <div class="change-password-item last">
        <div class="change-password-item-title">确认密码</div>
        <div
          class="change-password-item-input"
          :class="{ 'has-error': isDiff && confirmPassword && !isFocus }"
        >
          <input
            v-model="confirmPassword"
            autocomplete="new-password"
            type="password"
            class="a-input password-input"
            placeholder="请再次输入新密码"
            :max-length="18"
          />
          <div
            class="password-error-tip"
            :style="{
              opacity: isDiff && confirmPassword ? 1 : 0,
            }"
          >
            两次密码输入不一致
          </div>
        </div>
      </div>
      <button
        :loading="loading"
        :disabled="disabled"
        class="change-password-btn"
        type="primary"
        @click="save"
      >
        保存
      </button>
    </div>
  </div>
</template>
<script>
import PasswordTip, {
  statusEnum,
  typeEnum,
} from "./components/password-tip.vue";

export default {
  name: "change-password",
  components: {
    PasswordTip,
  },
  data() {
    return {
      oldPassword: "",
      password: "",
      confirmPassword: "",
      tipShow: false,
      loading: false,
      isFocus: false,
      strongStatus: false,
    };
  },
  props: {
    tip: {
      type: String,
      default: "团队管理员基于信息安全要求定期修改密码，请及时更换。",
    },
    http: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    disabled() {
      return this.invalid || this.isDiff || !this.oldPassword;
    },
    invalid() {
      return Object.values(this.errors).some((v) => v !== statusEnum.success);
    },
    isDiff() {
      return this.password !== this.confirmPassword;
    },
    errors() {
      if (!this.strongStatus) {
        const rReg = /^[a-zA-Z0-9!@#\*_]{6,18}$/;
        return {
          [typeEnum.format]: rReg.test(this.password)
            ? statusEnum.success
            : statusEnum.error,
        };
      }

      const len = this.password.length;
      const cReg = /([\u4e00-\u9fa5]|\s)+/g;
      const fReg =
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]).+$/g;
      if (len === 0) {
        return {
          [typeEnum.length]: statusEnum.default,
          [typeEnum.format]: statusEnum.default,
          [typeEnum.type]: statusEnum.default,
        };
      }

      return {
        [typeEnum.length]: len < 8 ? statusEnum.error : statusEnum.success,
        [typeEnum.type]: cReg.test(this.password)
          ? statusEnum.error
          : statusEnum.success,
        [typeEnum.format]: fReg.test(this.password)
          ? statusEnum.success
          : statusEnum.error,
      };
    },
  },
  mounted() {
    this.strongPasswordVerify();
    window.addEventListener("beforeunload", this.beforeUnloadHandler);
  },
  methods: {
    async strongPasswordVerify() {
      try {
        const {
          body: { code, data },
        } = await this.http.post("/team/fuse/password/strongPasswordVerify", {
          gw: true,
        });
        if (code === 200) {
          this.strongStatus = data.strongStatus;
        }
      } catch (e) {}
    },
    async save() {
      this.loading = true;
      try {
        const {
          body: { code },
        } = await this.http.post("/accountcenter/user/changePwd", {
          oldPwd: this.oldPassword,
          newPwd: this.password,
          custom_flag: this.strongStatus ? 1 : 3,
        });
        if (code === 200) {
          this.destroy();
        } else {
          const errorMap = {
            "-4": "参数错误",
            "-2": "密码格式错误",
            "-1": "用户未登录",
            0: "旧密码输入有误",
            "-401": "风控检测异常",
          };
          this.$message.error(errorMap[code] || "修改密码失败");
        }
      } catch (e) {}
      this.loading = false;
    },
    destroy() {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
      this.$emit("destroy");
      this.$destroy();
      window.removeEventListener("beforeunload", this.beforeUnloadHandler);
    },
    logout() {
      this.http
        .post("/accountcenter/login/sSOLogout", {
          gw: true,
        })
        .then((res) => {
          if (res.body.code !== 200) return;
          window.localStorage.removeItem("currentDataClientType");
          window.localStorage.removeItem("newUserGuideStep");
          window.localStorage.removeItem("accessToken");
          try {
            window.gio("clearUserId");
          } catch (e) {}
        });
    },
    beforeUnloadHandler(event) {
      event.preventDefault();
      event.returnValue = "";
      this.logout();
      return "关闭提示";
    },
  },
};
</script>
<style lang="less" scoped>
.change-password-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.change-password {
  width: 430px;
  height: 548px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 42px 56px 0;
  .has-error .a-input {
    background-color: #fff;
    border-color: #f5222d;
  }
  .a-input {
    color: #1b2337;

    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    &:not([type="range"]) {
      touch-action: manipulation;
    }
    &[type="password"] {
      -webkit-appearance: none;
    }
    &:placeholder-shown {
      text-overflow: ellipsis;
    }
    &:hover {
      border-color: #40a9ff;
      border-right-width: 1px !important;
    }
    &:focus {
      outline: none;
      border: 1px solid #0773fc;
      box-shadow: 0px 0px 0px 2px rgba(7, 115, 252, 0.25);
    }
  }
  &-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #1b2337;
    margin-bottom: 12px;
  }
  &-tip {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #8693ab;
    margin-bottom: 52px;
  }
  &-item {
    margin-bottom: 28px;
    &.last {
      margin-bottom: 50px;
    }
    &-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #1b2337;
      margin-bottom: 10px;
    }
    &-input {
      width: 318px;
      height: 42px;
      position: relative;

      .password-input {
        width: 100%;
        height: 100%;
      }
      .password-tip-icon {
        width: 14px;
        height: 14px;
        cursor: pointer;
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        color: #8693ab;
        background-image: url("../img/change-password/tishi.svg");
        background-size: contain;
        &:hover {
          background-image: url("../img/change-password/tishi_hover.svg");
        }
      }
      .password-error-tip {
        position: absolute;
        bottom: -22px;
        height: 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #fa2323;
        transition: all 0.2s;
      }
    }
  }
  &-btn {
    width: 100%;
    height: 42px;
    transition: none;
    box-shadow: none;
    border-radius: 8px;
    line-height: 1.5;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    user-select: none;
    touch-action: manipulation;
    padding: 0 15px;
    font-size: 14px;
    color: #fff;
    background-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    &[disabled] {
      color: #fff;
      background-color: #b4d5fe;
      border-color: #b4d5fe;
      cursor: not-allowed;
    }
    &:not([disabled]):hover {
      color: #fff;
      background-color: #0667e2;
      border-color: #0667e2;
    }
  }
}
</style>
