<template>
  <!-- 如果有更改，请对比后同步更改index.vue文件 -->
  <div>
    <lt-modal
      :value="visible"
      :title="title"
      :simple="false"
      :width="628"
      :footer-hide="true"
      :mask-closable="false"
      class-name="upload-batch-modal"
      @on-cancel="closeModal"
    >
      <div class="description">
        <div>
          <span class="desc-title">导入{{type === 0 ? '设计' : '模板'}}：</span>
          <lt-selector :defaultValue="SELECT_LIST[0].label" :selectorSize="{width: '210px', height: '40px'}" class="select-option">
            <lt-option
              v-for="item in SELECT_LIST"
              :key="item.value"
              :isActive="selectedValue === item.value"
              :value="item"
              @select-value="selectedValue = item.value"
            />
          </lt-selector>
        </div>
        <div class="desc-text">
          *请将图层样式与蒙版图层栅格化，
          <a @click="clickViewStandard">PSD 整理规范>></a>
        </div>
      </div>
      <div class="upload-batch">
        <div
          :class="{'upper-part': true, 'list-show': list.length > 0, 'dragenter': dragenter, 'psd-upper-part': true}"
          @dragover.stop.prevent="(e) => {e.dataTransfer.dropEffect = 'copy'}"
          @drop.stop.prevent="uploadChange($event)"
          @dragenter.stop.prevent="dragenter = true"
          @dragleave.stop.prevent="dragenter = false"
        >
          <img src="./img/upload-icon.svg" alt="" class="upload-icon" />
          <div class="upload-info">
            <span>支持 {{fileType.map(item => item).join('、')}} 格式，将文件拖到此处，或</span>
            <div class="upload-buton" @click="$refs.uploadInput.click()">
              <span>点击上传</span>
              <input
                type="file"
                @change="uploadChange($event)"
                :accept="[
                  ...fileType.map(item => (FILE_TYPE_MENU[item])),
                  ...fileType.map(item => (`.${item.toLowerCase()}`)),
                  ...fileType.map(item => (`.${item.toUpperCase()}`))
                ].join()"
                multiple="multiple"
                style="display: none"
                ref="uploadInput"
              />
            </div>
          </div>
          <p class="explain">单次最多上传{{maxLength}}个文件，单个文件大小不超过{{maxSize}}M</p>
        </div>
        <div v-if="list.length > 0" class="upload-content">
          <div class="upload-tab">
            <span :class="{selected: selected === 0}" @click="selected = 0">正在上传 ({{uploadList[0].length}})</span>
            <span :class="{selected: selected === 1}" @click="selected = 1">上传完成 ({{uploadList[1].length}})</span>
          </div>
          <div class="upload-list">
            <div class="table-head list-th">
              <span>名称</span>
              <span>大小</span>
              <span>{{selected === 0 ? '进度' : '状态'}}</span>
            </div>
            <div class="table-body">
              <div v-if="uploadList[selected].length === 0" class="no-data">
                <img src="./img/no-data.svg" alt="">
                <div>暂无数据</div>
              </div>
              <ul class="data-list">
                <li else v-for="item in (selected === 0 ? uploadList[selected] : [...uploadList[selected]].reverse())" class="list-th" :key="item.id">
                  <span>
                    <span class="name">{{item.name}}</span>
                    <span style="vertical-align: top">{{item.type}}</span>
                  </span>
                  <span>{{item.size / 1024 > 1024 ? `${Math.round(item.size / 1024 / 1024 * 10) / 10}MB` : `${Math.round(item.size / 1024 * 10) / 10}KB`}}</span>
                  <span>
                    <lt-icon
                      v-if="progressShow[item.id] < 0"
                      type="error"
                      color="#FA2323"
                      :size="14"
                    />
                    <lt-icon
                      v-else-if="progressShow[item.id] >= 200"
                      type="succeed"
                      color="#02BB00"
                      :size="14"
                    />
                    <span
                      v-else
                      class="progress"
                    >
                      <lt-progress :percent="progressShow[item.id].toFixed(2) || 0" :stroke-width="6" :hide-info="true"></lt-progress>
                    </span>
                    <span :class="{'progress-text': true, 'end-text': progressShow[item.id] < 0 || progressShow[item.id] >= 200}">{{progressText(item.id)}}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="upload-bottom">
            <span>本次已上传{{Object.values(this.progress).filter(progress => (progress >= 100 )).length}}个文件</span>
            <lt-button
              type="primary"
              :disabled="Object.values(this.progress).some(progress => (progress < 100 && progress >=0))"
              class="upload-ok"
              @click="closeModal"
            >完成</lt-button>
          </div>
        </div>
      </div>
    </lt-modal>
    <template>
      <UploadPSDFunc
        v-for="item in fileList"
        :key="item.id"
        :type="type"
        :fid="fid"
        :parserConfig="{splitLayer, editableText}"
        :id="item.id"
        :file="item.file"
        :isUploadStart="isUploadTransformStart({item, type: 'upload'})"
        :isTransformStart="isUploadTransformStart({item, type: 'transform'})"
        :stopTransform="stopTransform"
        @upload-start="uploadStart"
        @change-progress="changeProgress"
        @upload-fail="uploadFail"
        @upload-success="uploadSuccess"
        @transform-success="transformSuccess"
        @transform-fail="transformFail"
        @login-fail="loginFail"
      />
    </template>
  </div>
</template>
<script>
import UploadPSDFunc from './uploadPSDFuncNew.vue';
import mixin from './mixin.js';
import {mapActions} from "vuex";
const SELECT_LIST = [
  {label: '分隔图层且文字可编辑', value: 0},
  {label: '分隔图层且文字为图片', value: 1},
  {label: '合并所有图层为一张图片', value: 2},
]
export default {
  name: 'uploadBatch',
  components: {
    UploadPSDFunc,
  },
  mixins: [mixin],
  data() {
    return {
      SELECT_LIST,
      selectedValue: SELECT_LIST[0].value,
    }
  },
  props: {
    type: {
      type: Number,
      defaule: 0, // 0 我的设计  1 团队模板
    },
    fid:{ // 文件夹 ID
      default: 0,
      type: Number
    },
  },
  computed: {
    // 分隔图层
    splitLayer() {
      return this.selectedValue === 0 || this.selectedValue === 1
    },
    // 是否文字可编辑
    editableText() {
      return this.selectedValue === 0
    },
    disable() {
      return Object.values(this.progressShow).some(progress => (progress < 100 && progress > 0))
    }
  },
  watch: {
    visible() {
      this.selectedValue = SELECT_LIST[0].value
    },
    disable(value) {
      this.SELECT_LIST = this.SELECT_LIST.map(item => ({...item, disabled: value}))
    }
  },
  methods: {
    ...mapActions({
      setLogregShow: 'setLogregShow',
      setIsLogin: 'setIsLogin',
    }),
    clickViewStandard: () => {
      window.open('https://www.yuque.com/docs/share/c3db2170-fb0e-49c2-947c-ca4b617e9411?#')
    },
    loginFail() {
      this.closeModal()
      this.setIsLogin(false)
      this.setLogregShow(1);
    }
  }
}
</script>
<style lang="less">
.upload-batch-modal {
  .lt-modal-content {
    padding-bottom: 24px;
    .lt-modal-header {
      border-bottom: none;
    }
    .lt-modal-body {
      padding-top: 8px;
      .lt-selector.select-option {
        .selector-input{
          input {
            &:hover {
              border-color: #C1C6D0;
              box-shadow: none;
            }
            &:focus {
              border-color: #C1C6D0;
              box-shadow: none;
            }
          }
          .lt-icon {
            font-size: 14px;
            color: rgb(73, 79, 95) !important;
            right: 12px;
            height: 20px;
          }
        }
        .lt-option {
          padding: 1px;
          .option-item {
            color: #1B2337;
            height: 40px !important;
            &.active {
              color: #1B2337;
              font-weight: 500;
            }
          }
        }
      }
      .progress{
        .lt-progress {
          .lt-progress-inner {
            overflow: hidden;
            background-color: #F3F4F9;
          }
        }
      }
    }
  }
}

</style>
<style lang="less" scoped>
  @import url('./index.less');
</style>
