import { axios } from '@/config/httpInterceptors';

export function sSOLogout() {
  return axios.post('/accountcenter/login/sSOLogout', {
    gw: true,
  });
}

export function changeNickname(params) {
  return axios.post('/accountcenter/user/changeNickname', {
    ...params,
    gw: true,
  });
}

export function changePwd(params) {
  return axios.post('/accountcenter/user/changePwd', {
    ...params,
    gw: true,
  });
}

export function sendSmsCaptcha(params) {
  return axios.post('/accountcenter/tools/sendSmsCaptcha', {
    ...params,
    gw: true,
  });
}

export function validateSmsCode(params) {
  return axios.post('/accountcenter/tools/validateSmsCode', {
    ...params,
    gw: true,
  });
}
//ab测试接口
export function getABtest(params) {
  return axios.post('/accountcenter/common/aBTest/get', {
    ...params,
    gw: true,
  },{
    timeout: 1000  // 设置超时时间为 5000 毫秒，即 5 秒
  });
}
