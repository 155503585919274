<template></template>

<script>
  import CryptoJS from 'crypto-js'

  class upload {
    constructor ({ base64, rect, type = 'image/jpg', name }, _this) {
      this.__proto__ = _this
      this.dataBin = atob(base64.split(',')[1])
      this.buffer = new Uint8Array(this.dataBin.length)
      for (let i = 0; i < this.dataBin.length; i++) {
        this.buffer[i] = this.dataBin.charCodeAt(i)
      }
      this.blob = new Blob([this.buffer.buffer], { type })
      computeMD5.apply(this).then(e => {
        this.preUploadMaterial(e, rect, name, base64)
      })

      function computeMD5 () {
        return new Promise((resolve) => {
          let md5Instance = CryptoJS.algo.MD5.create()
          let reader = new FileReader()
          reader.readAsBinaryString(this.blob)
          reader.onload = (e) => {
            md5Instance.update(CryptoJS.enc.Latin1.parse(e.target.result))
            let md5Value = md5Instance.finalize()
            resolve({ md5: md5Value, blob: this.blob })
          }
        })
      }
    }
  }
  export default {
    name: 'uploadImgFuc',
    props: {
      id: {
        type: Number,
        default: 0
      },
      fid:{ // 文件夹 ID
        default: 0,
        type: Number
      },
      tid:{ // 团队 ID
        default: 0,
        type: Number
      },
      canEdit: {
        type: Boolean,
        default: true
      },
      isUploadStart: { // 是否开始上传
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        uploadProgress: 0,
        fileInfo: {},
      }
    },
    methods: {
      async UploadImg (file) {
        if (!this.canEdit) return 
        this.uploadStart()
        const resolve = await fR(file, this)
        this.fileInfo = resolve;
        new upload(resolve, this)
        function fR (file, that) {
          return new Promise((resolve) => {
              let fr = new FileReader()
              fr.readAsDataURL(file)
              fr.onload = e => {
                let base64 = e.target.result
                let img = new Image()
                img.src = base64
                img.onload = () => {
                  resolve({
                    base64,
                    rect: { w: img.width, h: img.height },
                    type: file.type,
                    name: file.name,
                  })
                }
                img.onerror = (err) => {
                  that.uploadFail('', err)
                }
              } 
          })
        }
      },
      uploadStart() {
        this.$emit('upload-start', this.id)
      },
      uploadSuccess(mid) {
        this.$emit('upload-success', {id: this.id, mid, ...this.fileInfo})
      },
      uploadFail(code, err) {
        this.$emit('upload-fail', {
          id: this.id,
          code,
          err
        })
      },
      progress(e) {
        this.$emit('change-progress', {id: this.id, progress: Math.round((e.loaded * 100) / e.total)})
      },
      //获取个人/团队素材的上传地址
      async preUploadMaterial ({ md5, blob }, rect, name, base64) {
        let fd = new FormData
        fd.append('md5', md5)
        fd.append('file', blob.slice(0, 10))
        fd.append('fid', this.fid)
        fd.append('tid', this.tid)
        fd.append('teamCheck', this.tid ? 1 : 0)
        fd.append('gw', true)
        try {
          const res = await this.$http.post('/team/material/preUploadMaterial', fd)
          //未匹配到库中相同文件
          if (res.body.uploadPms) {
            var { paramMap, fileKey, url, method} = res.body.uploadPms || {}
          } else { //匹配到库中相同文件
            var { dt, mid, w, h, fid, ik, url } = res.body
          }
          switch (res.body.code) {
            case 1:
              this.uploadSuccess(mid)
              break
            case 0:
              this.uploading(paramMap, fileKey, url, method, md5, blob, rect, name)
              break
            case -431:
              this.uploadFail(-431)
              break
            case -110:
              this.uploadFail(-110)
              break
            case -3:
              this.uploadFail(-3)
              break
            default:
              this.uploadFail()
          }
        } catch (err) {
          this.uploadFail('', err)
        }
      },
      
      //上传素材
      async uploading (paramMap, fileKey, url, method, md5, file, rect, name) {
        let fd = new FormData
        Object.keys(paramMap).forEach((key) => {
          fd.append(key, paramMap[key])
        })
        fd.append('file', file)
        let percentCompleted = 0
        try {
            await this.$http.post(url.replace(/^(https|http)/, 'ul'), fd, {
              onUploadProgress: this.progress
            })
            // await axios({url: url, method: method, data: fd, onUploadProgress: this.progress})
            this.reportUpload(fileKey, rect, name, md5)
          } catch (err) {
            console.log(err,'zhelidnasdsad=========')
            this.uploadFail('', err)
          }
      },
      
      //上报个人/团队素材的上传结果
      async reportUpload (p, { w, h }, file_name,md5) {
        try {
          const res = await this.$http.post('/team/material/reportUploadMaterial', {
            fileKey: p, 
            md5: md5.toString(),
            fid: this.fid,
            tid: this.tid,
            w,
            h,
            file_name,
            gw: true
          });
          const { code, mid } = res.body
          switch (code) {
            case 1:
              this.uploadSuccess(mid)
              break
            case -431:
              this.uploadFail(-431)
              break
            case 87014:
              this.uploadFail(87014)
              break
            default:
              this.uploadFail()
          }
        } catch (err) {
          this.uploadFail('' ,err)
        }
      }
    }
  }
</script>

<style scoped>
</style>
