<template>
  <section class="vip-modal">
    <div class="vip-modal-mask" v-show="showVipGuideModal">
      <div class="vip-modal-pop" :class="{ 'overdate-modal': vipModalStyleType === 1}">
        <div class="close-button" @click="handleClose()"></div>
        <div class="modal-title">{{ modalData.title }}</div>
        <div class="content">
          <span class="des">{{ modalData.description }}</span>
          <div class="introduce-box">
            <div
              v-for="(item, index) in modalData.guidanceIntroduceList"
              :key="index"
              class="introduce-item"
              :class="{ 'overdate-item': vipModalStyleType === 1}"
            >
              {{ item.describe }}
            </div>
          </div>
          <!-- 倒计时 -->
          <div class="count-down" v-if="vipModalStyleType===0">
            <div class="clock"></div>
            <span class="text">福利剩余</span>
            <span>{{timeText}}</span>
          </div>
          <div v-else-if="modalData.couponInfoList && modalData.couponInfoList.length > 0" class="coupon-box">
            <p>领取专属优惠券续费权益：</p>
            <div class="coupon-card" v-for="(item,index) in modalData.couponInfoList" :key="index">
              <div class="left">
                <div class="price">
                  <div class="unit">￥</div>
                  <div class="price-num">{{item.discountAmount}}</div>
                </div>
                <div class="condition">{{item.thresholdInfo}}</div>
              </div>
              <div class="right">
                <div class="coupon-title">{{item.title}}</div>
                <div class="des">{{item.description}}</div>
              </div>
            </div>
          </div>
          <!-- 开通会员按钮 -->
          <div class="open-vip-button" @click="handleOpenVip">{{modalData.buttonInfo}}</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    props: {
      showVipGuideModal: {
        type: Boolean,
        default: false,
      },
      modalData: {
        type: Object,
        default: () => {},
      },
      timeText: {
        type: String,
        default: '',
      },
      vipModalStyleType: {
        type: Number,//0:默认样式 1:带优惠卷的样式【不含倒计时】
        default: 'default',
      },
      trackerBaseParams: {
        type: Object,
        default: () => ({})
      }  
    },
    data() {
      return {};
    },
    watch: {},
    methods: {
      handleClose() {
        this.$emit('onVipGuideClose');
      },
      handleOpenVip(){
        this.$emit('onOpenVip');
        this.handleClose();
        const {style,id,buttonInfo,name} = this.modalData
        try {
          window.gio("track", "vip_guide_click", {
            popup_name: name,
            popup_type: style,
            active_id: id,
            button_name: buttonInfo,
            is_login: '是',
            ...this.trackerBaseParams,
          });
        } catch (error) {
          console.log('埋点异常')
        }
      }
    },
  };
</script>
<style lang="less" scoped>
  @import './index.less';
</style>
