<template>
  <div
    class="temp-key-warp"
    @mouseleave="setChooseItem(0)"
  >
    <div class="temp-key-warp_left">
      <ul>
        <li
          v-for="(item, i) in selectedBlockList"
          v-show="item.firstKinds.length > 0"
          :key="i"
          class="left-tab"
          :class="{ active: chooseItemInd == i }"
          @mouseenter="setChooseItem(i)"
        >
          <h3 class="tab-title">{{ item.tagName }}</h3>
          <div class="tab-box">
            <span
              class="tab-name"
              @click.stop="searchTempKeywords(item.firstKinds[0])"
              >{{
                item.firstKinds[0] && item.firstKinds[0].firstKindName
              }}</span
            >
            <span
              class="tab-name"
              @click.stop="searchTempKeywords(item.firstKinds[0])"
              >{{
                item.firstKinds[1] && item.firstKinds[1].firstKindName
              }}</span
            >
          </div>
          <i class="tab-line"></i>
        </li>
      </ul>
    </div>
    <div
      v-if="chooseItem && chooseItem.tagName !== '风格'"
      class="temp-key-warp_right "
    >
      <div v-for="item in chooseFirstKinds" :key="item.id" class="itemize">
        <span class="itemize-left" @click.stop="searchTempKeywords(item)">{{
          item.firstKindName
        }}</span>
        <ul class="itemize-right">
          <li
            v-for="child in item.secondKinds || item.designKind"
            v-show="!child.kindType"
            :key="child.id"
            class="keyword"
            @click.stop="searchTempKeywords(child, item)"
          >
            {{ child.secondKindName || child.kindTitle }}
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="temp-key-warp_right not-class">
      <ul>
        <li
          v-for="item in chooseFirstKinds"
          :key="item.id"
          class="keywordBox"
          @click.stop="searchTempKeywords(item)"
        >
          <span>{{ item.firstKindName }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['header'],
  name: 'TempKey',
  props: {
    isIndex: {
      type: Boolean,
      default: false
    },
    selectedBlockList: {}
  },
  data() {
    return {
      chooseItemInd: 0
    }
  },
  computed: {
    chooseItem() {
      return (
        this.selectedBlockList && this.selectedBlockList[this.chooseItemInd]
      )
    },
    chooseFirstKinds() {
      return this.chooseItem && this.chooseItem.firstKinds
    }
  },
  methods: {
    setChooseItem(i) {
      this.chooseItemInd = i
    },
    searchTempKeywords(e, item) {
      let route = ''
      if (this.chooseItem.isScene) {
        if (e.route) {
          route = `/templatecenter/${e.route}.html`
        } else {
          route = `/sj-pi${item.firstKindId}-si${e.kindId}-or0-pt0-pn1.html`
        }
      } else {
        route = `/sj-or0-pt0-ke${e.keyword}-rd${e.id}-pn1.html`
      }
      // this.$router.replace({ path: route })
      location.href = location.origin + route
    }
  }
}
</script>

<style scoped lang="less">
@import './tempKey.less';
</style>
