<template>
  <div
    id="user-bar"
    :style="templateDetailBarStyle"
    :class="{ index: isIndex && scrollTop === 0 }"
  >
     <!-- 团队设置入口 -->
    <div id="enterprise-setting-btn"  v-if="showTeamEntry" class="enterprise-setting" @click="goEnterprisePage" :style="{backgroundImage:isVipPage?`url(${require('../img/setting-icon.svg')})`:''}">
      <div class="message_tip">团队管理</div>
    </div>
    <!-- 消息通知 -->
    <div
      class="message"
      :class="{ messageNoShow: messageCount === 0 }"
      :num="messageCount>=100?'99+':messageCount"
      @mouseenter="canShowNormalNotice"
      @mouseleave="normalNoticeShow = false"
    >
      <span class="notice-icon" @click="handleClickNoticeIcon">
        <img :src="require('../img/white-ling.svg')" class="icon-font" alt="" v-if="isIndex && scrollTop === 0 && !isVipPage">
        <img :src="require('../img/vector.svg')" class="icon-font" alt="" v-else>
      </span>
      <div class="message_tip">消息通知</div>
      <div v-show="normalNoticeShow" class="normal-notice">
        <div class="normal-notice__main">
          <div class="normal-notice__subject" :title="normalNotice.subject">
            {{ normalNotice.subject }}
          </div>
          <div
            class="normal-notice__content"
            v-html="decodeURIComponent((normalNotice.content).replace(/%/g, '%25'))"
          ></div>
        </div>
        <a
          :href="`/messagecenter?id=${normalNotice.noticeId}`"
          class="read-more"
        >
          查看更多
        </a>
      </div>
    </div>
    
    <slot></slot>

    <div id="GUIDE_3_USER" class="user-bar-right" @mouseenter="switchBtn(3,true)">
      <a :href="jumpToAccountCenter()" class="user-img">
        <!-- <img src="../img/team_icon.png" alt="头像"/> -->
        <img :src="userInfo.userHeadImgUrl" alt="头像"/>
        <!--团队头像-->
        <img :src="teamInfo.headIconUrl"  class="teamInfo-symbol"  v-if="teamInfo.headIconUrl"/>
        <img src="../img/team_icon.png" class="teamInfo-symbol"  v-else-if="isIncludeEmoji(teamInfo)"/>
        <span
          v-else-if="teamInfo.teamName&&(!canCreateFreeTeam||!isTeamAdmin)"
          class="teamInfo-symbol"
        >{{getTeamName(teamInfo,true) }}</span>
        <!--设计师标志-->
        <span
          v-if="userInfo.designerCheckStatus === 2"
          class="designer-symbol"
        ></span>
      </a>
      <div class="pop-line" @click.stop>
        <a :href="jumpToAccountCenter()" class="pop-line-top">
          用户ID：{{ userInfo.userId }}
        </a>
        <div class="pop-line-list">
          <!-- 会员信息-在自己的团队内 -->
          <div v-if="!isWorkWeixinUser && isTeamAdmin" class="vip-info">
            <p class="description">{{ getVipDescription }}</p>
            <div class="btn-container">
              <span v-if="showHalfPriceButton" class="half-price-button" @click.stop="buyNow">{{modalData.buttonInfo}}</span>
              <span v-else class="btn-buy-now" @click.stop="buyNow">
              立即{{ curTeamAdminVipLevel > 0 ? '续费' : '开通' }}
              </span>
              <span class="upgrade-enterprise-button" v-if="showUpgradeEnterprise" @click.stop="openDifer">
                升级企业版
              </span>
            </div>
          </div>
          <!-- 会员信息, 在别人的团队内 -->
          <div v-if="!isWorkWeixinUser && !isTeamAdmin" class="vip-info">
            <p class="description">{{ getOtherVipDescription }}</p>
            <div class="btn-container">
              <span class="btn-buy-now" @click.stop="goBuyNow">
                立即{{ VIP_TEAM_VERSION.includes(curTeamAdminVipLevel) ? '续费' : '开通' }}
              </span>
              <span class="upgrade-enterprise-button" v-if="showUpgradeEnterprise" @click.stop="openDifer">
                升级企业版
              </span>
            </div>
          </div>
          <p class="switch-team">当前团队</p>
           <!-- 自己个人/自己创建的团队 -->
          <div class="only-user-row"
              @mouseenter="switchBtn(2)"
              @mouseleave="switchBtn(3)">
            <div
              class="user-self"
              >
              <div class="left-side">
                <span
                  v-if="!canCreateFreeTeam || teamInfo.teamRoleEnum != 'Administrator'"
                  class="name"
                  v-text="getTeamName(teamInfo)"
                  :class="{ active: selectedId === teamInfo.teamId }">
                </span>
                <span
                  class="name"
                  v-else
                  >
                  个人版
                </span>
              </div>
              <div class="right-side" @click="switchBtn(2)">
               <span class="switch_btn">{{canCreateFreeTeam?'创建团队':'切换'}}></span>
              </div>
            </div>
            <ChangeTeamModal 
            @mouseenterBtn="clearTimer"
            :userInfo="userInfo" 
            :teamInfo="teamInfo" 
            :isLogin="isLogin"
            :canCreateFreeTeam="canCreateFreeTeam"
            @getUserInfo="$emit('getUserInfo')"
            @getVipDetail="$emit('getVipDetail')"
            @createModal="createModalFun"
            ref="changeTeamModal"
          />
          </div>
          <!-- 菜单 -->
          <section class="list-warp">
            <a
              v-for="item in computedPopList"
              :key="item.des"
              :href="jumpCheck(item.href)"
              class="list-link"
            >
              <span class="list-title">{{ item.des }}</span>
              <span
                v-if="item.des === '消息中心' && messageCount"
                class="message-count"
                >{{ messageCount }}</span
              >
              <span
                v-if="item.des === '体验新版本'"
                class="new-version-icon"
                ></span
              >
            </a>
            <!-- <a
              href="javascript:void(0);"
              class="list-link"
              @click.prevent="showRechargePop"
            >
              <span class="list-title">余额充值</span>
            </a> -->
            <a :href="jumpCheck('/userquan')" class="list-link">
              <span class="list-title">我的优惠券</span>
            </a>
            <a
              v-if="!isWorkWeixinUser"
              href="javascript:void(0);"
              class="list-link"
              @click.prevent="logout"
            >
              <span class="list-title">退出登录</span>
            </a>
          </section>
        </div>
      </div>
     
    </div>
  </div>
</template>

<script>
  const VIP_VERSION = {
    0: '普通用户',
    1: '基础版VIP',
    2: '协作版VIP',
    3: '高级团队VIP',
    4: '个人版VIP',
    5: '标准版团队VIP',
    6: '企业专业版VIP',
    7: '加强版VIP',
    8: '基础团队版VIP',
    9: '全用途版VIP',
    100: '基础版VIP',
    105: '全用途版VIP',
    110: '标准团队版VIP',
    115: '高级团队版VIP',
    120: '企业专业版',
    125: '新媒体商户版',
    130: '全用途商户版',
    135: '团队协作版',
    199: '标准版（个人）',   
    200: '标准版（企业）',
    210: '标准版（大企业）',
    201: '个人高级版',   
    202: '企业高级版',
    203: '高级版',
    205: '协作版',
    204: '高级版(大企业)',
    220: '旗舰版',
    211: "通用版（个人）",
    212: "通用版（企业）",
    '135_10': "团队协作10人版"
  };
  const VIP_TEAM_VERSION = [2, 7, 3, 8, 135, 5, 201, 202, 203, 220, 204, 205]
  // 根据 vipLevel 判断 vip 类型
  const getVipTypeByLevel = level => level === 0 ? 'normal' : (level === 4 ? 'individual' : 'enterprise')
  const popList = (isDesigner, isWorkWeixinUser, isNewUeser) => [
    {
      href: '/designer',
      des: '设计师主页',
      hidden: !isDesigner,
    },
    {
      href: '/list',
      des: '订单/发票',
      hidden: isWorkWeixinUser,
    },
    {
      href: '/auth_record',
      des: '我的授权记录',
      hidden: isNewUeser,
    },
    {
      href: '/messagecenter',
      des: '消息中心',
    },
  ];

  import dayjs from 'dayjs';
  import { mapActions } from 'vuex';
  import ChangeTeamModal from "./changeTeam/changeTeam.vue";

  export default {
    inject: ['header', 'app'],
    components: {
      ChangeTeamModal
    },
    props: {
      userInfo: {
        type: Object,
        required: true,
        default() {
          return {
            nickname: '',
            email: '',
            userHeadImgUrl: '',
            urnCount: 0,
          };
        },
      },
      route: {
        type: Object,
      },
      isIndex: {
        type: Boolean,
        default: false,
      },
      isFuseNewTeam:{
        type: Boolean,
        default: false,
      },
      isLogin: {
        type: Boolean
      },
      teamInfo: {
        type: Object,
        default() {
          return {};
        },
      },
      normalNotice: {
        type: Object,
        default() {
          return {
            subject: '',
            content: '',
            noticeId: 0,
          };
        },
      },
      scrollTop: {
        type: Number,
        default: 0,
      },
      isWorkWeixinUser: {
        type: Boolean,
        default: false,
      },
      teams: {
        type: Array,
        default: () => [],
      },
      wwAuthUser: {
        type: String,
      },
      messageCountInfo: {
        type: Object,
        default: () => {}
      },
      modalData: {
        type: Object,
        default: () => {}
      },
      halfPriceTimeOut: {
        type: Boolean,
        default: false
      },  
    },
    data() {
      return {
        normalNoticeShow: false,
        selectedId: this.header.teamId,
        clientHeight: 0,
        modifyTempDesigner: false,
        localCanCreateFreeTeam: false,
        showTeamCar: false,
        VIP_TEAM_VERSION,
        timer:null,
      };
    },
    computed: {
      showHalfPriceButton(){
        return (this.modalData.switchStatus===2||this.modalData.switchStatus===3)&&!this.halfPriceTimeOut
      },
      isVipPage(){
        return this.$route.path === '/price/vip'
      },
      showUpgradeEnterprise(){
        return this.curTeamAdminVipLevel == 1 ||this.curTeamAdminVipLevel == 4 || this.curTeamAdminVipLevel == 199 || this.curTeamAdminVipLevel == 201 || this.curTeamAdminVipLevel == 211
      },
     // 角色 设计师、创建者、管理者
      showSettingTeamRole() {
        return this.teamInfo.teamRoleEnum === 'Administrator'|| this.teamInfo.teamRoleEnum === 'Manager'|| this.teamInfo.teamRoleEnum === 'Designer'
      },
      // eslint-disable-next-line vue/return-in-computed-property
      showTeamEntry() {
        if (this.isTeamAdmin&&this.canCreateFreeTeam){
          return false
        } else if (this.showSettingTeamRole&&this.isFuseNewTeam){
          return true
        } else {
          return false
        }
      },
      userName() {
        let nickname = 'chuangker';
        if (this.userInfo.nickname) {
          const fixedString = this.userInfo.nickname.replace(/%/g, '%25');
          nickname = decodeURIComponent(fixedString);
        }
        return nickname;
      },
      computedPopList() {
        const isDesigner =
          this.userInfo.designerCheckStatus === 2 && !this.modifyTempDesigner;
        // 2021-03-26 之后算新用户
        const isNewUeser = this.userInfo.regTime > 1616688000000;
        return popList(isDesigner, this.isWorkWeixinUser, isNewUeser).filter(
          (item) => !item.hidden,
        );
      },
      // 未读消息数
      messageCount() {
        return this.messageCountInfo.totalUnReadCount||0;
      },
      // 当前所在团队的vip等级
      curTeamAdminVipLevel() {
        let vipLevel = 0;
        if (this.teamInfo.adminUserMemberExpire > Date.now()) {
          vipLevel = this.teamInfo.adminUserVipLevel;
        }
        return vipLevel;
      },
      // 用户版本
      userVersionName() {
        let name = '';
        if (this.header.isProVip) {
          name = VIP_VERSION[this.curTeamAdminVipLevel];
        } else {
          name = VIP_VERSION[this.curTeamAdminVipLevel] || '个人免费版';
        }
        return name;
      },
      templateDetailBarStyle() {
        let result = {};
        if (this.route.path.includes('/muban/td')) {
          result = {
            width: 255 + 'px',
          };
        }
        return result;
      },
      // 剩余会员时长
      getVipDescription() {
        let des = '开通VIP尊享特权';
        try {
          const { adminUserMemberExpire } = this.teamInfo;
          if (this.curTeamAdminVipLevel > 0) {
            const expireStr = dayjs(Number(adminUserMemberExpire)).format('YYYY.MM.DD');
            des = this.userVersionName;
            des += `(${expireStr}到期)`;
          }
        } catch (error) {
          console.error(error);
        }

        return des;
      },
      // 在他人团队时的提示语. 免费团队 / 付费团队
      getOtherVipDescription() {
        let des = '开通团队协作版套餐，尊享团队协作全部特权';
        try {
          const { adminUserMemberExpire, adminUserVipLevel } = this.teamInfo;
          if (VIP_TEAM_VERSION.includes(this.curTeamAdminVipLevel)) {
            const expireStr = dayjs(Number(adminUserMemberExpire)).format('YYYY.MM.DD');
            const versionName = VIP_VERSION[adminUserVipLevel]
            des = `${versionName}（${expireStr}到期）`
          }
        } catch (error) {
          console.error(error);
        }
        return des;
      },
      canCreateFreeTeam() {
        return this.app.canCreateFreeTeam === undefined ? this.localCanCreateFreeTeam : this.app.canCreateFreeTeam
      },
      // 是否在自己创建的团队内
      isTeamAdmin() {
        return this.teamInfo.teamRoleEnum === 'Administrator'
      },
      // 判断是否加入过别人的团队
      isJoinedTeam() {
        const { teams } = this
        return teams.some(team => team.teamRoleEnum !== 'Administrator')
      },
      userOwnIconType() {
        // normal, enterprise, individual
        const { userInfo: { vipLevel } } = this
        return getVipTypeByLevel(vipLevel)
      },
      // 用户名下面的状态, 跟随用户团队/个人切换
      userCurrentVersionInfo() {
        const { canCreateFreeTeam, userVersionName, userOwnIconType, curTeamAdminVipLevel } = this
        let versionName = '个人免费版'
        let versionType = 'normal'
        const { userSelfInfo: { teamId }, selectedId } = this
        if (canCreateFreeTeam && teamId === selectedId) {
          // 未开通团队协作， 展示的是个人的状态
          versionName = userVersionName
          versionType = userOwnIconType
        } else {
          // 已开通团队协作， 展示的是当前团队的状态
          versionName = VIP_TEAM_VERSION.includes(curTeamAdminVipLevel) ? '付费团队' : '免费团队'
          versionType = versionName === '付费团队' ? 'enterprise' : 'normal' 
        }
        return { versionName, versionType }
      },
      // 用户自己创建的团队， 可能有多个，以前的老逻辑
      userOwnTeams() {
        return this.teams.filter(team => team.teamRoleEnum === 'Administrator').map(team => {
          const { teamName, adminUserName, adminUserVipLevel, } = team
          let name = decodeURIComponent(teamName).trim() || decodeURIComponent(adminUserName).trim() + '的团队'
          let versionName
          let versionType
          versionName = VIP_TEAM_VERSION.includes(adminUserVipLevel) ? '付费团队' : '免费团队'
          versionType = versionName === '付费团队' ? 'enterprise' : 'normal'
          // 判断过期状态
          if (this.isExpireTeam(team)) {
            versionName = '免费团队'
            versionType = 'normal'
          }
          return { ...team, name, versionName, versionType }
        })
      },
      userSelfInfo() {
        const { userName: name, userOwnIconType, userOwnTeams: [team = {}] } = this
        return { ...team, name, userOwnIconType }
      },
      // 用户自己的信息或者自己创建的团队的信息
      userSelfOrTeamsRowInfo() {
        // 未开通团队协作，但是已加入过其他人的团队，则展示自己的个人信息
        // isJoinedTeam 曾经加入过别人的团队
        const { isJoinedTeam, canCreateFreeTeam, userOwnTeams, userSelfInfo } = this
        const isHaveOwnTeam = !canCreateFreeTeam // 自己开通过团队
        const isShow = isHaveOwnTeam || isJoinedTeam
        return {
          isShow,
          isHaveOwnTeam,
          list: isHaveOwnTeam ? userOwnTeams : [userSelfInfo]
        }
      },
    },
    watch: {
      wwAuthUser(v) {
        this.$nextTick(() => {
          const tag = document.createElement('ww-open-data');
          tag.setAttribute('id', 'ww-open-data');
          tag.setAttribute('type', 'userName');
          tag.setAttribute('openid', v);
          document.getElementById('open-data').appendChild(tag);
          window.WWOpenData.bind(document.getElementById('ww-open-data'));
        });
      },
    },
    mounted() {
      this.getDesignerInfo();
      this.getLocalCanCreateFreeTeam()
    },
    methods: {
      ...mapActions(['setRechargeShow']),
      canShowNormalNotice() {
        this.normalNoticeShow = this.normalNotice.content.length > 0;
      },
      isIncludeEmoji(item){
        let reg = /[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/;
        if(reg.test(item.teamName)){
          return true
        }else{
          return false
        }
      },
        //创建团队
      createModalFun(){
        this.$emit('createModal')
      },
      clearTimer(){
        if(this.timer){
          clearTimeout(this.timer)
        }
      },
      showRechargePop() {
        //如果当前路由是在VIP会员页，直接显示充值窗口，如果不是，将跳转VIP页面后显示
        if (this.$route.path.startsWith('/price/vip')) {
          this.setRechargeShow(true);
        } else {
          location.href = '/price/vip?recharge=true';
        }
      },
      logout() {
        this.$emit('logout');
      },
      handleClickNoticeIcon() {
        this.$emit('messageShow');
      },
      buyNow() {
        let level = this.userInfo.vipLevel;
        try {
          const value = !level ? 'renewal' : 'buy';
          // 续费
          window.gio('track', `portrait_${value}_click`);
        } catch (error) {
          throw error;
        }
        level = !level ? 4 : level;
        this.header.buyNow(34, level);
      },
      openDifer(){
        window.open('/difference?fmodule=txinfo_upqy&fpage=topbar&level=205')
      },
      goBuyNow() {
        this.$message('请联系团队的创建者购买')
      },
      jumpToAccountCenter() {
        // 2021/9/9 不跳转旧版用户中心，跳转至新版用户中心页
        //   return this.jumpCheck('/accountcenter');
        return this.jumpCheck('/accountcenter/v2/user');
      },
      jumpCheck(link) {
        return this.header.jumpCheck(link);
      },
      // 获取团队名称
      getTeamName(item,isSubstr) {
        let name = '';
        const { teamName, adminUserName } = item;
        try {
          if (teamName && teamName.trim()) {
            name = decodeURIComponent(teamName.trim());
          } else if (adminUserName) {
            name = `${decodeURIComponent(adminUserName.trim())}的团队`;
          }
        } catch (error) {
          if (teamName && teamName.trim()) {
            name = decodeURIComponent(teamName.replace(/%/g, '%25').trim());
          } else if (adminUserName) {
            name = `${decodeURIComponent(adminUserName.replace(/%/g, '%25').trim())}的团队`;
          }
        }
        if(isSubstr){
          name= name.substr(0, 1)
        }
        return name;
      },
      //切换状态
      switchBtn(showState,timeState=false){
        if(showState==2){
          if(this.timer){
            clearTimeout(this.timer)
          }
          this.$refs.changeTeamModal.showMoadl()
        }else{
          if(timeState){
            this.$refs.changeTeamModal.hideMoadl()
            this.$emit('clearCreateDesignTime',true)
            return
          }
          this.timer =setTimeout(() => {
            this.$refs.changeTeamModal.hideMoadl()
          }, 1000);
        }
      },
      // // 切换团队
      // skipToTeamManage(team) {
      //   if (this.selectedId === team.teamId) return;

      //   const data = {
      //     tid: team.teamId,
      //   };
      //   this.$http.post('/user/updateUserCurTid', data).then((res) => {
      //     // -1参数错误;-2未登录;0操作错误;1操作成功
      //     const code = res.body.code;
      //     if (code === 1) {
      //       this.selectedId = team.teamId;
      //       this.$emit('getUserInfo');
      //       this.$emit('getVipDetail');
      //       this.$emit('setTeamId', team.teamId);
      //       this.$emit('setwwAuthUser', '');
      //       localStorage.removeItem(`isWxworkLogin_${this.userInfo.userId}`);
      //     } else {
      //       this.$message(`团队切换失败，错误码：${code}`);
      //     }
      //   });
      // },
      // 获取设计师信息
      getDesignerInfo() {
        this.$http
          .post('/contentcopyright/designer/getDesignerInfo', { gw: true })
          .then((res) => {
            const { code, data } = res.body;
            this.modifyTempDesigner = code === 200 && data.superState === 1;
          });
      },
      // 判断是否过期团队
      isExpireTeam(teamInfo) {
        return teamInfo.adminUserMemberExpire - Date.now() <= 0;
      },
      async getLocalCanCreateFreeTeam() {
        try {
          const { data: {body : {code, data}} } = await this.$http.post('/team/team/freeUseTeamPop', { gw: true }) || {}
          this.localCanCreateFreeTeam = code === 200 && data
        } catch (error) {
          console.log(error)
        }
       
      },
      goEnterprisePage(){
        window.location.href = '/designtools/enterprise/accountOverview'
      },
    },
  };
</script>

<style lang="less">
  .normal-notice__content a {
    color: #0773fc;
  }
  // 为兼容引导层显示样式，放在公共位置，而非scoped内
  #GUIDE_3_USER {
    .flex(@row: center, @col: center, @direction: row) {
      display: flex;
      align-items: @col;
      justify-content: @row;
      flex-direction: @direction;
    }
    .ellipsis {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .flex();
    position: relative;
    height: 40px;
    .user-img {
      position: relative;
      font-size: 0;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      .designer-symbol {
        position: absolute;
        right: -6px;
        bottom: -6px;
        width: 16px;
        height: 20px;
        background-image: url('../img/designer_symbol.svg');
        background-repeat: no-repeat;
        display: inline-block;
      }
      .teamInfo-symbol{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 16px;
        height: 16px;
        font-size: 10px;
        background-color:#E6F1FE;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: #0773FC;
      }
    }
    .user-text {
      .flex(center, flex-end, column);
      height: 44px;
      margin-right: 16px;
      max-width: 110px;
      .user-name-row {
        display: flex;
        justify-content: right;
        align-items: center;
        &:hover .user-icon .hover-tip {
          display: block;
          position: relative;
          z-index: 1002;
        }
        .user-icon {
          width: 20px;
          height: 20px;
          position: relative;
          margin-right: 4px;
          background-image: url('../img/normal-icon.png');
          background-size: contain;
          &.normal {
            background-image: url('../img/normal-icon.png');
          }
          &.enterprise {
            background-image: url('../img/enterprise-icon.png');
          }
          &.individual {
            background-image: url('../img/individual-icon.png');
          }
          .hover-tip {
            display: none;
            position: absolute;
            bottom: 0;
            height: 28px;
            padding: 0 8px;
            line-height: 28px;
            background: #1B2337;
            border-radius: 2px;
            color: #fff;
            font-size: 12px;
            width: max-content;
            left: -16px;
            top: 24px;
            &::before {
              content: '';
              width: 6px;
              height: 6px;
              transform: rotate(45deg);
              position: absolute;
              top: -3px;
              left: 24px;
              background-color: #1B2337;
            }
          }
        }
        .user-name {
          font-size: 12px;
          color: #1B2337;
          line-height: 18px;
          font-weight: 600;
          .ellipsis;
          max-width: 7em;
          text-align: right;
        }
      }
      .user-version-row {
        padding: 3px 0;
        .version-desc {
          height: 20px;
          line-height: 20px;
          background-color: #EBEEF5;
          border-radius: 2px;
          font-size: 12px;
          color: #505A71;
          padding: 0 8px;
          &:empty {
            background-color: transparent;
          }
          &.individual {
            color: #0773FC;
            background-color: #E6F1FE;
          }
          &.enterprise {
            color: #6B3D1E;
            background-image: linear-gradient(90deg, #FFE9D2 0%, #FFBF8F 100%);
          }
        }
      }
    }
    .pop-line {
      position: absolute;
      visibility: hidden;
    }
  }
</style>

<style lang="less" scoped>
  @import 'userbar.less';
</style>
