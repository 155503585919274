<template>
  <div class="recommend-page">
    <div class="sub-title">加入我们，一起创建设计</div>
    <div class="small-des">创客贴感谢每一位伙伴一路以来的支持与陪伴</div>
    <div class="des-card-box" ref="desCardBox">
      <div class="des-card" v-for="(item, index) in desCard" :key="index">
        <div class="user-head">
          <div
            class="user-icon"
            :style="{
              backgroundPosition: `-${index * 64}px 0`,
            }"
          ></div>
          <div class="user-title">{{ item.user }}</div>
        </div>
        <div class="divide"></div>
        <div class="user-des">
          {{ item.des }}
        </div>
      </div>
    </div>
    <div class="scroll-box">
      <div
        class="scroll-button left"
        :class="{ disable: leftLength <= 0 }"
        @click="moveToPre"
      ></div>
      <div
        class="scroll-button right"
        :class="{ disable: isRightDisable }"
        @click="moveToNext"
      ></div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        isRightDisable: false,
        leftLength: 0,
        desCard: [
          {
            user: '商品运营',
            des: '以前每次遇到双十一、六一八这种节日时，物料准备都是个很头疼的问题，但是用创客贴之后，可以提前做内容规划，小组内大家在统一设计规范下快速制作适配不同渠道的物料内容，非常大的程度提高了我们物料制作的效率。',
          },
          {
            user: '芋泥波波奶糖',
            des: '作为社媒博主我们都内容需要有明显的个人IP特征，同时为了维护粉丝的活跃度需要快速更新内容，每天内容更新的压力很大，通过创客贴的团队模板我可以非常快速的做出带有我个人IP的内容，通过简单修改可以快速产出非常多内容，这为我节约了很多时间',
          },
          {
            user: 'CUlater',
            des: '每次商品上新还有节日促销都需要大量宣传物料，以前这些得找专门的人来做，时间和金钱成本都比较高，现在用创客贴了，我自己拍照自己做海报，做完的图一方面可以发朋友圈，另一方面还可以直接在创客贴下单印刷，不仅便宜还为我节约了很多时间。',
          },
          {
            user: '辰心',
            des: '我是行政部门，公司经常举办一些活动，在活动中都需要电子海报、三折页和易拉宝等物料。有时候活动需求很急，协调设计师再印刷根本来不及。经朋友推荐，使用了创客贴设计工具，发现不光有大量的模板，还可以直接印刷，真的太方便。',
          },
          {
            user: '内容运营',
            des: '我是运营，经常需要介绍项目和活动内容等，长图海报是最常用的形式。一般内部从需求-排期-输出需要至少一周的时间，而且输出后的调整和更改很麻烦，使我很苦恼。在网上搜索海报设计工具时，找到了创客贴设计。试用了下，有大量海报可以选择，而且编辑很简单，出图快，就算经常有文案变动也是几分钟就解决，在工作上帮了我大忙。',
          },
          {
            user: '店主',
            des: '我是个体，宣传商品和做活动的时，需要易拉宝，展架这些材料。找人设计的费用很高，而且印刷很麻烦，老得来回跑。同行推荐了创客贴设计工具，发现里面有很多现成的模板，只需要更改文字就可以了，很简单。最主要的制作完成后，可以直接印刷，第二天就送到了，真是省钱又省心啊。',
          },
          {
            user: '培训师',
            des: '我是老师，在招生和做讲义的时候，经常需要做海报，自己做一张海报需要很长时间，自己也对自己做的海报不是很满意。偶然的机会接触到了创客贴设计，里面有教育培训专栏，海报更是多种多样，能符合不同工作的需求。在一次公开课上，我的讲义被校领导表扬了。课后其他老师都问我哪里学的设计，真的太高兴了，感谢平台帮助了我。',
          },
          {
            user: 'Designer',
            des: '我是一名设计师，现在设计需求越来越多，要求的时效也越来越短，满满的工作排期，真的是一刻也闲不下来。看到同组的其他同事，产出比我多，而且也没这么忙，我一直很好奇为什么，后来发现他在用创客贴设计工具，我自己也试了下，这里有好多素材，很多做好了的模板。可以很快的制作出高质量设计图，同时还能给予一些设计灵感，哈哈，我真的感觉发现了宝藏，原来真的是找对的方法比勤奋更重要。',
          },
          {
            user: '老粉',
            des: '用创客贴已经七八年了，这几年里看到了创客贴非常多的改变，这些年也在创客贴上创建了非常多的设计。从在学校期间的一张宣传海报、到大学毕业时的一份简历、再到毕业工作后的一张促销宣传单等等都有创客贴的身影，现在回头看历史的那些设计满满的都是回忆，非常感谢创客贴。',
          },
        ],
      };
    },
    methods: {
      moveToPre() {
        const leftLength = this.$refs.desCardBox.scrollLeft - 441;
        this.leftLength = leftLength;
        this.isRightDisable = false;
        this.$refs.desCardBox.scrollTo({
          left: leftLength,
          behavior: 'smooth',
        });
      },
      moveToNext() {
        const leftLength = this.$refs.desCardBox.scrollLeft + 441;
        this.leftLength = leftLength;
        this.$refs.desCardBox.scrollTo({
          left: leftLength,
          behavior: 'smooth',
        });
        console.log(
          '滚了多少',
          '元素内容总宽度',
          '可见宽度',
          this.$refs.desCardBox.scrollLeft,
          this.$refs.desCardBox.scrollWidth,
          this.$refs.desCardBox.clientWidth,
        );
        // 判断是否已经滚动到最右边
        if (
          this.$refs.desCardBox.scrollLeft >=
          this.$refs.desCardBox.scrollWidth - this.$refs.desCardBox.clientWidth
        ) {
          this.isRightDisable = true;
        }
      },
    },
  };
</script>
<style lang="less">
  .recommend-page {
    width: 100%;
    height: 862px;
    background: #FAFAFA;
    background-image: url('../img/dot-img.svg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    .sub-title {
      margin-top: 110px;
    }
    .small-des {
      margin-bottom: 80px;
    }
    .des-card-box {
      width: 100%;
      padding: 0  176px;
      display: flex;
      align-items: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;

      .des-card {
        width: 441px;
        height: auto;
        padding: 40px 48px;
        border-radius: 32px;
        border: 1px solid var(--5, #ebeef5);
        background: #fff;
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.03);
        &:not(:first-child) {
          margin-left: 24px;
        }
        .user-head {
          display: flex;
          align-items: center;
          .user-icon {
            width: 64px;
            height: 64px;
            background-image: url('../img/spring-head-icon.png');
            background-size: cover;
            background-repeat: no-repeat;
          }
          .user-title {
            color: var(--1, #1b2337);
            /* 标题/标题4 */
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 600;
            margin-left: 24px;
          }
        }
        .divide {
          width: 345px;
          height: 1px;
          margin: 24px 0;
          background-color: #ebeef5;
        }
        .user-des {
          width: 345px;
          color: var(--1, #1b2337);
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
        }
      }
    }
    .des-card-box::-webkit-scrollbar {
      display: none;
    }
    .scroll-box{
      margin-top: 48px;
    }
  }
</style>
