<template>
  <div class="home-pagefooter">
    <!-- 友情链接 -->
    <div class="innerLinkes-box">
      <div class="innerlinks">
        <lt-collapse-transition>
          <div class="innerlinks__content" ref="innerlinks__content">
            <div class="innerlinks__parent">
              <span
                v-for="(item, index) in parentLinkList"
                :class="{ selected: item.parentId == selectedParentId }"
                :key="index"
                @click="showInnerLinks(item.parentId)"
                >{{ item.name }}</span
              >
            </div>
            <div
              class="innerlinks__second"
              v-for="(item, index) in secondKindLinkList"
              v-show="item.parentId == selectedParentId"
              :key="index"
            >
              <a
                v-for="(son, i) in item.sonlinkList"
                :key="i"
                :href="son.link || son.linkAddress"
                target="_blank"
                :rel="son.noFollow === 1 ? 'nofollow' : ''"
                >{{ son.name || son.linkName }}</a
              >
            </div>
          </div>
        </lt-collapse-transition>
      </div>
    </div>
    <div class="divide"></div>
    <div class="center-box">
      <div class="left">
        <div class="logo"></div>
        <!-- 图标和二维码 -->
        <div
          class="service-icon-container"
          @mouseleave="showQrCodeBottomBox = false"
        >
          <div
            class="service-icon"
            v-for="item in bottomIconList"
            @mouseenter="showFooterQrCode(item)"
            :style="{
              backgroundImage: `url(${require(`../img/${item}-icon-white.svg`)})`,
            }"
          ></div>
          <div
            class="qrcode-box"
            v-show="showQrCodeBottomBox"
            :style="{ left: getQrCodeBottomLeft + 'px' }"
          >
            <div class="qrcode">
              <div
                class="qrcode-img"
                :style="{
                  backgroundImage: `url(${require(`../img/qrcode-${footerCodeType}.png`)})`,
                }"
              ></div>
              <span v-show="footerCodeType === 'mobile'" class="qrcode-title"
                >iOS/Android</span
              >
              <span v-show="footerCodeType === 'mobile'" class="qrcode-des">{{
                footerCodeType === 'mobile'
                  ? '下载APP畅享多端协作'
                  : '咨询顾问了解企业定制化服务'
              }}</span>
            </div>
          </div>
        </div>
        <!-- 更多产品服务 -->
        <a href="https://www.chuangkit.com/clientpage" target="_blank"
          >更多产品服务</a
        >
      </div>
      <div class="center-divide"></div>
      <div class="design-res">
        <div class="friend-link-title">设计资源</div>
        <a
          v-for="(item, i) in designRes"
          :key="i"
          :href="item.url"
          target="_blank"
          >{{ item.name }}</a
        >
      </div>
      <div class="product-service">
        <div class="friend-link-title">产品服务</div>
        <a
          v-for="(item, i) in productService"
          :key="i"
          :href="item.url"
          target="_blank"
          >{{ item.name }}</a
        >
      </div>
      <div class="tool">
        <div class="friend-link-title">工具箱</div>
        <a
          v-for="(item, i) in toolService"
          :key="i"
          :href="item.url"
          target="_blank"
          >{{ item.name }}</a
        >
      </div>
      <div class="extra-service">
        <div class="friend-link-title">拓展服务</div>
        <a
          v-for="(item, i) in extraService"
          :key="i"
          :href="item.url"
          target="_blank"
          >{{ item.name }}</a
        >
      </div>
      <div class="help">
        <span class="friend-link-title">帮助咨询</span>
        <span class="custom-online" @click="openCustom">在线客服</span>
        <span class="tel">商务合作：782-297-964</span>
        <span class="tel">联系电话：400-871-8211</span>
        <span class="tel">工作时间：9:30~18:30</span>
      </div>
    </div>
    <div class="footer-bottom">
      <a
        v-for="(item, i) in footerBottomList"
        :key="i"
        :href="item.url"
        target="_blank"
        >{{ item.name }}</a
      >
    </div>
    <div class="nav-container"></div>
    <footerCopyright theme="dark" />
  </div>
</template>
<script>
  import LtCollapseTransition from '../../../transitions/collapse-transition';
  import footerCopyright from 'layouts/footer/footer-copyright.vue';
  export default {
    inject: ['app'],
    components: {
      LtCollapseTransition,
      footerCopyright,
    },
    data() {
      return {
        parentLinkList: [], // 内链一级分类数据
        secondKindLinkList: [], // 内链二级分类数据
        selectedParentId: 1, // 选择的一级分类 ID
        designRes: [
          {
            name: '海报模板',
            url: 'https://www.chuangkit.com/sj-pi20-or0-pt0-bt1-pn1.html',
          },
          {
            name: 'PPT模板',
            url: 'https://www.chuangkit.com/sj-pi3-or0-pt0-bt1-pn1.html',
          },
          {
            name: 'H5模板',
            url: 'https://www.chuangkit.com/sj-pi1459-or0-pt0-bt1-pn1.html',
          },
          {
            name: '视频模板',
            url: 'https://www.chuangkit.com/sj-pi44-or0-pt0-bt1-pn1.html',
          },
          { name: '高清图片', url: 'https://www.chuangkit.com/image' },
          { name: '版权素材', url: 'https://www.chuangkit.com/material' },
        ],
        productService: [
          {
            name: '定制设计',
            url: 'https://www.chuangkit.com/design-service?from=ckt',
          },
          {
            name: '在线印刷',
            url: 'https://www.chuangkit.com/print/printcenter',
          },
          { name: '内容中台', url: 'https://www.chuangkit.com/contech' },
          {
            name: '团队协作',
            url: 'https://www.chuangkit.com/contech/product/coopration',
          },
          {
            name: '开放平台',
            url: 'https://www.chuangkit.com/terms/introduce',
          },
          { name: '创客贴AI', url: 'https://aiart.chuangkit.com/matrix' },
        ],
        toolService: [
          { name: '智能设计', url: 'https://aiart.chuangkit.com/matrix' },
          { name: '智能抠图', url: 'https://www.chuangkit.com/koutu' },
          { name: '图片编辑', url: 'https://www.chuangkit.com/picture' },
          { name: '图片修复', url: 'https://www.chuangkit.com/eliminate' },
          { name: '批量设计', url: 'https://www.chuangkit.com/templatebatch' },
          {
            name: '智能改图',
            url: 'https://aiart.chuangkit.com/print/inpainting',
          },
        ],
        extraService: [
          {
            name: 'API 接入',
            url: 'https://www.chuangkit.com/terms/introduce',
          },
          // { name: '功能定制', url: 'https://www.chuangkit.com/contech' },
          {
            name: '屏幕营销',
            url: 'https://www.chuangkit.com/screen-marketing',
          },
          {
            name: '视频审片',
            url: 'https://www.chuangkit.com/video-cooperation',
          },
          {
            name: '公益版',
            url: 'https://www.chuangkit.com/enterpriseSaasLandPage',
          },
          {
            name: '企业解决方案',
            url: 'https://aiart.chuangkit.com/solutions/enterprise',
          },
          {
            name: '渠道合作',
            url: 'https://www.chuangkit.com/activity/channelAgentPage',
          },
        ],
        bottomIconList: ['mobile', 'xiaochengxu', 'weixin', 'weibo', 'video'],
        footerCodeType: 'mobile',
        showQrCodeBottomBox: false,
        footerBottomList: [
          { name: '关于我们', url: 'https://www.chuangkit.com/help/aboutus' },
          { name: '联系我们', url: 'https://www.chuangkit.com/help/contactus' },
          {
            name: '用户协议',
            url: 'https://www.chuangkit.com/help/useragreement',
          },
          {
            name: '隐私政策',
            url: 'https://www.chuangkit.com/help/privacypolicy',
          },
          { name: '帮助中心', url: 'https://support.qq.com/product/55374' },
          {
            name: '常见问题',
            url: 'https://support.qq.com/products/55374/faqs-more/',
          },
          { name: '意见反馈', url: 'https://support.qq.com/product/55374' },
          { name: '设计文章', url: 'https://www.chuangkit.com/blog' },
          { name: '更多服务', url: 'https://www.chuangkit.com/clientpage' },
        ],
      };
    },
    mounted() {
      this.$http
        .post('/home/getFriendlyLinks', { apidomainv2: true })
        .then((res) => {
          let links = res.body.data && res.body.data.friendlyLinks;
          let arr = [];
          let friendLinks = res.body.data.innerLinks;
          let fl = {};
          let fc = {};
          let moreLink = {};
          for (let i in links) {
            if (links[i].linkPosition !== 2) {
              arr.push(links[i]);
            }
          }
          fl.parentId = '-1';
          fl.name = '友情链接';
          fc.parentId = '-1';
          fc.sonlinkList = arr;
          moreLink.linkAddress = '/help/friendshiplink';
          moreLink.linkName = '更多友链>>';
          friendLinks.parentLinkList.unshift(fl);
          friendLinks.secondKindLinkList.unshift(fc);
          friendLinks.secondKindLinkList[0].sonlinkList.push(moreLink);
          this.setInnerLinks(friendLinks);
        });
    },
    computed: {
      getQrCodeBottomLeft() {
        let num = 18;
        switch (this.footerCodeType) {
          case 'mobile':
            num = 18;
            break;
          case 'xiaochengxu':
            num = 66;
            break;
          case 'weixin':
            num = 114;
            break;
          case 'weibo':
            num = 162;
            break;
          case 'video':
            num = 210;
            break;

          default:
            break;
        }
        return num;
      },
    },
    methods: {
      setInnerLinks(v) {
        this.parentLinkList = v.parentLinkList;
        this.secondKindLinkList = v.secondKindLinkList;
        this.showInnerLinks();
      },
      showInnerLinks(pId) {
        if (pId) {
          this.selectedParentId = pId;
        } else {
          this.selectedParentId = this.parentLinkList[0]
            ? this.parentLinkList[0].parentId
            : 1;
        }
      },
      showFooterQrCode(type) {
        this.footerCodeType = type;
        this.showQrCodeBottomBox = true;
      },
      openCustom() {
        this.app.contactService('web_品牌首页_页底客服');
      },
    },
  };
</script>
<style lang="less">
  .home-pagefooter {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(27, 35, 55, 1);
    .innerLinkes-box {
      margin: 0 auto;
      padding: 32px 190px 26px 136px;
      position: relative;
    }
    .innerlinks {
      margin: 0 auto;
      // position: relative;
      transition-duration: 1s;
      transition-timing-function: cubic-bezier(0.38, 0.67, 0.74, 1.05);
      transition: all ease 0.4s;
      .second_move {
        position: absolute;
        left: 0;
        bottom: 15px;
        height: 20px;
        z-index: 100;
        cursor: pointer;
        line-height: 20px;
        color: #9b9b9b;
        .innerlinks__triangle {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          display: inline-block;
          width: 18px;
          height: 18px;
          background-color: #0773fc;
          margin-left: 4px;
          border-radius: 4px;
          cursor: pointer;
          &::before {
            position: absolute;
            left: 50%;
            top: 50%;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: #fff transparent transparent transparent;
            transform: translate(-50%, -50%);
            transition: all ease 0.4s;
          }
          &[class~='rotate'] {
            &:before {
              transform: translate(-50%, -50%) rotate(180deg);
            }
          }
        }
      }
    }

    .innerlinks__parent {
      margin-bottom: 16px;
      span {
        height: 28px;
        padding: 4px 10px;
        display: inline-block;
        border-radius: 27px;
        background: rgba(255, 255, 255, 0.05);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: var(--1, #fff);
        cursor: pointer;
        &:hover {
          background: rgba(255, 255, 255, 0.2);
          color: #fff;
        }
        &:not(:first-child) {
          margin-left: 6px;
        }
        &[class~='selected'] {
          background: #fff;
          color: rgba(27, 35, 55, 1);
        }
      }
    }
    .innerlinks__second {
      position: relative;
      height: 46px;
      overflow: hidden;
      a {
        display: inline-block;
        color: #9b9b9b;
        margin: 0 24px 6px 0;
        line-height: 17px;
        height: 17px;
        font-size: 12px;
        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }
      span {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
        &[class~='active'],
        &:hover {
          color: #0773fc;
        }
      }
    }

    .divide {
      width: calc(100% - 272px);
      height: 1px;
      box-sizing: border-box;
      margin: 14px 136px 48px 136px;
      background: rgba(255, 255, 255, 0.1);
    }

    .center-box {
      width: 100%;
      height: 188px;
      padding: 0 136px;
      display: flex;
      justify-content: space-between;
      .left {
        width: 236px;
        height: 100%;
        .logo {
          width: 136px;
          height: 40px;
          background-image: url('../img/ckt-logo.png');
          background-size: contain;
          background-repeat: no-repeat;
        }
        .service-icon-container {
          display: flex;
          margin-top: 32px;
          margin-bottom: 24px;
          position: relative;
          .service-icon {
            width: 40px;
            height: 40px;
            position: relative;
            border-radius: 50%;
            background: rgba(74, 80, 96, 1);
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            &:not(:first-child) {
              margin-left: 8px;
            }
            &:hover {
              background: rgba(115, 121, 138, 1);
              background-position: center;
              background-repeat: no-repeat;
            }
          }
          .qrcode-box {
            left: 32px;
            top: -120px;
            margin-left: 32px;
          }
        }
        a {
          color: var(--1, #fff);
          /* 正文/正文3 */
          font-family: PingFang SC;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .center-divide {
        width: 1px;
        height: 158px;
        background: rgba(255, 255, 255, 0.1);
      }
      .friend-link-title {
        color: var(--1, #fff);
        /* 标题/标题6 */
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 166.667% */
        margin-bottom: 8px;
      }
      a {
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
      }
      .design-res {
        width: 60px;
        display: flex;
        flex-direction: column;
      }
      .product-service {
        width: 60px;
        display: flex;
        flex-direction: column;
      }
      .tool {
        width: 60px;
        display: flex;
        flex-direction: column;
      }
      .extra-service {
        width: 90px;
        display: flex;
        flex-direction: column;
      }
      .help {
        width: 200px;
        display: flex;
        flex-direction: column;
        color: #fff;
        .custom-online {
          width: 80px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          border-radius: 4px;
          background: var(--unnamed, #0773fc);
          cursor: pointer;
          margin: 16px 0;
          &:hover {
            background: rgba(6, 103, 226, 1);
          }
          &:active {
            background: rgba(5, 90, 198, 1);
          }
        }
        .tel {
          margin-bottom: 8px;
          font-size: 12px;
        }
      }
    }
    .nav-container {
      width: 100%;
      height: 76px;
    }
    .footer-bottom {
      display: flex;
      align-items: flex-start;
      gap: 72px;
      margin-top: 35px;
      margin-bottom: 24px;
    }
    .copyright {
      width: 100%;
      height: 36px;
      background-color: #111a32;
      color: var(--1, #fff);
      /* 正文/正文3 */
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 166.667% */
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }
</style>
