import * as types from '../mutation-types'

const state = {
  resize: Date.now(),
  appClick: Date.now(),
  teamSwitch: Date.now(),  //下拉团队切换
  collectAnimate: false,
  teamFontList: null,
  baiduFactor: {},
  bodyClickSign: false,
  searchKeywords: '',   //  图库搜索关键词
  templateSearchKeyWords: null,
  jobSubmitState: false,
  headerActivityShow:false,
}

const getters = {
  getResizeFlag: state => state.resize,
  getAppClick: state => state.appClick,
  getTeamSwitch: state => state.teamSwitch,
  getCollectAnimate: state => state.collectAnimate,
  getTeamFontList: state => state.teamFontList,
  getBaiduFactor: state => state.baiduFactor,
  getBodyClickSign: state => state.bodyClickSign,
  getSearchKeywords: state => state.searchKeywords,
  getTemplateSearchKeyWords: state => state.templateSearchKeyWords,
  getJobSubmitState: state => state.jobSubmitState,
  getHeaderActivityShow: state => state.headerActivityShow,
}

const mutations = {
  [types.SET_RESIZE_FLAG](state, v) {
    state.resize = v
  },
  setAppClick(state, v) {
    state.appClick = v
  },
  setTeamSwitch(state, v) {
    state.teamSwitch = v
  },
  setCollectAnimate(state, c) {
    state.collectAnimate = c
  },
  setTeamFontList(state, c) {
    state.teamFontList = c
  },
  setBaiduFactor(state, c) {
    state.baiduFactor = c
  },
  setBodyClickSign(state, c) {
    state.bodyClickSign = c
  },
  setSearchKeywords(state, v) {
    state.searchKeywords = v
  },
  setTemplateSearchKeyWords(state, v) {
    state.templateSearchKeyWords = v
  },
  setJobSubmitState(state, v) {
    state.jobSubmitState = v
  },
  setHeaderActivityShow(state, val) {
    state.headerActivityShow = val
  },
}

const actions = {
  setResize({ commit }, v) {
    commit(types.SET_RESIZE_FLAG, v)
  },
  setAppClick({ commit }, v) {
    commit('setAppClick', v)
  },
  setTeamSwitch({ commit }, v) {
    commit('setTeamSwitch', v)
  },
  setCollectAnimate({ commit }, v) {
    commit('setCollectAnimate', v)
  },
  setTeamFontList({ commit }, v) {
    commit('setTeamFontList', v)
  },
  setBaiduFactor({ commit }, v) {
    commit('setBaiduFactor', v)
  },
  setBodyClickSign({ commit }, v) {
    commit('setBodyClickSign', v)
  },
  setSearchKeywords({ commit }, v) {
    commit('setSearchKeywords', v)
  },
  setTemplateSearchKeyWords({ commit }, v) {
    commit('setTemplateSearchKeyWords', v)
  },
  setHeaderActivityShow({ commit }, val) {
    commit('setHeaderActivityShow', val)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
