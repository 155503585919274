/**
 * Created by Feng on 2017/7/5.
 */

import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import logreg from './modules/logreg'
import print from './modules/print'
import tplTDK from './modules/tplTDK'
import global from './modules/global'
import thirdpartyLogin from './modules/thirdpartyLogin'
import orderInfo from './modules/orderInfo'
import shopping from './modules/shoppingCart'
import recharge from './modules/recharge'
import guide from './modules/guide'
import designNames from './modules/designFile'
import tariffPackages from './modules/tariffPackages'
import message from './modules/message'
import teamExpired from './modules/teamExpired'
import functionJs from './modules/function'
import capacity from "@/store/modules/capacity";


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    print,
    user,
    logreg,
    tplTDK,
    global,
    thirdpartyLogin,
    orderInfo,
    shopping,
    recharge,
    guide,
    designNames,
    tariffPackages,
    message,
    teamExpired,
    functionJs,
    capacity,
  }
})
