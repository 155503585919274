const state = {
  teamFunctionServiceList: [],
};

const getters = {
  getTeamFunctionServiceList: (state) => state.teamFunctionServiceList,
};

const actions = {
  setTeamFunctionServiceList({ commit }, v) {
    commit('setTeamFunctionServiceList', v)
  },
};

const mutations = {
  setTeamFunctionServiceList(state, functionServiceList) {
    state.teamFunctionServiceList = functionServiceList;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
