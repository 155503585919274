var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"change-password-box"},[_c('div',{staticClass:"change-password"},[_c('div',{staticClass:"change-password-title"},[_vm._v("修改密码")]),_c('div',{staticClass:"change-password-tip"},[_vm._v(_vm._s(_vm.tip))]),_c('div',{staticClass:"change-password-item"},[_c('div',{staticClass:"change-password-item-title"},[_vm._v("原密码")]),_c('div',{staticClass:"change-password-item-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldPassword),expression:"oldPassword"}],staticClass:"a-input password-input",attrs:{"autocomplete":"off","type":"password","placeholder":"请输入原密码","max-length":18},domProps:{"value":(_vm.oldPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.oldPassword=$event.target.value}}})])]),_c('div',{staticClass:"change-password-item"},[_c('div',{staticClass:"change-password-item-title"},[_vm._v("新密码")]),_c('div',{staticClass:"change-password-item-input",class:{ 'has-error': _vm.invalid && _vm.password && !_vm.isFocus }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"a-input password-input",attrs:{"autocomplete":"new-password","type":"password","placeholder":`请输入${_vm.strongStatus ? 8 : 6}-18位新密码`,"max-length":18},domProps:{"value":(_vm.password)},on:{"focus":function($event){_vm.isFocus = true},"blur":function($event){_vm.isFocus = false},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{staticClass:"password-error-tip",style:({
            opacity: _vm.invalid && _vm.password && !_vm.isFocus ? 1 : 0,
          })},[_vm._v(" "+_vm._s(_vm.strongStatus ? "请按照密码要求重新设置密码" : "密码不符合要求，请重新输入")+" ")]),(_vm.strongStatus)?_c('div',{staticClass:"password-tip-icon",attrs:{"type":"icon-tishi"},on:{"mouseenter":function($event){$event.stopPropagation();_vm.tipShow = true},"mouseleave":function($event){$event.stopPropagation();_vm.tipShow = false}}}):_vm._e(),(_vm.strongStatus)?_c('PasswordTip',{style:({
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 'calc(100% + 12px)',
            transition: 'all 0.2s',
            opacity: _vm.tipShow || _vm.isFocus ? 1 : 0,
          }),attrs:{"errors":_vm.errors}}):_vm._e()],1)]),_c('div',{staticClass:"change-password-item last"},[_c('div',{staticClass:"change-password-item-title"},[_vm._v("确认密码")]),_c('div',{staticClass:"change-password-item-input",class:{ 'has-error': _vm.isDiff && _vm.confirmPassword && !_vm.isFocus }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"a-input password-input",attrs:{"autocomplete":"new-password","type":"password","placeholder":"请再次输入新密码","max-length":18},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value}}}),_c('div',{staticClass:"password-error-tip",style:({
            opacity: _vm.isDiff && _vm.confirmPassword ? 1 : 0,
          })},[_vm._v(" 两次密码输入不一致 ")])])]),_c('button',{staticClass:"change-password-btn",attrs:{"loading":_vm.loading,"disabled":_vm.disabled,"type":"primary"},on:{"click":_vm.save}},[_vm._v(" 保存 ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }