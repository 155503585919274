<template>
  <div :class="`custom-size custom-size__${type}`">
    <div class="size-title">
      <div class="size-title-width">宽度</div>
      <div class="size-title-height">高度</div>
    </div>
    <div class="size-input">
      <!-- 解决自动填充问题 -->
      <input type="text" class="hidden-input">
      <input type="password" class="hidden-input">
      <input
        :class="`size-input-width ${ widthError ? 'error' : '' }`"
        type="number"
        :min="0"
        v-model="width"
        autocomplete="off"
        @input="widthChange"
        @blur="onValidWidth"
      >
      <input
        :class="`size-input-height ${ heightError ? 'error' : '' }`"
        type="number"
        :min="0"
        v-model="height"
        autocomplete="off"
        @input="heightChange"
        @blur="onValidHeight"
      >
      <div class="select-custom-unit" ref="select" >
        <span class="select-custom-unit_more"
              v-click-outside="closeDrop"
              @click="dropDownShow = !dropDownShow"
        >{{ unit }}</span>
        <ul class="select-custom-unit_list" :class="{'hover': dropDownShow}">
          <li @click.stop="selectUnit(units)" :key="units.name" v-for="units in unitList">
            {{ units.unit }}（{{ units.zh }}）
          </li>
        </ul>
      </div>
      <div :class="`size-input-${isLock ? 'lock' : 'open'} ${ unLock ? 'un-lock' : ''}`" @click="onLock"/>
    </div>
    <div class="size-error" v-if="errorTip() && (widthError || heightError)">{{ errorTip() }}</div>
    <button
      :disabled="errorTip()"
      :class="{
          'size-btn': true,
          disabled: errorTip()
        }"
      @click="create"
    >创建新设计</button>
  </div>
</template>

<script>
export default {
  name: "custom-size",
  props: {
    type: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
      dropDownShow: false,
      unitList: [
        {'unit': 'px', 'zh': '像素', 'name': 'px(像素)'},
        {'unit': 'cm', 'zh': '厘米', 'name': 'cm(厘米)'},
        {'unit': 'mm', 'zh': '毫米', 'name': 'mm(毫米)'},
        {'unit': 'in', 'zh': '英寸', 'name': 'in(英寸)'},
      ],
      unit: 'px',
      isLock: false,
      lockScale: 1,
      width: '',
      height: '',
      widthError: false,
      heightError: false,
    }
  },
  computed: {
    unLock() {
      return (!this.width || !this.height || Math.min(this.width, this.height) < 1) && !this.isLock
    }
  },
  methods: {
    closeDrop() {
      this.dropDownShow = false
    },
    onLock() {
      if (this.unLock) {
        return;
      }
      this.isLock = !this.isLock;
      this.lockScale = this.width / this.height;
    },
    selectUnit(v) {
      this.unit = v.unit
      this.dropDownShow = false
      this.$refs.select.blur()
    },
    widthChange() {
      if (this.isLock) {
        this.height = Math.ceil(this.width / this.lockScale);
      }
    },
    heightChange() {
      if (this.isLock) {
        this.width = Math.ceil(this.height * this.lockScale);
      }
    },
    onValidWidth() {
      if (this.unit === 'px') {
        if (this.width && this.height && this.width * this.height > 80000000) {
          this.widthError = this.heightError = true;
          return '宽高的乘积不能超过 80000000 像素'
        } else {
          this.widthError = this.heightError = false;
        }
        if (this.width > 10000 || this.width < 40) {
          this.widthError = true;
        } else {
          this.widthError = false;
        }
        if (this.height && (this.height > 10000 || this.height < 40)) {
          this.heightError = true;
        } else {
          this.heightError = false;
        }
      }

      if (this.unit === 'mm') {
        if (this.width > 2000 || this.width < 15) {
          this.widthError = true;
        } else {
          this.widthError = false;
        }
      }

      if (this.unit === 'in') {
        if (this.width > 78 || this.width < 1) {
          this.widthError = true;
        } else {
          this.widthError = false;
        }
      }

      if (this.unit === 'cm') {
        if (this.width > 200 || this.width < 1.5) {
          this.widthError = true;
        } else {
          this.widthError = false;
        }
      }
    },
    onValidHeight() {
      if (this.unit === 'px') {
        if (this.height && this.height && this.width * this.height > 80000000) {
          this.widthError = this.heightError = true;
          return '宽高的乘积不能超过 80000000 像素'
        } else {
          this.widthError = this.heightError = false;
        }
        if (this.height > 10000 || this.height < 40) {
          this.heightError = true;
        } else {
          this.heightError = false;
        }
        if (this.width && (this.width > 10000 || this.width < 40)) {
          this.widthError = true;
        } else {
          this.widthError = false;
        }
      }

      if (this.unit === 'mm') {
        if (this.height > 2000 || this.height < 15) {
          this.heightError = true;
        } else {
          this.heightError = false;
        }
      }

      if (this.unit === 'in') {
        if (this.height > 78 || this.height < 1) {
          this.heightError = true;
        } else {
          this.heightError = false;
        }
      }

      if (this.unit === 'cm') {
        if (this.height > 200 || this.height < 1.5) {
          this.heightError = true;
        } else {
          this.heightError = false;
        }
      }
    },
    errorTip() {
      if (this.unit === 'px') {
        if (Math.max(this.width, this.height) > 10000 || Math.min(this.width, this.height) < 40) {
          return '尺寸范围为 40px * 10000px'
        }
        if (this.width * this.height > 80000000) {
          return '宽高的乘积不能超过 80000000 像素'
        }
      }

      if (this.unit === 'mm') {
        if (Math.max(this.width, this.height) > 2000 || Math.min(this.width, this.height) < 15) {
          return '宽高的取值范围为 15 - 2000mm（毫米）'
        }
      }

      if (this.unit === 'in') {
        if (Math.max(this.width, this.height) > 78 || Math.min(this.width, this.height) < 1) {
          return '宽高的取值范围为 1 - 78in（英寸）'
        }
      }

      if (this.unit === 'cm') {
        if (Math.max(this.width, this.height) > 200 || Math.min(this.width, this.height) < 1.5) {
          return '宽高的取值范围为 1.5 - 200cm（厘米）'
        }
      }

      this.widthError = this.heightError = false;

      return false;
    },
    create() {
      this.$emit('on-create', {
        unit: this.unit,
        width: this.width,
        height: this.height
      })
    }

  }
}
</script>

<style scoped lang="less">
/* 谷歌 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
/* 火狐 */
input{
  -moz-appearance:textfield;
}
.custom-size {
  padding: 6px 24px;
  &__large {
    .size-input {
      &-width {
        width: 100px;
        height: 40px;
      }
      &-height {
        width: 100px;
        height: 40px;
      }
    }
    .size-btn {
      width: 312px;
    }
  }
  &__normal {
    .size-input {
      &-width {
        width: 87px;
        height: 40px;
      }
      &-height {
        width: 87px;
        height: 40px;
      }
    }
    .size-btn {
      width: 284px;
    }
  }
  .size-title {
    display: flex;
    padding: 6px 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #8693AB;
    &-width {
      width: 87px;
      margin-right: 8px;
    }
    &-height {
      flex: 1;
    }
  }
  .size-input {
    display: flex;
    align-items: center;
    & > input {
      outline: none;
      border-radius: 4px;
      background: #FEFEFE;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      color: #1B2337;;
      font-size: 14px;
      line-height: 40px;
      margin-right: 8px;
      padding-left: 12px;

      &:hover {
        border: 1px solid #C1C6D0
      }

      &:focus {
        outline: none;
        border: 1px solid #0773FC;
        border-radius: 4px;
        box-shadow: 0 0 0 2px rgba(7, 174, 252, 0.1) !important;
      }
      &.error {
        border: 1px solid #FA2323;
        box-shadow: none !important;
      }
    }
    &-lock {
      width: 16px;
      height: 16px;
      background: url("./img/lock1.svg") no-repeat;
      cursor: pointer;
    }
    &-open {
      width: 16px;
      height: 16px;
      background: url("./img/lock.svg") no-repeat;
      cursor: pointer;
      &.un-lock {
        cursor: not-allowed;
      }
    }
    .select-custom-unit {
      width: 70px;
      box-sizing: border-box;
      height: 40px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      line-height: 40px;
      position: relative;
      cursor: pointer;
      display: inline-block;
      margin-right: 8px;

      &:hover {
        border: 1px solid #C1C6D0;
      }

      &_more {
        color: #1B2337;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 40px;
        display: block;
        width: 100%;
        height: 40px;
        padding-left: 16px;
        background: url('./img/arrow-down.svg') 44px 14px no-repeat;
      }

      &_list {
        width: 108px;
        max-height: 0;
        overflow: auto;
        position: absolute;
        z-index: 9;
        top: 40px;
        left: 0;
        transition: all 0.1s ease;
        background: #FFFFFF;
        box-shadow: 0 6px 10px 0 rgba(13, 29, 79, 0.1);
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.8);
        transform: translate3d(0, 4px, 0);
        overflow-y: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overscroll-behavior-x: none;
        overscroll-behavior-y: none;

        &[class~='hover'] {
          visibility: visible;
          opacity: 1;
          transform: translateZ(0);
          max-height: 160px;
          padding: 1px;
        }
        & > li {
          height: 40px;
          font-size: 14px;
          color: #1B2337;
          letter-spacing: 0;
          line-height: 40px;
          display: block;
          width: 100%;
          padding-left: 16px;
          box-sizing: border-box;
          cursor: pointer;

          &:hover {
            background: #f3f4f9;
            font-weight: 600;
          }
        }
      }
    }
  }
  .size-error {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #FA2323;
    padding-top: 12px;
  }
  .size-btn {
    margin: 16px auto 0;
    border: none;
    padding: 0;
    height: 40px;
    background: #0773FC;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: #0667E2;

    }
    &.disabled {
      background: #B4D5FE;
      cursor: not-allowed;
    }
  }
}

.hidden-input {
  position: absolute;
  left: -99999px;
  opacity: 0;
  width: 0;
  height: 0;
}
</style>