<template>
  <div class="custom-size-pop">
    <header>
      <span>自定义尺寸</span>
      <lt-icon class="close" @click="$emit('closeCustomSizePop')" type="close" size="18"></lt-icon>
    </header>
    <!--    自定义尺寸输入框-->
    <section class="custom-input">
      <input
          ref="inputWidth"
          type="number"
          placeholder="宽"
          v-model="Width"
          :class="{'inputWarning': widthWarning}"
      >
      <lt-icon type="close" size="12" class="ride"></lt-icon>
      <input
          ref="inputHeight"
          type="number"
          placeholder="高"
          v-model="Height"
          :class="{'inputWarning': heightWarning}"
      >
      <div class="select-custom-unit" ref="select" >
        <span class="select-custom-unit_more"
              v-click-outside="closeDrop"
              @click="dropDownShow = !dropDownShow"
        >{{ selectedUnit }}</span>
        <ul class="select-custom-unit_list" :class="{'hover': dropDownShow}">
          <li @click.stop="selectUnit(units)" :key="units.name" v-for="units in unitList">{{ units.unit }}（{{
              units.zh
            }}）
          </li>
        </ul>
      </div>
    </section>
    <!--    自定义尺寸输入框提示信息-->
    <section class="custom-input-tips">
      <p class="size-range" :class="{'warning': heightWarning || widthWarning}"
         v-show="unit === 'mm' && (heightWarning || widthWarning)">* 宽高的取值范围为 15 -
        2000mm（毫米）</p>
      <p class="size-range" :class="{'warning': heightWarning || widthWarning}"
         v-show="unit === 'cm' && (heightWarning || widthWarning)">* 宽高的取值范围为 1.5 -
        200cm（厘米）</p>
      <p class="size-range" :class="{'warning': heightWarning || widthWarning}"
         v-show="unit === 'px' && (heightWarning || widthWarning)">* 尺寸范围为 40px * 10000px </p>
      <p class="size-range" :class="{'warning': squareWaring}"
         v-show="unit === 'px' && squareWaring">* 宽高的乘积不能超过 80000000 像素<sup>2</sup></p>
      <p class="size-range" :class="{'warning': heightWarning || widthWarning}"
         v-show="unit === 'in' && (heightWarning || widthWarning)">* 宽高的取值范围为 1 -
        78in（英寸）</p>
    </section>
    <header>
      <span>常用尺寸</span>
    </header>
    <!--    常用尺寸列表-->
    <section class="often-size-container">
      <ul class="often-size-container_list">
        <li
            v-for="item in tabList"
            :key="item.url"
            :class="{'tab-item': true, 'active': chooseName === item.url}"
            @click="switchTab(item)"
        >
          <span class="name">{{ item.name }}</span>
        </li>
      </ul>
      <ul class="often-size-container_often-list">
        <li
            v-for="(item,index) in oftenSizeInfo[chooseName]"
            :key="index"
            :class="{'often-item': true,
            'pc': chooseName === 'pc',
            'mobile': chooseName === 'mobile',
            'print': chooseName === 'print',
            'active': oftenSizeIndex === index}"
            @click="chioseOftenSize(item,index)"
        >
          <div><i></i></div>
          <h4>{{ item.name }} </h4>
          <p>{{ item.width }}{{ item.unit }} × {{ item.height }}{{ item.unit }}</p>
        </li>
      </ul>
    </section>
    <!--    创建设计按钮-->
    <button class="create-design-btn" @click="startDesign"
            :class="{'prohibit': heightWarning || widthWarning || !Width || !Height || squareWaring}">创建设计
    </button>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

const tabList = [
  {
    url: 'pc',
    name: 'PC端'
  },
  {
    url: 'mobile',
    name: '移动端'
  },
  {
    url: 'print',
    name: '印刷'
  },
]
export default {
  name: 'customSizePop',
  inject: ['header'],
  data() {
    return {
      tabList,
      chooseName: 'pc',
      dropDownShow: false,
      oftenSizeIndex: -1,
      oftenSizeInfo: {
        'pc': [
          {name: '16:9', width: 1366, height: 768, unit: 'px', 'zh': '像素'},
          {name: '16:9', width: 1280, height: 720, unit: 'px', 'zh': '像素'},
          {name: '16:9', width: 1440, height: 900, unit: 'px', 'zh': '像素'},
          {name: '16:9', width: 1920, height: 1080, unit: 'px', 'zh': '像素'},
          {name: '4:3', width: 1024, height: 768, unit: 'px', 'zh': '像素'}
        ],
        'mobile': [
          {name: 'Iphone X', width: 1242, height: 2208, unit: 'px', 'zh': '像素'},
          {name: 'Iphone 7', width: 750, height: 1334, unit: 'px', 'zh': '像素'},
          {name: 'Android', width: 1920, height: 1080, unit: 'px', 'zh': '像素'},
          {name: 'Kindle', width: 789, height: 1024, unit: 'px', 'zh': '像素'},
          {name: 'Ipad Pro', width: 2048, height: 2732, unit: 'px', 'zh': '像素'},
          {name: 'Ipad', width: 1536, height: 2048, unit: 'px', 'zh': '像素'}
        ],
        'print': [
          {name: 'A2', width: 42, height: 59, unit: 'cm', 'zh': '厘米'},
          {name: 'A3', width: 30, height: 42, unit: 'cm', 'zh': '厘米'},
          {name: 'A4', width: 21, height: 30, unit: 'cm', 'zh': '厘米'},
          {name: 'A5', width: 15, height: 21, unit: 'cm', 'zh': '厘米'},
          {name: 'B3', width: 35, height: 50, unit: 'cm', 'zh': '厘米'},
          {name: 'B4', width: 25, height: 35, unit: 'cm', 'zh': '厘米'}
        ]
      },
      unitList: [
        {'unit': 'px', 'zh': '像素', 'name': 'px(像素)'},
        {'unit': 'cm', 'zh': '厘米', 'name': 'cm(厘米)'},
        {'unit': 'mm', 'zh': '毫米', 'name': 'mm(毫米)'},
        {'unit': 'in', 'zh': '英寸', 'name': 'in(英寸)'},
      ],
      activeIndex: -1,

      selectorSize: {
        width: '120px',
        height: '40px'
      },
      unit: 'px',
      selectedUnit: 'px(像素)',
      Height: '',
      Width: ''
    }
  },
  watch: {
    Height(v) {
      if (this.oftenSizeInfo[this.chooseName] && this.oftenSizeInfo[this.chooseName][this.oftenSizeIndex] &&
          this.oftenSizeInfo[this.chooseName][this.oftenSizeIndex].height !== v) {
        this.oftenSizeIndex = -1
      }
    },
    Width(v) {
      if (this.oftenSizeInfo[this.chooseName] && this.oftenSizeInfo[this.chooseName][this.oftenSizeIndex] &&
          this.oftenSizeInfo[this.chooseName][this.oftenSizeIndex].width !== v) {
        this.oftenSizeIndex = -1
      }
    }
  },
  props: {
    teamId: {
      type: Number,
      default: 0
    },
    isLogin: {
      type: Boolean
    },
    isPoint: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isOpenNewDesign: 'getIsOpenNewDesign',
    }),
    heightWarning() {
      let bool = false
      if (this.Height !== '') {
        if (this.unit === 'px') {
          if (this.Height < 40 || this.Height > 10000) {
            bool = true
          }
        } else if (this.unit === 'mm') {
          if (this.Height < 15 || this.Height > 2000) {
            bool = true
          }
        } else if (this.unit === 'in') {
          if (this.Height < 1 || this.Height > 78) {
            bool = true
          }
        } else if (this.unit === 'cm') {
          if (this.Height < 1.5 || this.Height > 200) {
            bool = true
          }
        }
      }
      return bool
    },
    widthWarning() {
      let bool = false
      if (this.Width !== '') {
        if (this.unit === 'px') {
          if (this.Width < 40 || this.Width > 10000) {
            bool = true
          }
        } else if (this.unit === 'mm') {
          if (this.Width < 15 || this.Width > 2000) {
            bool = true
          }
        } else if (this.unit === 'in') {
          if (this.Width < 1 || this.Width > 78) {
            bool = true
          }
        } else if (this.unit === 'cm') {
          if (this.Width < 1.5 || this.Width > 200) {
            bool = true
          }
        }
      }
      return bool
    },
    squareWaring() {
      let bool = false
      if (this.Width !== '' && this.Height !== '') {
        if (this.unit === 'px' && !this.widthWarning && !this.heightWarning) {
          let square = this.Width * this.Height
          if (square > 80000000 || square < 1600) {
            bool = true
          }
        }
      }
      return bool
    }
  },
  methods: {
    ...mapActions({
      setLogregShow: 'setLogregShow'
    }),
    closeDrop() {
      this.dropDownShow = false
    },
    switchTab(item){
      this.chooseName = item.url
      this.oftenSizeIndex = -1
    },
    // 选择单位
    selectUnit(v) {
      this.setUnitDel(v)
      this.dropDownShow = false
      this.oftenSizeIndex = -1
      this.$refs.select.blur()
    },
    setUnitDel(v) {
      this.unit = v.unit
      this.selectedUnit = `${v.unit}(${v.zh})`
    },
    //选择常用尺寸
    chioseOftenSize(item, index) {
      this.oftenSizeIndex = index
      this.Width = item.width
      this.Height = item.height
      this.setUnitDel(item)
    },
    startDesign() {
      const startDesignCb = () => {
        let width = this.Width
        let height = this.Height
        let href
        if (this.oftenSizeIndex === -1) {
          // 1 in = 25.4mm  英寸转换为mm   自定义尺寸
          if (this.unit === 'mm') {
            width /= 10
            height /= 10
          }
          if (this.unit === 'in') {
            width = width * 25.4 / 10
            height = height * 25.4 / 10
          }
          href = this.getStartDesignUrl(width, height)

        } else {
          //常用尺寸
          if (this.unit === 'px' || this.unit === 'cm') {
            href = this.getStartDesignUrl(width, height)
          } else {
            href = this.getStartDesignUrl(width / 10, height / 10)
          }
        }
        if (this.isPoint) {
          try {
            window.gio('track', 'recommendpage_click', {
              click_position: '工具首页_设计场景模块',
              click_content: '自定义尺寸'
            })
            window.gio('track', 'pc_home_distribution_statistics', {
              design_scenarios: '自定义尺寸',
              banner_name: '',
              my_design: ''
            })
          } catch (error) {
            console.error('gio error \n' + error)
          }
        }
        try {
          window.gio('track', 'template_edit', {
            design_type: '平面模板', // 设计类型
            template_id: '', // 模板ID
            template_name: '', // 模板名称
            design_scenarios: '自定义', // 设计场景
            edit_type: '自定义', // 编辑种类
            keywords: '', // 搜索关键词
            url: location.href, // 当前页面地址
            is_free: '是'
          })
        } catch (error) {
          console.error('gio error \n' + error)
        }
        window.open(href)
      }
      if (!this.isLogin) {
        sessionStorage.setItem('forward', JSON.stringify({
            forward_module_name: this.$route.path ==='/designtools/designindex' ? '页内_设计场景_自定义尺寸设计' : "已收藏场景_自定义尺寸设计",
            forward_page_name: this.$route.path ==='/designtools/designindex' ? '推荐页':"创建设计页"
        }))
        this.setLogregShow(1)
        this.header.loginSuccessCb = () => {
          startDesignCb()
        }
        return
      }

      startDesignCb()

    },
    getStartDesignUrl(width, height) {
      let unit = this.unit === 'px' ? 'px' : 'cm'
      const k = unit === 'px' ? '127' : '128'
      let url

      let teamStr = this.teamId ? '&tid=' + this.teamId : ''
      url = `${this.isOpenNewDesign ? '/odyssey': '/odyssey'}/createDesign?k=${k}&w=${width}${unit}&h=${height}${unit}${teamStr}`
      return url
    }
  }
}
</script>

<style scoped lang="less">
@import "./customSizePop.less";
</style>
