
getParams = () => {
    var temp = location.search;
    var params = {};
    if (location.search.indexOf('?') >= 0) {
        temp = location.search.substr(1);
    }
    if (temp != '') {
        temp = temp.split('&');
        for (var index = 0; index < temp.length; index++) {
            var element = temp[index];
            var a = element.split('=');
            a[1] = decodeURIComponent(a[1]).replaceAll(' ', '')
            params[a[0]] = a[1] || '';
        }
    }
    return params;
}

function sleep(time) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    })
}


module.exports = {
    getParams,
    sleep,
};