<template>
  <div class="scroll-slider" ref="themeSlider">
    <div
      class="arrowBox arrowBoxL"
      v-show="arrowLShow && isArrowButtonShow"
      @click="onSwitch('left')"
    >
      <div class="arrow arrowL"></div>
    </div>
    <div
      class="arrowBox arrowBoxR"
      v-show="arrowRShow && isArrowButtonShow"
      @click="onSwitch('right')"
    >
      <div class="arrow arrowR"></div>
    </div>
    <div
      class="scroll-slider-body"
      @scroll="onScroll('drag')"
      ref="sliderContent"
    >
      <div class="scroll-slider-body-view" ref="sliderList">
        <ul class="scroll-slider-body-view_list">
          <slot></slot>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ScrollSlider',
    props: {
      listLength: {
        type: Number,
      },
      rowLength: {
        type: Number,
        default: 7,
      },
      isResize: {
        type: Boolean,
      },
      width: {
        type: Number,
      },
      activeIndex: {
        type: Number,
      },
      moreShow: {
        type: Boolean,
        default: true,
      },
      isHeader: {
        type: Boolean,
      },
    },
    data() {
      return {
        arrowRShow: true,
        arrowLShow: false,
        // 操作方式
        operationMode: '',
        requesId: undefined,
        isArrowButtonShow: false,
      };
    },
    watch: {
      isResize() {
        this.setButtonDisplay();
      },
      activeIndex(v, olv) {
        if (v !== olv) {
          this.arrowLShow = false;
          this.arrowRShow = true;
        }
      },
    },
    mounted() {
      const sliderContent = this.$refs.sliderContent;
      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.95) {
            this.isArrowButtonShow = true;
            this.setButtonDisplay();
          } else {
            this.isArrowButtonShow = false;
          }
        });
      };
      const observer = new IntersectionObserver(callback, {
        root: document.querySelector(
          `#search-scroll-box-${this.isHeader ? 'header' : ''}`,
        ),
        threshold: [0.5, 0.95, 1],
        rootMargin: '10px',
      });
      observer.observe(sliderContent);
    },
    methods: {
      onScroll(direction) {
        this.operationMode = direction;
        this.setButtonDisplay();
      },
      setButtonDisplay() {
        // 当前滚动的距离
        let scrollLeft = this.$refs.sliderContent.scrollLeft;
        // 模板总宽度
        let maxWidth = this.$refs.sliderList.getBoundingClientRect().width;
        // 视窗大小
        const showWidth = this.$refs.themeSlider.getBoundingClientRect().width;
        // 两侧按钮显示
        this.arrowLShow = scrollLeft > 0;
        this.arrowRShow = !(maxWidth - showWidth - scrollLeft < 5);
      },
      onSwitch(direction) {
        this.setButtonDisplay();
        // 当前滚动的距离
        let scrollLeft = this.$refs.sliderContent.scrollLeft;
        // 模板总宽度
        let maxWidth = this.$refs.sliderList.getBoundingClientRect().width;
        // 视窗大小
        const showWidth = this.$refs.themeSlider.getBoundingClientRect().width;
        // 一次移动的长度 (this.width + 24) 模板长度加margin
        const moveLong = parseInt(showWidth / (this.width + 12));
        // 当前一次移动的宽度
        // const moveWidth = (this.width + 24) * moveLong
        // 模板列表可移动总长度
        const maxDistance = maxWidth - showWidth;
        // 已经移动过的长度
        const movedLength = Math.ceil(scrollLeft / (this.width + 12));
        if (this.requesId) return;
        if (direction === 'right') {
          const surplus = this.operationMode == 'drag' ? 1 : 0;
          let movingDistance =
            (movedLength + moveLong - surplus) * (this.width + 12);
          movingDistance =
            movingDistance > maxDistance ? maxDistance : movingDistance;

          if (
            typeof window.getComputedStyle(document.body).scrollBehavior !==
            'undefined'
          ) {
            // 传统的JS平滑滚动处理代码...
            this.$refs.sliderContent.scrollTo({
              top: 0,
              left: movingDistance,
              behavior: 'smooth',
            });
          } else {
            this.requesId = this.stepFn(movingDistance, false);
            // this.$refs.sliderContent.scrollLeft = movingDistance
          }
        }
        if (direction === 'left') {
          let movingDistance = (movedLength - moveLong) * (this.width + 12);
          if (
            typeof window.getComputedStyle(document.body).scrollBehavior !==
            'undefined'
          ) {
            // 传统的JS平滑滚动处理代码...
            this.$refs.sliderContent.scrollTo({
              top: 0,
              left: movingDistance,
              behavior: 'smooth',
            });
          } else {
            this.requesId = this.stepFn(movingDistance, true);
            // this.$refs.sliderContent.scrollLeft = movingDistance
          }
        }
        let timer = null;
        this.$refs.sliderContent.addEventListener('scroll', () => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            // 这里写滚动完成后的逻辑
            this.operationMode = direction;
          }, 100); // 100 毫秒内没有再次滚动，认为滚动完成
        });
      },
      stepFn(movingDistance, isLeft) {
        return window.requestAnimationFrame(() => {
          let stop = false;
          let scrollLeft = 60;
          if (isLeft) {
            stop =
              this.$refs.sliderContent.scrollLeft <= movingDistance ||
              this.$refs.sliderContent.scrollLeft === 0;
            scrollLeft = -60;
          } else {
            stop = this.$refs.sliderContent.scrollLeft >= movingDistance;
          }
          if (stop) {
            this.$refs.sliderContent.scrollLeft = movingDistance;
            this.requesId = undefined;
            return;
          }
          this.$refs.sliderContent.scrollLeft += scrollLeft;
          window.requestAnimationFrame(
            this.stepFn.bind(this, movingDistance, isLeft),
          );
        });
      },
    },
  };
</script>

<style scoped lang="less">
  .scroll-slider {
    box-sizing: border-box;
    position: relative;
    .arrowBox {
      width: 26px;
      height: 92px;
      position: absolute;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .arrowBoxL {
      left: 0;
      background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }
    .arrowBoxR {
      right: 0;
      background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }
    .arrow {
      height: 40px;
      width: 40px;
      background: #ffffff;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      box-shadow: 0 6px 12px 0 rgba(48, 62, 83, 0.14);
      border-radius: 50%;
      position: absolute;
    }
    .arrowL {
      background-image: url('./img/slider_arrow_left.svg');
      left: -14px;
    }
    .arrowR {
      right: -14px;
      background-image: url('./img/slider_arrow_right.svg');
      // &:after {
      //   right: 42%;
      //   border-width: 1.5px 1.5px 0 0;
      // }
    }

    .scroll-slider-body {
      &::-webkit-scrollbar {
        display: none;
      }

      height: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none;
      -ms-overflow-style: none;
      overscroll-behavior-x: none;
      box-sizing: border-box;
      scroll-behavior: smooth;
      display: flex;
      justify-content: space-between;

      .scroll-slider-body-view {
        display: flex;
        justify-content: space-between;

        &.has-data {
          padding: 20px 0 48px 0;
        }

        &_list {
          width: max-content;
          white-space: nowrap;
          position: relative;
          transition: 0.7s;
          font-size: 0;
        }

        .flex() {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &_more {
          width: 152px;
          .flex();
          .more-item {
            width: 72px;
            height: 72px;
            border-radius: 50%;
            background-color: #f3f4f9;
            .flex();

            .more-icon {
              font-size: 60px;
              color: #505a71;
            }

            &:hover {
              background-color: #e9eaef;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1600px) {
    .scroll-slider {
      .arrow {
        height: 36px;
        width: 36px;
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .scroll-slider {
      .arrow {
        height: 32px;
        width: 32px;
      }
    }
  }
</style>
