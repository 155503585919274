/**
 * Created by suti on 2017/7/17.
 */

const defaultSort = [
  'kindId',
  'parentKindId',
  'secondKindId',
  'themeId',
  'tagId',
  'order',
  'priceType',
  'pageNo',
  'keywords',
  'id',
  'usage']
const defaultShortSort =
  {
    ki: 'kindId', pi: 'parentKindId', si: 'secondKindId', th: 'themeId',
    ti: 'tagId', or: 'order', pt: 'priceType',
    ke: 'keywords', id: 'id', ua: 'usage', ta: 'tags',
    st: 'style', co: 'color', us: 'use', iu: 'industry',
    cs: 'choiceScene', cr: 'copyright', ft: 'fontType',
    fs: 'fontStyle', pn: 'pageNo',
  }

export default class {
  constructor (that) {
    this._this = that
  }

  parse () {
    let {_this} = this,
      path = decodeURIComponent(_this.$route.path).split('.html')[0],
      array = path.split('-'), object = {}
    array.shift()
    array.forEach(el => {
      let
        // node=el.split('_'),con=(node[1]==='undefined')||(node[1]==='null')?undefined:node[1]
        key = el.substring(0, 2).toLocaleLowerCase(), con = el.substring(2)
      if (con)
        object[defaultShortSort[key]] = key === 'ke' ? con : (con | 0)
    })
    if (object.keywords == undefined && path.indexOf('tupian') > -1) {
      object['shortName'] = path.split('/')[2].split('-')[0]
    }
    return typeof object === 'object' ? object : null
  }

  parseIt (p) {
    let path = decodeURIComponent(p).split('.html')[0],
      array = path.split('-'), object = {}
    array.shift()
    array.forEach(el => {
      let
        // node=el.split('_'),con=(node[1]==='undefined')||(node[1]==='null')?undefined:node[1]
        key = el.substring(0, 2), con = el.substring(2)
      if (con)
        object[defaultShortSort[key]] = con | 0
    })
    return typeof object === 'object' ? object : null
  }

  /**
   *
   * @param p path
   * @param obj query
   * @param op query 标识是否隐藏默认
   */
  set (p, obj, q) {
    let {_this} = this, array = [], query = ''
    if (obj)
      obj = this.transform(obj)
    Object.keys(defaultShortSort).forEach(k => {
      if (obj[k] !== undefined && obj[k] !== null)
        array.push(k + '' + obj[k])
      if (defaultShortSort[k] === 'order') {
        if (obj['or'] == undefined)
          array.push('or0')
      }
      if (defaultShortSort[k] === 'priceType') {
        if (obj['pt'] == undefined)
          array.push('pt0')
      }
      if (defaultShortSort[k] === 'copyright') {
        if (obj['cr'] == undefined)
          array.push('cr0')
      }
      if (defaultShortSort[k] === 'pageNo') {
        if (obj['pn'] == undefined)
          array.push('pn1')
      }
    })
    query = (array.join('-'))
    location.href = `${p}-${query}.html`
  }

  compute (obj, op) {
    let array = []
    obj = this.transform(obj)
    Object.keys(defaultShortSort).forEach(k => {
      if (obj[k] !== undefined && obj[k] !== null)
        array.push(k + '' + obj[k])
      if (defaultShortSort[k] === 'order'&& op === undefined) {
        if (obj['or'] == undefined)
          array.push('or0')
      }
      if (defaultShortSort[k] === 'priceType' && op === undefined) {
        if (obj['pt'] == undefined)
          array.push('pt0')
      }
      if (defaultShortSort[k] === 'copyright' && op === undefined) {
        if (obj['cr'] == undefined)
          array.push('cr0')
      }
      if (defaultShortSort[k] === 'pageNo') {
        if (obj['pn'] == undefined)
          array.push('pn1')
      }

    })
    return (array.join('-') + '.html')
  }

  transform (obj) {
    let newObj = {}
    Object.keys(defaultShortSort).forEach(k => {
      for (let i in obj) {
        if (defaultShortSort[k] === i) {
          newObj[k] = obj[i]
        }
      }
    })
    // console.log(obj,newObj)
    return newObj
  }

  isSortSearchPage () {
    return decodeURIComponent(this._this.$route.path).indexOf('/tupian') > -1
  }

}
