<template>
  <LtModal
    v-model="showRiskLogin"
    :mask-closable="false"
    :closable="false"
    :z-index="100001">
    <div class="login-risk">
      <div class="risk-title"><LtIcon type="remind" color="#FF4D4D" size="18" class="help-icon" /> 风险警告</div>
      <p class="risk-content" v-html="resultWords"></p>
      <div class="risk-button" @click="close()">关闭</div>
    </div>
  </LtModal>
</template>

<script>
  export default {
    name: 'risklogin',
    props: {
      showRiskLogin: {
        type: Boolean,
        default: true
      },
      riskLoginLimitInfo: {
        type: Object,
      },
    },
    data() {
      return {
        resultWords: ''
      };
    },
    watch:{
      riskLoginLimitInfo(v){
        if (v.text){
          this.formatTextWithLinks(this.riskLoginLimitInfo)
        }
      }
    },
    methods: {
      close () {
        this.$emit('closeRisk')
      },
      formatTextWithLinks(riskLoginLimitInfo) {
        let { text, links } = riskLoginLimitInfo;
        text = text
        .split('\n')
        .map(line => '&nbsp;&nbsp;&nbsp;&nbsp;' + line) // 在每行前添加缩进
        .join('<br>'); // 用 <br> 标签替换换行符
        if (links && links.length > 0){
          links.forEach(link => {
            const { linkTitle, linkUrl } = link;
            const linkRegex = new RegExp(linkTitle, 'g'); // 全局查找 linkTitle
            // 替换匹配到的 linkTitle 为带 <a> 标签的链接
            text = text.replace(linkRegex, `<a href="${linkUrl}" target="_blank">${linkTitle}</a>`);
          });
        }
        this.resultWords = text;
      }
    }
  };
</script>

<style lang="less" scoped>
  .login-risk {
    position: relative;
    width: 448px;
    height: auto;
    padding: 24px;
    border-radius: 6px;
    background: #FFFFFF;
    .risk-title{
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #1B2337;
      margin-bottom: 30px;
    }
    .risk-content{
      font-size: 14px;
      line-height: 22px;
      color: #505A71;
      margin-bottom: 16px;
    }
    .risk-button{
      width: 92px;
      margin-left: auto;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #0773FC;
      border-radius: 4px;
      font-size: 14px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
</style>
