<template>
  <div>
    <div class="catch-wrap down-wrap compatible wechat"  v-if="show == 'wechat'">
      <div class="compatible-top">
        <img src="../../pages/templateCenter/img/checkBrowser/tree_left.png" />
        <img class= "img-computer" src="../../pages/templateCenter/img/checkBrowser/computer.png" />
        <img class="tree-right" src="../../pages/templateCenter/img/checkBrowser/tree_right.png" />
        <span class="title">微信自带浏览器兼容性较差</span>
      </div>
      <div class="catch-bottom">
        <p class="title">为了保证您有高品质的使用体验，请在浏览器中访问我们的网站。</p>
        <p>您可以在上方顶栏中：</p>
        <img class="img-tool" src="../../pages/templateCenter/img/checkBrowser/tool.png" />
        <div class="descript">
          <p><img src="../../pages/templateCenter/img/checkBrowser/liulan.png" />使用默认浏览器打开</p>
          <p><img src="../../pages/templateCenter/img/checkBrowser/link.png" />复制链接地址到浏览器后打开</p>
        </div>
      </div>
    </div>
    <div class="catch-wrap down-wrap compatible other" v-if="show == 'chrome'">
      <div class="compatible-top">
        <img src="../../pages/templateCenter/img/checkBrowser/tree_left.png" />
        <img class= "img-computer" src="../../pages/templateCenter/img/checkBrowser/computer.png" />
        <img class="tree-right" src="../../pages/templateCenter/img/checkBrowser/tree_right.png" />
        <span class="title">您当前的浏览器兼容性较差</span>
      </div>
      <div class="catch-bottom">
        <p class="title">为了保证您有高品质的使用体验：</p>
        <div class="descript">
          <p>若当前为360浏览器，请切换至  &nbsp; &nbsp;  “极速模式”  &nbsp; &nbsp;  <a href="http://edu.chuangkit.com/forum.php?mod=viewthread&tid=980" target="blank">如何切换？</a></p>          
          <p>或者直接升级为最新版的谷歌浏览器。</p>
        </div>
        <div class="chrome-link-con">
          <a class="chrome-link" :href="chrome"><img src="../../pages/templateCenter/img/checkBrowser/google.png" />下载谷歌浏览器</a>
        </div>        
      </div>
    </div>
  </div>
</template>
<script>
  import checkBrowser from './checkBrowser.js'
  export default {
    data () {
      return {
        os: 'other',
        arch: '64',
        show: 'false'
      }
    },
    methods: {},
    computed: {
      chrome () {
        switch (this.os) {
          case 'mac':
            return 'https://eyuankupub-cdn-oss.chuangkit.com/sys/chrome_mac.dmg?v=' + Math.round(new Date().getTime() / 1000)
          case 'windows':
            return `https://eyuankupub-cdn-oss.chuangkit.com/sys/chrome_windows${this.arch}.exe?v=` +
              Math.round(new Date().getTime() / 1000)
        }
      },
    },
    created () {
      if (checkBrowser.wechat) {
        this.show = 'wechat'
      } else {
        this.show = 'chrome'
      }
      let usr = navigator.userAgent.toLocaleLowerCase()
      if (usr.indexOf('mac') > -1) {
        this.os = 'mac'
      } else if (usr.indexOf('windows') > -1) {
        this.os = 'windows'
        this.arch = usr.indexOf('wow64') > -1 ? '64' : ''
      }
    },
  }
</script>
<style lang="less" scoped>
  @import './catcherror.less';

  .compatible {
    // 原
    .catch-bottom {
      .font20 {
        font-size: 20px;
        font-weight: normal !important;
      }
      .client-link {
        color: #00ACFF;
        text-decoration: #00ACFF underline;
      }
      .chrome-link {
        display: inline-block;
        background: #50C6FC;
        width: 236px;
        height: 40px;
        color: #FFFFff;
        text-align: center;
        line-height: 40px;
      }
    }

    // 新
    // public
    height: auto;
    .catch-bottom {      
      padding: 35px 0;
      text-align: left;
      .descript {
        p {
          display: flex;
          align-items: center;
          &:before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #000;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;            
            // background-color: #ff0000;
            // vertical-align: bottom;
          }
        }
      }
      p{
        margin-bottom: 20px;
        font-size: 16px;
        color: #1B2337;
      }
      p.title {
        margin-bottom: 35px;
        font-size: 18px;
        color: #666666;
      }
      .chrome-link {
        display: block;
        margin: 0 auto;
        margin-top: 50px;
      }
    }
    .compatible-top {
      position: relative;
      padding-top: 0;
      height: 128px;
      line-height: 128px;
      background-color: #F1F8FD;
      text-align: left;
      img {
        vertical-align: bottom;
        &.img-computer {
          vertical-align: middle;
          margin-left: 20px;
        }
        &.tree-right {
          position: absolute;
          right: 35px;
          bottom: 0
        }
      }
      .title {
        display: inline-block;
        margin-left: 15px;
        font-size: 24px;
        font-weight: bold;        
        color: #333;
      }
    }
    // wechat
    &.wechat {
      .catch-bottom {
        height: 285px;
        padding: 35px 0 0 83px;
        .img-tool{
          margin-left: -6px;
        }
        p.title {
          opacity: 0.66;
        }
        .descript {
          margin-top: 26px;          
          p {
            display: inline-flex;
            img{
              vertical-align: bottom;
              margin-right: 5px;
            }
          }
          p:nth-child(2) {
            margin-left: 36px;
          }
        }
      }
    }
    // other
    &.other{
      .catch-bottom {
        height: 276px;
        padding: 35px 0 0 110px;
        .descript {
          p {
            display: flex;
          }
        }
      }
      .chrome-link-con{
        margin-left: -110px;
        .chrome-link {
          display: block;
          margin: 0 auto;
          margin-top: 45px;
          background: #2276E4;
          border-radius: 4px;
          width: 180px;
          img {
            vertical-align: middle;
            margin-right: 8px;
          }
        }
      }      
      .descript {
        a {
          color: #2276E4;
          text-decoration: underline;
        }
      }
    }
  }
</style>





