<template>
  <div class="task-center">
    <div class="tit">
      做任务领福利
    </div>
    <div class="sub-tit">
      <span class="bolder">免费</span>领试用会员，畅享40w+模板
      <span class="bolder">无水印下载</span>
    </div>
    <div class="award">
      <div class="award-tit">
        我的奖励
      </div>
      <ul class="award-list">
        <li>
          <div class="total-day">
            <span class="day">{{ totalDay }}</span
            >天
          </div>
          <div class="award-list-item-tit">
            试用会员
          </div>
        </li>
      </ul>
    </div>
    <div class="award">
      <div class="award-tit">
        福利任务
      </div>
    </div>
    <ul class="task-list">
      <li class="task" v-for="item in taskList" :key="item.taskId">
        <div class="task-info">
          <img class="task-icon" :src="item.iconUrl" alt="" srcset="" />
          <div class="task-txt-info">
            <div class="txt">{{ item.name }}</div>
            <div class="day-num">
              试用会员<span class="color-tit"
                >{{ '+' + item.vipDayNum }}天</span
              >
            </div>
          </div>
        </div>
        <div
          class="task-status"
          :class="item.status === 2 ? 'task-done' : 'task-undone'"
          @click="doTask(item)"
        >
          {{ item.status | statusFilter }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  const statusMap = {
    0: '去完成',
    1: '领取奖励',
    2: '已完成',
  };
  export default {
    name: 'taskCenter',
    data() {
      return {
        taskList: [],
      };
    },
    filters: {
      statusFilter(val) {
        return statusMap[val];
      },
    },
    computed: {
      totalDay() {
        return (
          this.taskList &&
          this.taskList.reduce((total, item) => {
            if (item.status === 2) {
              total++;
            }
            return total;
          }, 0)
        );
      },
    },
    methods: {
      async doTask(item) {
        const { status, taskId } = item;
        if (status === 1) {
          const flag = await this.getReward(taskId);
          if (flag) {
            this.$emit('bindSuccess');
          } else {
            this.$message('领取失败')
          }
        }
        if (status === 0) {
          if (taskId === 1) {
            this.$emit('bindPhoneEmitHandler', taskId);
          }
          if (taskId === 2) {
            window.location.replace('/designtools/startdesign?activity=1');
          }
        }
      },
      getTaskAdvert() {
        try {
          this.$http
            .get('/hatchery/activity/task/advert/get', {
              params: {
                _dataClientType: 0,
                platform: 0,
                gw: true,
                alias: 'task_for_reward',
              },
              data: {
                gw: true,
                requestMethod: 'GET',
                template: true,
                nottrans: true,
              },
            })
            .then((res) => {
              let code, data;
                // 如果是chuangkit-saas 项目
                if (this.$route.path.includes('/designtools') || this.$route.path === '/') {
                  code = res.body && res.body.code;
                  data = res.body && res.body.data;
                } else {
                  code = res.data && res.data.body && res.data.body.code;
                  data = res.data && res.data.body && res.data.body.data;
                }
              if (code === 200) {
                const { taskList } = data;
                this.taskList = taskList;
              }
            });
        } catch (error) {
          console.log(error);
        }
      },
      async getReward(taskId) {
        const res = await this.$http.get('/hatchery/activity/task/receive', {
          params: { taskId, gw: true, nottrans: true },
          data: {
            gw: true,
            requestMethod: 'GET',
            template: true,
            nottrans: true,
          },
        });
        let data;
        // 如果是chuangkit-saas 项目
        if (this.$route.path.includes('/designtools') || this.$route.path === '/') {
          data = res.body && res.body.data;
        } else {
          data = res.data && res.data.body && res.data.body.data;
        }
        return data;
      },
    },
    beforeMount() {
      this.getTaskAdvert();
    },
  };
</script>

<style lang="less" scoped>
  .task-center {
    width: 425px;
    height: 448px;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 4px;
    background: url('../img/task_bg.png') no-repeat center;
    background-color: #ffffff;
    background-size: 425px 448px;

    .tit {
      font-weight: 600;
      height: 34px;
      font-size: 26px;
      line-height: 34px;
      color: #1b2337;
    }
    .sub-tit {
      font-size: 14px;
      line-height: 14px;
      color: #342b2b;
      margin-top: 10px;

      .bolder {
        font-weight: 600;
      }
    }

    .award {
      margin-top: 24px;
      .award-tit {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #1b2337;
      }

      .award-list {
        display: flex;

        li {
          .total-day {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #342b2b;
            margin-bottom: 4px;
            .day {
              height: 46px;
              font-weight: 600;
              font-size: 38px;
              line-height: 46px;
              color: #f25774;
            }
          }

          .award-list-item-tit {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #342b2b;
          }
        }
      }
    }

    .task-list {
      margin-top: 24px;
      .task {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 24px;

        .task-info {
          display: flex;
          .task-icon {
            width: 44px;
            height: 44px;
            display: block;
            object-fit: cover;
            margin-right: 16px;
          }

          .task-txt-info {
            display: flex;
            height: 44px;
            flex-direction: column;
            justify-content: space-between;

            .txt {
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
              color: #1b2337;
            }

            .day-num {
              font-weight: 400;
              font-size: 14px;
              line-height: 14px;
              color: #342b2b;

              .color-tit {
                color: #f25774;
              }
            }
          }
        }

        .task-status {
          width: 86px;
          height: 38px;
          border-radius: 129px;
          line-height: 38px;
          text-align: center;
        }

        .task-undone {
          border: 1px solid #0773fc;
          color: #0773fc;
          cursor: pointer;
        }

        .task-done {
          border: 1px solid rgba(7, 115, 252, 0.2);
          color: #0773fc;
          opacity: 0.4;
        }
      }
    }
  }
</style>
