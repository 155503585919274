<template>
  <div class="mask" v-show="visible">
    <div class="form-modal">
      <div class="modal-head">
        <p class="title">免费试用</p>
        <img class="close" src="./imgs/close.svg" alt="close" @click="close">
      </div>
      <div class="content">
        <div class="service">
          <p class="title">方式一</p>
          <div class="service-detail">
            <img :src="contactsQRcode" alt="扫码添加客服微信" class="qrcode">
            <p>扫码添加客服微信</p>
            <p>我们会有专人为您服务</p>
          </div>
        </div>
        <div class="form-content">
          <p class="title">方式二</p>
          <div class="form-item" v-for="(item, key) in formData" :key="item.name">
            <div class="label">
              <span class="font-require">＊</span>
              <span>{{item.label}}</span>
            </div>
            <input
              v-model="formData[key].value"
              :type="item.type"
              :id="key"
              :maxlength="item.maxlength"
              :placeholder="item.placeholder"
              :oninput="item.maxlengthString"
            >
          </div>
          <div class="btn confirm" @click="submit">免费试用</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: "FormModal",
    data() {
      return {
        formData: {
          name: {
            label: '姓名',
            type: 'text',
            value: '',
            placeholder: '请填写您的姓名',
            maxlength: 20,
            maxlengthString: 'if (value.length > 20) value=value.slice(0, 20)'
          },
          phone: {
            label: '联系方式',
            type: 'number',
            value: '',
            placeholder: '请填写您的联系方式',
            maxlength: 11,
            maxlengthString: 'if (value.length > 11) value=value.slice(0, 11)'

          },
          screenNum: {
            label: '屏幕数量',
            type: 'number',
            value: '',
            placeholder: '请填写您的屏幕数量',
            maxlength: 3,
            maxlengthString: 'if (value.length > 3) value=value.slice(0, 3)'

          }
        },
        canSubmit: true,
        contactsQRcode: `https://static.chuangkit.com/files/enterprise/screen_contact.png?${Date.now()}`
      }
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'getUserInfo',
        isLogin: 'getIsLogin',
      })
    },
    watch: {
      visible: function(newV) {
        if (newV) {
          const formData = Object.assign({}, this.formData)
          formData.name.value = ''
          formData.phone.value = ''
          formData.screenNum.value = ''
          this.formData = formData
        }
      }
    },
    mounted() {
      document.getElementById('screenNum').addEventListener('input', (e) => {
        e.target.value  = e.target.value.replace(/\-/g, '')
        e.target.value = e.target.value > 0 ? e.target.value : ''
      })
    },
    methods: {
      close() {
        this.$emit('setscreenMarketingForm', false)
      },
      submit() {
        if (!this.canSubmit) return
        this.canSubmit = false
        const {
          name: {value: name},
          phone: {value: phone},
          screenNum: {value: screenNum}
        } = this.formData

        if (!name || name.length === 0 || /[^\u4E00-\u9FA5]/.test(name)) {
          this.canSubmit = true
          return this.$message('请输入正确姓名格式')
        }

        if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phone)) {
          this.canSubmit = true
          return this.$message('请输入正确电话格式')
        }

        if (!screenNum || !Number(screenNum) || screenNum.length === 0 || Math.floor(screenNum) !== Number(screenNum)) {
          this.canSubmit = true
          return this.$message('请输入1~999之间的整数')
        }

        this.$http
          .post('/team/screen/sale/lead/addSaleLead', {
            gw: true,
            name,
            phone,
            screenNum,
            source: 1,
            type: 1,
            company: '',
            userId: this.isLogin ? this.userInfo.userId : 0
          })
          .then(res => {
            const {code} = res.body
            if (code === 200) {
              this.$message('提交成功，我们将会很快联系您')
            }
            this.close()
          })
          .catch(() => {
            this.$message('提交失败')
          })
          .finally(() => {
            this.canSubmit = true
          })
      }
    }
  }
</script>

<style lang="less" scoped>
  .mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .4);
    z-index: 99999;

    .form-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: #FFFFFF;
      border-radius: 6px;
      width: 621px;
      height: 473px;
      padding: 20px 0;

      .modal-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #E4E7ED;

        .title {
          font-family: PingFangSC-Semibold;
          font-size: 16px;
          color: #1B2337;
          line-height: 16px;
          font-weight: 600;
        }

        .close {
          cursor: pointer;
        }
      }

      .content {
        display: flex;

        > div {
          padding: 24px 20px 32px;
        }

        p.title {
          margin-bottom: 24px;
          color: #1B2337;
          line-height: 14px;
          font-weight: 600;
        }

        .service {
          flex: 1;
          border-right: 1px solid #E4E7ED;

          .service-detail {
            text-align: center;

            .qrcode {
              width: 160px;
              height: 160px;
              margin-top: 28px;
              margin-bottom: 4px;
              vertical-align: center;
            }

            p {
              margin-top: 12px;
              font-size: 14px;
              color: #1B2337;
              line-height: 14px;
              font-family: PingFangSC-Regular;
            }
          }


        }

        .form-content {
          flex: 0 0 380px;
          .form-item {
            margin-bottom: 26px;

            &:last-of-type {
              margin-bottom: 32px;
            }

            .label {
              font-family: PingFangSC-Semibold;
              font-size: 14px;
              color: #505A71;
              line-height: 14px;
              font-weight: 600;
              margin-bottom: 12px;

              .font-require {
                font-family: PingFangSC-Regular;
                color: #E64340;
                font-weight: 400;
              }
            }

            input {
              font-family: PingFangSC-Regular;
              outline: none;
              width: 100%;
              height: 40px;
              line-height: 40px!important;
              font-weight: 400;
              padding: 13px 16px;
              border: 1px solid #EEEEEE;
              border-radius: 4px;
              display: block;
              box-sizing: border-box;
              width: 340px;

              &::placeholder {
                line-height: 40px;
                font-size: 14px;
                font-family: PingFangSC-Regular;
                color: #8693AB;
                font-weight: 400;
              }

              &:hover {
                border: 1px solid #C1C6D0;
              }

              &:focus {
                border: 1px solid #0773FC;
                box-shadow: 0 0 2px 2px rgba(7,115,252,0.10);
              }

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }
              /*火狐*/
              &[type="number"] {
                -moz-appearance: textfield;
                line-height: 1px!important;
              }
            }
          }
        }
      }


      .btn {
        line-height: 40px;
        text-align: center;
        height: 40px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;

        &.confirm {
          background: #0773FC;
          margin-bottom: 16px;
          font-family: PingFangSC-Semibold;
          color: #FFFFFF;
          font-weight: 600;
          width: 160px;
          margin: 0 auto;
          margin-top: 32px;

          &:hover {
            background: #0667E2;
          }
        }
      }
    }
  }
</style>
