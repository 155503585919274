<template>
    <div class="new-user-lead">
      <LtModal
        class="new-user-lead-modal"
        v-model="showLeadMoadl"
        :mask-closable="false"
        @on-cancel="closeModalBtn"
       >
       <div class="userLeadWarp">
        <div class="new-user-lead-content">
          <ul class="lead-tab-list">
                <li v-for="item in leadList" :key="item.id" :class="{'activeTab':activeId === item.id}" @click="changeTab(item)">
                    {{item.title}}
                    <div  :class="{'lead-tab-bar':activeId === item.id}">
                    </div>
                </li>
            </ul>
            <div class="lead-tab-content-list" @mouseenter="stopLoop" @mouseleave="swiperLoop">
                <div v-for="item in leadList" :key="item.id">
                    <img :src="item.img"  v-show="activeId === item.id"/>
                </div>
            </div>
        </div>
        <div class="new-user-lead-footer">
            <div class="footer-btn" @click="closeModalBtn(true)">{{ tariffPackageParasm.guidExplain }}</div>
        </div>
       </div>
      </LtModal>
    </div>
  </template>
  <script>
    // import tracker  from "@/utils/tracker";
    export default {
        name: 'FreeUseLeadModal',
        components: {
        },
        data () {
            return {
                showLeadMoadl: false,
                activeId:1,
                orderStateId:-1,
                difWidth:0,
                leadList: [
                    {
                        title:'海量模板',
                        img:require('./img/tip1.png'),
                        id:1,
                    },
                    {
                        title:'无水印下载',
                        img:require('./img/tip2.png'),
                        id:2,
                    },
                    {
                        title:'AI体验',
                        img:require('./img/tip4.png'),
                        id:3,
                    },
                    {
                        title:'智能抠图',
                        img:require('./img/tip3.png'),
                        id:4,
                    }
                ],
            }
        },
        props: {
            tariffPackageParasm: {
                type: Object,
                required: true
            },
            userInfo: {
                type: Object,
                required: true
            },
            teamInfo: {
                type: Object,
                required: true
            },
        },
        watch:{
            tariffPackageParasm(v){
                let leftTime = false
                const getCloseFreeTime = localStorage.getItem('closeFreeTime')
                if (getCloseFreeTime){
                    leftTime = Number(getCloseFreeTime) < new Date(Date.now()).setHours(23, 59, 59, 999); //一天内不弹窗
                }
                if (v.tariffPackageId>0 && !leftTime) {
                    this.showLeadMoadl =true
                    this.swiperLoop()
                }
            }
        },
        computed: {
        },
        methods: {
            swiperLoop(){
                if (this.swiperTimer){
                    clearInterval(this.swiperTimer)
                }
                this.swiperTimer = setInterval(() => {
                    if (this.activeId ===this.leadList.length){
                        this.activeId =0
                    }
                    this.activeId++
                }, 2000);
            },
            stopLoop(){
                clearInterval(this.swiperTimer)
            },
            async closeModalBtn(state = false){
                if (this.swiperTimer){
                    clearInterval(this.swiperTimer)
                }
                this.showLeadMoadl =false
                this.activeId = 1
                //存一下当前的时间
                localStorage.setItem("closeFreeTime",new Date().getTime())
                if (state){
                    this.$emit('goVipPackage',this.tariffPackageParasm.tariffPackageId)
                }
                const params ={
                    page_name: '会员试用引导弹框',
                    page_url: location.href,
                    module_name: "全屏弹框",
                    button_name: this.tariffPackageParasm.guidExplain,
                    is_login: '是',
                }
                window.gio("track", "button_click",params);
                const { default: tracker } = await import('@/utils/tracker');
                window.tracker = tracker
                if(window.tracker){
                    window.tracker.loginReport({
                        event: "button_click",
                        datetime: Date.now(),
                        params,
                    })
                }
            },
            //切换团队
            changeTab(item){
                this.activeId = item.id
                this.swiperLoop()
            },
        },
    }
</script>
<style lang='less' scoped>
.new-user-lead-modal {
  .userLeadWarp{
      width: 484px;
      border-radius: 10px;
      background: #FFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      .new-user-lead-content{
          position: relative;
          .lead-tab-list{
              display: flex;
              justify-content: center;
              align-items: center;
              li{
                width: 100px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;
                border-radius: 8px;
                border: 1px solid var(---5, #EBEEF5);
                background: #FFF;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                &:hover{
                    font-weight: 600;
                    color: var(--1, #0773FC);
                }
                .lead-tab-bar{
                    position: absolute;
                    bottom: 0;
                    height: 3px;
                    width: 40px;
                    background-color: var(--1, #0773FC);
                }
              }
              li:last-child{
                  margin-right: 0;
              }
              .activeTab{
                  color: var(--1, #0773FC);
                  font-weight: 600;
              }
          }
          .lead-tab-content-list{
              margin-top: 20px;
              img{
                  width: 436px;
                  height: 290px;
                  border-radius: 8px;
              }
          }
      }
      .new-user-lead-footer{
          margin-top: 24px;
          display: flex;
          .footer-btn{
              display: flex;
              width: 200px;
              height: 48px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              background-color: #0773FC;
              cursor: pointer;
              font-weight: 600;
              font-size: 16px;
              color: #FFF;
              animation: breathe 1s linear forwards infinite;
          }
      }
      @keyframes breathe {
          0% {
              transform: scale(1,1);
          }
          50% {
              transform: scale(1.06,1.06);
          }
          100% {
              transform: scale(1,1);
          }
        }
      .new-user-lead-upTime-content{
          padding: 0 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .new-user-lead-tips{
              color: var(--2, #505A71);
              text-align: center;
              font-size: 14px;
              line-height: 22px;
          }
          .new-user-lead-upTime-list{
              margin-top: 28px;
              margin-bottom: 40px;
              display: flex;
              flex-wrap: wrap;
              width: 352px;
              .new-user-lead-upTime-item{
                  display: flex;
                  width: 168px;
                  height: 44px;
                  padding: 8px 16px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  border-radius: 4px;
                  border: 1px solid var(--5, #EBEEF5);
                  margin-right: 16px;
                  margin-bottom:16px;
                  cursor: pointer;
                  &:hover{
                      color: var(--unnamed, #0773FC);
                      border: 1px solid var(--unnamed, #0773FC);
                      font-weight: 600;
                  }
                  &:nth-child(2){
                      margin-right: 0;
                  }
                  &:nth-child(3){
                      margin-bottom:0;
                  }
                  &:nth-child(4){
                      margin-right: 0;
                      margin-bottom:0;
                  }
              }
              .orderActive{
                  color: var(--unnamed, #0773FC);
                  border: 1px solid var(--unnamed, #0773FC);
                  font-weight: 600;
              }
          }
      }
  }
}

</style>