<template>
  <div class="quick-wrapper" @mouseenter="showQuick" @mouseleave="hideQuick(false)" v-if="isLogin">
    <div>
      <slot></slot>
    </div>
    <div v-show="visible" class="quick">
      <div class="quick-main">
       <div class="quick-search">
         <img class="quick-search-icon" :src="require('./img/search.svg')" alt="搜索场景尺寸创建空白画布" />
         <!-- 解决自动填充问题 -->
         <input type="text" class="hidden-input">
         <input type="password" class="hidden-input">
         <input
           name="quick-search-input"
           class="quick-search-input"
           placeholder="搜索场景开始设计"
           autocomplete="off"
           v-model="searchValue"
           type="text"
           :maxlength="32"
         />
         <div v-if="searchValue" class="quick-search-clear" @click="onClear" />
       </div>
       <template v-if="!showCustomSize">
         <ul class="quick-scenes" v-if="filterScenes.length">
           <li class="quick-scenes-item" v-for="(item, index) in filterScenes" :key="index" @click="goDesign(item, true)">
             <img class="quick-scenes-item-icon" :src="item.iconUrl || defaultImg" @error="imgError"/>
             <div class="quick-scenes-item-name">{{ item.title }}</div>
             <div class="quick-scenes-item-desc">{{ item.width }} * {{ item.height }}</div>
           </li>
         </ul>
         <div v-else class="quick-scenes-empty">
           <div class="quick-scenes-empty-tip" v-if="searchValue">
             没有关于“{{ searchValue.length > 8 ? searchValue.slice(0, 8) + '...' : searchValue }}”的搜索结果
           </div>
           <div class="quick-scenes-empty-link" @click="createCustomSize">
             创建自定义大小
           </div>
         </div>
       </template>
       <template v-else>
         <CustomSize @on-create="customSizeCreateDesign"/>
         <template v-if="recommendedScenes.length">
           <div class="recommended-size">推荐尺寸</div>
           <ul class="quick-scenes" style="max-height: 180px">
             <li class="quick-scenes-item" v-for="(item, index) in recommendedScenes" :key="index" @click="goDesign(item)">
               <img class="quick-scenes-item-icon" :src="item.iconUrl || defaultImg" @error="imgError" />
               <div class="quick-scenes-item-name">{{ item.title }}</div>
               <div class="quick-scenes-item-desc-t">{{ item.width }} * {{ item.height }}</div>
             </li>
           </ul>
         </template>
       </template>
      </div>
      <div class="quick-footer" v-if="!showCustomSize">
       <div class="quick-operate">
         <div class="quick-operate-icon quick-size" @click="customSize">
         </div>
         <div class="quick-operate-text" @click="customSize">自定义尺寸</div>
       </div>
       <div class="quick-operate" v-if="!isFuseNewTeam">
         <div class="quick-operate-icon quick-upload" @click="uploadFile">
         </div>
         <div class="quick-operate-text" @click="uploadFile">上传并编辑图片</div>
       </div>
       <div class="quick-operate" v-if="!isFuseNewTeam">
         <div class="quick-operate-icon quick-import" @click="importDesign">
         </div>
         <div class="quick-operate-text" @click="importDesign">导入设计</div>
       </div>
      </div>
    </div>
    <PreviewModal :userInfo="userInfo" :teamInfo="teamInfo" :teamId="teamId" ref="previewModalRef" />
    <LtModal
      v-model="customSizePopShow"
      :closable="false"
    >
      <!--自定义尺寸弹窗-->
      <CustomSizePop
        :teamId="teamId"
        :isLogin="isLogin"
        :is-point="true"
        @closeCustomSizePop="customSizePopShow = false"
      />
    </LtModal>

    <UploadPSD
      :visible="uploadPSDModalVisible"
      title="上传设计"
      :fileType="['PSD']"
      :maxSize="300"
      :type="0"
      :fid="0"
      @on-cancel="uploadPSDModalVisible = false"
    />
  </div>
</template>

<script>
import CustomSize from "./custom-size.vue";
import PreviewModal from "./preview-modal.vue";
import CustomSizePop from './components/customSizePop/customSizePop.vue'
import UploadPSD from './components/uploadBatch/psdIndex.vue'
import {mapActions, mapGetters} from "vuex";
import mixin from "./mixin";
import pageNameEnum from "../../pageNameEnum";

export default {
  name: "quickDesign",
  components: {
    CustomSize,
    PreviewModal,
    CustomSizePop,
    UploadPSD,
  },
  inject: ['header'],
  computed: {
    ...mapGetters({
      teamId: 'getTeamId',
    }),
  },
  mixins: [mixin],
  props: {
    userInfo: {
      type: Object,
      required: true,
      default() {
        return {
          nickname: '',
          email: '',
          userHeadImgUrl: '',
          urnCount: 0,
        };
      },
    },
    teamInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    isLogin: {
      type: Boolean
    },
    teamId: {
      type: Number,
      default: 0
    },
    isFuseNewTeam: {
      type: Boolean
    },
  },
  data() {
    return {
      name: 'createDesign',
      customSizePopShow: false,
      uploadPSDModalVisible: false,
    }
  },
  methods: {
    ...mapActions({
      setLogregShow: 'setLogregShow',
    }),
    goTemplateCenter(item){
      let href = `/sj-pi${item.parentKindId}-si${item.id}-or0-pt0-pn1.html`
      window.location.href = href
    },
    reportFunctionArea(is_login, button_name) {
      try {
        window.gio('track', 'button_click', {
          page_name: '创建设计小弹窗',
          module_name: '快捷功能区',
          button_name,
          class_id: null,
          page_url: location.href,
          is_login: is_login ? '是' : '否',
        })
      } catch (err) {
        console.error(err)
      }
    },
    reportPopupShow1(is_login) {
      try {
        window.gio('track', 'popup_show', {
          page_name: pageNameEnum[this.$route.path] || '',
          popup_name: '自定义尺寸弹窗',
          page_url: location.href,
          is_login: is_login ? '是' : '否',
        })
      } catch (err) {
        console.error(err)
      }
    },
    customSize() {
      this.reportFunctionArea(this.isLogin, '自定义尺寸')
      if (!this.isLogin) {
        this.setLogregShow(1);
        this.header.loginSuccessCb = () => {
          this.customSizePopShow = true
        }
        return;
      }
      this.customSizePopShow = true
      this.reportPopupShow1(this.isLogin)
    },
    uploadFile() {
      this.reportFunctionArea(this.isLogin, '上传并编辑图片')
      if (!this.isLogin) {
        this.setLogregShow(1);
        this.header.loginSuccessCb = () => {
          this.$refs.previewModalRef.uploadFile()
        }
        return;
      }
      this.$refs.previewModalRef.uploadFile()
    },
    importDesign() {
      this.reportFunctionArea(this.isLogin, '导入设计')
      if (!this.isLogin) {
        this.setLogregShow(1);
        this.header.loginSuccessCb = () => {
          this.uploadPSDModalVisible = true
        }
        return;
      }
      this.uploadPSDModalVisible = true
    }
  }
}
</script>

<style scoped lang="less">
.quick-wrapper {
  position: relative;
  user-select: none;
  -webkit-font-smoothing: antialiased;
}
.quick {
  position: absolute;
  top: 41px;
  left: -250px;
  width: 332px;
  background-color: #fff;
  box-shadow: 0 6px 24px 4px rgba(27, 35, 55, 0.12);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .quick-main {
    flex: 1;
    padding: 20px 0 10px;
    .quick-search {
      margin: 0 24px 10px;
      width: 284px;
      height: 40px;
      position: relative;
      &-input {
        box-sizing: border-box;
        width: 284px;
        height: 100%;
        background: #F5F7FD;
        border-radius: 4px;
        padding-left: 48px;
        padding-right: 38px;
        border: none;
        outline: none;
        color: #1B2337;
        &::placeholder {
          color: #8693AB;
        }
      }
      &-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
      &-clear {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        background: url("./img/clear.svg") no-repeat;
        &:hover {
          background: url("./img/clear-hover.svg") no-repeat;
        }
      }
    }
    .quick-scenes {
      max-height: 340px;
      overflow-y: auto;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &-item {
        cursor: pointer;
        padding: 0 24px;
        height: 40px;
        display: flex;
        align-items: center;
        &:hover {
          background: #F5F7FD;
          .quick-scenes-item-desc {
            display: block;
          }
        }
        &-icon {
          width: 24px;
          height: 24px;
          //background: #FEE9E9;
          margin-right: 8px;
          object-fit: contain;
        }
        &-name {
          flex: 1;
          font-weight: 400;
          font-size: 14px;
          color: #1B2337;
        }
        &-desc {
          display: none;
          font-weight: 400;
          font-size: 12px;
          color: #8693AB;
        }
        &-desc-t {
          font-weight: 400;
          font-size: 12px;
          color: #8693AB;
        }
      }
      &-empty {
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-tip {
          height: 22px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #505A71;
          margin-bottom: 15px;
        }
        &-link {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          border-bottom: 1px solid #0773FC;
          color: #0773FC;
          cursor: pointer;
        }
      }
    }
    .recommended-size {
      padding: 16px 24px 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #8693AB;
    }
  }
  .quick-footer {
    height: 124px;
    background: #F5F7FD;
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid #EBEEF5;
    .quick-operate {
      width: 64px;
      height: 100%;
      padding-top: 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-icon {
        cursor: pointer;
        width: 64px;
        height: 64px;
        border: 1px solid #EBEEF5;
        border-radius: 50%;
        margin-bottom: 8px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: 24px 24px;
      }
      .quick-size {
        background: #FFFFFF url("./img/size.svg") no-repeat center;
        &:hover {
          border: 1px solid rgba(7, 115, 252, 0.2);
          background: #FFFFFF url("./img/size-hover.svg") no-repeat center;
        }
        &::after {
          content: url("./img/size-hover.svg");
          width: 0;
          height: 0;
          overflow: hidden;
        }
      }
      .quick-upload {
        background: #FFFFFF url("./img/upload.svg") no-repeat center;
        &:hover {
          border: 1px solid rgba(7, 115, 252, 0.2);
          background: #FFFFFF url("./img/upload-hover.svg") no-repeat center;
        }
        &::after {
          content: url("./img/upload-hover.svg");
          width: 0;
          height: 0;
          overflow: hidden;
        }
      }
      .quick-import {
        background: #FFFFFF url("./img/import.svg") no-repeat center;
        &:hover {
          border: 1px solid rgba(7, 115, 252, 0.2);
          background: #FFFFFF url("./img/import-hover.svg") no-repeat center;
        }
        &::after {
          content: url("./img/import-hover.svg");
          width: 0;
          height: 0;
          overflow: hidden;
        }
      }
      &-text {
        cursor: pointer;
        white-space: nowrap;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #1B2337;
      }
    }
  }
  .hidden-input {
    position: absolute;
    left: -99999px;
    opacity: 0;
    width: 0;
    height: 0;
  }
}
</style>