/**
 * Created by Chuanfeng on 2017/7/5.
 */

import * as types from '../mutation-types'

const state = {
  tdk: {
    title: '',
    description: '',
    keywords: ''
  }
}

const getters = {
  getTDK: state => state.tdk
}

const mutations = {
  [types.SET_TDK] (state, tdk) {
    state.tdk = tdk
  }
}

const actions = {
  setTDK ({commit}, tdk) {
    commit(types.SET_TDK, tdk)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}