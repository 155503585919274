<template>
  <div class="tips-wrap" v-if="tipsShow">
    <ul class="tips-content">
      <li class="mounting-li"
          :style="{'z-index': index + 2, 'display': readIds.includes(item.noticeId) ? 'none': ''}"
          v-for="(item,index) in noticeList" :key="item.noticeId">
        <div class="tips-msg">
          {{ getPlainText(item.content) }} <a style="margin-left: 20px" href="javascript:void(0);"
                                              @click="setNoticeAsHasRead(item, true)">立即查看</a>
        </div>
        <a class="close-icon" href="javascript:void(0);" @click="setNoticeAsHasRead(item, false)"></a>
      </li>
      <!-- <li class="overdue-li" style="z-index: 1;" v-if="false">
      <span class="tips-msg">
      您的企业 {{ teamName }} {{ leftDaysDesc }}试用到期，为保证企业正常使用，请&nbsp;<a href="/price/company">购买企业版</a>
    </span>
        <a class="close-icon" href="javascript:void(0);" @click="close"></a>
      </li> -->
    </ul>
  </div>
</template>

<script>
// 显示条件：0)已登录 1)localStorage 2)是否为试用团队且 7 天内到期

const aDay = 24 * 60 * 60 * 1000

export default {
  data() {
    return {
      tipsShow: false,
      readIds: [],
      isShowExpired: false,
    }
  },
  props: {
    vipTrailExpireDate: {
      type: Number,
    },
    teamId: {
      type: Number,
      default: 0
    },
    teamName: {
      type: String
    },
    noticeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    leftDaysDesc() {
      let days = '还有'
      if (this.diff === 0) {
        days = '今天'
      } else if (this.diff > 0) {
        days += `${this.diff}天`
      } else {
        days = '已经'
      }
      return days
    }
  },
  watch: {
    vipTrailExpireDate() {
      this.judgeIsShowTips()
    },
    noticeList() {
      this.judgeIsShowTips()
    }
  },
  mounted() {
    this.judgeIsShowTips()
  },
  methods: {
    judgeIsShowTips() {
      // 获取当前试用版团队的到期时间  this.vipTrailExpireDate
      // 判断是否在 7 天内  this.vipTrailExpireDate - Date.now() <= aDay * 7
      // 判断今日是否已显示  Date.now() - lastShowTime >= aDay
      if (this.vipTrailExpireDate === 0 && this.noticeList.length === 0) {
        this.tipsShow = false
        this.$nextTick(()=>{
          this.$emit('change', this.tipsShow)
        })
        return
      }

      if (this.noticeList.length) {
        this.tipsShow = true
      } else {
        const lastShowTime = localStorage.getItem(`expire_tips_show_${this.teamId}`) || 0
        if (Date.now() - lastShowTime >= aDay && this.vipTrailExpireDate - Date.now() <= aDay * 7) {
          this.diff = Math.ceil((this.vipTrailExpireDate - Date.now()) / aDay)
          this.tipsShow = true
          this.isShowExpired = true
        }
      }
      this.$nextTick(()=>{
        this.$emit('change', this.tipsShow)
      })
    },
    getPlainText(str) {
      return str.replace(/<[^>]*>|/g, "")
    },
    setNoticeAsHasRead(item, needJump) {
      let data = {
        gw: true,
        noticeId: item.noticeId,
      }
      this.readIds.push(item.noticeId)
      if (needJump) {
        window.gio('track', 'top_bar_click', {
          activity_type: '分层营销活动'
        });
      }
      this.$http
          .post('/mbox/notice/setNoticeAsHasRead', data)
          .then(res => {
            // 200:设置为已读成功 -1:未登录 -2:设置失败
            let code = res.body.code
            if (this.readIds.length === this.noticeList.length && !this.isShowExpired) {
              this.tipsShow = false
              this.$nextTick(()=>{
                this.$emit('change')
              })
            }
            if (needJump) {
              location.href = item.noticeUrl
            }
            if (code === -401) {
              this.logReg(1)
            }
          })
    },
    close() {
      this.tipsShow = false
      // 当天不再提示
      localStorage.setItem(`expire_tips_show_${this.teamId}`, Date.now())
      this.$nextTick(()=>{
        this.$emit('change')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.tips-wrap {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
}

.tips-content {
  width: 100%;
  position: relative;
  height: 32px;

  li {
    position: absolute;
    background-color: #FFE5BE;
    width: 100%;
    height: 32px;
  }
}

.tips-msg {
  flex: 1;
  text-align: center;
  width: 100%;
  display: block;
  height: 32px;
  line-height: 32px;

  a {
    color: #0773fc;
    text-decoration: underline;
    cursor: pointer;
  }
}

.close-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.768 10.669L7.023 5.943l4.62-4.676a.737.737 0 0 0 0-1.05c-.292-.29-.558-.29-.85 0L6.026 4.95 1.276.218a.75.75 0 0 0-1.057 0 .737.737 0 0 0 0 1.049l4.76 4.722L.22 10.71a.737.737 0 0 0 0 1.049.75.75 0 0 0 1.057 0l4.71-4.768 4.805 4.768c.292.29.682.248.973-.04a.74.74 0 0 0 .002-1.051z' fill='%23000' fill-rule='evenodd' fill-opacity='.4'/%3E%3C/svg%3E");
}
</style>
