<template>
  <LtModal
    v-model="showFlagTips"
    :closable="true"
    :mask-closable="false"
    @on-cancel="handleCancel"
  >
    <div class="show-flag-tips-box">
      <div class="title">温馨提示</div>
      <div class="des">
        您当前为旗舰版，可扫码联系顾问续费或咨询
      </div>
      <img src="../img/flag-tip-code.jpg" />
      <div class="confirm-button" @click="handleCancel">我知道了</div>
    </div>
  </LtModal>
</template>
<script>
  export default {
    data() {
      return {};
    },
    props: {
      showFlagTips: {
        type: Boolean,
        default: false,
      },
      pageName: {
        type: String,
        default: '',
      },
      moduleName: {
        type: String,
        default: '',
      },
    },
    watch: {
      showFlagTips(val) {
        if (val) {
          try {
            window.gio('track', 'vip_qjsale_show', {
              forward_module_name: this.moduleName,
              forward_page_name: this.pageName,
              popup_name: '旗舰版咨询弹框',
              is_login: '是',
            });
          } catch (error) {
            console.log('error', error)
          }
        }
      },
    },
    methods: {
      handleCancel() {
        this.$emit('update:showFlagTips', false);
      },
    },
  };
</script>
<style lang="less">
  .show-flag-tips-box {
    width: 396px;
    height: 412px;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
    }
    .des {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-top: 32px;
    }
    img {
      width: 160px;
      height: 160px;
      margin-top: 32px;
    }
    .confirm-button {
      width: 136px;
      height: 40px;
      margin-top: 40px;
      background-color: #0773fc;
      border-radius: 8px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      color: #fff;
      &::hover {
        background-color: #0667e2;
      }
    }
  }
</style>
