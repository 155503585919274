<template>
  <div ref="aiBox" class="component-ai-deisign">
   <div class="component-ai-top">
    <div class="component-ai-tips">
      <img :src="searchDefaultArr[0].picUrl"/>
      <span :style="{ '--selfWidth': presetWordsWidth }" :title="presetWords">{{presetWords}}</span>
    </div>
    <div class="component-ai-lead">
      <span @click="goAiDesign">更多一键生成</span>
      <span type="more" class="more-icon"></span>
    </div>
   </div>
   <div class="component-ai-list-warp">
      <ul class="component-ai-list">
        <li  class="component-ai-list-item" v-for=" item in renderList()" :key="item.taskId" @click="goToDesign(item)">
          <img :src="item.thumbnailUrl"/>
        </li>
      </ul>
   </div>
  </div>
</template>

<script>

import {mapGetters } from 'vuex'

export default {
  name: 'AiDesignModule',
  props: {
    value: {
      type: Boolean,
    },
    searchWarpWidth: {
      type: Number,
      default:0,
    },
    isLogin: {
      type: Boolean,
      default: false
    },
    teamId: {
      type: Number,
      default: 0
    },
    searchDefaultArr: {
      type: Array,
      default: () => {
        return []
      }
    },
    presetWords: {
      type: String,
      default: ''
    },
    aiTemplateList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      presetWordsWidth:null
    }
  },
  computed: {
    ...mapGetters({
        userInfo: 'getUserInfo',
        teamInfo: 'getTeamInfo',
      }),
  },
  mounted() {
    this.$nextTick(() => {
      this.presetWordsWidth = (this.$refs.aiBox?.clientWidth-158)+'px'
    })
    const newList = this.renderList()
    if (!newList.length) return
    this.reportData('template_expoud',newList)
  },
  watch:{
    searchWarpWidth(){
      if (!this.aiTemplateList.length) return
      this.presetWordsWidth = (this.$refs.aiBox?.clientWidth-158)+'px'
      this.renderList()
    },
    userInfo(v){
      if (sessionStorage.getItem('designOpen') && !v.LoginTimeOut) {
        let isBeta = sessionStorage.getItem('isBeta')
        if ((isBeta && workEnv === 'prod') || (!isBeta && workEnv === 'beta')) return
        this.goToDesign(JSON.parse(sessionStorage.getItem('designOpen')))
        sessionStorage.removeItem('designOpen')
        sessionStorage.removeItem('isBeta')
      }
      if (sessionStorage.getItem('designAIOpen') && v){
        this.goAiDesign()
        sessionStorage.removeItem('designAIOpen')
      }
    },
  },
  methods: {
    renderList(){
      let resultList= this.aiTemplateList.slice(0,5)
      if (this.searchWarpWidth<=360){
        return resultList.filter((item,index) =>  index !== resultList.length-1 && index !== resultList.length-2 && index !== resultList.length-3);
      }
      if (this.searchWarpWidth>=361 && this.searchWarpWidth<=488){
        return resultList.filter((item,index) =>  index !== resultList.length-1 && index !== resultList.length-2);
      }
      if (this.searchWarpWidth>=489 && this.searchWarpWidth<=624){
        return resultList.filter((item,index) =>  index !== resultList.length-1);
      }
      return resultList
    },
    //去智能设计
    goAiDesign(){
      if (!this.isLogin) {
        this.$message('请先登录')
        this.$emit('setLogin')
        sessionStorage.setItem('designAIOpen', true)
        this.reportGio('搜索联想','智能设计_点击场景')
        return
      }
      window.location.href='/ai-design/session?source=web-bigsearch1'
      this.$emit('reportDesigntrack')
    },
     // 模版去设计页
     goToDesign(item){
      if (!this.isLogin) {
          this.$message('请先登录')
          this.$emit('setLogin')
          sessionStorage.setItem('designOpen', `${JSON.stringify(item)}`)
          this.reportGio('搜索框','搜索下拉框广告位')
          this.reportAIData()
          return
      }
      const href = '/odyssey/design'
      try {
        const params={
          gw: true,
          isInclude: true,
          nottrans: true,
          taskId:item.taskId,
          intellectType:0
        }
        // 获取设计id
        this.$http.post("/tools/design/intellect/save",  params).then((response) => {
          if (response.body.code === 200){
            this.reportData('template_click',{templateId:response.body.data})
            this.reportAIData()
            window.open(
              `${href}?d=${response.body.data}&forward_page_name=搜索框面板结果&version=会话版`
            )
          }
        });
      } catch (error) {}
    },
    // 上报数据仓库和gio
    reportData(event,data) {
      try {
        const exploreBaseData = []
        let baseData = {}
        // 数仓数据上报
        if (event==='template_expoud'){
          data.map((temp, index) => {
            baseData = {
              event,
              event_name: '模板曝光',
              trigger_timing: '模板显示时打点（前端打点）',
              params: {
                forward_page_name: '搜索框',
                forward_module_name: '搜索下拉框广告',
                template_params: {
                  template_type: 'AI', //
                  template_input_keyword: this.presetWords, //
                  template_seach_keyword: this.presetWords, //
                  template_seach_range: '智能设计', // 分类
                  template_id: '无', // 模板id
                }
              }
            }
            exploreBaseData.push(baseData)
          })
        } else {
          baseData={
            event,
            event_name: '模板点击',
            trigger_timing: '模板点击时打点（前端打点）',
            params: {
              forward_page_name: '搜索框',
              forward_module_name: '搜索下拉框广告',
              template_params: {
                template_type: 'AI', //
                template_input_keyword: this.presetWords, //
                template_seach_keyword: this.presetWords, // 模板第几个
                template_seach_range: '智能设计', // 分类
                template_id: data.templateId, // 模板id
              }
            }
          }
        }
        const vipNameObj = {
          0: '普通用户',
          1: '企业VIP-基础版',
          2: '协作会员',
          4: '个人版',
          5: '企业VIP-标准团队版',
          6: '企业专业版VIP',
          7: '企业VIP-加强版',
          8: '企业VIP-基础团队版',
          9: '企业VIP-全用途版',
          100: '基础版VIP',
          105: '全用途版VIP',
          110: '标准团队版VIP',
          115: '高级团队版VIP',
          120: '企业专业版',
          125: '新媒体商户版',
          130: '全用途商户版',
          135: '团队协作版',
          201: '个人高级版',
          3: '高级团队VIP',
          202: '企业高级版',
          203: '高级版',
          205: '协作版',
          220: '旗舰版'
        }
        const baseHeader = {
          user_id: this.userInfo?.userId ||'',
          user_vip: this.userInfo?.vipLevelName ||'',
          team_id: this.teamId,
          team_name: this.teamInfo ? decodeURI(this.teamInfo.teamName):'',
          team_vip: this.teamInfo ? vipNameObj[this.teamInfo.adminUserVipLevel]:'',
        }
        window.cktTracker.setBaseParams(baseHeader)
        if (event==='template_expoud'){
          window.cktTracker.bulkReport(exploreBaseData)
          exploreBaseData.map((item) => {
            window.gio('track', 'template_expoud',item.params.template_params)
          })
        } else {
          window.cktTracker.report(baseData)
          window.gio('track', 'template_click',baseData.params.template_params)
        }
      } catch (error) {
        console.log(error)
      }
    },
    //登录上报gio
    reportGio(page_name, module_name){
      let params ={
          forward_page_name:page_name,
          forward_module_name:module_name,
          page_name: '微信登陆框',
        }
      window.gio("track", "login_show", params);
    },
     // 模版点击上报
     reportAIData(){
      try {
        window.gio('track', 'ai_design_result_edit',{
          forward_page_name: '搜索框面板结果',
          ai_design_scene_name: '无',
          versions_type: '会话版',
          user_id: this.userInfo?.userId ||'',
          user_vip: this.userInfo?.vipLevelName ||'',
          template_id: '无', // 模板id
          content_click:'高级编辑',
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="less">
@selfWidth: var(--selfWidth);

.component-ai-deisign {
  margin: 20px 24px 0;
  border-radius: 8px;
  padding: 12px;
  background: var(---9, #F5F7FD);
  .component-ai-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .component-ai-tips{
      display: flex;
      align-items: center;
      img{
        width: 24px;
        height: 24px;
      }
      span{
        color: #505A71;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-left: 4px;
        width: @selfWidth;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .component-ai-lead{
      display: flex;
      align-items: center;
      cursor: pointer;
      span{
        color: #635FF2;
        font-size: 12px;
        line-height: 20px;
        &:hover{
          color: #4F4CC2;
        }
      }
      .more-icon {
        width: 14px;
        height: 14px;
        display: inline-block;
        background-image: url("./img/more-icon.svg");
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 2px;
      }
    }
  }
  .component-ai-list-warp{
    margin-top: 12px;
    .component-ai-list{
      display: flex;
      align-items: center;
      justify-content: center;
      .component-ai-list-item{
        margin-right: 10px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        max-height: 200px;
        overflow-y: hidden;
        &:last-child{
          margin-right: 0;
        }
        img {
          width: 100%;
          height: 100%;
          background-size: contain;
          border-radius: 6px;
          display: block;
        }
      }
    }
    
  }
}
@media screen and (max-width: 1580px) {
  .component-ai-tips {
    span{
      width: 116px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
