<!--Created by Chuanfeng on 2017/6/27.-->
<template>
  <div class="notice">
    <p class="notice-subject">{{ notice.subject }}</p>
    <div
      class="notice-content"
      v-html="decodeURIComponent(notice.content)"
      @click="setNoticeAsHasRead(notice.noticeId)"
    ></div>
    <a
      v-if="notice.noticeUrl"
      :href="notice.noticeUrl"
      class="detail-link"
    >点击查看详情</a>
    <div class="notice-close" @click="close"></div>
  </div>
</template>

<script>
  export default {
    props: {
      notice: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
      setNoticeAsHasRead(noticeId = 0) {
        this.$http.post('/mbox/notice/setNoticeAsHasRead', {
          gw: true,
          noticeId: noticeId
        }).then(res => {
          // 200:设置为已读成功 -1:未登录 -2:设置失败
          let code = res.body.code
          if (code === -401) {
            this.$message('请登录')
            this.$router.push('/logreg')
          }
        })
      },
      close() {
        this.$emit('closeNotice');
      }
    }
  }
</script>

<style lang="less" scoped>
  .notice {
    position: absolute;
    z-index: 1000;
    top: 88px;
    right: 24px;
    width: 323px;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.16);
    border-radius: 4px;
    .notice-subject {
      color: #9B9B9B;
      font-size: 12px;
      margin-bottom: 16px;
      width: 285px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .notice-content {
      color: #4A4A4A;
      font-size: 14px;
      line-height: 18px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      user-select: text;
    }
    .detail-link {
      position: relative;
      font-size: 12px;
      color: #4A4A4A;
      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 1px;
        background-color: #626262;
      }
    }
    .notice-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 28px;
      height: 28px;
      background-image: url("./img/notice_close_icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }
</style>
