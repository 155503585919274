<template>
  <div class="send-vip-box">
    <img src="./img/vip_box.png" />
    <p class="send-vip-title">免费领3天VIP尝鲜会员</p>
    <p class="send-vip-text">新用户专享礼</p>
    <div class="receive-vip-btn" @click="$emit('close',2)">点击领取</div>
    <p class="send-vip-time">
      倒计时<span>{{ time }}s</span>
    </p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      time: 6,
      timer: null,
    };
  },
  mounted() {
    this.countDown();
  },
  methods: {
    ...mapActions([
        "setLogregShow",
    ]),
    countDown() {
      this.timer = setInterval(() => {
        this.time--;
        if (this.time < 0) {
          clearInterval(this.timer);
          this.$emit('close')
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.send-vip-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 430px;
  height: 292px;
  border-radius: 4px;
  background: #ffffff;
  img {
    width: 106px;
    height: 79px;
    margin-top: 24px;
  }
  .send-vip-title {
    margin-top: 20px;
    color: #0773fc;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
  }
  .send-vip-text {
    margin-top: 8px;
    color: #1b2337;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
  }
  .receive-vip-btn {
    width: 120px;
    height: 40px;
    margin-top: 26px;
    border-radius: 4px;
    background: #0773fc;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    animation: breathe 1.1s infinite;
    cursor: pointer;
  }
  @keyframes breathe{
		  0%{ transform: scale(1); }
		  50%{ transform: scale(1.1); }
		  100%{ transform: scale(1); }
		}
  .send-vip-time {
    margin-top: 8px;
    margin-bottom: 19px;
    font-size: 14px;
    font-weight: 400;
    color: #505a71;
    line-height: 1;
    span {
      color: #fa2323;
    }
  }
}
</style>