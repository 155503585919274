<template>
  <div>
    <LtModal
      v-model="fillPopShow"
      :closable="false"
      :mask-closable="false"
      width="624"
    >
      <div class="authorization-pop">
        <LtIcon
          type="close"
          size="16"
          class="close-icon"
          @click="closePop"
        />
        <p class="title">{{ echoAuthorInfo && !cantFillAuthorization ? '授权信息确认' : '授权信息填写' }}</p>
        <p class="desc" v-if="echoAuthorInfo && !cantFillAuthorization">
          当您购买创客贴会员并在使用创客贴平台提示无版权风险的素材或字体时，创客贴将为您所使用的前述素材和字体提供商业授权保证。
          本次购买的商业授权仅限单一主体{{ isPersonalVip ? '（个人）' : noAuthor ? '' : '（单位）'}}使用，禁止转让！
          <b>再次购买需要您重新确认授权书信息，若您没有主动确认授权书信息，您在创客贴平台下输出的设计将无法得到商业使用授权保证。</b>
        </p>
        <p class="desc" v-else>
          当您购买创客贴会员并在使用创客贴平台提示无版权风险的素材或字体时，创客贴将为您所使用的前述素材和字体提供商业授权保证。
          本次购买的商业授权书仅限单一主体{{ isPersonalVip ? '（个人）' : noAuthor ? '' : '（单位）'}}使用，禁止转让！
          请您根据下方提示填写授权书内容，本次授权书一经提交下载，将无法更改。
        </p>

        <div class="rquired-from">
          <div class="rquired-input">
            <p class="rquired-title">
              <span class="label">{{ inputName }}</span>
              <span class="message" :class="{'show-message': isError[0]}">
                请输入正确的{{ inputName }}
              </span>
            </p>
            <input
              type="text"
              v-model="enterpriseName"
              :placeholder="inputName"
              :class="{'error': isError[0]}"
              :readonly="readOnly"
              maxlength="64"
            >
          </div>

          <div class="rquired-input">
            <p class="rquired-title">
              <span class="label">{{ unionCode }}</span>
              <span class="message" :class="{'show-message': isError[1]}">
                请输入正确的{{ unionCode }}
              </span>
            </p>
            <input
              type="text"
              v-model="creditCode"
              :placeholder="unionCode"
              :class="{'error': isError[1]}"
              :readonly="readOnly"
            >
          </div>

          <div class="rquired-input phone">
            <p class="rquired-title">
              <span class="label">手机号</span>
              <span class="message" :class="{'show-message': isError[2]}">
                请输入正确的手机号
              </span>
            </p>
            <div class="area-code">
              <input
                type="text"
                class="areaCode-input"
                v-model="areaCode"
                :readonly="readOnly"
                maxlength="5"
                @input="onAreaCodeChange"
              />
              <LtIcon
                type="arrow-dropdown"
                class="selector-icon"
                @click="selectMenuShow = !selectMenuShow"
              />
              <hr class="vertical-bar" />
            </div>
            <input
              type="text"
              placeholder="手机号码"
              v-model="enterprisePhone"
              :class="{'error': isError[2]}"
              :readonly="readOnly"
              maxlength="20"
            />
            <ul
              v-if="selectMenuShow"
              class="selector-list"
              @v-click-outside="closeSelectMenu"
            >
              <li
                v-for="item in areaCodeList"
                :class="{active: item.code === areaCode}"
                class="selector-option"
                @click.stop="onAreaCodeSelect(item)"
              >{{ item.value }}</li>
              <li class="selector-desc">
                选择常用的国家/地区，或者手动
                输入国际码
              </li>
            </ul>
          </div>

          <div class="rquired-input">
            <p class="rquired-title">
              <span class="label">邮箱地址</span>
              <span class="message" :class="{'show-message': isError[3]}">
                请输入正确的邮箱地址
              </span>
            </p>
            <input
              type="text"
              placeholder="邮箱地址"
              v-model="enterpriseEmail"
              :class="{'error': isError[3]}"
              :readonly="readOnly"
            >
          </div>

          <div class="rquired-input">
            <p class="rquired-title">
              <span class="label">联系地址</span>
              <span class="message" :class="{'show-message': isError[4]}">
                请输入正确的联系地址
              </span>
            </p>
            <input
              type="text"
              placeholder="联系地址"
              v-model="enterpriseAddress"
              :class="{'error': isError[4]}"
              :readonly="readOnly"
            >
          </div>

          <LtButton type="primary" class="submit-button" @click="verifyInputInfo">
            {{ echoAuthorInfo ? '确认填写' : '填写完成'}}
          </LtButton>
          <p class="switch-author" v-if="!personalAuthor && !readOnly">
            没有企业授权主体？
            <span @click="noAuthor = true">点击这里</span>
          </p>
        </div>
      </div>
    </LtModal>

    <LtModal
      v-model="tipsPopShow"
      :simple="false"
      title="温馨提示"
      width="400"
      ok-text="返回填写"
      :mask-closable="false"
      @on-ok="backFill"
      @on-cancel="cancelPop"
    >
      <p class="tipsPop-wrap">
        为了保障您的商业权益，购买会员后请填写授权信息。
        <b>不填写商业授权书则无法享受商用权益保障。</b>
      </p>
    </LtModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      fillPopShow: false,
      tipsPopShow: false,
      enterpriseName: '',
      creditCode: '',  //  统一社会信用代码
      enterprisePhone: '',
      enterpriseEmail: '',
      enterpriseAddress: '',
      isError: [false, false, false, false, false],  //  错误提示标记数组
      downloadUrl: '',
      isforbidClink: false,  //  禁止点击提交按钮
      areaCode: '+86',
      selectMenuShow: false,
      areaCodeList: [
        {
          value: '中国大陆（+86）',
          code: '+86'
        },
        {
          value: '中国香港（+852）',
          code: '+852'
        },
        {
          value: '中国台湾（+886）',
          code: '+886'
        },
        {
          value: '日本（+81）',
          code: '+81'
        },
        {
          value: '美国（+1）',
          code: '+1'
        },
        {
          value: '韩国（+82）',
          code: '+82'
        },
        {
          value: '印度（+91）',
          code: '+91'
        }
      ],
      noAuthor: false,
      authorType: 0,
    }
  },
  props: {
    authorizationPopShow: {
      type: Boolean
    },
    // 个人VIP
    isPersonalVip: {
      type: Boolean,
      default: false
    },
    cantFillAuthorization: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      isLogin: 'getIsLogin'
    }),
    inputName() {
      let name = ''
      if (this.personalAuthor) {
        name = '真实姓名'
      } else {
        name = '公司名称'
      }
      return name
    },
    unionCode() {
      let code = ''
      if (this.personalAuthor) {
        code = '身份证号码'
      } else {
        code = '统一社会信用代码'
      }
      return code
    },
    personalAuthor() {
      return this.isPersonalVip || this.noAuthor
    },
    // 授权主体相同时可回显授权信息
    echoAuthorInfo() {
      // 1标识个人版，5标识商业版
      return this.authorType === 1 && this.isPersonalVip || this.authorType === 5 && !this.isPersonalVip
    },
    readOnly() {
      return this.cantFillAuthorization && this.echoAuthorInfo
    }
  },
  watch: {
    enterpriseName(v) {
      if (v !== '') {
        this.$set(this.isError, 0, false)
      }
    },
    creditCode(v) {
      if (v !== '') {
        this.$set(this.isError, 1, false)
      }
    },
    enterprisePhone(v) {
      if (v !== '') {
        this.$set(this.isError, 2, false)
      }
    },
    enterpriseEmail(v) {
      if (v !== '') {
        this.$set(this.isError, 3, false)
      }
    },
    enterpriseAddress(v) {
      if (v !== '') {
        this.$set(this.isError, 4, false)
      }
    },
    authorizationPopShow(v) {
      this.fillPopShow = v
    },
    areaCode(v) {
      const reg = /^1[0-9]{10}$/
      if (v !== '+86' && !reg.test(this.enterprisePhone)) {
        this.$set(this.isError, 2, false)
      }
    }
  },
  mounted() {
    this.getUserFillAuthorization()
    this.fillPopShow = this.authorizationPopShow
  },
  methods: {
    cancelPop(){
      this.$emit('cancelPop')
    },
    getUserFillAuthorization() {
      const params = {
        gw: true
      }
      this.$http.post('/contentcopyright/user/authorization/getUserFillAuthorization', params).then(res=> {
        if (res.body.code === 200) {
          const data = JSON.parse(res.body.data)
          this.authorType = data.authorizationType
          if (this.echoAuthorInfo) {
            this.enterpriseName = data.enterpriseName
            this.creditCode = data.creditCode
            this.areaCode = data.countryCode || '+86'
            this.enterprisePhone = data.enterprisePhone
            this.enterpriseEmail = data.enterpriseEmail
            this.enterpriseAddress = data.enterpriseAddress
          }
        }
      })
    },
    onAreaCodeChange(event) {
      this.areaCode = event.target.value
      this.selectMenuShow = false
    },
    onAreaCodeSelect(item) {
      this.areaCode = item.code
      this.selectMenuShow = false
    },
    closeSelectMenu() {
      this.selectMenuShow = false
    },
    // 验证填写信息
    verifyInputInfo() {
      if (this.readOnly) {
        this.$emit('close')
        return
      }
      if (this.isforbidClink) return

      const reg_1 = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/   // 真实姓名不能输入特殊字符
      if (this.enterpriseName.trim().length === 0 ||
        this.personalAuthor && !reg_1.test(this.enterpriseName)
      ) {
        this.$set(this.isError, 0, true)
        return
      }
      const reg_2 = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/   // 身份证号码仅可为数字和字母,18位
      const reg_22 = /^(([0-9A-Za-z]{15})|([0-9A-Za-z]{18})|([0-9A-Za-z]{20}))$/ // 营业执照
      if (this.creditCode.trim().length === 0 ||
        this.personalAuthor && !reg_2.test(this.creditCode) ||
        !reg_22.test(this.creditCode)
      ) {
        this.$set(this.isError, 1, true)
        return
      }
      let reg_3 = /^1[0-9]{10}$/
      if (this.enterprisePhone === '' || !reg_3.test(this.enterprisePhone) && this.areaCode === '+86') {
        this.$set(this.isError, 2, true)
        return
      }
      let reg_4 = /^\w[-\w.+]*@([A-Za-z0-9][-_A-Za-z0-9]+\.)+[A-Za-z]{2,14}$/
      if (this.enterpriseEmail === '' || !reg_4.test(this.enterpriseEmail)) {
        this.$set(this.isError, 3, true)
        return
      }
      if (this.enterpriseAddress.trim().length === 0) {
        this.$set(this.isError, 4, true)
        return
      }
      this.isforbidClink = true
      this.saveAndSubmit()
    },
    // 保存和提交
    saveAndSubmit() {
      const data = {
        authorization_type: this.isPersonalVip ? 1 : 5,
        enterprise_name: this.enterpriseName,
        credit_code: this.creditCode,
        country_code: this.areaCode,
        enterprise_phone: this.enterprisePhone,
        enterprise_email: this.enterpriseEmail,
        enterprise_address: this.enterpriseAddress,
        gw: true
      }
      this.$http.post('/contentcopyright/user/authorization/fillAuthorizationInfo', data ).then(res => {
        const code = res.body.code
        // -101：参数错误，-102：公司名称太长，-103：社会信用码太长，-104：联系地址太长，
        // -105：手机号或者邮箱格式错误，-106：用户未登录，-201：用户已填写
        this.isforbidClink = false
        switch (code) {
          case 200:
            this.$emit('success')
            break
          case -106:
            this.$message('未登录')
            break
          case -201:
            this.$message('已填写，请勿重复提交')
            break
          default:
            this.$message(`提交失败，错误码${code}`)
            break
        }
      })
    },
    // 关闭弹窗
    closePop() {
      if (this.readOnly) {
        this.$emit('close')
        return
      }
      this.fillPopShow = false
      this.tipsPopShow = true
    },
    backFill() {
      this.tipsPopShow = false
      this.fillPopShow = true
    }
  },
}
</script>

<style lang="less">
.authorization-pop {
  .lt-radio-wrapper {
    font-size: 14px;
    color: rgba(0,0,0,0.60);
    margin-right: 24px;
  }
}
</style>

<style lang="less" scoped>
@import "./authorizationPop.less";
</style>
