export default function() {
  // padStart()方法的polyfill
  if (!String.prototype.padStart) {
    String.prototype.padStart = function(targetLength, padString) {
      // 截断数字或将非数字转换为0
      targetLength = targetLength >> 0
      padString = String(typeof padString !== 'undefined' ? padString : ' ')
      if (this.length > targetLength || padString === '') {
        return String(this)
      }
      targetLength = targetLength - this.length
      if (targetLength > padString.length) {
        // 添加到初始值以确保长度足够
        padString += padString.repeat(targetLength / padString.length)
      }
      return padString.slice(0, targetLength) + String(this)
    }
  }
}
