<template>
  <div class="send-vip-box">
    <img src="./img/vip_open_box.png" />
    <p class="send-vip-title"><span>恭喜你获得：</span>3天VIP尝鲜会员</p>
    <p class="send-vip-text">每天可以免费下载<span> 1张会员专属内容</span></p>
    <div class="receive-vip-btn" @click="$emit('close')">我知道了</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: 6,
    };
  },
};
</script>

<style lang="less" scoped>
.send-vip-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 430px;
  height: 300px;
  border-radius: 4px;
  background: #ffffff;
  img {
    width: 137px;
    height: 95px;
    margin-top: 38px;
  }
  .send-vip-title {
    margin-top: 13px;
    color: #0773fc;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    span {
      color: #1b2337;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .send-vip-text {
    margin-top: 16px;
    color: #1b2337;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    cursor: pointer;
    span {
      color: #fa2323;
    }
  }
  .receive-vip-btn {
    width: 120px;
    height: 40px;
    margin: 24px 0 44px 0;
    border-radius: 4px;
    background: #0773fc;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background: #0667e2;
    }
  }
}
</style>