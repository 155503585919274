
const state = {
  teamGuide: null,
  funcFuide: null,
}

const getters = {
  getTeamGuide: state => state.teamGuide,
  getFuncGuide: state => state.funcFuide,
}

const mutations = {
  setTeamGuide (state, v) {
    state.teamGuide = v
  },
  setFuncGuide (state, v) {
    state.funcFuide = v
  },
}

const actions = {
  setTeamGuide ({commit}, v) {
    commit('setTeamGuide', v)
  },
  setFuncGuide ({commit}, v) {
    commit('setFuncGuide', v)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}