<template>
  <lt-modal
    v-model="rechargeModalShow"
    :closable="false"
    @on-visible-change="closeRechargeShow"
  >
    <div class="recharge-pop">
      <header>
        <span class="pop-title">账户充值</span>
        <lt-icon
          type="close"
          size="18"
          class="close-icon"
          @click="rechargeClose"
        ></lt-icon>
      </header>

      <main>
        <div class="content-subtitle">
          <span class="title">我的可用余额</span>
        </div>
        <div style="margin: 16px 0;">
          <span class="choose-money">
            ￥<span class="price">{{ userBalance }}</span>
          </span>
        </div>
        <div class="content-subtitle">
          <div class="tab-box">
              <span
                  :class="['tab-item', index === selectTab && 'title']"
                  v-for="(item, index) in tabs"
                  :key="index"
                  @click="tabChange(index)"
              >
                  {{item}}
              </span>
          </div>
          <lt-poptip
            trigger="hover"
            placement="bottom-end"
            offset="0,0"
            :visible-arrow="false"
            class="need-to-know"
          >
            <lt-icon
              type="remind"
              color="#F5930D"
              size="16"
              class="remind-icon"
            ></lt-icon>
            <span style="color: #1B2337;">充值说明</span>
            <div class="need-to-know__content" slot="content">
              <p style="margin-bottom: 8px;">
                1.
                余额仅能用于兑换创客贴平台下的产品和服务，暂不支持兑换现金，不支持转账交易，不支持退款，不能兑换创客贴体系外的产品和服务；
              </p>
              <p>2. 如在充值过程中遇到任何问题，请及时和客服联系。</p>
            </div>
          </lt-poptip>
        </div>

        <ul class="select-list" v-if="selectTab === 0">
          <li
            v-for="item in amountList"
            :key="item.id"
            @click="selectPrice(item, false)"
            :class="{active: rechargePrice === item.initPrice / 100}"
          >
            <span class="choose-money">￥<span class="price">{{ item.initPrice / 100}}</span></span>
            <span v-show="item.discountPrice/100" class="coupon-money">售价：<span style="font-weight:bold">{{(item.initPrice - item.discountPrice)/100}}</span>元</span>
          </li>
        </ul>

        <ul class="select-list" v-else>
          <li :class="['custom-number', !focus && 'active']" @click="tabChange(1)">
            <template v-if="focus">
                <input
                  ref="input"
                  type="number"
                  placeholder="请输入充值金额"
                  v-model="customNumber"
                  @keyup="$event.currentTarget.value = $event.currentTarget.value.replace(/[^\d]/g, '')"
                  @blur="closeInput"
                  @keyup.enter="closeInput"
              >
            </template>
            <template v-else>
              <span class="choose-money">￥<span class="price">{{ customNumber }}</span></span>
              <span v-show="customNumber && customPackage.discountPrice" class="coupon-money">售价：<span style="font-weight:bold">{{customNumber - customPackage.discountPrice / 100}}</span>元</span>
            </template>
          </li>
        </ul>

        <p class="choose-pay-way">
          <span class="title">支付方式</span>
          <a
            href="https://www.chuangkit.com/terms/faq-invoice"
            class="invoice"
            target="_blank"
            >如何开发票？</a
          >
        </p>
        <ul class="pay-way-list">
          <li
            v-for="(item, index) in payWay"
            :key="index"
            :class="{ active: selectedRecharge === index }"
            @click="selectRecharge(index)"
          >
            {{ item }}
          </li>
        </ul>
        <!-- 支付宝支付 -->
        <div class="ali-pay" v-if="selectedRecharge === 0"></div>

        <!-- 微信支付 -->
        <div class="qrcode-way" v-else-if="selectedRecharge === 1">
          <div class="mpay-qrcode-container">
            <qrcode :qrcodeurl="qrcodeurl" :size="120"></qrcode>
            <!-- <span class="refresh-mask" v-if="qrcodeExpired" @click="refreshQrcode"></span> -->
          </div>
          <div class="mpay-qrcode-tips">
            <p class="pay-info-container">
              应付金额：<span class="need-to-pay"
                ><span style="font-size:14px;font-weight: 500;">￥</span
                >{{ computeTotalPrice.toFixed(2) }}</span
              >
              <span v-if="computeTotalPrice !== rechargePrice"
                >[已优惠{{ haveCounped }}元]</span
              >
            </p>
            <p class="qr-tip">微信扫码付款</p>
          </div>
        </div>

        <!-- 对公转账 -->
        <div v-else-if="selectedRecharge === 2" class="transfer-way">
          <!-- <p class="tips">* 对公转账不支持使用优惠券，强烈建议使用快捷支付（微信、支付宝）</p> -->
          <P class="transfer-info">
            <label>收款公司名称：</label>
            <span class="copy-text">北京艺源酷科技有限公司</span>
          </P>
          <P class="transfer-info">
            <label>收款账户：</label>
            <span class="copy-text">1109 3889 4010 101</span>
          </P>
          <P class="transfer-info bottom">
            <label>开户行名称：</label>
            <span class="copy-text">招商银行股份有限公司北京青年路支行</span>
          </P>
        </div>

        <!-- 应付金额 -->
        <p class="pay-info-container" v-if="selectedRecharge !== 1">
          应付金额：<span class="need-to-pay"
            >￥{{ computeTotalPrice.toFixed(2) }}</span
          >
          <span v-if="computeTotalPrice !== rechargePrice"
            >[已优惠{{ haveCounped }}元]</span
          >
        </p>

        <div class="pay-button-container" v-if="selectedRecharge !== 1">
          <LtButton type="primary" class="pay-button" @click="payCharge">{{
            setPayButton
          }}</LtButton>
          <span class="arrival-time" v-if="selectedRecharge === 2"
            >（到账时间：招行1-2天，跨行3-5天）</span
          >
        </div>
      </main>

      <!-- 对公转账弹窗 -->
      <modal
        v-if="transferModalShow"
        type="modal1"
        :modalShow="transferModalShow"
        :closeShow="false"
        @modalClose="transferModalClose"
      >
        <public-transfer
          :transferAmount="computeTotalPrice"
          :tariffPackageId="choseCouponId"
          @promptShow="
            (e) => {
              $emit('promptShow', e);
            }
          "
          @close="transferModalClose"
        ></public-transfer>
      </modal>
    </div>
  </lt-modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import modal from '../../components/modal/modal.vue';
  import qrcode from '../../components/qrcode/qrcode.vue';
  import publicTransfer from 'components/publicTransferPop/publicTransferPop.vue';

  export default {
    components: {
      modal,
      qrcode,
      publicTransfer,
    },
    data() {
      return {
        rechargeModalShow: false,
        payWay: ['支付宝支付', '微信支付', '对公转账'],
        rechargePrice: 100,
        selectedRecharge: 1, // 默认选择微信支付
        amountList: [],
        qrcodeurl: '',
        canPay: false,
        haveCounped: '',
        transferModalShow: false,
        choseCouponId: 23,
        tabs: ['充值金额', '自定义充值金额'],
        selectTab: 0,
        focus: true,
        customNumber: 1000,
        customPackage: {
          discountPrice: 0
        },
        customPay: false,
        showCustom: false
      };
    },
    computed: {
      ...mapGetters({
        rechargeShow: 'getRechargeShow',
        userInfo: 'getUserInfo',
      }),
      userBalance() {
        return parseFloat(
          ((this.userInfo && this.userInfo.vmAmount) || 0) / 100,
        ).toFixed(2);
      },
      computeTotalPrice() {
        return this.rechargePrice == '' ||
          isNaN(this.rechargePrice - this.haveCounped) ||
          this.rechargePrice - this.haveCounped < 0
          ? '0'
          : this.rechargePrice - this.haveCounped;
      },
      setPayButton() {
        return this.selectedRecharge === 2
          ? '点击提交转账凭证'
          : '前往支付宝支付';
      },
    },
    props: {
      orderNo: {}
    },
    watch: {
      rechargeShow(v) {
        this.rechargeModalShow = v;
        if (v) {
          
          this.getBalanceTariffPackages();
        }
      },
      rechargePrice(val) {
        if (val == '') return;
        this.rechargePrice =
          isNaN(val) || this.rechargePrice < 0 ? '' : parseInt(val);
      },
    },
    mounted() {
      this.rechargeModalShow = this.rechargeShow;
      if (this.rechargeModalShow) {
        this.getBalanceTariffPackages();
      }
    },
    methods: {
      closeInput() {
        this.customNumber = Number(this.customNumber);
        if (this.customNumber < 1000 || this.customNumber > 100000) {
          this.customNumber = 1000;
          this.$message({
            message: '只能输入1000-100000之间的整数',
            type: 'warning'
          });
          // 恢复弹窗初始时的选择
          this.selectPrice(this.amountList[3], false);
          this.customPay = false;
        } else {
          this.focus = false;
          this.customPay = true;
          // 都是
          this.customPackage.initPrice = this.customNumber * 100;
          this.selectPrice(this.customPackage, false);
        }
      },
      tabChange(index) {
        this.selectTab = index;
        this.focus = !!index;
        this.customPay = !!index;
        if (index) {
          this.$nextTick(() => {
              this.$refs.input.focus()
          })
        }
      },
      // 选择充值金额
      selectPrice(item, gio) {
        this.rechargePrice = item.initPrice / 100; //总金额
        this.haveCounped = item.discountPrice / 100; //优惠金额
        this.choseCouponId = item.id;
        this.checkBeforePay();
        if (this.selectedRecharge === 1 && this.rechargeModalShow) {
          this.useQrcodePay();
        }
        if (gio) {
          const orderPage = {
            '/price/personal': '个人价格页',
            '/price/company': '企业价格页',
            '/accountcenter': '账户中心',
            '/design': '编辑页面',
          };
          const params = {
            order_type: '余额',
            order_name: item.name,
            order_sum: (item.initPrice - item.discountPrice) / 100,
            order_page:
              orderPage[location.pathname] !== undefined
                ? orderPage[location.pathname]
                : '其他',
          };
          console.log(params);
          try {
            // 会员支付弹窗-关闭-点击 埋点
            window.gio('track', 'order_new', params);
          } catch (e) {
            console.error('gio error \n' + e);
          }
        }
      },
      //获取充值套餐信息
      getBalanceTariffPackages() {
        this.$http
          .post('/pay/getBalanceTariffPackages')
          .then((res) => {
            console.log('获取充值套餐信息');
            const customPackages = [];
            const amountList = [];
            res.body.tariffPackages.forEach(item => {
              if (item.initPrice) {
                amountList.push(item)
              } else {
                customPackages.push(item)
              }
            });
            this.amountList = amountList;
            this.customPackage = customPackages[0];
            this.showCustom = !!customPackages.length;
            if (amountList.length && !customPackages.length) {
              this.tabs.splice(1, 1)
            }
            if (!amountList.length && customPackages.length) {
              this.tabs.splice(0, 1)
            }
              this.selectPrice(this.amountList[3], true);
              this.useQrcodePay();
            })
          .catch((error) => {
            console.log(error);
          });
      },
      // 账户充值选择支付方式
      selectRecharge(index) {
        this.selectedRecharge = index;
        this.checkBeforePay();
        if (index === 1) {
          this.useQrcodePay();
        }
      },
      checkBeforePay() {
        this.canPay = this.rechargePrice > 0 && this.selectedRecharge > -1;
      },
      // 虚拟货币充值
      payCharge() {
        let self = this;
        if (
          this.rechargePrice - this.haveCounped > 0 &&
          this.selectedRecharge > -1
        ) {
          if (this.canPay) {
            if (this.selectedRecharge === 0) {
              // 支付宝
              let loadingPage = null;
              if (
                navigator.userAgent.toLowerCase().indexOf('chuangkit') === -1
              ) {
                // 非客户端环境
                loadingPage = window.open('');
              }
              const commonParam = {tpi: this.choseCouponId, pt: 1, url: 'payback'}; 
              const params = this.customPay ? {flexiblePayNum: this.rechargePrice * 100,...commonParam} : commonParam;
              this.$http
                .post('/pay/getVMPayUrl', params)
                .then((res) => {
                  if (res.body.success) {
                    // 申请支付 URL 成功
                    let billSerialNo = res.body.bill_serial_no;
                    if (loadingPage) {
                      // 非客户端环境
                      loadingPage.location.href = res.body.payUrl; // 重定向为支付宝支付链接
                    } else {
                      window.open(res.body.payUrl);
                    }
                    // 查询订单支付状态
                    self.getPayState(billSerialNo, function() {
                      self.rechargeClose();
                      self.$emit('promptShow', {
                        promptText: '充值成功',
                        promptKind: 'success',
                      });
                      // 刷新账户余额
                      self.$emit('getUserInfo');
                    });
                  } else {
                    // 申请支付 URL 失败
                    if (loadingPage) {
                      loadingPage.close(); // 关闭先前打开的空白页面
                    }
                    this.$emit('promptShow', {
                      promptText: '获取支付宝支付URL出错',
                      promptKind: 'error',
                    });
                  }
                  if (res.body.LoginTimeOut) {
                    this.$emit('promptShow', {
                      promptText: '登录超时',
                      promptKind: 'error',
                    });
                    this.$emit('setLogregShow', 1);
                  }
                });
            } else if (this.selectedRecharge === 1) {
              // 微信
              this.useQrcodePay();
            } else if (this.selectedRecharge === 2) {
              // 对公转账
              this.transferModalShow = true;
              this.$emit('closeShow');
            }
          }
        }
      },
      //  微信
      useQrcodePay() {
        let self = this;
        const commonParam = {tpi: this.choseCouponId, pt: 2};
        const params = this.customPay ? {flexiblePayNum: this.rechargePrice * 100, ...commonParam} : commonParam;
        this.$http
          .post('/pay/getVMPayUrl', params)
          .then((res) => {
            if (res.body.success) {
              // 申请支付 URL 成功
              let url = res.body.payUrl,
                billSerialNo = res.body.bill_serial_no;
              // 生成二维码
              this.qrcodeurl = url;
              console.log('生成二维码');
              this.getPayState(billSerialNo, function() {
                self.rechargeClose();
                self.$emit('promptShow', {
                  promptText: '充值成功',
                  promptKind: 'success',
                });
                // 刷新用户余额
                self.$emit('getUserInfo');
              });
            } else {
              // 申请支付 URL 失败
              this.$emit('promptShow', {
                promptText: '获取支付URL出错',
                promptKind: 'error',
              });
            }
            if (res.body.LoginTimeOut) {
              this.$emit('promptShow', {
                promptText: '登录超时',
                promptKind: 'error',
              });
              this.$emit('setLogregShow', 1);
            }
          });
      },
      getPayState(val, func) {
        // 查询订单支付状态
        let timer = () => {
          if (this.selectedRecharge === 2) return; // 如果选择对公转账，则不再查询
          this.$http.post('/pay/getPayState', { bill_no: val }).then((res) => {
            let code = res.body.code;
            let flag = true; // 设置检测标志
            switch (code) {
              case 1:
                ////支付成功
                func();
                flag = false; // 改变检测标志
                break;
            }
            clearTimeout(this.payStateTimer);
            if (flag) {
              this.payStateTimer = setTimeout(timer, 3000);
            }
          });
        };
        setTimeout(timer(), 2000);
      },
      rechargeClose() {
        this.rechargeModalShow = false;
        this.$emit('closeRecharge', false);
      },
      transferModalClose() {
        this.transferModalShow = false;
        this.$emit('closeRecharge', false);
      },
      closeRechargeShow(e) {
        if (!e) {
          this.rechargeClose();
          clearTimeout(this.payStateTimer);
        }
      },
    },
  };
</script>
<style lang="less">
  .need-to-know {
    .lt-poptip-popper {
      top: 100px !important;
      left: 208px !important;
    }
    .lt-poptip-inner {
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 30px 80px -20px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
  }
</style>

<style lang="less" scoped>
  @import 'rechargePop.less';
</style>
