export const jobs = [{
  'JobName': '新媒体运营',
  'jobId': 1,
  'icon': '24px 16px',
  'sceneList': [{
    'sceneName': '公众号封面首图',
    'id': 216,
    'imgUrl': '//imgpub.chuangkit.com/designKind/216',
    'href': '/odyssey/createDesign?k=216'
  }, {
    'sceneName': '手机海报',
    'id': 166,
    'imgUrl': '//imgpub.chuangkit.com/designKind/166',
    'href': '/odyssey/createDesign?k=166'
  }, {
    'sceneName': '营销长图',
    'id': 35,
    'imgUrl': '//imgpub.chuangkit.com/designKind/35',
    'href': '/odyssey/createDesign?k=35'
  }, {
    'sceneName': '公众号封面小图',
    'id': 40,
    'imgUrl': '//imgpub.chuangkit.com/designKind/40',
    'href': '/odyssey/createDesign?k=40'
  }, {
    'sceneName': '方形二维码',
    'id': 41,
    'imgUrl': '//imgpub.chuangkit.com/designKind/41',
    'href': '/odyssey/createDesign?k=41'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': '视频封面',
    'id': 276,
    'imgUrl': '//imgpub.chuangkit.com/designKind/276',
    'href': '/odyssey/createDesign?k=276'
  }, {
    'sceneName': '小程序封面',
    'id': 278,
    'imgUrl': '//imgpub.chuangkit.com/designKind/278',
    'href': '/odyssey/createDesign?k=278'
  }, {
    'sceneName': '手机截屏',
    'id': 25,
    'imgUrl': '//imgpub.chuangkit.com/designKind/25',
    'href': '/odyssey/createDesign?k=25'
  }, {
    'sceneName': '简历',
    'id': 38,
    'imgUrl': '//imgpub.chuangkit.com/designKind/38',
    'href': '/odyssey/createDesign?k=38'
  }]
}, {
  'JobName': '电商运营',
  'jobId': 2,
  'icon': '-142px 16px',
  'sceneList': [{
    'sceneName': '手机淘宝首页海报',
    'id': 301,
    'imgUrl': '//imgpub.chuangkit.com/designKind/301',
    'href': '/odyssey/createDesign?k=301'
  }, {
    'sceneName': '手机淘宝banner',
    'id': 194,
    'imgUrl': '//imgpub.chuangkit.com/designKind/194',
    'href': '/odyssey/createDesign?k=194'
  }, {
    'sceneName': '主图直通车',
    'id': 29,
    'imgUrl': '//imgpub.chuangkit.com/designKind/29',
    'href': '/odyssey/createDesign?k=29'
  }, {
    'sceneName': '营销长图',
    'id': 35,
    'imgUrl': '//imgpub.chuangkit.com/designKind/35',
    'href': '/odyssey/createDesign?k=35'
  }, {
    'sceneName': '宝贝详情页',
    'id': 157,
    'imgUrl': '//imgpub.chuangkit.com/designKind/157',
    'href': '/odyssey/createDesign?k=157'
  }, {
    'sceneName': '手机海报',
    'id': 166,
    'imgUrl': '//imgpub.chuangkit.com/designKind/166',
    'href': '/odyssey/createDesign?k=166'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }]
}, {
  'JobName': '微商运营',
  'jobId': 12,
  'icon': '24px -320px',
  'sceneList': [{
    'sceneName': '手机海报',
    'id': 166,
    'imgUrl': '//imgpub.chuangkit.com/designKind/166',
    'href': '/odyssey/createDesign?k=166'
  }, {
    'sceneName': '问候语录',
    'id': 209,
    'imgUrl': '//imgpub.chuangkit.com/designKind/209',
    'href': '/odyssey/createDesign?k=209'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': '营销长图',
    'id': 35,
    'imgUrl': '//imgpub.chuangkit.com/designKind/35',
    'href': '/odyssey/createDesign?k=35'
  }, {
    'sceneName': '印刷海报',
    'id': 13,
    'imgUrl': '//imgpub.chuangkit.com/designKind/13',
    'href': '/odyssey/createDesign?k=13'
  }, {
    'sceneName': '社交图片',
    'id': 18,
    'imgUrl': '//imgpub.chuangkit.com/designKind/18',
    'href': '/odyssey/createDesign?k=18'
  }, {
    'sceneName': '搞笑表情',
    'id': 169,
    'imgUrl': '//imgpub.chuangkit.com/designKind/169',
    'href': '/odyssey/createDesign?k=169'
  }]
}, {
  'JobName': '市场/品牌',
  'jobId': 3,
  'icon': '-312px 16px',
  'sceneList': [{
    'sceneName': '印刷海报',
    'id': 13,
    'imgUrl': '//imgpub.chuangkit.com/designKind/13',
    'href': '/odyssey/createDesign?k=13'
  }, {
    'sceneName': '名片',
    'id': 12,
    'imgUrl': '//imgpub.chuangkit.com/designKind/12',
    'href': '/odyssey/createDesign?k=12'
  }, {
    'sceneName': 'DM宣传单',
    'id': 148,
    'imgUrl': '//imgpub.chuangkit.com/designKind/148',
    'href': '/odyssey/createDesign?k=148'
  }, {
    'sceneName': 'PPT 16:9',
    'id': 1,
    'imgUrl': '//imgpub.chuangkit.com/designKind/1',
    'href': '/odyssey/createDesign?k=1'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': '邀请函',
    'id': 3,
    'imgUrl': '//imgpub.chuangkit.com/designKind/3',
    'href': '/odyssey/createDesign?k=3'
  }, {
    'sceneName': '手机海报',
    'id': 166,
    'imgUrl': '//imgpub.chuangkit.com/designKind/166',
    'href': '/odyssey/createDesign?k=166'
  }, {
    'sceneName': '三折页',
    'id': 143,
    'imgUrl': '//imgpub.chuangkit.com/designKind/143',
    'href': '/odyssey/createDesign?k=143'
  }, {
    'sceneName': '折页',
    'id': 142,
    'imgUrl': '//imgpub.chuangkit.com/designKind/142',
    'href': '/odyssey/createDesign?k=142'
  }, {
    'sceneName': '2m易拉宝',
    'id': 129,
    'imgUrl': '//imgpub.chuangkit.com/designKind/129',
    'href': '/odyssey/createDesign?k=129'
  }, {
    'sceneName': '1.8m展架',
    'id': 135,
    'imgUrl': '//imgpub.chuangkit.com/designKind/135',
    'href': '/odyssey/createDesign?k=135'
  }, {
    'sceneName': '展板',
    'id': 206,
    'imgUrl': '//imgpub.chuangkit.com/designKind/206',
    'href': '/odyssey/createDesign?k=206'
  }, {
    'sceneName': '营销长图',
    'id': 35,
    'imgUrl': '//imgpub.chuangkit.com/designKind/35',
    'href': '/odyssey/createDesign?k=35'
  }, {
    'sceneName': '授权书（横版）',
    'id': 188,
    'imgUrl': '//imgpub.chuangkit.com/designKind/188',
    'href': '/odyssey/createDesign?k=188'
  }, {
    'sceneName': '日报简讯',
    'id': 23,
    'imgUrl': '//imgpub.chuangkit.com/designKind/23',
    'href': '/odyssey/createDesign?k=23'
  }, {
    'sceneName': '简历',
    'id': 38,
    'imgUrl': '//imgpub.chuangkit.com/designKind/38',
    'href': '/odyssey/createDesign?k=38'
  }]
}, {
  'JobName': '学生',
  'jobId': 4,
  'icon': '-480px 16px',
  'sceneList': [{
    'sceneName': '简历',
    'id': 38,
    'imgUrl': '//imgpub.chuangkit.com/designKind/38',
    'href': '/odyssey/createDesign?k=38'
  }, {
    'sceneName': '印刷海报',
    'id': 13,
    'imgUrl': '//imgpub.chuangkit.com/designKind/13',
    'href': '/odyssey/createDesign?k=13'
  }, {
    'sceneName': 'PPT 16:9',
    'id': 1,
    'imgUrl': '//imgpub.chuangkit.com/designKind/1',
    'href': '/odyssey/createDesign?k=1'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': '邀请函',
    'id': 3,
    'imgUrl': '//imgpub.chuangkit.com/designKind/3',
    'href': '/odyssey/createDesign?k=3'
  }, {
    'sceneName': 'DM宣传单',
    'id': 148,
    'imgUrl': '//imgpub.chuangkit.com/designKind/148',
    'href': '/odyssey/createDesign?k=148'
  }, {
    'sceneName': '书签',
    'id': 182,
    'imgUrl': '//imgpub.chuangkit.com/designKind/182',
    'href': '/odyssey/createDesign?k=182'
  }, {
    'sceneName': '相册书(A4竖版)',
    'id': 178,
    'imgUrl': '//imgpub.chuangkit.com/designKind/178',
    'href': '/odyssey/createDesign?k=178'
  }, {
    'sceneName': '聘书奖状（横版）',
    'id': 177,
    'imgUrl': '//imgpub.chuangkit.com/designKind/177',
    'href': '/odyssey/createDesign?k=177'
  }, {
    'sceneName': '聘书奖状（竖版）',
    'id': 176,
    'imgUrl': '//imgpub.chuangkit.com/designKind/176',
    'href': '/odyssey/createDesign?k=176'
  }]
}, {
  'JobName': '教师',
  'jobId': 5,
  'icon': '24px -96px',
  'sceneList': [{
    'sceneName': '印刷海报',
    'id': 13,
    'imgUrl': '//imgpub.chuangkit.com/designKind/13',
    'href': '/odyssey/createDesign?k=13'
  }, {
    'sceneName': 'PPT 16:9',
    'id': 1,
    'imgUrl': '//imgpub.chuangkit.com/designKind/1',
    'href': '/odyssey/createDesign?k=1'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': '邀请函',
    'id': 3,
    'imgUrl': '//imgpub.chuangkit.com/designKind/3',
    'href': '/odyssey/createDesign?k=3'
  }, {
    'sceneName': '聘书奖状（横版）',
    'id': 177,
    'imgUrl': '//imgpub.chuangkit.com/designKind/177',
    'href': '/odyssey/createDesign?k=177'
  }, {
    'sceneName': '聘书奖状（竖版）',
    'id': 176,
    'imgUrl': '//imgpub.chuangkit.com/designKind/176',
    'href': '/odyssey/createDesign?k=176'
  }, {
    'sceneName': '日报简讯',
    'id': 23,
    'imgUrl': '//imgpub.chuangkit.com/designKind/23',
    'href': '/odyssey/createDesign?k=23'
  }, {
    'sceneName': 'A4',
    'id': 24,
    'imgUrl': '//imgpub.chuangkit.com/designKind/24',
    'href': '/odyssey/createDesign?k=24'
  }]
}, {
  'JobName': '人事/行政',
  'jobId': 6,
  'icon': '-144px -96px',
  'sceneList': [{
    'sceneName': '印刷海报',
    'id': 13,
    'imgUrl': '//imgpub.chuangkit.com/designKind/13',
    'href': '/odyssey/createDesign?k=13'
  }, {
    'sceneName': 'PPT 16:9',
    'id': 1,
    'imgUrl': '//imgpub.chuangkit.com/designKind/1',
    'href': '/odyssey/createDesign?k=1'
  }, {
    'sceneName': '名片',
    'id': 12,
    'imgUrl': '//imgpub.chuangkit.com/designKind/12',
    'href': '/odyssey/createDesign?k=12'
  }, {
    'sceneName': '日报简讯',
    'id': 23,
    'imgUrl': '//imgpub.chuangkit.com/designKind/23',
    'href': '/odyssey/createDesign?k=23'
  }, {
    'sceneName': '手机海报',
    'id': 166,
    'imgUrl': '//imgpub.chuangkit.com/designKind/166',
    'href': '/odyssey/createDesign?k=166'
  }, {
    'sceneName': '竖版工作证',
    'id': 152,
    'imgUrl': '//imgpub.chuangkit.com/designKind/152',
    'href': '/odyssey/createDesign?k=152'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': '邀请函',
    'id': 3,
    'imgUrl': '//imgpub.chuangkit.com/designKind/3',
    'href': '/odyssey/createDesign?k=3'
  }]
}, {
  'JobName': '销售',
  'jobId': 7,
  'icon': '-312px -96px',
  'sceneList': [{
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': '手机海报',
    'id': 166,
    'imgUrl': '//imgpub.chuangkit.com/designKind/166',
    'href': '/odyssey/createDesign?k=166'
  }, {
    'sceneName': '名片',
    'id': 12,
    'imgUrl': '//imgpub.chuangkit.com/designKind/12',
    'href': '/odyssey/createDesign?k=12'
  }, {
    'sceneName': 'PPT 16:9',
    'id': 1,
    'imgUrl': '//imgpub.chuangkit.com/designKind/1',
    'href': '/odyssey/createDesign?k=1'
  }, {
    'sceneName': 'DM宣传单',
    'id': 148,
    'imgUrl': '//imgpub.chuangkit.com/designKind/148',
    'href': '/odyssey/createDesign?k=148'
  }, {
    'sceneName': '印刷海报',
    'id': 13,
    'imgUrl': '//imgpub.chuangkit.com/designKind/13',
    'href': '/odyssey/createDesign?k=13'
  }, {
    'sceneName': '邀请函',
    'id': 3,
    'imgUrl': '//imgpub.chuangkit.com/designKind/3',
    'href': '/odyssey/createDesign?k=3'
  }, {
    'sceneName': '营销长图',
    'id': 35,
    'imgUrl': '//imgpub.chuangkit.com/designKind/35',
    'href': '/odyssey/createDesign?k=35'
  }, {
    'sceneName': '1.8m展架',
    'id': 135,
    'imgUrl': '//imgpub.chuangkit.com/designKind/135',
    'href': '/odyssey/createDesign?k=135'
  }, {
    'sceneName': '三折页',
    'id': 143,
    'imgUrl': '//imgpub.chuangkit.com/designKind/143',
    'href': '/odyssey/createDesign?k=143'
  }, {
    'sceneName': '折页',
    'id': 142,
    'imgUrl': '//imgpub.chuangkit.com/designKind/142',
    'href': '/odyssey/createDesign?k=142'
  }]
}, {
  'JobName': '记者/编辑',
  'jobId': 8,
  'icon': '-480px -96px',
  'sceneList': [{
    'sceneName': '公众号封面首图',
    'id': 216,
    'imgUrl': '//imgpub.chuangkit.com/designKind/216',
    'href': '/odyssey/createDesign?k=216'
  }, {
    'sceneName': '公众号封面小图',
    'id': 40,
    'imgUrl': '//imgpub.chuangkit.com/designKind/40',
    'href': '/odyssey/createDesign?k=40'
  }, {
    'sceneName': '微博焦点图',
    'id': 43,
    'imgUrl': '//imgpub.chuangkit.com/designKind/43',
    'href': '/odyssey/createDesign?k=43'
  }, {
    'sceneName': '手机海报',
    'id': 166,
    'imgUrl': '//imgpub.chuangkit.com/designKind/166',
    'href': '/odyssey/createDesign?k=166'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': 'PPT 16:9',
    'id': 1,
    'imgUrl': '//imgpub.chuangkit.com/designKind/1',
    'href': '/odyssey/createDesign?k=1'
  }, {
    'sceneName': '名片',
    'id': 12,
    'imgUrl': '//imgpub.chuangkit.com/designKind/12',
    'href': '/odyssey/createDesign?k=12'
  }, {
    'sceneName': '方形二维码',
    'id': 41,
    'imgUrl': '//imgpub.chuangkit.com/designKind/41',
    'href': '/odyssey/createDesign?k=41'
  }]
}, {
  'JobName': '设计师',
  'jobId': 9,
  'icon': '24px -208px',
  'sceneList': [{
    'sceneName': '印刷海报',
    'id': 13,
    'imgUrl': '//imgpub.chuangkit.com/designKind/13',
    'href': '/odyssey/createDesign?k=13'
  }, {
    'sceneName': '邀请函',
    'id': 3,
    'imgUrl': '//imgpub.chuangkit.com/designKind/3',
    'href': '/odyssey/createDesign?k=3'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': '手机海报',
    'id': 166,
    'imgUrl': '//imgpub.chuangkit.com/designKind/166',
    'href': '/odyssey/createDesign?k=166'
  }, {
    'sceneName': 'DM宣传单',
    'id': 148,
    'imgUrl': '//imgpub.chuangkit.com/designKind/148',
    'href': '/odyssey/createDesign?k=148'
  }, {
    'sceneName': '展板',
    'id': 206,
    'imgUrl': '//imgpub.chuangkit.com/designKind/206',
    'href': '/odyssey/createDesign?k=206'
  }, {
    'sceneName': '三折页',
    'id': 143,
    'imgUrl': '//imgpub.chuangkit.com/designKind/143',
    'href': '/odyssey/createDesign?k=143'
  }, {
    'sceneName': '手提袋',
    'id': 183,
    'imgUrl': '//imgpub.chuangkit.com/designKind/183',
    'href': '/odyssey/createDesign?k=183'
  }, {
    'sceneName': '公众号封面首图',
    'id': 216,
    'imgUrl': '//imgpub.chuangkit.com/designKind/216',
    'href': '/odyssey/createDesign?k=216'
  }, {
    'sceneName': '方形二维码',
    'id': 41,
    'imgUrl': '//imgpub.chuangkit.com/designKind/41',
    'href': '/odyssey/createDesign?k=41'
  }]
}, {
  'JobName': '个体户',
  'jobId': 10,
  'icon': '-144px -208px',
  'sceneList': [{
    'sceneName': '印刷海报',
    'id': 13,
    'imgUrl': '//imgpub.chuangkit.com/designKind/13',
    'href': '/odyssey/createDesign?k=13'
  }, {
    'sceneName': '手机海报',
    'id': 166,
    'imgUrl': '//imgpub.chuangkit.com/designKind/166',
    'href': '/odyssey/createDesign?k=166'
  }, {
    'sceneName': '营销长图',
    'id': 35,
    'imgUrl': '//imgpub.chuangkit.com/designKind/35',
    'href': '/odyssey/createDesign?k=35'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': 'DM宣传单',
    'id': 148,
    'imgUrl': '//imgpub.chuangkit.com/designKind/148',
    'href': '/odyssey/createDesign?k=148'
  }, {
    'sceneName': '食品酒水单',
    'id': 27,
    'imgUrl': '//imgpub.chuangkit.com/designKind/27',
    'href': '/odyssey/createDesign?k=27'
  }, {
    'sceneName': '优惠券',
    'id': 241,
    'imgUrl': '//imgpub.chuangkit.com/designKind/241',
    'href': '/odyssey/createDesign?k=241'
  }, {
    'sceneName': '公众号封面首图',
    'id': 216,
    'imgUrl': '//imgpub.chuangkit.com/designKind/216',
    'href': '/odyssey/createDesign?k=216'
  }, {
    'sceneName': '方形二维码',
    'id': 41,
    'imgUrl': '//imgpub.chuangkit.com/designKind/41',
    'href': '/odyssey/createDesign?k=41'
  }]
}, {
  'JobName': '其他',
  'jobId': 11,
  'icon': '-480px -208px',
  'sceneList': [{
    'sceneName': '手机海报',
    'id': 166,
    'imgUrl': '//imgpub.chuangkit.com/designKind/166',
    'href': '/odyssey/createDesign?k=166'
  }, {
    'sceneName': '印刷海报',
    'id': 13,
    'imgUrl': '//imgpub.chuangkit.com/designKind/13',
    'href': '/odyssey/createDesign?k=13'
  }, {
    'sceneName': '公众号封面首图',
    'id': 216,
    'imgUrl': '//imgpub.chuangkit.com/designKind/216',
    'href': '/odyssey/createDesign?k=216'
  }, {
    'sceneName': '视频封面',
    'id': 276,
    'imgUrl': '//imgpub.chuangkit.com/designKind/276',
    'href': '/odyssey/createDesign?k=276'
  }, {
    'sceneName': '营销长图',
    'id': 35,
    'imgUrl': '//imgpub.chuangkit.com/designKind/35',
    'href': '/odyssey/createDesign?k=35'
  }, {
    'sceneName': '朋友圈邀请函',
    'id': 170,
    'imgUrl': '//imgpub.chuangkit.com/designKind/170',
    'href': '/odyssey/createDesign?k=170'
  }, {
    'sceneName': 'DM宣传单',
    'id': 148,
    'imgUrl': '//imgpub.chuangkit.com/designKind/148',
    'href': '/odyssey/createDesign?k=148'
  }, {
    'sceneName': '三折页',
    'id': 143,
    'imgUrl': '//imgpub.chuangkit.com/designKind/143',
    'href': '/odyssey/createDesign?k=143'
  }, {
    'sceneName': '方形二维码',
    'id': 41,
    'imgUrl': '//imgpub.chuangkit.com/designKind/41',
    'href': '/odyssey/createDesign?k=41'
  }]
}]

export const industries = [
  {
    industryName: 'IT/互联网',
    jobIds: [1,2,3,6,7,8,9,11],
    icon: '30px 16px'
  },
  {
    industryName: '电商/微商',
    jobIds: [1,2,12,3,6,7,9,11],
    icon: '-136px 16px'
  },
  {
    industryName: '教育培训',
    jobIds: [1,3,5,6,7,8,9,11],
    icon: '-304px 16px'
  },
  {
    industryName: '餐饮业',
    jobIds: [1,2,3,6,7,9,10,11],
    icon: '-472px 16px'
  },
  {
    industryName: '广告/公关',
    jobIds: [1,3,6,7,8,9,11],
    icon: '-642px 16px'
  },
  {
    industryName: '酒店旅游',
    jobIds: [1,2,3,6,7,8,9,10,11],
    icon: '-808px 16px'
  },
  {
    industryName: '美容健身',
    jobIds: [1,2,3,6,7,9,10,11],
    icon: '30px -96px'
  },{
    industryName: '金融理财',
    jobIds: [1,2,3,6,7,9,11],
    icon: '-136px -96px'
  },
  {
    industryName: '文化娱乐',
    jobIds: [1,2,3,6,7,8,9,11],
    icon: '-304px -96px'
  },
  {
    industryName: '地产家居',
    jobIds: [1,2,3,6,7,9,10,11],
    icon: '-472px -96px'
  },
  {
    industryName: '百货零售',
    jobIds: [1,2,3,6,7,9,10,11],
    icon: '-642px -96px'
  },
  {
    industryName: '校园生活',
    jobIds: [5,4,8,9,11],
    icon: '-808px -96px'
  },
  {
    industryName: '生活服务',
    jobIds: [1,3,6,7,9,10,11],
    icon: '30px -208px'
  },
  {
    industryName: '政府公益',
    jobIds: [1,3,6,9,11],
    icon: '-136px -208px'
  },
  {
    industryName: '医疗医药',
    jobIds: [1,2,3,6,7,8,9,5,4,10,11],
    icon: '-304px -208px'
  },
  {
    industryName: '其他',
    jobIds: [1,2,3,6,7,8,9,5,4,10,11],
    icon: '-472px -208px'
  }
]
