<template>
  <LtModal 
    v-model="modalShow"
    :mask-closable="false"
    class="wx-work-pop"
    @on-visible-change="getModalVisible"
  >
    <div class="pop-wrap">
      <p class="pop-title">{{ popContent.title }}</p>
      <p>{{ popContent.content }}</p>
      <img src="./img/workWeixin_expire_qrcode.png" class="qr-code" />
      <p><b>客服电话</b></p>
      <p class="phone-num">400-871-8211</p>
    </div>
  </LtModal>
</template>

<script>
export default {
  name: 'wxWorkPop',
  props: {
    wxWorkPopShow: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'expire'
    }
  },
  data() {
    return {
      modalShow: false
    }
  },
  computed: {
    popContent() {
      const data = {
        expire: {
          title: '企业团队已到期',
          content: '添加专属顾问为您做续约升级处理'
        },
        limit: {
          title: '团队使用人数超过购买人数',
          content: '请联系企业微信管理员修改应用可见范围'
        }
      }
      return data[this.type]
    } 
  },
  watch: {
    wxWorkPopShow(v) {
      this.modalShow = v
    }
  },
  mounted() {
    this.modalShow = this.wxWorkPopShow
  },
  methods: {
    getModalVisible(state) {
     if(!state) {
       this.$emit('close')
     }
    }
  }
}
</script>

<style lang="less">
.wx-work-pop {
  .lt-modal-mask, .lt-modal-wrap {
    top: 71px;
  }
}
</style>
<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pop-wrap {
  .flex;
  flex-direction: column;
  padding: 40px 0 32px;
  width: 480px;
  p {
    color: rgba(0,0,0,0.8);
    line-height: 14px;
  }
  .pop-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .qr-code {
    width: 160px;
    height: 160px;
    margin: 20px 0;
  }
  .phone-num {
    font-size: 12px;
    margin-top: 10px;
    line-height: 12px;
    color: rgba(0,0,0,0.6);
  }
}
</style>