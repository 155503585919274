/**
 * Created by hangxg on 2018/3/1.
 */


import * as types from '../mutation-types'

const state = {
  designNames: [],
  addedToShoppingCart: Date.now()
}

const getters = {
  getDesignNames: state => state.designNames,
  getAddedToShoppingCart: state => state.addedToShoppingCart
}

const mutations = {
  [types.SET_DESIGN_FILE_NAME](state, v) {
    state.designNames = v
  },
  [types.SET_ADDED_TO_SHOPPING_CART](state, v) {
    state.addedToShoppingCart = v
  }
}

const actions = {
  setDesignFileNames({ commit }, v) {
    commit(types.SET_DESIGN_FILE_NAME, v)
  },
  setAddedToShoppingCart({ commit }, v) {
    commit(types.SET_ADDED_TO_SHOPPING_CART, v)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
