<template>
  <div class="header-pop-vip">
    
    <div class="left-wrap" @click.stop='goToolVip'>
      <p class="title">设计工具会员</p>
      <ul>
        <li v-for="(item, index) in leftList" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="vertical-line"></div>

    <div class="right-wrap" @click.stop='goServiceVip'>
      <p class="title">设计服务会员</p>
      <ul>
        <li v-for="(item, index) in rightList" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'hoverVip',
  data() {
    return {
      leftList:['海量正版内容','高清无水印下载','团队协作共享'],
      rightList:['无限量定制需求','专属创意团队','高效流转线上交付']
    }
  },
  methods:{
    goToolVip(){
      window.location.href = '/price/vip?fmodule=topbar_vip&fpage=topbar'
    },
    goServiceVip(){
      window.location.href = '/cloud-design/design/home?from=ckt'
    }
  }
}
</script>

<style lang="less" scoped>
.header-pop-vip {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between ;
  width: 332px;
  height: 129px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 2;
  padding: 15px 26px;
  .vertical-line{
    margin-top: 22px;
    width: 0px;
    height: 73px;
    border: 1px solid #0773FC;
  }
  .title{
    position: relative;
    color: #0773FC;
    font-size: 16px;
    padding-left: 20px;
    padding-bottom: 3px;
  }
  .right-wrap{
    opacity: 0.5;
    .title{
      &:before {
        content: '';
        position: absolute;
        left: -10px;
        top: -6px;
        width: 30px;
        height: 30px;
        background-image: url('../img/serviceIcon.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
    &:hover{
      opacity: 1;
    }
  }
  .left-wrap{
    opacity: 0.5;
    .title{
      &:before {
        content: '';
        left: -10px;
        top: -6px;
        position: absolute;
        width: 30px;
        height: 30px;
        background-image: url('../img/toolIcon.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
    &:hover{
      opacity: 1;
    }
  }
  li {
    position: relative;
    margin-top: 10px;
    height: 16px;
    line-height: 16px;
    margin-left: 13px;
    cursor: pointer;
    font-size: 14px;
    color: #1B2337;
    &:before{
        content: '';
        left: -14px;
        top: 4px;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 4px 4px;
        background: #0773FC;
    }
  }
}
</style>
