<template>
  <div class="manual-create-team">
    <LtModal
      class="create-team-modal"
      v-model="showCreateTeamForm"
      @on-cancel="closeModalBtn"
     >
     <CreateTeamForm @closeModal="closeModalBtn" @createTeam="doCreateTeam" :userInfo="userInfo" :isLogin="isLogin" ref="createTeamForm"/>
    </LtModal>
    <LtModal
      class="invite-member-modal"
      v-model="popInvite"
      @on-cancel="closeModalPop"
     >
      <InvitePop
        :oCtrl="false"
        :teamId="teamId"
        :teamInfo="teamInfo"
        @closeModal="closeModalPop"
        @getTeamInfo="$emit('getTeamInfo')"
        @rights-buy-pop="(e)=> { $emit('rights-buy-pop', e) }"
      />
    </LtModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InvitePop from "./components/invitePop.vue"
import CreateTeamForm from "./components/createTeamForm.vue"
export default {
  name: 'CreateTeamModal',
  components: {
    CreateTeamForm,
    InvitePop
  },
  data () {
    return {
      isBindPhone: false,
      showCreateTeamForm: false,
      popInvite: false,
      isLoading: true,
      createTeamState: false,//创建团队成功
    }
  },
  props: {
    teamInfo: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      logregShow: 'getLogregShow',
      isLogin: 'getIsLogin',
      getIsVip: 'getIsVip',
      teams: 'getTeams',
      teamId: 'getTeamId',
    })
  },
  methods: {
    ...mapActions([
      'setLogregShow',
      'setMemberRedDotStatus',
      'setIsCreateFreeTeam'
    ]),
    createTeam () {
      const { userInfo: { phone } = {}, isLogin } = this
      if (!isLogin) return this.setLogregShow(1)
      // this.isBindPhone = !!phone
      this.showCreateTeamForm = true
      this.createTeamState =false
    },
    async doCreateTeam (companyName) {
      // 修改团队名称
      const isSucessUpdateTeam = await this.updateTeamBaseInfo(companyName)
      if (!isSucessUpdateTeam) return
      //进入下一步
      this.$refs.createTeamForm.goNextState()
      this.createTeamState = true
      // 必须先更新团队名称后再通知后端一键预约的状态
      this.oneClickReserve(companyName)
      // this.$message('创建团队成功')
      // try {
      //   window.gio('track', 'set_up_team', {
      //     create_type: this.isBindPhone ? '直接创建' : '需要绑定手机号'
      //   })
      // } catch (error) {
      //   console.error('gio error \n' + error)
      // }
      // 创建示例文件夹
      const isSucessCreateExampleFolder = await this.createExampleFolder()
      if (!isSucessCreateExampleFolder) return
      // this.popInvite = true
      // 切换到团队身份， 设置特殊标记，在/designtools/team 根据该标记判断是否由用户手动创建的团队
      // 如果是用户手动创建的团队，则需要打开邀请成员弹窗，并且设置初始的小红点/团队功能介绍框
      this.setMemberRedDotStatus(true)
      this.setIsCreateFreeTeam(true)
    },
    async updateTeamBaseInfo (companyName) {
      const { body: { code, msg } = {} } = await this.$http.post('/team/fuse/team/update', { gw: true,name: companyName }) || {}
      if (code === 200) {
        return true
      }else {
        this.$message(msg||"团队名称检测违规")
        return false
      }
    },
    async createExampleFolder () {
      const { teamId } = this
      try {
        const { body: { code, msg } = {} } = await this.$http.post('/team/team/createExampleFolder', { teamId, gw: true }) || {}
        if (code === 200) return true
        // this.$message(msg)
      } catch (error) {
        console.log(error)
      }
      return false
    },
    async oneClickReserve (companyName) {
      const { teamId } = this
      const isOrNotReserve = 0
      const { body: { code } = {} } = await this.$http.post('/team/team/oneClickReserve', { teamId, teamName: companyName, isOrNotReserve, gw: true }) || {}
      return code === 200
    },
    closeModalBtn(){
      if(this.createTeamState){
        this.showCreateTeamForm = false
        this.$emit('getCanCreateFreeTeam');
        this.getIsFuseNewTeam()
      }
    },
    //切换团队
    changeTeam(teamId){
      try {
        const data = {
          teamId: teamId,
          gw:true
        };
        this.$http.post('/team/fuse/team/update/activeTeam', data).then((res) => {
          // -1参数错误;-2未登录;0操作错误;1操作成功
          const code = res.body.code;
          if (code === 200) {
            this.teamInfo.teamId = teamId;
            this.$emit('getUserInfo');
            this.$emit('setTeamId', teamId);
            this.goInvite()
          } else if(code===10002) {
            this.setLogregShow(1)
          } else {
            this.$message(`团队切换失败，错误码：${code}`);
          }
        }) 
      } catch (error) {
        console.log(error)
      }
    },
    async getIsFuseNewTeam() {
      this.teams.length>0&&this.teams.map(v=>{
        if(v.teamRoleEnum=='Administrator'&&v.teamId!=this.teamId){
          this.changeTeam(v.teamId)
        }else if(v.teamRoleEnum=='Administrator'&&v.teamId==this.teamId){
          this.$emit('getUserInfo');
          this.goInvite()
        }
      })
    },
    async goInvite(){
      try {
        const  {
           body: { code, data }  
        } = await this.$http.post('/team/fuse/team/isFuseNewTeam', 
          { gw: true}
        )
        if (code === 200){
          if(data){
            this.popInvite = true
          }else{
            location.href='/designtools/team/member?invite=1'
          }
        } else {
          location.href='/designtools/team/member?invite=1'
        }
      } catch (error) {
        location.href='/designtools/team/member?invite=1'
      }
    },
    closeModalPop(){
      this.popInvite = false
      location.href='/dam-page'
    }
      
  },
}
</script>

<style lang='less' scoped>
.manual-create-team {
  ::v-deep {
    .lt-modal-content {
      padding: 24px 24px 20px 24px;
      .lt-modal-close {
        top: 24px;
        right: 24px;
        .lt-icon-window-close {
          font-size: 16px !important;
        }
      }
      .lt-modal-header {
        border-bottom: 0;
        .lt-modal-header-inner {
          font-size: 16px;
          color: #1B2337;
          line-height: 16px;
          font-weight: 600;
        }
      }
      .lt-modal-body {
        padding-top: 12px;
        .form-text {
          font-size: 12px;
          color: #505A71;
          line-height: 12px;
        }
        .invite-limit {
          padding: 0 15px!important;
        }
        .scroll-wrap {
          padding: 22px 16px 10px!important;
        }
      }
    }
  }
}
</style>