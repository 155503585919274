<template>
    <div class="first-page-box" :style="{ height: getScreenHeight + 'px',opacity: !abTestLoding? 1 : 0  }">
      <div class="first-page-middle-content" :style="{ height: getContentHeight  + 'px' }">
        <div class="first-page-title">{{ pageInfo.title }}</div>
        <div class="first-page-desc">{{ pageInfo.desc }}</div>
        <div class="first-page-btn" @click="handleLocation()">
          <span>{{ pageInfo.btnName }}</span>
        </div>
        <ul class="productList" :style="{ width: getProductWidth + 'px' }">
          <li class="productItem" v-for="item in pageInfo.products" :key="item.id" @click="handleLocation(item)">
            <img :src="item.imgUrl"/>
            <span>{{ item.name }}</span>
          </li>
           <!-- 复制一份内容实现循环效果 -->
          <li class="productItem" v-for="item in pageInfo.products" :key="'clone-' + item.id" @click="handleLocation(item)">
            <img :src="item.imgUrl"/>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      data() {
        return {
          pageInfo:{
            title: "你今天想设计什么？",
            desc: "简单好用的设计工具 创客贴帮助你轻松完成专业设计",
            btnName: "免费使用",
            products:[
              {
                name:'简历模板',
                id:9,
                imgUrl:require('../img/product9.png'),
                href:'https://www.chuangkit.com/sj-pi0-si38-or0-pt0-bt1-pn1.html',
              },
              {
                name:'公众号首图',
                id:5,
                imgUrl:require('../img/product5.png'),
                href:'https://www.chuangkit.com/si-pit-si216-or0-ptO-bt1-pn1.html'
              },
              {
                name:'小红书图文',
                id:3,
                imgUrl:require('../img/product3.png'),
                href:'https://www.chuangkit.com/solution/1'
              },
              {
                name:'海报',
                id:2,
                imgUrl:require('../img/product2.png'),
                href:'https://www.chuangkit.com/sj-pi20-si447-or0-ptO-bt1-pn1.html'
              },
              {
                name:'智能抠图',
                id:6,
                imgUrl:require('../img/product6.png'),
                href:'https://aiart.chuangkit.com/editor/koutu?fmodule=tools_list&fpage=brand_home'
              },
              {
                name:'AI LOGO',
                id:7,
                imgUrl:require('../img/product7.png'),
                href:'https://www.chuangkit.com/solution/55',
              },
              {
                name:'演示文档 PPT',
                id:1,
                imgUrl:require('../img/product1.png'),
                href:'https://www.chuangkit.com/sj-pi3-si1-or0-pt0-bt1-pn1.html'
              },
              {
                name:'更多50万+模板',
                id:15,
                imgUrl:require('../img/product15.png'),
                href:'https://www.chuangkit.com/designtools/designindex'
              },
              {
                name:'智能设计',
                id:4,
                imgUrl:require('../img/product4.png'),
                href:'https://www.chuangkit.com/ai-design/?source=brand_home'
              },
              {
                name:'智能消除',
                id:8,
                imgUrl:require('../img/product8.png'),
                href:'https://aiart.chuangkit.com/editor/eliminate?fmodule=tools_list&fpage=brand_home',
              },
              {
                name:'图片变清晰',
                id:10,
                imgUrl:require('../img/product10.png'),
                href:'https://aiart.chuangkit.com/editor/cleartool?fmodule=ads_list&fpage=landing_page',
              },
              {
                name:'图片批处理',
                id:12,
                imgUrl:require('../img/product12.png'),
                href:'https://aiart.chuangkit.com/editor/batchLanding?fmodule=tools_list&fpage=brand_home'
              },
              {
                name:'AI 商品图',
                id:13,
                imgUrl:require('../img/product13.png'),
                href:'https://aiart.chuangkit.com/editor?fmodule=tools_list&fpage=brand_home'
              },
              {
                name:'AI 去水印',
                id:14,
                imgUrl:require('../img/product14.png'),
                 href:'https://aiart.chuangkit.com/editor/removewatermark?fmodule=tools_list&fpage=brand_home'
              }
            ]
          },
          customNavList: ['平面海报', '电商', '印刷品', '手绘', '品牌设计'],
          currentCustomNav: 0,
          timer: null,
        };
      },
      props:{
        getScreenHeight:{
          default: 0,
        },
        getScreenWidth:{
          default: 0,
        },
        abTestLoding:{
          default: false,
        },
      },
      computed:{
        getProductWidth(){
         if (this.getScreenWidth<=1280){
          return 170 * this.pageInfo.products.length + this.pageInfo.products.length*18
         }
         if (this.getScreenWidth>1281 && this.getScreenWidth<=1440){
          return 184 * this.pageInfo.products.length + this.pageInfo.products.length*20
         }
         if (this.getScreenWidth>1441 && this.getScreenWidth<=1680){
          return 222 * this.pageInfo.products.length + this.pageInfo.products.length*20
         }
         return 230 * this.pageInfo.products.length + this.pageInfo.products.length*24
        },
        getContentHeight(){
          return this.getScreenHeight - 108 -124
        }
      },
      mounted() {
        // this.startScrolling();
      },
      methods: {
        handleLocation(item) {
          let location = !item ? '/designtools/designindex': item.href
          let moduleName = !item ? '首屏_开始设计': `产品功能_${item.name}`
          this.$emit('openLocation', location,moduleName);
        },
      },
    };
  </script>
  <style lang="less">
    .first-page-box {
      width: 100%;
      height: 868px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(../img/new-frist-bg.png);
      background-size: cover;
      background-repeat: no-repeat;
      .first-page-middle-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin:auto;
        max-height: 678px;
      
      }
      .first-page-title {
        color: var(---1, #1B2337);
        font-size: 48px;
        font-weight: 500;
        line-height: 70px;
      }
      .first-page-desc{
        color: var(---2, #505A71);
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.6px;
        margin-top: 20px;
      }
      .first-page-btn{
        display: flex;
        width: 270px;
        height: 86px;
        min-height: 86px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background: linear-gradient(to right, #0773FC, #3041FF);
        margin-top: 72px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        span{
          position: relative;
          z-index: 10;
          font-size: 26px;
          font-weight: 600;
          color: #FFF;
        }
      }
      .first-page-btn::before,
      .first-page-btn::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
      }

      .first-page-btn::before {
        content: "";
        background: var(---1, #1B2337);
        width: 120%;
        left: -10%;
        transform: skew(30deg);
        transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
      }

      .first-page-btn:hover::before {
        transform: translate3d(100%, 0, 0);
      }

      .first-page-btn:active {
        transform: scale(0.95);
      }
      .productList{
        display:flex;
        align-items: center;
        margin-top: auto;
        animation: scroll-left 72s linear infinite; /* 滚动动画 */
        transform: translateX(880px);
        li{
          display: flex;
          width: 230px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 24px;
          outline: 2px solid #FFF;
          margin-right: 24px;
          cursor: pointer;
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05);
          transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
          img{
            width: 230px;
            height: 196px;
            border-radius: 24px 24px 0 0;
          }
          span{
            display: flex;
            height: 62px;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            color: var(---1, #1B2337);
            background: var(---1, #FFF);
            border-radius: 0 0 20px 20px;
            font-size: 18px;
            font-weight: 600;
          }
          &:hover{
            transform: scale(1.1);
          }
        }
      }
      /* 动画暂停 */
      .productList:hover {
        animation-play-state: paused;
      }
      @keyframes scroll-left {
        from {
          transform: translateX(880px);
        }
        to {
          transform: translateX(-100%);
        }
      }
    }
    @media screen and (max-width: 1280px) {
      .first-page-box {
        .first-page-title {
          font-size: 32px;
        }
        .first-page-desc {
          font-size: 14px;
          margin-top: 14px;
        }
        .first-page-btn {
          width: 180px;
          height: 56px;
          min-height: 56px;
          margin-top: 40px;
          span{
            font-size: 18px;
          }
        }
        .productList{
          transform: translateX(64px);
          li{
            width: 170px;
            margin-right: 18px;
            img{
              width: 170px;
              height: 146px;
            }
            span{
              font-size: 13px;
            }
          }
        }
      }
    }
    @media screen and (min-width: 1281px) and (max-width: 1440px) {
      .first-page-box {
        .first-page-title {
          font-size: 36px;
        }
        .first-page-desc {
          font-size: 15px;
          margin-top: 16px;
        }
        .first-page-btn {
          width: 202px;
          height: 64px;
          min-height: 64px;
          margin-top: 40px;
          span{
            font-size: 20px;
          }
        }
        .productList{
          transform: translateX(72px);
          li{
            width: 184px;
            margin-right: 20px;
            img{
              width: 184px;
              height: 158px;
            }
            span{
              font-size: 14px;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1441px) and (max-width: 1680px) {
      .first-page-box {
        .first-page-title {
          font-size: 46px;
        }
        .first-page-desc {
          font-size: 20px;
          margin-top: 20px;
        }
        .first-page-btn {
          width: 260px;
          height: 84px;
          min-height: 84px;
          margin-top: 70px;
          span{
            font-size: 24px;
          }
        }
        .productList{
          transform: translateX(96px);
          li{
            width: 222px;
            img{
              width: 222px;
              height: 190px;
            }
            span{
              font-size: 18px;
            }
          }
        }
      }
    }
  </style>
  