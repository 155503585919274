<template>
  <div class="task-center">
    <div class="title">
      <img src="../img/success.svg" alt="" />
      获得<span class="color-tit">{{ vipDays }}天</span>试用会员
    </div>
    <div class="sub-tit">
      有效期至 {{vipExpireTime | vipExpireTimeFilter}}，免费无水印下载3张/天
    </div>
    <div class="award">
      <div class="award-tit">
        福利任务
      </div>
    </div>
    <ul class="task-list">
      <li class="task" v-for="item in taskList" :key="item.taskId">
        <div class="task-info">
          <img class="task-icon" :src="item.iconUrl" alt="" srcset="" />
          <div class="task-txt-info">
            <div class="txt">{{ item.name }}</div>
            <div class="day-num">
              试用会员<span class="color-tit">{{ '+' + item.vipDayNum }}</span>
              天
            </div>
          </div>
        </div>
        <div
          class="task-status"
          :class="item.status ? 'task-done' : 'task-undone'"
          @click="doTask(item)"
        >
          {{ item.status | statusFilter }}
        </div>
      </li>
    </ul>
    <div class="btns">
      <div class="keep-on" @click="goToDesign">开始设计，体验无水印下载</div>
    </div>
  </div>
</template>

<script>
import dayJs from 'dayjs';
  const statusMap = {
    0: '去完成',
    1: '领取奖励',
    2: '已完成',
  };
  export default {
    name: 'taskCenter',
    data() {
      return {
        taskList: [],
        vipExpireTime: null
      };
    },
    props: {
      vipDays: {
        type: Number || String,
        default: () => 1,
      },
    },
    filters: {
      statusFilter(val) {
        return statusMap[val];
      },
      vipExpireTimeFilter(val) {
          return dayJs(val).format('YYYY-MM-DD')
      }
    },
    computed: {
      totalDay() {
        return (
          this.taskList &&
          this.taskList.reduce((total, item) => {
            if (item.status === 2) {
              total++;
            }
            return total;
          }, 0)
        );
      },
    },
    methods: {
      async doTask(item) {
        const { status, taskId } = item;
        if (status === 1) {
          const flag = await this.getReward(taskId);
          if (flag) {
            this.getTaskList();
          } else {
            this.$message('领取失败')
          }
        }
        if (status === 0) {
          if (taskId === 1) {
            this.$emit('bindPhoneEmitHandler', taskId);
          }
          if (taskId === 2) {
            window.location.replace('/designtools/startdesign?activity=1');
          }
        }
      },
      goToDesign() {
        window.location.replace('/designtools/designindex');
      },
      getTaskList() {
        try {
          this.$http
            .get('/hatchery/activity/task/list', {
              params: {
                _dataClientType: 0,
                platform: 0,
                gw: true,
                alias: 'task_for_reward',
              },
              data: {
                gw: true,
                requestMethod: 'GET',
                template: true,
                nottrans: true,
              },
            })
            .then((res) => {
              let code, data;
                // 如果是chuangkit-saas 项目
                if (this.$route.path.includes('/designtools') || this.$route.path === '/') {
                  code = res.body && res.body.code;
                  data = res.body && res.body.data;
                } else {
                  code = res.data && res.data.body && res.data.body.code;
                  data = res.data && res.data.body && res.data.body.data;
                }
              if (code === 200) {
                const { taskList, vipExpireTime } = data;
                this.taskList = taskList;
                this.vipExpireTime = vipExpireTime;
              }
            });
        } catch (error) {
          console.log(error);
        }
      },
      async getReward(taskId) {
        const res = await this.$http.get('/hatchery/activity/task/receive', {
          params: { taskId, gw: true, nottrans: true },
          data: {
            gw: true,
            requestMethod: 'GET',
            template: true,
            nottrans: true,
          },
        });
        let data;
        // 如果是chuangkit-saas 项目
        if (this.$route.path.includes('/designtools') || this.$route.path === '/') {
          data = res.body && res.body.data;
        } else {
          data = res.data && res.data.body && res.data.body.data;
        }
        return data;
      },
    },
    beforeMount() {
      this.getTaskList();
    },
  };
</script>

<style lang="less" scoped>
  .task-center {
    width: 425px;
    height: 408px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 4px;
    background: url('../img/task_bg.png') no-repeat center;
    background-color: #ffffff;
    background-size: 425px 408px;
    .day-num {
      font-size: 14px;
      line-height: 14px;
      color: #342b2b;
    }

    .color-tit {
      color: #f25774;
    }

    .title {
      font-weight: 600;
      font-size: 26px;
      height: 40px;
      line-height: 40px;
      color: #000000;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        padding-right: 12px;
      }
    }
    .sub-tit {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #505a71;
      margin-top: 8px;
    }
    .award {
      margin-top: 24px;
      .award-tit {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #1b2337;
      }
    }

    .task-list {
      margin-top: 18px;
      .task {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 24px;

        .task-info {
          display: flex;
          .task-icon {
            width: 44px;
            height: 44px;
            display: block;
            object-fit: cover;
            margin-right: 16px;
          }

          .task-txt-info {
            display: flex;
            height: 44px;
            flex-direction: column;
            justify-content: space-between;

            .txt {
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
              color: #1b2337;
            }
          }
        }

        .task-status {
          width: 86px;
          height: 38px;
          border-radius: 129px;
          line-height: 38px;
          text-align: center;
        }

        .task-undone {
          border: 1px solid #0773fc;
          color: #0773fc;
          cursor: pointer;
        }

        .task-done {
          border: 1px solid rgba(7, 115, 252, 0.2);
          color: #0773fc;
          opacity: 0.4;
        }
      }
    }
    .btns {
      width: 100%;

      .keep-on {
        width: 100%;
        height: 48px;
        background: #0773fc;
        border-radius: 4px;
        text-align: center;
        line-height: 48px;
        color: #ffffff;
        margin-top: 33px;
        cursor: pointer;
      }
    }
  }
</style>
