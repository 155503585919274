<template>
  <div class="password-tip">
    <div class="password-tip-cell">
      <div class="password-tip-icon-box">
        <img
          :src="iconMap[errors[typeEnum.length]]"
          class="password-tip-icon"
        />
      </div>
      <div class="password-tip-text">密码长度为 8-18 位</div>
    </div>
    <div class="password-tip-cell">
      <div class="password-tip-icon-box">
        <img
          :src="iconMap[errors[typeEnum.format]]"
          class="password-tip-icon"
        />
      </div>
      <div class="password-tip-text">
        密码须包含数字、大小写字母、特殊字符四种类型，且每种类型字符至少含 1 位
      </div>
    </div>
    <div class="password-tip-cell">
      <div class="password-tip-icon-box">
        <img :src="iconMap[errors[typeEnum.type]]" class="password-tip-icon" />
      </div>
      <div class="password-tip-text">不允许输入中文和空格</div>
    </div>
  </div>
</template>
<script>
import defaultIcon from "../../img/change-password/default.svg";
import successIcon from "../../img/change-password/success.svg";
import failIcon from "../../img/change-password/fail.svg";

export const statusEnum = {
  0: "error",
  1: "success",
  2: "default",
  error: 0,
  success: 1,
  default: 2,
};

export const typeEnum = {
  0: "length",
  1: "format",
  2: "type",
  length: 0,
  format: 1,
  type: 2,
};

export default {
  name: "password-tip",
  data() {
    return {
      typeEnum: typeEnum,
      iconMap: {
        [statusEnum.error]: failIcon,
        [statusEnum.success]: successIcon,
        [statusEnum.default]: defaultIcon,
      },
    };
  },
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="less" scoped>
.password-tip {
  height: 94px;
  width: 267px;
  background: #1b2337;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  padding: 9px;
  &::before {
    content: " ";
    box-sizing: border-box;
    width: 4px;
    height: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -4px;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid #1b2337;
  }
  &:last-of-type(&-cell) {
    margin-bottom: 0;
  }
  &-cell {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    .password-tip-icon-box {
      width: 14px;
      height: 15px;
      box-sizing: border-box;
      padding-top: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }
    .password-tip-icon {
      width: 14px;
      height: 14px;
      color: #02bb00;
    }
    .password-tip-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
    }
  }
}
</style>
