import { render, staticRenderFns } from "./sendVip.vue?vue&type=template&id=60469754&scoped=true&"
import script from "./sendVip.vue?vue&type=script&lang=js&"
export * from "./sendVip.vue?vue&type=script&lang=js&"
import style0 from "./sendVip.vue?vue&type=style&index=0&id=60469754&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60469754",
  null
  
)

export default component.exports