import * as types from '../mutation-types'

const state = {
  messageShow: false,
  placeHolder: '',
  type: 'error', // success, error, warning, info
  hideTime: 1500,
}

const getters = {
  getMessageShow: state => state.messageShow,
  getMessagePlaceHolder: state => state.placeHolder,
  getMessageType: state => state.type,
  getMessageHideTime: state => state.hideTime,
}

const mutations = {
  [types.SET_MESSAGE_SHOW] (state, message) {
    // 新{placeHolder:'', kind:''}
    // 旧{'promptText': '', 'promptKind': ''}
    if (message.hasOwnProperty('promptText')) {
      // 兼容处理
      message.placeHolder = message.promptText
      message.type = message.promptKind
    }
    state.messageShow = typeof message === 'object' ? Object.keys(
      message).length !== 0 : message
    state.placeHolder = message.placeHolder
    state.type = message.kind || message.type || 'error'
    state.hideTime = message.hideTime ? message.hideTime : 1500
    if (state.messageShow) {
      setTimeout(() => {
        state.messageShow = false
      }, state.hideTime)
    }
  },
}

const actions = {
  setMessageShow ({commit}, message) {
    commit(types.SET_MESSAGE_SHOW, message)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
