import * as types from '../mutation-types'

const state = {
  orderDetails: [],
  shoppingDetails: [],
  flagTimer: null,
}

const getters = {
  getOrderDetails: state => state.orderDetails,
  getShoppingDetails: state => state.shoppingDetails,
  getShoppingTimer: state => state.flagTimer,
}
const mutations = {
  [types.SET_ORDERDETAILS] (state, v) {
    state.orderDetails.push(v)
  },
  [types.SET_SHOPPINGDETAILS] (state, v) {
    state.shoppingDetails.push(v)
  },
  [types.SET_TIMER] (state, v) {
    state.flagTimer = v
  },
}

const actions = {
  setOrderDetails ({commit}, v) {
    commit(types.SET_ORDERDETAILS, v)
  },
  setShoppingDetails ({commit}, v) {
    commit(types.SET_SHOPPINGDETAILS, v)
  },
  setShoppingTimer ({commit}, v) {
    commit(types.SET_TIMER, v)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}