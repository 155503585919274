<template>
  <LtModal
    v-model="modalShow"
    :mask-closable="false"
    :closable="false"
    class="workWeixi-limitModal"
  >
    <div class="workWeixin-limit">
      <p class="pop-title">
        <i class="icon-font"></i>团队使用人数，超过购买人数！
      </p>
      <p>请联系企业微信管理员修改应用可见范围</p>
      <img src="./img/workWeixin_expire_qrcode.png" class="qr-code" />
      <p>
        <b>客服电话</b>
      </p>
      <p class="phone-num">400-871-8211</p>
    </div>
  </LtModal>
</template>

<script>
export default {
  name: "workWeixinExpirePop",
  props: {
    wxWorkExpirePopShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalShow: false,
    };
  },
  watch: {
    wxWorkExpirePopShow(v) {
      this.modalShow = v;
    },
  },
  mounted() {
    this.modalShow = this.wxWorkExpirePopShow;
  },
};
</script>

<style lang="less" scoped>
.workWeixi-limitModal {
  .lt-modal-mask,
  .lt-modal-wrap {
    top: 71px;
    z-index: 1 !important;
  }
}
</style>
<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.workWeixin-limit {
  .flex;
  flex-direction: column;
  padding: 40px 0 32px;
  width: 480px;
  p {
    color: rgba(0, 0, 0, 0.8);
    line-height: 14px;
  }
  .pop-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 10px;
    .icon-font {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url(./img/warning.png);
      background-size: cover;
      vertical-align: bottom;
      margin-right: 15px;
    }
  }
  .qr-code {
    width: 160px;
    height: 160px;
    margin: 20px 0;
  }
  .phone-num {
    font-size: 12px;
    margin-top: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>