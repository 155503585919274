<template>
  <div>
    <!--邀请成员提示 -->
    <LtModal 
    :closable="false"
    :mask-closable="false"
     v-model="cancelUpgrade">
     <div class="close-upgrade-pop">
       <div class="header">
          <p class="title">温馨提示</p>
          <LtIcon
            type="close"
            size="16"
            class="close-icon"
            @click="sureCancel"
          />
       </div>
      <ul>
        <li>如果您还未邀请团队成员，后续可以在“首页>我的团队>成员管理”模块里邀请团队成员。</li>
      </ul>
      <div class="btn" @click="sureCancel">我知道了</div>
     </div>
    </LtModal>
    <!--不限次个人会员升级提示 单人使用的企业会员+多人使用的企业会员 -->
    <LtModal 
      :mask-closable="false"
      @on-cancel='cancelUpgradePop'
      v-model="popup">
     <div class="upgrade-pop">
      <img src="./img/person.png" alt="" class="title" v-if='popupType===0'>
      <img src="./img/company.png" alt="" class="title" v-else>
      <div class="content"
      :class="{
        company:popupType===1 ? true : false
      }"
      >
        <p class="instruct">
          创客贴会员全面升级，<span class="instruct-tip">团队成员与您共享会员权益！</span>您当前身份是创客贴<span class="level">“{{vipTitle}}”</span>，您可邀请成员加入团队，共享会员权益，<span class="instruct-tip">高效协作编辑</span>。
        </p>
        <div class="cross-line"></div>
        <p class="time">原会员到期时间：{{formatTime(originalExpireDate)}}</p>
        <p class="time">升级后到期时间：{{formatTime(upgradedExpireDate)}}</p>
        <p class="num">共<span>{{totalTeamMemberCount}}</span>个成员席位，还可邀请<span>{{availableTeamMemberCount}}</span>人</p>
        <div class="btn" @click="goInvite">邀请成员</div>
        <p class="jump" @click="vipPageUrl">查看会员权益</p>
      </div>
     </div>
    </LtModal>
  </div>
</template>
<script>

export default {
  data() {
    return {
      cancelUpgrade: false,
      popup:false,
      popupType:0,
      vipTitle:'',
      originalExpireDate: '',
      upgradedExpireDate: '',
      totalTeamMemberCount: '',
      availableTeamMemberCount: ''
    }  
  },
  props: {
    userInfo: {
      required: true,
    }
  },
  watch: {
    'userInfo.userId': {
        handler(v) {
          if (v){
            this.loginSucceed()
          }
        },
        immediate: true
    }
  },
  methods:{
    vipPageUrl() {
      window.open(`//${location.host}/price/vip`)
    },
    formatTime (str) {
      let time = new Date()
      time.setTime(str)
      let leftTime = time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate()
      return leftTime
    },
    goInvite(){
      this.popup = false
      this.$emit('goInvite')
    },
    loginSucceed(){
      try {
        this.$http
        .post("/vip/userPopup/getVipChangePop",{ gw: true})
        .then((res) => {
          if (res.body.code === 200) {
            const result = res.body.data
            this.popup= result.popup;
            this.popupType = result.popupType;
            this.vipTitle = result.vipTitle;
            this.originalExpireDate = result.originalExpireDate
            this.upgradedExpireDate = result.upgradedExpireDate
            this.totalTeamMemberCount = result.totalTeamMemberCount
            this.availableTeamMemberCount = result.availableTeamMemberCount
          }
        });
      } catch (error) {
        console.log(error)
      }
    },
    sureCancel(){
      window.location.reload()
      this.cancelUpgrade = false
    },
    cancelUpgradePop(){
      this.cancelUpgrade = true
    }
  }
}
</script>

<style scoped lang="less">
.close-upgrade-pop{
  width: 495px;
  height: 228px;
  padding:24px;
  .header{
    display: flex;
    justify-content: space-between;
    .close-icon{
      cursor: pointer;
    }
  }
  .title{
    padding-bottom: 32px;
    color: #1B2337;
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  li{
    padding-left: 20px;
    color: #1B2337;
    font-family: PingFang SC;
    font-weight: regular;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
  .btn{
    cursor: pointer;
    margin: 0 auto;
    margin-top: 32px;
    border-radius: 4px;
    background: #0773FC;
    width: 120px;
    height: 40px;
    color: #FFFFFF;
    font-family: PingFang SC;
    font-weight: regular;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: center;
    &:hover{
      background: #0667E2;
    }
  }
}
.upgrade-pop{
  width: 400px;
  .title{
    width: 400px;
  }
  .content{
    padding:24px;
    height: 356px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .instruct{
      color: #1B2337;
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      .level{
        color: #0773FC;
        font-weight: bold;
      }
      .instruct-tip{
        font-weight: bold;
      }

    }
    .cross-line{
      width: 352px;
      border: 0;
      flex-shrink: 0;
      height: 1px;
      background: #00000019;
      margin: 24px 0 12px 0;
    }
    .time{
      margin-top: 12px;
      font-size: 12px;
      font-weight: bold;
      color: #1B2337;
      line-height: 20px;
    }
    .num{
      margin-top: 24px;
      color: #1B2337;
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      span{
        color: #0773FC;
      }
    }
    .btn{
      cursor: pointer;
      width: 104px;
      height: 40px;
      border-radius: 4px;
      background: #0773FC;
      color: #FFFFFF;
      font-family: PingFang SC;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      margin: 16px 0;
      &:hover{
        background-color: #0667E2;
      }
    }
    .jump{
      cursor: pointer;
      color: #0773FC;
      font-size: 12px;
      line-height: 20px;
    }
    &.company{
      .instruct .level{
        color: #FFC9A0;
      }
      .btn{
        background: linear-gradient(90deg, #FCEEE1 0%, #FFC9A0 100%);
        color: #6B3D1E;
        &:hover{
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), linear-gradient(90deg, #FCEEE1 0%, #FFC9A0 100%);
        }
      }
    }

  }
}
</style>