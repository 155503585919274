<template>
  <div class="invite-section">
    <div class="section-left" v-if="activityEntryShow" @click="toActivityPage">
      <img :src="imgUrl" />
    </div>
    <div class="section-righ-warp">
      <div class="section-right">
        <div class="sec-title">
          <div>邀请成员加入团队</div>
          <div>邀请你的全部团队成员加入团队，共同解锁高效设计协作</div>
        </div>
        <header>
          <span 
            v-for="(item, index) in tabs"
            :key="index"
            class="tab-item"
            :class="{active: activeTabIndex === index}"
            @click="switchTab(index)"
          >{{ item }}</span>
        </header>
        <main>
          <!-- 链接邀请 -->
          <section v-if="activeTabIndex === 0" class="invite-byurl">
            <p class="title">使用微信扫描二维码或者通过链接邀请更多的人加入协作。</p>
            <div class="qrcode-container">
              <qrcode v-if="qrcodeurl" :qrcodeurl="qrcodeurl" size="140"></qrcode>
            </div>
            <div class="invite-url">
                <LtInput
                  v-model="inviteLink"
                  :autofocus="false"
                  class="invite-input"
                  readonly
                />
                <hr class="input-line" />
                <lt-selector
                  class="role-select"
                  defaultValue="成员"
                  :selectorSize="{ height: '40px', width: '84px'}"
                >
                  <lt-option
                    v-for="item in roleList"
                    :key="item.role"
                    :value="item"
                    :isActive="selectedRole.value === item.value"
                    :optionSize="{ height: '40px' }"
                    suffix="value"
                    @select-value="getRole"
                  >
                    <LtIcon 
                      type="checkmark" 
                      size="12" 
                      v-if="selectedRole.value === item.value"
                      class="check-mark" 
                    />
                  </lt-option>
                </lt-selector>
              </div>
          </section>

          <!-- 邮件邀请 -->
          <section v-else class="invite-byemail">
            <p :class="{shadow: inviteCount > 4 && activeTabIndex === 1}"></p>
            <div class="scroll-wrap" :class="{scroll: inviteCount > 4}">
              <div 
                class="invite-item" 
                v-for="index in inviteCount" 
                :key="index"
              >
                <input
                  class="invite-input"
                  type="email"
                  v-model="inputEmail[index - 1]"
                  placeholder="输入电子邮箱地址"
                />
                <div
                  class="role-select"
                  @click="setRoleListShow(index)"
                  v-click-outside="hiddenRoleList"
                >
                  <span>{{ getSelectedRole(index) }}</span>
                  <lt-icon
                    type="arrow-dropdown"
                    size="14"
                    color="#6F6F6F"
                  ></lt-icon>
                </div>

                <ul
                  class="role-list"
                  v-show="roleListShowIndex === index && roleListShow"
                >
                  <li
                    v-for="role in roleList"
                    :key="role.role"
                    :class="{ active: selectedRoleId === role.role }"
                    @click="selectRole(role, index)"
                  >
                    {{ role.value }}
                    <LtIcon type="checkmark" size="12" v-if="selectedRoleId === role.role" />
                  </li>
                </ul>
              </div>
                <section 
                class="add-roleInfo"
                :class="{shadow: inviteCount >4 && activeTabIndex === 1}"
              >
                <div 
                  class="add-more" 
                  :class="{show: inviteCount < 10 && activeTabIndex === 1}"
                  @click="addInviteInput"
                >
                  <LtIcon type="add-file" size="12" class="add-icon" />
                  <span>新增</span>
                </div>
              </section>
            </div>
            <p :class="{shadow: inviteCount > 4 && activeTabIndex === 1}"></p>
          </section>
          <section class="invite-limit">
            <p class="limit-title">
              <a-checkbox class="switch"  v-model="inviteLimit" @change="toggleInviteLimit">开启管理员审核</a-checkbox>
              <!-- 放在这里 -->
              <input id="inviteLink" :value="inviteLink" title="link" />
            </p>
            <p class="limit-info">
              开启后，所有申请加入团队的人都必须要通过管理员审核后，才可以加入团队。
            </p>
          </section>
          <div class="btn-warp">
            <!-- <div
              v-if=" activeTabIndex === 0"
              class="reset-button"
              @click="inviteByUrl"
            >
            <span>重置链接</span>
            <lt-tooltip placement="top">
              <img src="../../../img/info-circle.svg" width="16" height="16" class="tipBtn"/>
              <div slot="content" style="line-height: 20px;padding: 2px 0;">
                  <div>链接重置后，历史该角色下生成</div>
                  <div>的邀请链接均将失效</div>
              </div>
            </lt-tooltip>
            </div> -->
            <LtButton
              type="primary"
              class="invite-button"
              :class="{copyButton: activeTabIndex === 0}"
              data-clipboard-target="#inviteLink"
              @click="clickButton"
            >{{ activeTabIndex === 0 ? '复制链接 ' : '发送邀请' }}</LtButton>
          </div>
        </main>
      </div>
      <div class="bottom_bar" v-if='teamInfo.teamRoleEnum=="Administrator"||teamInfo.teamRoleEnum=="Manager"'>
          <lt-progress :percent="prosgressBar" :stroke-width="4" :hide-info="true" :class="prosgresPersent==1?'g_class':prosgresPersent==2?'y_class':'r_class'"></lt-progress>
          <span class="per_num">{{ teamInfo.memberAmount }}/{{ teamInfo.maxMemberAmount }}</span>
          <span class="addTeam" @click="gobuy">增加可用席位数></span>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import qrcode from 'components/qrcode/qrcode.vue'
import { mapGetters } from 'vuex'
export default {
  props: {
    teamId: {
      type: Number,
      required: true
    },
    teamInfo: {
      type: Object,
      required: true
    },
  },
  components: { qrcode},
  data() {
    return {
      tabs: ['链接邀请', '邮件邀请'],
      activeTabIndex: 0,
      roleList: [
        {
          role: 2,
          value: '设计师'
        },
        {
          role: 3,
          value: '成员'
        },
        {
          role: 4,
          value: '管理员'
        }
      ],
      roleListShow: false,
      roleListShowIndex: -1,
      // 邮箱邀请
      inviteCount: 4,
      // 邀请成员邮箱角色列表
      emailRoleList: [
        {
          email: '',
          role:  3
        },
        {
          email: '',
          role: 3
        },
        {
          email: '',
          role: 3
        },
        {
          email: '',
          role: 3
        },
      ],
      emailRoleItem: {
        email: '',
        role: 3
      },
      inputEmail: [],
      selectedRoleId: 3,
      // 链接邀请
      inviteLink: '',
      selectedRole: {
        role: 3,
        value: '成员'
      },
      size:140,
      // 邀请限制
      inviteLimit: this.teamInfo.enableAdminVerify === 0 ? false : true,
      qrcodeurl:'',
      imgUrl:'',
      activityEntryShow:'',
      activityJumpUrl:'',
    }
  },
  computed:{
    ...mapGetters({
      bannerImage: 'getBannerInfo'
    }),
    prosgressBar(){
      let teamInfo =this.teamInfo
      return (teamInfo.memberAmount/teamInfo.maxMemberAmount).toFixed(2)*100
    },
    prosgresPersent(){
      let teamInfo =this.teamInfo
      let percentNum = (teamInfo.memberAmount/teamInfo.maxMemberAmount).toFixed(2)*100
      if(percentNum<=60){
        return 1
      }
      if(percentNum>60&&percentNum<=90){
        return 2
      }
      if(percentNum>90){
        return 3
      }
    }
  },
  watch: {
    inputEmail(v) {
      for (let i = 0; i < v.length; i++) {
        this.emailRoleList[i].email = v[i]
      }
    },
    roleListShowIndex(v) {
      if (v) {
        this.roleListShowTime = Date.now()
      }
    },
    teamInfo(v) {
      this.inviteLimit = v.enableAdminVerify === 0 ? false : true
    }
  },
  mounted() {
    this.inviteByUrl()
    this.getBannerPictureInfo()
  },
  methods: {
    async getCaptchaJsUrl () {
      const TCaptchaScript = document.getElementById('TCaptchaScript')
      if (TCaptchaScript) {
        TCaptchaScript.parentNode.removeChild(TCaptchaScript)
      }
      //const res = await this.$http.post('/tools/getCaptchaJsUrl', {apidomainv2: true})
      //滑块升级
      const res = "https://ssl.captcha.qq.com/TCaptcha.js";
      try {
        var sc = document.createElement('script')
        sc.type = 'text/javascript'
        sc.src = res
        document.querySelector('head').appendChild(sc)
        sc.onload = () => {
          this.inviteByUrl()
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getBannerPictureInfo(){
        try {
            const  {
            body: { code, data }  
            } = await this.$http.get(
            '/hatchery/adposition',
            {
            data: { gw: true, requestMethod: "GET", template: true, nottrans: true },
            params: { platform: 0, alias:'team_invite_adv', gw: true },
            })
            if (code === 200 ){
            this.activityEntryShow = typeof data !== 'undefined' && data.length
            this.imgUrl = data && data[0].picUrl
            this.activityJumpUrl = data && data[0].jumpUrl
            }
        } catch (e) {
            console.error('error \n' + e)
        }
    },
    toActivityPage(){
      if(this.activityJumpUrl){
        window.open(this.activityJumpUrl);
      }
    },
    gobuy(){
      this.$emit("closeModal");
      this.$emit("rights-buy-pop",{source:{ moduleName:'buyseats',pageName:'teaminvite'},standardOrEnterprise:'enterprise'});
    },
    switchTab(index) {
      this.activeTabIndex = index
    },
    getSelectedRole(index) {
      let role = ''
      switch (this.emailRoleList[index - 1].role) {
        case 2:
          role = '设计师'
          break
        case 3:
          role = '成员'
          break
        case 4:
          role = '管理员'
          break
      }
      return role
    },
    // 显示角色下拉列表
    setRoleListShow(index) {
      this.roleListShow = !this.roleListShow
      this.roleListShowIndex = index
    },
    // 选择角色
    selectRole(item, index) {
      this.selectedRoleId = this.emailRoleList[index - 1].role = item.role
      this.roleListShowIndex = -1
      this.roleListShow = false
    },
    // 添加更多邀input
    addInviteInput() {
      if (this.inviteCount === 10) return
      this.inviteCount++
      this.emailRoleList.push(JSON.parse(JSON.stringify(this.emailRoleItem)))
    },
    clickButton() {
      if (this.activeTabIndex === 0) {
        this.copyInviteLink()
      } else {
        this.sendInviteEmail()
      }
    },
    // 发送邀请邮件
    sendInviteEmail() {
      let count = 0,
        canSend = true
      this.emailRoleList.forEach((item, index) => {
        const reg = /^\w[-\w.+]*@([A-Za-z0-9][-_A-Za-z0-9]+\.)+[A-Za-z]{2,14}$/
        if (item.email) {
          count++
        }
        if (item.email && !reg.test(item.email)) {
          this.$message(`第${index + 1}个邮箱地址格式不正确`)
          canSend = false
        }
      })
      if (count === 0) {
        this.$message('请输入邮箱地址')
        return
      }
      if (!canSend) return

      //方法1: 直接生成一个验证码对象。
      try {
        const data = {
            gw: true,
            type: 0,
            emailListJson: JSON.stringify(this.emailRoleList),
          }
          this.$http.post('/team/fuse/team/invite', data).then(async res => {
            // -3未登录；-2权限不足；-8团队成员数量已达上限；
            // 1操作成功;-1 用户角色不可为管理员或者为空;-9 邮件邀请团队成员信息为空；
            switch (res.body.code) {
              case 200:
                this.$message('邀请邮件已发送成功')
                this.inputEmail = []
                this.inviteCount = 4
                this.emailRoleList = [
                  {
                    email: '',
                    role: 3
                  },
                  {
                    email: '',
                    role: 3
                  },
                  {
                    email: '',
                    role: 3
                  },
                  {
                    email: '',
                    role: 3
                  },
                ]
                break
              case 10040:
                this.$message(`${res.body.msg}`)
                await sleep(2000)
                window.location.replace('/designtools/team')
                break
              default:
                this.$message(`${res.body.msg}`)
                break
            }
            window.capDestroy()
          })
        // captcha.show();
      } catch (error) {
        //loadErrorCallback();
      }
    },
    hiddenRoleList() {
      // 点击空白处关闭：防止在外部打开后立即关闭
      if (
        Date.now() - this.roleListShowTime < 100 ||
        this.roleListShowIndex === -1
      )
        return
      this.roleListShowIndex = -1
      this.roleListShow = false
    },
    // 邀请限制
    toggleInviteLimit() {
      console.log(this.teamInfo)
      const stateText = this.inviteLimit ? '开启' : '关闭'
      if (this.teamInfo.teamRoleEnum === 'Worker') {
        this.$message(`权限不足，请联系创建者或管理员${stateText}`)
        return
      }

      const data = {
        turn: 13,
        state: this.inviteLimit ? 1 : 0,
        gw: true,
        requestMethod: 'GET' 
      }
      this.$http.get('/team/fuse/team/switchExpandTurnState',{params: {...data }},).then(async res => {
        // -201 state不正确;-202 turn 对应类型不存在;-203 未登录;-102 团队内无该成员；
        // -103 该角色不存在; -104 该角色不存在; -105 权限不足;-107 更新失败;200 成功；
        const code = res.body.code
        switch (code) {
          case 200:
            this.$emit('getTeamInfo')
            break
          case 10040:
            this.$message(`${res.body.msg}`)
            await sleep(2000)
            window.location.replace('/designtools/team')
            break 
          default:
            this.$message(`${res.body.msg}`)
            break
        }
      })
    },
    // 链接邀请
    inviteByUrl() {
      this.$http.get('/team/fuse/team/invite',{
          params: {
            gw: true,
            role: this.selectedRole.role,
            type: 1
          },
          data: {
            gw: true,
            requestMethod: 'GET',
            template: true,
            nottrans: true,
          },}).then(async res => {
        // -3未登录；-2权限不足；-8团队成员数量已达上限；
        // 1操作成功;-1 用户角色不可为管理员或者为空;-9 邮件邀请团队成员信息为空；
        switch (res.body.code) {
          case 200:
            this.inviteLink = res.body.data.shortUrl
            this.qrcodeurl = res.body.data.shortUrl
            break
          case 10040:
            this.$message(`${res.body.msg}`)
            await sleep(2000)
            window.location.replace('/designtools/team')
            break
          default:
            this.$message(`${res.body.msg}`)
            break
        }
      })
    },
    // 选择角色
    getRole(item) {
      this.selectedRole = item
      this.inviteByUrl()
    },
    // 复制邀请链接
    copyInviteLink() {
      let copy = new Clipboard('.invite-button')
      copy.on('success', () => {
        this.$message('复制成功')
        copy.destroy()
      })

      copy.on('error', () => {
        this.$message('复制失败，请手动复制')
        copy.destroy()
      })
    },
  }
}
function sleep (delay = 0) {
  return new Promise(resolve => setTimeout(resolve, delay))
}
</script>

<style lang="less">
.invite-url {
  .lt-input:hover {
    border-color: rgba(0, 0, 0, .2);
  }
  .lt-input:focus {
    border-color: #0773fe;
  }
}
.role-select {
  .selector-input .input-ipt {
    border: none;
    box-shadow: none;
  }
  .lt-option {
    top: 44px;
    right: -8px;
    width: 112px !important;
    .option-item {
      padding: 0 12px 0 22px;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 2px;
      }
      &.active {
        color: rgba(0, 0, 0, 0.8);
        background: #f3f4f9;
        flex-flow: row-reverse;
      }
    }
  }
  .lt-icon {
    font-size: 14px;
  }
}
.invite-limit {
  .lt-switch-small {
    width: 38px;
    height: 18px;
    &.lt-switch-checked:after {
      left: 20px;
    }
  }
}
</style>

<style lang="less" scoped>
@import './invitePop.less';
</style>
