<template>
  <div class="small-button" @click="onButtonClick">
    <span class="buttom-name">{{ buttonName }}</span>
    <span class="buttom-icon"></span>
  </div>
</template>

<script>
  export default {
    props: {
      locationUrl: {
        default: '',
      },
      buttonName: {
        default: '立即体验',
      },
    },
    watch: {},
    methods: {
      onButtonClick() {
        this.$emit('onButtonClick');
      },
    },
  };
</script>

<style lang="less">
  .small-button {
    width: 195px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 2px solid #000;
    color: var(---1, #1B2337);
    cursor: pointer;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.05);
    .buttom-name {
      font-size: 15px;
      font-weight: 500;
    }
    .buttom-icon {
      width: 27px;
      height: 24px;
      margin-left: 24px;
      display: inline-block;
      background-image: url('../img/enter-black.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover {
      color: #fff;
      background: var(--1, #1b2337);
      box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.05);
      .buttom-icon {
        background-image: url('../img/enter-white.svg');
      }
    }
  }
</style>
