import { mapGetters } from 'vuex'
const FILE_TYPE_MENU = {
  'png': 'image/png',
  'jpg': 'image/jpeg',
  'gif': 'image/gif',
  'svg': 'image/svg+xml',
  'PSD': 'image/vnd.adobe.photoshop',
};
const UPLOAD_PROGRESS = 0.5; // 上传占总进度的占比（剩余为解析）
const TOGETHER_NUM = 5; // 同时上传文件的数量

const mixin = {
  data() {
    return {
      FILE_TYPE_MENU,
      fileList: [],
      progress: {},
      progressShow: {},
      uploadId: 0,
      selected: 0, // 0 上传中， 1 上传完成
      uploadIndex: -1, // list列表中当前正在上传中状态的index
      transformIndex: -1, // list列表中当前正在解析中状态的index
      progerssInterval: {},
      stopTransform: false,
      midList: [],
      dragenter: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '上传素材'
    },
    // 可上传文件类型
    fileType: {
      type: Array,
      default: () => {
        return ['png', 'jpg', 'svg', 'gif']
      }
    },
    // 单个文件大小限制
    maxSize: {
      type: Number,
      default: 100
    },
    // 最多可以上传的文件个数
    maxLength: {
      type: Number,
      default: 30
    },
    teamId: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
    }),
    list() {
      return this.fileList.map(item => {
        const name = item.file.name
        const index = name.lastIndexOf('.');
        return {
          id: item.id,
          name: name.slice(0, index),
          type: name.slice(index),
          size: item.file.size
        }
      })
    },
    uploadList() {
      return {
        0: this.list.filter((item) => this.progress[item.id] < 200 && this.progress[item.id] >= 0),
        1: this.list.filter((item) => this.progress[item.id] >= 200 || this.progress[item.id] < 0)
      }
    },
  },
  mounted() {
    document.documentElement.addEventListener('dragover', this.disabledMove)
  },
  beforeDestroy() {
    document.documentElement.removeEventListener('dragover', this.disabledMove)
  },
  watch: {
    visible(value) {
      if(value) {
        this.fileList = [];
        this.progress = {};
        this.progressShow = {};
        this.uploadId = 0;
        this.selected = 0; // 0 上传中， 1 上传完成
        this.uploadIndex = -1; // list列表中当前正在上传中状态的index
        this.transformIndex = -1; // list列表中当前正在解析中状态的index
        this.progerssInterval = {};
        this.midList = [];
        this.stopTransform = false;
      }
    },
    progress(progress) {
      Object.keys(progress).forEach(id => {
        if (progress[id] > this.progressShow[id] || progress[id] < 0) {
          this.$set(this.progressShow, id, progress[id])
        }
      });
    },
  },
  methods: {
    // 禁止页面拖入
    disabledMove(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    // 函数
    isUploadTransformStart({ item, type }) {
      const index = this[`${type}Index`]
      return this.list[index] && (this.list[index].id == item.id) || false
    },
    progressText(id) {
      if (this.progressShow[id] < 0) {
        return '上传失败'
      }
      if (this.progressShow[id] >= 200) {
        return '上传成功'
      }
      if (this.progressShow[id] === 0) {
        return "等待中"
      }
      return `${this.progressShow[id].toFixed(2)}%`
    },
    async getImageSize(file) {
      let img = new Image()
      const imgUrl = URL.createObjectURL(file)
      img.src = imgUrl
      return await new Promise((resolve, reject) => {
        img.onload = () => resolve({
          width: img.width,
          height: img.height
        })
        img.onerror = reject
      })
    },
    // 上传
    async uploadChange(e) {
      this.dragenter = false
      const files = e.target.files || [...e.dataTransfer.files]
      if (files.length === 0) {
        return;
      }
      if (files.length > this.maxLength) {
        this.$message(`单次最多上传${this.maxLength}个文件`)
      }
      let uploadLength = this.maxLength > files.length ? files.length : this.maxLength
      for(var i=0; i<uploadLength; i++) {
        const file = files[i];
        let fileNameArr = file.name.split('.')
        // 将后缀都转化为小写判断
        let extension = fileNameArr[fileNameArr.length - 1].toLowerCase()
        if (!this.fileType.find(type => FILE_TYPE_MENU[type] === file.type) && !this.fileType.find(type => extension === type.toLowerCase())) {
        //   this.$message(`仅支持上传 ${this.fileType.join('、')} 格式的文件`)
          this.$message('文件格式错误，请确认后重新上传')
          continue;
        }
        if( extension === 'gif' && file.size > 5 * 1024 * 1024 && this.userInfo.designerCheckStatus !== 2){
          this.$message(`文件大小错误，请确认后重新上传`)
          continue;
        }
        if (file.size > this.maxSize * 1024 * 1024) {
        // this.$message(`文件大小不能超过${this.maxSize}M`)
          this.$message(`文件大小错误，请确认后重新上传`)
          continue
        }
        try {
          // 字体跳过不检查像素点
          if (!file.type.includes("font")) {
            const info = await this.getImageSize(file);
            const { width, height } = info;
            // 宽/高像素超过16384px，且总像素不超过2.5亿
            if (
              width > 16384 ||
              height > 16384 ||
              width * height > 2500000000
            ) {
              this.$message(`文件像素过大，请确认后重新上传`);
              continue;
            }
          }
        } catch (e) {
          console.log(e)
        }
        this.uploadId++;
        const id = this.uploadId;
        this.fileList.push({id, file});
        this.$set(this.progress, id, 0);
        this.$set(this.progressShow, id, 0);
      }
      this.changeUploadTransformIndex('start')
      this.$refs.uploadInput.value = null
    },
    // 判断继续上传或解析下一条
    changeUploadTransformIndex(type) {
      const uploadIndex = this.uploadIndex;
      const transformIndex = this.transformIndex
      // >=1 上传完成； >=2解析完成； 0未完成
      const judgeEnd = (index) => {
        const id = (this.list[index] || {}).id
        if (this.progress[id] >= 100 || this.progress[id] < 0) {
          return 2;
        }
        if (this.progress[id] >= 100 * UPLOAD_PROGRESS) {
          return 1;
        }
        return 0
      }

      // 开始上传判断
      if (type === 'start') {
        // 当前为第一条
        if (uploadIndex === -1 && transformIndex === -1) {
          this.uploadIndex = uploadIndex + 1;
        }
        // 当前条已上传完成并且上传和解析中的数量不超过TOGETHER_NUM
        if (uploadIndex < transformIndex + TOGETHER_NUM - 1 && judgeEnd(uploadIndex) >= 1) {
          this.uploadIndex = uploadIndex + 1;
        }
      }
      // 上传完成判断
      if (type === 'upload') {
        // 是否可以上传下一条（正在上传和解析中的数量不超过TOGETHER_NUM）
        if (uploadIndex < this.list.length - 1 && uploadIndex < transformIndex + TOGETHER_NUM - 1) {
          this.uploadIndex = uploadIndex + 1;
        }
        // 是否解析本次上传文件（解析完成处于等待状态）
        if (uploadIndex === transformIndex + 1 && (transformIndex === -1 || judgeEnd(transformIndex) >= 2)) {
          this.transformIndex = transformIndex + 1;
        }
      }
      // 解析完成判断
      if (type === 'transform') {
        // 是否需要上传（当前正在上传和解析数量等于TOGETHER_NUM，上传处于等待）
        if (uploadIndex < this.list.length - 1 && uploadIndex <= transformIndex + TOGETHER_NUM - 1 && judgeEnd(uploadIndex) >= 1) {
          this.uploadIndex = uploadIndex + 1;
        }
        // 是否可以解析下一条（下一条已上传完成）
        if (judgeEnd(transformIndex + 1) >= 1) {
          this.transformIndex = transformIndex + 1;
        }
      }
    },
    // 开始上传文件
    uploadStart(id) {
      this.$set(this.progressShow, id, this.progressShow[id] + 0.06)
      this.progerssInterval[id] = setInterval(() => {
        if (this.progressShow[id] < 70) {
          this.$set(this.progressShow, id, this.progressShow[id] + 0.06)
        } else if (this.progressShow[id] < 85) {
          this.$set(this.progressShow, id, this.progressShow[id] + 0.04)
        } else if (this.progressShow[id] < 90){
          this.$set(this.progressShow, id, this.progressShow[id] + 0.01)
        } else if (this.progressShow[id] < 95){
          this.$set(this.progressShow, id, this.progressShow[id] + 0.005)
        } else if (this.progressShow[id] < 98){
          this.$set(this.progressShow, id, this.progressShow[id] + 0.001)
        }
      }, 200)
    },
    // 更改上传进度
    changeProgress({id, progress}) {
      this.progress = {...this.progress, [id]: progress * UPLOAD_PROGRESS}
    },
    // 上传成功
    uploadSuccess({id, mid}) {
      this.progress = {...this.progress, [id]: 100 * UPLOAD_PROGRESS}
      this.midList.push(mid);
      this.changeUploadTransformIndex('upload')
    },
    // 上传失败
    uploadFail(id) {
      this.progress = {...this.progress, [id]: -100}
      this.changeUploadTransformIndex('upload')
      clearInterval(this.progerssInterval[id])
    },
    // 解析成功
    transformSuccess(id) {
      this.progress = {...this.progress, [id]: 100}
      setTimeout(() => {
        this.progress = {...this.progress, [id]: 200}
      }, 400);
      this.changeUploadTransformIndex('transform')
      clearInterval(this.progerssInterval[id])
    },
    // 解析失败
    transformFail(id) {
      this.progress = {...this.progress, [id]: -100}
      this.changeUploadTransformIndex('transform')
      clearInterval(this.progerssInterval[id])
    },
    closeModal() {
      this.$emit('on-cancel', this.midList);
      this.stopTransform = true;
    }
  }
}

export default mixin;