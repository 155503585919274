/**
 * Created by Feng on 2017/7/5.
 */

// 设置用户信息
export const SET_USERINFO = 'SET_USERINFO'
// 设置登录状态
export const SET_ISLOGIN = 'SET_ISLOGIN'
// 设置团队ID
export const SET_TEAMID = 'SET_TEAMID'
// 设置登录注册显示
export const SET_LOGREG_SHOW = 'SET_LOGREG_SHOW'
export const SET_LOGREG_SHOW_LOCATION = 'SET_LOGREG_SHOW_LOCATION'
// 设置印刷订单编号
export const SET_PRINT_ORDER_NO = 'SET_PRINT_ORDER_NO'
// 设计管理设置开启设计
export const SET_TURNON_SETTIMG = 'SET_TURNON_SETTIMG'
export const SET_DESIGN_LOADING = 'SET_DESIGN_LOADING'

// 窗口变化标记
export const SET_RESIZE_FLAG = 'SET_RESIZE_FLAG'
/*
 * 邀请相关
 */
// 设置邀请id
export const SET_INVITE_ID = 'SET_INVITE_ID'

// 模板详情TDK
export const SET_TDK = 'SET_TDK'

// 设置第三方登录iframe
export const SET_IFRAME_SHOW = 'SET_IFRAME_SHOW'
export const SET_IFRAME_URL = 'SET_IFRAME_URL'
// 订单详情页数据存储
export const SET_ORDERDETAILS = 'SET_ORDERDETAILS'
export const SET_SHOPPINGDETAILS = 'SET_SHOPPINGDETAILS'
// 存储购物车所有信息
export const SET_SHOPPINGCAT_DATE = 'SET_SHOPPINGCAT_DATE'
export const ADD_SHOPPING_DATA = 'ADD_SHOPPING_DATA'
export const ADD_ICONLIST = 'ADD_ICONLIST'
export const SET_ICONLIST = 'SET_ICONLIST'
export const SET_TIMER = 'SET_TIMER'

export const SET_RECHARGE_SHOW = 'SET_RECHARGE_SHOW'
export const SET_SHOPPINGANIMATIOIN = 'SET_SHOPPINGANIMATIOIN'
export const SET_CARALLFLAG = 'SET_CARALLFLAG'

//上传设计稿名字集合
export const SET_DESIGN_FILE_NAME = 'SET_DESIGN_FILE_NAME'
export const SET_ADDED_TO_SHOPPING_CART = 'SET_ADDED_TO_SHOPPING_CART'

export const GET_DESIGN_FILE_NAME = 'GET_DESIGN_FILE_NAME'
// 设置会员套餐信息
export const SET_TARIFF_PACKAGES = 'SET_TARIFF_PACKAGES'

//提示信息
export const SET_MESSAGE_SHOW = 'SET_MESSAGE_SHOW'

// 正在编辑设计标题的设计id
export const SET_EDIT_DESIGN_ID = 'SET_EDIT_DESIGN_ID'

//  团队过期提示
export const SET_TEAM_EXPIRED_POP_SHOW = 'SET_TEAM_EXPIRED_POP_SHOW'

//  首页滚动
export const SET_HEADER_IS_FIXED = 'SET_HEADER_IS_FIXED'

// 容量储存
export const GET_CAPACITY_TIP_MODAL_VISIBLE = 'GET_CAPACITY_TIP_MODAL_VISIBLE'




