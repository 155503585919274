<template>
  <div class="resource-page">
    <div class="rotate-container-res">
      <div class="block-1"></div>
      <div class="block-3"></div>
    </div>
    <div class="content-box">
      <div class="sub-title">海量高品质可商用资源</div>
      <div class="small-des">各种资源品类应用尽有，满足不同场景的设计需求</div>
      <div class="center" ref="resourceCenter">
        <div
          class="box video-box"
          @click="handleLocation('/sj-pi44-or0-pt0-bt1-pn1.html','可商用资源_视频')"
        >
          <img src="../img/video.png" width="365" height="235" />
          <div class="resource-title" style="margin: 24px 0 8px 16px">视频</div>
          <div class="resource-des" style="margin-left: 16px">
            整合10万+优质视频资源，商用无忧
          </div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box haibao-box"
          @click="handleLocation('/sj-pi20-or0-pt0-bt1-pn1.html','可商用资源_海报')"
        >
          <img v-lazy="require('../img/haibao.png')" width="492" height="328" />
          <div class="resource-title">海报</div>
          <div class="resource-des">placard</div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box newmedia-box"
          @click="handleLocation('/sj-pi1-si216-or0-pt0-bt1-pn1.html','可商用资源_新媒体')"
        >
          <img
            v-lazy="require('../img/newmedia.png')"
            width="345"
            height="122"
          />
          <div class="resource-title" style="margin: 24px 0 8px 16px">
            新媒体
          </div>
          <div class="resource-des" style="margin-left: 16px">
            爆款传播最全攻略，带你掌握流量密码
          </div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box h5-box"
          @click="handleLocation('/sj-pi1459-or0-pt0-bt1-pn1.html','可商用资源_H5')"
        >
          <img v-lazy="require('../img/H5.png')" width="334" height="154" />
          <div class="resource-des" style="margin: 24px 0 0 16px">
            3分钟即可在线制作H5
          </div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box music-box"
          @click="handleLocation('/designtools/designindex','可商用资源_音频')"
        >
          <img v-lazy="require('../img/music.png')" width="365" height="234" />
          <div class="resource-title">音频</div>
          <div class="resource-des">Audio material</div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box custom-box"
          @click="handleLocation('/designtools/designindex','可商用资源_定制包装')"
        >
          <img
            v-lazy="require('../img/custom-box.png')"
            width="263"
            height="234"
          />
          <div class="block">定制包装</div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box bus-box"
          @click="handleLocation('/sj-pi7-or0-pt0-bt1-pn1.html','可商用资源_电商')"
        >
          <img v-lazy="require('../img/bus.png')" width="197" height="234" />
          <div class="resource-title">电商</div>
          <div class="resource-des">一站式营销工具</div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box print-box"
          @click="handleLocation('/sj-pi2-or0-pt0-bt1-pn1.html','可商用资源_印刷')"
        >
          <img
            v-lazy="require('../img/print-box.png')"
            width="329"
            height="234"
          />
          <div class="resource-title" style="margin: 24px 0 8px 16px">印刷</div>
          <div class="resource-des" style="margin-left: 16px">
            专业印刷管理系统，完美适配多种类印品需求
          </div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box icon-box"
          @click="handleLocation('/sj-pi1-si198-or0-pt0-bt1-pn1.html','可商用资源_图标设计')"
        >
          <img
            v-lazy="require('../img/icon-box.png')"
            width="334"
            height="195"
          />
          <div class="resource-title">图标设计</div>
          <div class="resource-des sub-des">icon Design</div>
          <div class="resource-des">icon、logo在线设计,创意品牌VI触手可及</div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div class="box font-box" @click="handleLocation('/tcrf-polyme','可商用资源_字体')">
          <img
            v-lazy="require('../img/font-box.png')"
            width="263"
            height="119"
          />
          <div class="resource-title" style="margin: 24px 0 8px 16px">字体</div>
          <div class="resource-des" style="margin-left: 16px">
            海量商用字体，满足多种设计需求
          </div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box gif-box"
          @click="handleLocation('/sj-pi12-si669-or0-pt0-bt1-pn1.html','可商用资源_动图')"
        >
          <img
            v-lazy="require('../img/gif-back.png')"
            width="181"
            height="213"
          />
          <video
            src="../img/gifbox.mp4"
            autoplay
            loop
            muted
            width="70"
            height="70"
          ></video>
          <div class="resource-title">动图</div>
          <div class="resource-des">让营销更生动</div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box ill-pic-box"
          @click="handleLocation('/sj-pi12-si233-or0-pt0-bt1-pn1.html','可商用资源_插画')"
        >
          <img
            v-lazy="require('../img/ill-pic.png')"
            width="381"
            height="213"
          />
          <div class="resource-title">插画</div>
          <div class="resource-des">illustration</div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div class="box pic-store-box" @click="handleLocation('/image','可商用资源_图片库')">
          <img
            v-lazy="require('../img/pic-store.png')"
            width="345"
            height="213"
          />
          <div class="resource-title">图片库</div>
          <div class="resource-des">inset</div>
          <div class="try-now-button">立即体验</div>
        </div>
        <div
          class="box ppt-box"
          @click="handleLocation('/sj-pi3-or0-pt0-bt1-pn1.html','可商用资源_PPT')"
        >
          <img v-lazy="require('../img/PPT.png')" width="334" height="240" />
          <div class="resource-title">PPT</div>
          <div class="resource-des sub-des">Power Point</div>
          <div class="resource-des">创意办公一键适配，无需修改下载即用</div>
          <div class="try-now-button">立即体验</div>
        </div>
      </div>
      <!-- <div class="scroll-box" v-show="getScreenWidth <= 1920">
        <div
          class="scroll-button left"
          :class="{ disable: resleftLength <= 0 }"
          @click="moveResToPre"
        ></div>
        <div
          class="scroll-button right"
          :class="{ disable: resRightDisable }"
          @click="moveResToNext"
        ></div>
      </div> -->
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        resRightDisable: false,
        resleftLength: 0,
      };
    },
    props: {
      getScreenWidth: {
        default: 0,
      },
    },
    methods: {
      handleLocation(location, moduleName) {
        this.$emit('openLocation', location, moduleName);
      },
      moveResToPre() {
        const leftLength = this.$refs.resourceCenter.scrollLeft - 200;
        this.resleftLength = leftLength;
        this.resRightDisable = false;
        this.$refs.resourceCenter.scrollTo({
          left: leftLength,
          behavior: 'smooth',
        });
      },
      moveResToNext() {
        const leftLength = this.$refs.resourceCenter.scrollLeft + 200;
        this.resleftLength = leftLength;
        this.$refs.resourceCenter.scrollTo({
          left: leftLength,
          behavior: 'smooth',
        });
        // 判断是否已经滚动到最右边 滚了多少 元素内容总宽度 可见宽度
        if (
          this.$refs.resourceCenter.scrollLeft >=
          this.$refs.resourceCenter.scrollWidth - this.getScreenWidth
        ) {
          this.resRightDisable = true;
        }
      },
    },
  };
</script>
<style lang="less">
  .resource-page {
    width: 100%;
    height: 1452px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .content-box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      .sub-title {
        margin-top: 110px;
      }
      .small-des {
        margin-top: 32px;
        color: #1b2337;
      }
      .center {
        width: 1648px;
        height: 856px;
        margin-bottom: 80px;
        position: relative;
        // overflow-x: scroll;
        // overflow-y: hidden;
        &::-webkit-scrollbar {
          display: none;
        }
        .box {
          padding: 8px;
          border-radius: 32px;
          background: rgba(255, 255, 255, 0.75);
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05);
          position: absolute;
          cursor: pointer;
          img {
            border-radius: 32px;
          }
          &:hover::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            border-radius: 32px;
            background-color: rgba(0, 0, 0, 0.35); /* 遮罩的背景色和透明度 */
          }
          &:hover {
            .try-now-button {
              display: block;
              z-index: 3;
            }
          }
        }
        .resource-title {
          color: var(--1, #1b2337);
          /* 标题/标题4 */
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        .resource-des {
          color: #8693ab;
          /* 正文/正文2 */
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
        .video-box {
          width: 381px;
          height: 345px;
          top: 0;
          left: 0;
        }
        .haibao-box {
          width: 508px;
          height: 344px;
          top: 0;
          left: 397px;
          .resource-title {
            position: absolute;
            top: 41px;
            left: 48px;
          }
          .resource-des {
            position: absolute;
            top: 91px;
            left: 48px;
          }
        }
        .newmedia-box {
          width: 361px;
          height: 232px;
          top: 0;
          left: 921px;
        }
        .h5-box {
          width: 350px;
          height: 232px;
          top: 0;
          left: 1298px;
        }
        .music-box {
          width: 381px;
          height: 250px;
          top: 361px;
          left: 0;
          .resource-title {
            position: absolute;
            top: 58.5px;
            left: 116px;
          }
          .resource-des {
            position: absolute;
            top: 90.5px;
            left: 116px;
          }
        }
        .custom-box {
          width: 279px;
          height: 250px;
          top: 361px;
          left: 397px;
          .block {
            width: 80px;
            height: 33px;
            text-align: center;
            line-height: 33px;
            border-radius: 20px;
            background: rgba(255, 255, 255, 0.75);
            color: var(--1, #1b2337);
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: 500;
            position: absolute;
            top: 181px;
            left: 32px;
          }
        }
        .bus-box {
          width: 213px;
          height: 250px;
          top: 361px;
          left: 692px;
          .resource-title {
            position: absolute;
            top: 145px;
            left: 90px;
          }
          .resource-des {
            position: absolute;
            top: 177px;
            left: 57px;
          }
        }
        .print-box {
          width: 361px;
          height: 354px;
          top: 248px;
          left: 921px;
        }
        .icon-box {
          width: 350px;
          height: 273px;
          top: 248px;
          left: 1298px;
          .resource-title {
            position: absolute;
            top: 81px;
            left: 40px;
          }
          .resource-des {
            position: absolute;
            top: 227px;
            left: 16px;
          }
          .sub-des {
            position: absolute;
            top: 113px;
            left: 40px;
          }
        }
        .font-box {
          width: 279px;
          height: 229px;
          left: 0;
          top: 627px;
        }
        .gif-box {
          width: 197px;
          height: 229px;
          top: 627px;
          left: 295px;
          video {
            top: 40px;
            left: 64px;
            position: absolute;
            border-radius: 99px;
          }
          .resource-title {
            position: absolute;
            top: 130px;
            left: 83px;
          }
          .resource-des {
            position: absolute;
            top: 162px;
            left: 58px;
          }
        }
        .ill-pic-box {
          width: 397px;
          height: 229px;
          top: 627px;
          left: 508px;
          .resource-title {
            position: absolute;
            top: 32px;
            left: 40px;
          }
          .resource-des {
            position: absolute;
            top: 64px;
            left: 40px;
          }
        }
        .pic-store-box {
          width: 361px;
          height: 229px;
          top: 627px;
          left: 921px;
          .resource-title {
            position: absolute;
            top: 32px;
            left: 40px;
          }
          .resource-des {
            position: absolute;
            top: 64px;
            left: 40px;
          }
        }

        .ppt-box {
          width: 350px;
          height: 318px;
          top: 537px;
          left: 1298px;
          .resource-title {
            position: absolute;
            top: 40px;
            left: 40px;
          }
          .resource-des {
            position: absolute;
            top: 272px;
            left: 24px;
          }
          .sub-des {
            position: absolute;
            top: 80px;
            left: 40px;
          }
        }
      }
    }
  }
</style>
