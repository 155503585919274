<template>
  <ul class="header-pop-list-seo">
    <li v-for="item in seo" :key="item.link">
      <a :href="item.link">{{ item.title }}</a>
    </li>
  </ul>
</template>

<script>
import seo from './seoData'

export default {
  inject: ['header'],
  name: 'HeaderSeo',
  data() {
    return {
      seo
    }
  }
}
</script>

<style lang="less" scoped>
.header-pop-list-seo {
  display: flex;
  flex-wrap: wrap;
  width: 660px;
  height: 290px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
  z-index: 2;
  padding: 22px 35px;
  li {
    width: 8em;
    height: 32px;
    line-height: 32px;
    margin-right: 0;
    cursor: pointer;
    font-size: 12px;
    color: #626262;
    text-align: left;
    a {
      display: block;
      padding: 0 8px;
      color: #626262;
    }
    &:hover {
      background-color: #f8f8f8;
      font-weight: bolder;
      color: #0773fc;
      font-size: 12px;
      a {
        color: #0773fc;
      }
    }
  }
}
</style>
