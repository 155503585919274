<template>
  <div class="send-vip-box">
    <p class="send-vip-title">您已经注册过会员</p>
    <p class="send-vip-text">本次活动仅限新用户领取</p>
    <div class="receive-vip-btn" @click="$emit('close')">我知道了</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      time: 6,
    };
  },
};
</script>

<style lang="less" scoped>
.send-vip-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 430px;
  height: 208px;
  border-radius: 4px;
  background: #ffffff;
  .send-vip-title {
    margin-top: 40px;
    color: #1B2337;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
  }
  .send-vip-text {
    margin-top: 20px;
    color: #0773FC;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    span {
      color: #fa2323;
    }
  }
  .receive-vip-btn {
    width: 120px;
    height: 40px;
    margin: 32px 0 40px 0;
    border-radius: 4px;
    background: #0773fc;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background: #0667e2;
    }
  }
}
</style>