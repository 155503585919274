<template>
  <div class="component-camera-search">
    <LtModal
      ref="cameraModal"
      v-model="cameraModalVisible"
      title="上传图片搜索相似模板"
      :simple="false"
      :footer-hide="true"
      :width="560"
      class-name="search-modal search-modal-camera"
    >
      <div class="modal-camera--content">
        <div class="modal-camera--main" v-if="!uploadLoading">
          <div class="camera-error">
            <span class="camera-error_text" v-if="urlErrorText">*{{urlErrorText}}</span>
          </div>
          <div class="camera-search">
            <input type="text" autocomplete="off" v-model="imgUrlVal" class="camera-search__input" @keyup.enter="handleImgUrlSearch" placeholder="请在此处粘贴图片地址" />
            <lt-button type="primary" class="camera-search__btn" @click="handleImgUrlSearch">
              <span class="icon-search-white"></span>
            </lt-button>
          </div>
          <div class="camera-upload" @dragover.prevent @drop.prevent="handleFileDrop">
            <div class="camera-upload--list">
              <input ref="fileSelect" accept="image/jpeg, image/jpg, image/png" type="file" id="file-select" @click.stop @change="handleFileChange">
              <lt-button type="primary" class="camera-upload__local" @click.stop="handleUploadClick">本地上传</lt-button>
              <lt-button type="ghost" class="camera-upload__phone" @click.stop="showScanModal">手机上传</lt-button>
            </div>
            <div class="camera-upload--tips">
              <div class="camera-upload__tips-main">拖拽到此处上传或点击“本地上传”按钮上传</div>
              <div class="camera-upload__tips-sub">仅支持4M以内的jpg、png格式图片</div>
            </div>
          </div>
        </div>
        <div class="modal-camera--loading" v-else>
          <ThreeColumnsLoading />
          <div class="modal-camera--loading__text">上传中</div>
        </div>
      </div>
    </LtModal>
    <LtModal
      ref="scanModal"
      v-model="scanModalVisible"
      title="手机上传"
      :simple="false"
      :footer-hide="true"
      :width="560"
      class-name="search-modal search-modal-scan"
    >
      <div class="modal-scan--content">
        <div class="scan--rect">
          <div class="scan--rect__img">
            <qrcode v-if="qrcodeUrl" :qrcodeurl="qrcodeUrl" size="145"></qrcode>
            <div class="time-out" v-if="pollQueryTimeOut">
              <div class="time-out__tip">二维码已失效</div>
              <div class="time-out__tip" @click.stop="handleReScan">点击刷新</div>
            </div>
          </div>
        </div>
        <div class="scan--tips">
          <div class="scan__tip-main">
            用手机“扫一扫”上传手机相册图片
          </div>
          <div class="scan__tip-sub mr-bottom-6">
            支持JPG/PNG格式图片
          </div>
          <div class="scan__tip-sub">
            支持4M以下分辨率4000*4000以下的图片
          </div>
        </div>
      </div>
    </LtModal>
    <LtModal
      v-model="errorScanModalVisible"
      :simple="false"
      :footer-hide="true"
      :width="560"
      class-name="search-modal search-modal-error"
    >
      <div class="modal-error--content">
        <div slot="header" class="lt-modal-header-inner">
          <lt-icon type="error" size="18" color="#FF4D4D"/>
          <span style="margin-left: 10px;">上传失败</span>
        </div>
        <div class="modal-error--inner">
          {{scanErrorText}}
        </div>
        <div class="modal-error--footer">
          <lt-button type="primary" @click="hideErrorScanModal">重新上传</lt-button>
        </div>
      </div>
    </LtModal>
  </div>
</template>

<script>
import ThreeColumnsLoading from '@/components/loading/threeColomnsLoading.vue'
import qrcode from 'components/qrcode/qrcode.vue'
import CryptoJS from 'crypto-js'
import axios from 'axios'

export default {
  name: 'CameraSearch',
  components: {
    ThreeColumnsLoading,
    qrcode,
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      cameraModalVisible: false,
      scanModalVisible: false,
      errorScanModalVisible: false,
      uploadLoading: false,
      urlErrorText: '',
      scanErrorText: '',
      qrcodeUrl: '',
      imgUrlVal: '',
      pollQueryTimer: null,
      pollQueryTimeOut: false,
    }
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.cameraModalVisible = true
      }
    },
    cameraModalVisible(newVal) {
      if (!newVal) {
        this.uploadLoading = false
        this.$emit('change', false)
      }
    },
    errorScanModalVisible(bol) {
      if (!bol) {
        try {
          this.$refs.scanModal &&
            (this.$refs.scanModal.isMouseTriggerIn = false)
        } catch (err) {}
        this.handleReScan()
      }
    },
    scanModalVisible(bol) {
      if (!bol) {
        if (this.pollQueryTimer) {
          clearInterval(this.pollQueryTimer)
        }
        try {
          this.$refs.cameraModal &&
            (this.$refs.cameraModal.isMouseTriggerIn = false)
        } catch (err) {}
      }
    }
  },
  methods: {
    generateUUID() {
      let d = new Date().getTime()
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return uuid
    },
    pollingQueryForMobileUpload(token) {
      if (this.pollQueryTimer) {
        clearInterval(this.pollQueryTimer)
      }
      let statusRdata = null
      let count = 1
      const t = this
      this.pollQueryTimer = setInterval(async () => {
        statusRdata = await this.$http.post('/template/getImageUploadState', {
          token,
          apidomainv2: true
        })
        if (!this.scanModalVisible) {
          clearInterval(this.pollQueryTimer)
          return
        }
        count += 1
        const { body: { code, msg, data }} = statusRdata
        if (count > 150) {
          clearInterval(t.pollQueryTimer)
          this.pollQueryTimeOut = true
          return
        }
        if (code !== -103 && code !== -102) {
          clearInterval(t.pollQueryTimer)
          if (code === 200) {
            this.navigatorToImgSearch(data)
          } else {
            this.scanErrorText = msg
            this.errorScanModalVisible = true
          }
        }
      }, 2000);
    },
    getMobileUrl() {
      const host = window.location.host
      if (host.indexOf('local') > -1) {
        return 'https://local-www.chuangkit.com'
      } else if (host.indexOf('moni') > -1) {
        return 'https://moniwww.chuangkit.com'
      } else if (host.indexOf('beta') > -1) {
        return 'https://beta.chuangkit.com'
      } else {
        return 'https://www.chuangkit.com'
      }
    },
    showScanModal() {
      const uuid = this.generateUUID()
      const mobileURl = this.getMobileUrl()
      this.qrcodeUrl = mobileURl + `/picsearchupload?t=${uuid}`
      this.pollQueryTimeOut = false
      this.scanModalVisible = true
      this.uploadImage({
        token: uuid,
      }, 'scan')
    },
    hideErrorScanModal() {
      this.errorScanModalVisible = false
    },
    handleReScan() {
      this.pollQueryTimeOut = false
      this.showScanModal()
    },
    navigatorToImgSearch(key) {
      this.cameraModalVisible = false
      const encodeKey = encodeURIComponent(key)
      location.href = `/pic-search/1?k=${encodeKey}`
    },
    async uploadImage(config, type) {
      try {
        this.urlErrorText = ''
        const params = {
          apidomainv2: true,
        }
        const uuid = this.generateUUID()
        switch (type) {
          case 'file':
            Object.assign(params, {
              md5: config.md5,
              token: uuid,
            })
            break
          case 'url':
            Object.assign(params, {
              url: config.url,
            })
            break
          case 'scan':
            this.pollingQueryForMobileUpload(config.token)
            return
        }
        this.uploadLoading = true
        const resData = await this.$http.post('/template/getTemplateSearchImageUploadUrl', params)
        if (!this.uploadLoading) {
          return
        }
        const { code, data, msg } = resData.body
        switch (type) {
          case 'file':
            if (code === 200) {
              const { paramMap, fileKey, url, method} = data
              const repFormData = new FormData()
              Object.keys(paramMap).forEach((key) => {
                repFormData.append(key, paramMap[key])
              })
              repFormData.append('file', config.file)
              repFormData.append('success_action_status', 200)
              // 上传图片
              const ossRes = await axios({
                url: 'null://'+url, method: method, data: repFormData, onUploadProgress: (event) => {
                  console.log('progress:', Math.round((event.loaded) / event.total))
                }
              })
              
              if (!this.uploadLoading) {
                return
              }
              if (ossRes.status === 200||ossRes.status === 204) {
                await sleep(1000)
                // 验证图片是否为黄图
                const statusRdata = await this.$http.post('/template/getImageUploadState', {
                  token: uuid,
                  apidomainv2: true
                })
                if (statusRdata.body.code === 200) {
                  this.navigatorToImgSearch(fileKey)
                } else {
                  this.urlErrorText = statusRdata.body.msg
                }
              } else {
                this.urlErrorText = '图片上传失败'
              }
            } else {
              this.urlErrorText = msg
            }
            break
          case 'url':
            if (code === 200) {
              const { fileKey } = data
              this.navigatorToImgSearch(fileKey)
            } else {
              this.urlErrorText = msg
            }
            break
        }
        this.uploadLoading = false
      } catch (err) {
        this.uploadLoading = false
        this.$message({
          message: '发生了一些错误, 请刷新重试',
          type: 'error',
        })
      }
    },
    checkIsUrl(imgurl) {
      return /^https?:\/\/.+/.test(imgurl)
    },
    async handleImgUrlSearch() {
      const isImgUrl = await this.checkIsUrl(this.imgUrlVal)
      if (isImgUrl) {
        this.uploadImage({
          url: this.imgUrlVal
        }, 'url')
      } else {
        this.urlErrorText = '请输入正确图片地址'
      }
    },
    // 获取图片上传 MD5
    parseMd5(file) {
      var md5Instance = CryptoJS.algo.MD5.create();
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
        md5Instance.update(CryptoJS.enc.Latin1.parse(e.target.result));
        var md5Value = md5Instance.finalize().toString();
        this.uploadImage({
          md5: md5Value,
          file
        }, 'file')
      }
    },
    /**
     * 获取图片尺寸
     */
    getFileImageRect(file) {
      return new Promise((resolve, reject) => {
        const src = window.URL.createObjectURL(file)
        const image = new Image()
        image.onload = () => {
          const width = image.width
          const height = image.height
          window.URL.revokeObjectURL(src)
          resolve({
            width,
            height
          })
        }
        image.onerror = reject
        image.src = src
      })
    },
    /* 文件选择之后 */
    async selectFile(file) {
      if (this.uploadLoading) {
        return
      }
      if (file.type.indexOf('image/png') === -1 && file.type.indexOf('image/jpeg') === -1) {
        this.urlErrorText = '只支持上传jpg和png文件'
        return
      }
      if (file.size > 1048576 * 4) {
        this.urlErrorText = '请上传4M以下大小的图片'
        return
      }
      const rectInfo = await this.getFileImageRect(file)
      if (rectInfo.width > 4000 || rectInfo.height > 4000) {
        this.urlErrorText = '请上传分辨率4000*4000以下大小的图片'
        return
      }
      this.parseMd5(file)
    },
    /** 拖拽到页面上传 */
    handleFileDrop(e) {
      const fileList = e.dataTransfer.files
      this.file = fileList[0]
      this.selectFile(fileList[0])
    },
    /** input图片上传 */
    handleFileChange(e) {
      const fileList = e.target.files
      if (fileList.length > 0) {
        this.selectFile(fileList[0])
      }
    },
    handleUploadClick() {
      const fileSelect = this.$refs.fileSelect
      fileSelect && fileSelect.click()
    }
  },
  beforeDestroy() {
    if (this.pollQueryTimer) {
      clearInterval(this.pollQueryTimer)
    }
  },
}
function sleep (delay = 0) {
  return new Promise(resolve => setTimeout(resolve, delay))
}
</script>

<style lang="less">
.search-modal {
  .lt-modal {
    .lt-modal-content {
      padding: 24px;
      .lt-modal-header {
        padding-bottom: 0;
        border-bottom: none;
      }
      .lt-modal-body {
        padding-top: 0px;
      }
      .lt-modal-close {
        .lt-icon {
          font-size: 16px !important;
          color: #1B2337 !important;
        }
      }
      .lt-modal-header-inner {
        color: #1B2337;
      }
    }
  }
}
.search-modal-camera {
  .lt-modal {
    .lt-modal-content {
      .modal-camera--content {
        .modal-camera--main {
          .camera-error {
            box-sizing: content-box;
            height: 12px;
            padding-top: 12px;
            padding-bottom: 8px;
            font-size: 12px;
            line-height: 12px;
            color: #FA2323;
          }
          .camera-search {
            display: flex;
            align-items: center;
            width: 100%;
            height: 40px;
            .camera-search__input {
              width: calc(100% - 56px);
              height: 100%;
              border: none;
              outline: none;
              padding: 0 16px;
              background: #F3F4F9;
              color: #333;
              font-size: 14px;
              box-sizing: border-box;
              border-radius: 4px 0px 0px 4px;
            }
          }
          .camera-search__btn {
            width: 56px;
            height: 100%;
          }
          .icon-search-white {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url('./img/header_search_map.svg');
            background-position: -120px 0;
            background-repeat: no-repeat;
          }
          .camera-upload {
            margin-top: 24px;
            padding: 40px 0;
            border: 1px dashed #8693AB;
            text-align: center;
            &.disabled {
              border: 1px dashed #0773FC;
              background: #E6F1FE;
            }
            .camera-upload--list {
              #file-select {
                display: none;
              }
              .camera-upload__phone {
                margin-left: 16px;
              }
              .lt-btn {
                width: 92px;
                height: 40px;
                font-size: 14px;
              }
            }
            .camera-upload--tips {
              margin-top: 16px;
              .camera-upload__tips-main {
                font-size: 14px;
                color: #1B2337;
                line-height: 14px;
                font-weight: 400;
              }
              .camera-upload__tips-sub {
                margin-top: 8px;
                line-height: 12px;
                color: #8693AB;
                font-size: 12px;
                font-weight: 400;
              }
            }
          }
        }
        .modal-camera--loading {
          padding-top: 100px;
          padding-bottom: 76px;
          .modal-camera--loading__text {
            margin-top: 16px;
            color: #1B2337;
            line-height: 14px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
          }
        }
      }
    }
  }
}

.search-modal-scan {
  .modal-scan--content {
    padding-top: 60px;
    padding-bottom: 16px;
    text-align: center;
    .scan--rect {
      display: inline-block;
      width: 180px;
      height: 180px;
      background: linear-gradient(to left, #2c76f3, #2c76f3) left top no-repeat,
        linear-gradient(to bottom, #2c76f3, #2c76f3) left top no-repeat,
        linear-gradient(to left, #2c76f3, #2c76f3) right top no-repeat,
        linear-gradient(to bottom, #2c76f3, #2c76f3) right top no-repeat,
        linear-gradient(to left, #2c76f3, #2c76f3) left bottom no-repeat,
        linear-gradient(to bottom, #2c76f3, #2c76f3) left bottom no-repeat,
        linear-gradient(to left, #2c76f3, #2c76f3) right bottom no-repeat,
        linear-gradient(to left, #2c76f3, #2c76f3) right bottom no-repeat;
      background-size: 2px 20px, 20px 2px, 2px 20px, 20px 2px;
      background-clip: border-box;
      border: 1px solid #ebf1fd;
      border-radius: 4px;
      overflow: hidden;
      .scan--rect__img {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .qrcode-container {
          height: 145px;
        }
        .time-out {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          .time-out__tip {
            font-size: 14px;
            line-height: 14px;
            color: #fff;
            &:last-child {
              margin-top: 12px;
              color: #0773fC;
              cursor: pointer;
            }
          }
        }
      }
    }
    .scan--tips {
      margin-top: 32px;
      .scan__tip-main {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
        color: #1B2337;
        line-height: 16px;
      }
      .scan__tip-sub {
        font-size: 14px;
        font-weight: 400;
        color: #8693AB;
        text-align: center;
        line-height: 14px;
        &.mr-bottom-6 {
          margin-bottom: 6px;
        }
      }
    }
  }
}

.search-modal-error {
  .lt-modal {
    .lt-modal-content {
      padding: 32px 24px;
    }
  }
  .modal-error--inner {
    padding-left: 28px;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #505A71;
    line-height: 14px;
  }
  .modal-error--footer {
    text-align: right;
    .lt-btn {
      width: 92px;
      height: 40px;
      font-size: 14px;
    }
  }
}
</style>
