import pageNameEnum from "../../pageNameEnum";

function debounce(fn, wait, immediate) {
  let timer;
  return function () {
    if (timer) clearTimeout(timer);
    if (immediate) {
      // 如果已经执行过，不再执行
      const callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, wait);
      if (callNow) {
        fn.apply(this, arguments);
      }
    } else {
      timer = setTimeout(() => {
        fn.apply(this, arguments);
      }, wait);
    }
  };
}

const defaultImg = require("./img/default.svg");

export default {
  inject: ["header"],
  data() {
    return {
      searchValue: "",
      allScenes: [],
      filterScenes: [],
      recommendedScenes: [],
      showCustomSize: false,
      visible: false,
      visibleTimer: 0,
      defaultImg,
    };
  },
  watch: {
    searchValue() {
      this.onSearch(true);
    },
    visible() {
      if (this.visible) {
        Promise.all([this.getAllScenes(), this.getRecommendedScenes()]).then(
          () => {
            this.onSearch();
          }
        );
      }
    },
  },
  methods: {
    reportImgHandle(is_login) {
      try {
        window.gio("track", "button_click", {
          page_name: "上传并编辑图片",
          module_name: "图片处理弹窗",
          button_name: "在设计中使用",
          class_id: null,
          page_url: location.href,
          is_login: is_login ? "是" : "否",
        });
      } catch (err) {
        console.error(err);
      }
    },
    reportPopupShow(is_login) {
      try {
        window.gio("track", "popup_show", {
          page_name: pageNameEnum[this.$route.path] || "",
          popup_name: "创建设计小弹窗",
          page_url: location.href,
          is_login: is_login ? "是" : "否",
        });
      } catch (err) {
        console.error(err);
      }
    },
    reportSearch(input_keyword, seach_result_real_number) {
      const forwardMap = {
        createDesign: "创建设计",
        imgHandle: "在设计中使用",
      };
      try {
        window.gio("track", "design_scene_seach", {
          forward_page_name: pageNameEnum[this.$route.path] || "",
          forward_module_name: forwardMap[this.name],
          input_keyword,
          seach_result_real_number,
        });
      } catch (err) {
        console.error(err);
      }
    },
    reportSceneClick({
      input_keyword,
      seach_result_real_number,
      design_scene_id,
      design_id_name,
    }) {
      const forwardMap = {
        createDesign: "创建设计小弹窗",
        imgHandle: "在设计中使用",
      };
      try {
        window.gio("track", "design_scene_click", {
          forward_page_name: pageNameEnum[this.$route.path] || "",
          forward_module_name: forwardMap[this.name],
          input_keyword,
          seach_result_real_number,
          design_scene_id,
          design_id_name,
          click_type: "空白创建",
        });
      } catch (err) {
        console.error(err);
      }
    },
    showQuick() {
      if (this.name === "createDesign") {
        this.reportPopupShow(this.isLogin);
      }
      if (this.visibleTimer) {
        clearTimeout(this.visibleTimer);
      }
      this.visible = true;
    },
    hideQuick(clearState=false) {
      if (clearState){
        this.visible = false;
        return
      }
      this.visibleTimer = setTimeout(() => {
        this.visible = false;
      }, 500);
    },
    onSearch: debounce(function (isReport) {
      if (this.searchValue === "自定义尺寸") {
        isReport && this.reportSearch(this.searchValue, 0);
        this.showCustomSize = true;
        return;
      }
      if (this.searchValue) {
        this.showCustomSize = false;
        this.filterScenes = this.allScenes.filter((v) =>
          v.title.includes(this.searchValue)
        );
        isReport &&
          this.reportSearch(this.searchValue, this.filterScenes.length);
      } else {
        this.showCustomSize = false;
        this.filterScenes = this.recommendedScenes;
      }
    }, 300),
    getSize(item) {
      const { width, height } = item || {};
      return {
        width: parseFloat(width),
        height: parseFloat(height),
        unit: width.match(/[a-zA-Z]+$/)[0],
      };
    },
    startDesign({ width, height, unit, id }) {
      if (this.name === "imgHandle") {
        this.reportImgHandle(this.isLogin);
      }

      const startDesignCb = () => {
        let href;
        if (id) {
          href = this.getStartDesignUrl({ id });
        } else if (unit === "px" || unit === "cm") {
          href = this.getStartDesignUrl({ oUnit: unit, width, height });
        } else if (unit === "mm") {
          href = this.getStartDesignUrl({
            oUnit: unit,
            width: width / 10,
            height: height / 10,
          });
        } else if (unit === "in") {
          href = this.getStartDesignUrl({
            oUnit: unit,
            width: (width * 25.4) / 10,
            height: (height * 25.4) / 10,
          });
        }
        this.searchValue = "";
        window.open(href);
        this.visible = false;
      };

      if (!this.isLogin) {
        this.setLogregShow(1);
        this.header.loginSuccessCb = () => {
          startDesignCb();
        };
        return;
      }

      startDesignCb();

      try {
        window.gio("track", "recommendpage_click", {
          click_position: "工具首页_设计场景模块",
          click_content: "自定义尺寸",
        });
        window.gio("track", "pc_home_distribution_statistics", {
          design_scenarios: "自定义尺寸",
          banner_name: "",
          my_design: "",
        });
      } catch (error) {
        console.error("gio error \n" + error);
      }
      try {
        window.gio("track", "template_edit", {
          design_type: "平面模板", // 设计类型
          template_id: "", // 模板ID
          template_name: "", // 模板名称
          design_scenarios: "自定义", // 设计场景
          edit_type: "自定义", // 编辑种类
          keywords: "", // 搜索关键词
          url: location.href, // 当前页面地址
          is_free: "是",
        });
      } catch (error) {
        console.error("gio error \n" + error);
      }
    },
    getStartDesignUrl({ oUnit, width, height, id }) {
      let unit = oUnit === "px" ? "px" : "cm";
      const k = unit === "px" ? "127" : "128";
      let teamStr = this.teamId ? "&tid=" + this.teamId : "";
      const midStr = this.mid ? "&mid=" + this.mid : "";
      if (id) {
        return `/odyssey/createDesign?k=${id}${midStr}`;
      }
      return `/odyssey/createDesign?k=${k}&w=${width}${unit}&h=${height}${unit}${teamStr}${midStr}`;
    },
    getAllScenes() {
      try {
        return this.$http
          .post("/team/distribution/template/listAllSecondScenes", { gw: true })
          .then((response) => {
            const {
              code,
              data: { carefulChosenId, kindSearchResultSoList = [] } = {},
            } = response.body || {};
            if (code === 200) {
              this.allScenes = kindSearchResultSoList.map((v) => ({
                ...v,
                carefulChosenId,
              }));
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    getRecommendedScenes() {
      try {
        return this.$http
          .post("/team/distribution/template/listSecondScenes", { gw: true })
          .then((response) => {
            const { code, data = [] } = response.body || {};
            if (code === 200) {
              this.recommendedScenes = data;
              if (!this.searchValue) {
                this.filterScenes = data;
              }
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    updateKindScore(item) {
      try {
        this.$http
          .post("/team/distribution/template/updateKindScore", {
            gw: true,
            firstKindId: item.parentKindId,
            kindId: item.id,
            ...(item.carefulChosenId
              ? { carefulChosenId: item.carefulChosenId }
              : {}),
          })
          .then((response) => {
            const { code, data } = response.body || {};
            if (code === 200) {
              this.recommendedScenes = data;
              if (!this.searchValue) {
                this.filterScenes = data;
              }
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    customSizeCreateDesign(arg) {
      this.reportSceneClick({
        input_keyword: "自定义尺寸",
        seach_result_real_number: 0,
        design_scene_id: null,
        design_id_name: "自定义尺寸",
      });
      this.startDesign(arg);
    },
    goDesign(item, formSenceList = false) {
      this.reportSceneClick({
        input_keyword: this.searchValue || null,
        seach_result_real_number: this.searchValue
          ? this.filterScenes.length
          : null,
        design_id_name: item.title,
        design_scene_id: item.id,
      });
      this.updateKindScore(item);
      if (formSenceList) {
        this.visible = false;
        window.open(
          `/sj-pi${item.centerParentKindId}-si${item.id}-or0-pt0-pn1.html`
        );
        return;
      }
      this.startDesign({
        ...this.getSize(item),
        id: item.id,
      });
    },
    onClear() {
      this.searchValue = "";
      this.showCustomSize = false;
    },
    createCustomSize() {
      this.showCustomSize = true;
      this.searchValue = "自定义尺寸";
    },
    imgError(e) {
      e.target.src = defaultImg;
    },
  },
};
