<!--Created by binnull on 2017/2/24.-->
<template>
  <transition :name="transitionName">
    <div v-if="modalShow && (type === 'modal1' || type === 'modal3' || type === 'modal5' || type === 'modal6')" class="modal-wrap" @click.self="modalClose(1)">
      <transition :name="type" appear>
        <div class="modal1-center" v-if="type === 'modal1' && modalShow">
          <slot></slot>
          <a role="button" class="modal-close modal1-close" v-if="type === 'modal1' && closeShow" @click="modalClose" href="javascript:void(0);" title="关闭"></a>
        </div>
        <div class="modal3-right" v-else-if="type === 'modal3' && modalShow">
          <slot></slot>
          <a role="button" class="modal-close modal3-close" @click="modalClose" href="javascript:void(0);" title="关闭"></a>
        </div>
        <div class="modal5-bottom" :class="{display:modalFiveDisplay}" v-else-if="type === 'modal5' && modalShow">
          <slot></slot>
          <a role="button" class="modal-close modal5-close" @click="modalClose" href="javascript:void(0);" title="关闭"></a>
        </div>
        <div class="modal1-center" v-if="type === 'modal6' && modalShow">
          <slot></slot>
          <a role="button" class="modal-close modal6-close" v-if="type === 'modal6' && closeShow" @click="modalClose" href="javascript:void(0);" title="关闭"></a>
        </div>
      </transition>
    </div>
    <span class="modal2-center" v-else-if="type === 'modal2' && modalShow">
      <span class="modal2-icon" :class="modal2IconClass"></span>
      <span class="modal2-text">
        <slot>
          {{placeHolder}}
        </slot>
      </span>
    </span>
    <div class="modal4" v-else-if="type === 'modal4' && modalShow" :style="modal4Position">
      <slot></slot>
    </div>
  </transition>
</template>

<style lang="less" scoped>
  @import "modal.less";
</style>

<script>

export default {
  props: {
    type: {
      default: 'modal1'
      /*
        {
          'modal1': '完全自定义对话框',
          'modal2': '成功，失败，警告提示'
          'modal3': '右侧拉显示对话框'
          'modal4': '自定义tag提示'
          'modal5': '底部弹窗'
        }
      */
    },
    modalShow: {
      required: true,
      default: false
    },
    placeHolder: {
      default: '操作成功'
    },
    promptKind: {
      default: 'success'
    },
    hideTime: {
      default: 1500
    },
    position: {
      default() {
        return {
          'left': 0,
          'top': 0
        }
      }
    },
    closeShow:{
      default: true
    },
    disableBlankClick: {
      default: false
    }
  },
  data() {
    return {
      modal4Position: {
        left: 0,
        top: 0
      },
      modalFiveDisplay: false
    }
  },
  methods: {
    modalClose(v) {
      if (v === 1) { // 点击遮罩区域
        if (this.disableBlankClick) return
        this.$emit('modalClose');
      } else {
        this.$emit('modalClose');
      }
    }
  },
  computed: {
    modal2IconClass() {
      return {
        'modal2-icon--success': this.promptKind === 'success',
        'modal2-icon--warning': this.promptKind === 'warning',
        'modal2-icon--error': this.promptKind === 'error',
        'modal2-icon--spin': this.promptKind === 'spin'
      }
    },
    transitionName() {
      return this.type === 'modal2' ? 'modal-wrap-2' : 'modal-wrap';
    }
  },
  watch: {
    modalShow(val){
      if (val && this.type === 'modal2') {
        setTimeout(() => {
          this.modalClose();
        }, this.hideTime);
      } else if (val && this.type === 'modal5') {
        setTimeout(() => {
          this.modalFiveDisplay = true;
        }, 500);
      }
    },
    position(val){
      this.modal4Position = {
        'left' : val.left + 'px',
        'top' : val.top + 'px'
      }
    }
  }
}

</script>

