import Vue from 'vue'
import axios from 'axios'
Vue.prototype.$http = axios
window.__e2 = {
  APIDOMAINV2,
  APIDOMAIN
}


function getClientType() {
  let _clientTypeStr = "&_dataClientType=0&client_type=0";
  let _clientType = 0;
  // currentDataClientType 超级用户80已废弃，无需处理
  if (window.jsbridge) {
    _clientTypeStr = "&_dataClientType=42&client_type=42";
    _clientType = 42;
  }
  return {
    _clientTypeStr,
    _clientType
  }
}

function getRequestUrl(baseUrl, url, nodo) {
  const rquestion = /\?/;
  return `${baseUrl}${url}${nodo ? "" : ".do"}${
    rquestion.test(url) ? "&" : "?"
  }_dataType=json${getClientType()._clientTypeStr}`;
}

function getOtherUrl(url) {
  const rquestion = /\?/
  return `${url}${
    rquestion.test(url) ? '&' : '?'
  }${getClientType()._clientTypeStr.slice(1)}`
}

export default function() {
  // 请求全局设置
  axios.interceptors.request.use(
    function(request) {
      request.method = 'POST'
      // 请求模板缓存
      if (request.data && request.data.template) {
        request.method = 'GET'
        if (request.data.nottrans) {
          request.withCredentials = true
          let apidomainv2 = false
          let gw = false

          if (request.data) {
            if (request.data.apidomainv2) {
              apidomainv2 = true
              delete request.data.apidomainv2
            } else if (request.data.gw) {
              gw = true
              delete request.data.gw
            }
          }
          let url = request.url
          if (request.data.requestMethod) {
            request.method = request.data.requestMethod
          }
          if (gw) {
            request.url = getRequestUrl(GW, url, request.data.nodo)
          } else {
            request.url = getRequestUrl(apidomainv2 ? APIDOMAINV2 : APIDOMAIN, url, false)
          }
        }
      } else if (request.data && request.url.substring(0, 5) === 'ul://') {
        //ul：upload
        request.url = getOtherUrl(request.url).substring(3)
      } else if (request.data && request.url.substring(0, 7) === 'null://') {
        //ul：upload
        request.url = getOtherUrl(request.url).substring(7)
      } else if (request.data && request.url.substring(0, 4) === 'll:/') {
        //ul：upload
        // 允许跨域
        request.withCredentials = true

        request.url = request.url.substring(4)

        let apidomainv2 = false
        if (request.data) {
          if (request.data.get('apidomainv2')) {
            apidomainv2 = true
            request.data.delete('apidomainv2')
          }
        }

        let url = request.url
        request.url = getRequestUrl(apidomainv2 ? APIDOMAINV2 : APIDOMAIN, url, false)
      } else if (request.url.substring(0, 6) === 'outer:') {
        request.crossOrigin = false

        request.headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
        request.url = getOtherUrl(request.url).substring(6)
      } else {

        if (request.data && request.data.nottrans) {
          request.headers = {
            'Content-Type': 'application/json'
          }
        } else {
          request.headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
        // 允许跨域
        request.withCredentials = true

        let apidomainv2 = false
        let gw = false

        if (request.data) {
          if (request.data.apidomainv2) {
            apidomainv2 = true
            delete request.data.apidomainv2
          } else if (request.data.gw) {
            gw = true
            delete request.data.gw
          }
        }
        if (request.data instanceof FormData && request.data.get("gw")) {
          gw = true
        }

        if (!(request.data instanceof FormData) && !gw) {
          request.data = {
            ...request.data,
            _dataClientType: getClientType()._clientType,
            client_type: window.jsbridge ? 42 : request.data?.client_type === undefined ? 0 : request.data.client_type
          }
        }

        if (!(request.data && request.data.nottrans)) {
          request.transformRequest = [
            function(data) {
              if (data instanceof FormData) return data
              let ret = ''
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  '=' +
                  encodeURIComponent(data[it]) +
                  '&'
              }
              ret = ret.substring(0, ret.length - 1)
              return ret
            }
          ]
        } else {
          if (request.data && request.data.nottrans) {
            delete request.data.nottrans;
          }
        }
        let url = request.url
        if (request.data.requestMethod) {
          request.method = request.data.requestMethod
        }
        if (gw) {
          request.url = getRequestUrl(GW, url, request.data.isInclude)
        } else {
          request.url = getRequestUrl(apidomainv2 ? APIDOMAINV2 : APIDOMAIN, url, false)
        }
      }
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken){
        request.headers['Authorization']= `Bearer ${accessToken}`
      }
      return request
    },
    function(error) {
      return Promise.reject(error)
    }
  )
  axios.interceptors.response.use(
    function(response) {
      response.body = response.data.body
        ? response.data.body
        : response.data
      return response
    },
    function(error) {
      return Promise.reject(error)
    }
  )
}

export { axios };
