<template>
   <div class="team-card" v-if="isLogin" @mouseenter="mouseenterBtn">
    <!-- 切换团队卡片显示 -->
      <div class="team_entry" :class="isShowHeader?'':'team_entry selfClass'" v-if="showTeamBar">
        <ul :class='[otherTeams.length>5?"team_change_list team_change_list_style":"team_change_list"]'>
          <li v-for="item in userOwnTeams" :key="item.teamId" @click.stop="changeTeam(item)">
              <div class="item_warp">
                <div class="team_list_icon">
                  <img :src="userInfo.userHeadImgUrl" v-if="canCreateFreeTeam&&item.teamRoleEnum=='Administrator'"/>
                  <img :src="item.headIconUrl"  v-else-if='!item.headIconUrl.endsWith("default")'/>
                  <span class="team_name_f" v-else >{{ getTeamName(item,true) }}</span>
                </div>
                <div class="team_list_cont">
                  <span
                    class="team_list-title"
                    v-text="getTeamName(item)"
                    >
                  </span>
                  <p v-if="canCreateFreeTeam&&item.teamRoleEnum=='Administrator'">{{ item.versionName}}</p>
                  <p v-else>{{item.versionName}} · {{item.memberAmount}}人</p>
                </div>
              </div>
              <img
                v-show="selectedId === item.teamId"
                src="../../img/header_team_checked.svg"
                class="check-icon"/>
          </li>
          <li class="boderLine" v-if="isJoinedTeam"></li>
          <li v-for="item in otherTeams" :key="item.teamId" @click.stop="changeTeam(item)">
              <div class="item_warp">
                <div class="team_list_icon">
                  <img :src="userInfo.userHeadImgUrl" v-if="canCreateFreeTeam&&item.teamRoleEnum=='Administrator'"/>
                  <img :src="item.headIconUrl"  v-else-if='!item.headIconUrl.endsWith("default")'/>
                  <span class="team_name_f" v-else >{{ getTeamName(item,true) }}</span>
                </div>
                <div class="team_list_cont">
                  <span
                    class="team_list-title"
                    v-text="getTeamName(item)"
                    >
                  </span>
                  <p v-if="canCreateFreeTeam&&item.teamRoleEnum=='Administrator'">{{ item.versionName}}</p>
                  <p v-else>{{item.versionName}} · {{item.memberAmount}}人</p>
                </div>
              </div>
              <img
                v-show="selectedId === item.teamId"
                src="../../img/header_team_checked.svg"
                class="check-icon"/>
          </li>
        </ul>
        <span :class='[canCreateFreeTeam?"mark_bottom":"mark_bottom no_team_mark_bottom"]' v-if='otherTeams.length>5'></span>
        <div class="create_team_tip" v-if="canCreateFreeTeam">
            <div class="create_btn" @click="createTeam">
              <span>创建团队/企业</span>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const VIP_TEAM_VERSION = [2, 7, 3, 8, 135, 5, 201, 202]
const VIP_VERSION = {
    1: '基础版VIP',
    2: '协作版VIP',
    3: '高级团队VIP',
    4: '个人版VIP',
    5: '标准版团队VIP',
    6: '企业专业版VIP',
    7: '加强版VIP',
    8: '基础团队版VIP',
    9: '全用途版VIP',
    100: '基础版VIP',
    105: '全用途版VIP',
    110: '标准团队版VIP',
    115: '高级团队版VIP',
    120: '企业专业版',
    125: '新媒体商户版',
    130: '全用途商户版',
    135: '团队协作版',
    199: '标准版（个人）',   
    200: '标准版（企业）',
    210: '标准版（大企业）',
    201: '个人高级版',   
    202: '企业高级版',
    203: '高级版',
    205: '协作版',
    204: '高级版（大企业）',
    220: '旗舰版',
    211: "通用版（个人）",
    212: "通用版（企业）",
    '135_10': "团队协作10人版"
  };
export default {
  name: 'ChangeTeamModal',
  inject: ['header'],
  data() {
    return {
      navAslideDom: null,
      showTeamBar:false,
      selectedId: this.header.teamId,
    }
  },
  props: {
    isLogin: {
      type: Boolean
    },
    teamInfo: {
      type: Object
    },
    userInfo: {
      type: Object,
      required: true,
    },
    isProVip: {
      type: Boolean
    },
    isShowHeader: {
      type: Boolean,
      default:false
    },
    canCreateFreeTeam: {
      type: Boolean
    },
  },
  computed: {
    ...mapGetters({
      designTypeList: 'getDesignTypeList',
      teams: 'getTeams',
      teamId: 'getTeamId'
      // designTypeList: 'getDesignTypeList',
    }),
    isAdmin() {
      const teamRole = this.teamInfo.teamRoleEnum;
      //是创建者、管理员、设计师
      //return teamRole === 'Administrator' || teamRole === 'Manager' || teamRole === 'Designer' || !teamRole
      return teamRole === 'Administrator' || teamRole === 'Manager' || !teamRole
    },

    // versionName(){
    //   if(this.canCreateFreeTeam&&this.isJoinedTeam){
    //     if(this.teamInfo.teamRoleEnum=='Administrator'){
    //       return this.userInfo.vipLevel>0?'付费版':'免费版'
    //     }
    //   }
    //   if (this.isExpireTeam(this.teamInfo)) {
    //     return '免费团队'
    //   }
    //  return VIP_TEAM_VERSION.includes(this.teamInfo.adminUserVipLevel) ? '付费团队' : '免费团队'
    // },
   
    //
    isWorker() {
      return this.teamInfo.teamRoleEnum === 'Worker'
    },
    // 是否在自己创建的团队内
    isTeamAdmin() {
      return this.teamInfo.teamRoleEnum === 'Administrator'
    },
    // 判断是否加入过别人的团队
    isJoinedTeam() {
      const { teams } = this
      return teams.some(team => team.teamRoleEnum !== 'Administrator')
    },
     // 用户自己创建的团队， 可能有多个，以前的老逻辑
     userOwnTeams() {
        return this.teams.filter(team => team.teamRoleEnum === 'Administrator').map(team => {
          let { teamName, adminUserName, adminUserVipLevel,  } = team
          let name=''
          try {
            name = decodeURIComponent(teamName).trim() || decodeURIComponent(adminUserName).trim() + '的团队'
          
          } catch (error) {
            name = decodeURIComponent(teamName.replace(/%/g, '%25')).trim() || decodeURIComponent(adminUserName.replace(/%/g, '%25')).trim() + '的团队'
          }
          let versionName
          versionName  = VIP_VERSION[adminUserVipLevel]
          if (this.isExpireTeam(team)) {
            versionName = '免费版'
          }
          if(this.canCreateFreeTeam){
            if(team.teamRoleEnum=='Administrator'){
              versionName='个人版'
            }
          }
          return { ...team, name, versionName }
        })
      },
      userSelfInfo() {
        const { userName: name, userOwnIconType, userOwnTeams: [team = {}] } = this
        return { ...team, name, userOwnIconType }
      },
      // 用户自己的信息或者自己创建的团队的信息
      userSelfOrTeamsRowInfo() {
        // 未开通团队协作，但是已加入过其他人的团队，则展示自己的个人信息
        // isJoinedTeam 曾经加入过别人的团队
        const { isJoinedTeam, canCreateFreeTeam, userOwnTeams, userSelfInfo } = this
        const isHaveOwnTeam = !canCreateFreeTeam // 自己开通过团队
        const isShow = isHaveOwnTeam || isJoinedTeam
        return {
          isShow,
          isHaveOwnTeam,
          list: isHaveOwnTeam ? userOwnTeams : [userSelfInfo]
        }
      },
      otherTeams() {
        this.selectedId = this.teamInfo.teamId
        return this.teams.filter(team => team.teamRoleEnum != 'Administrator').map(team => {
          let { teamName, adminUserName, adminUserVipLevel,  } = team
          let name=''
          try {
            name = decodeURIComponent(teamName).trim() || decodeURIComponent(adminUserName).trim() + '的团队'
          
          } catch (error) {
            name = decodeURIComponent(teamName.replace(/%/g, '%25')).trim() || decodeURIComponent(adminUserName.replace(/%/g, '%25')).trim() + '的团队'
          }
          let versionName
          versionName  = VIP_VERSION[adminUserVipLevel]
          if (this.isExpireTeam(team)) {
            versionName = '免费版'
          }
          if(this.canCreateFreeTeam){
            if(team.teamRoleEnum=='Administrator'){
              versionName='个人版'
            }
          }
          return { ...team, name, versionName }
        })
      }
  },
  watch: {
    teamInfo() {
      this.getNavGroupTitle('团队')
    },
  },
  created() {
    console.log(this.showTeamCar,'选中的team=====')
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'setLogregShow',
      'setLogregShowLocation'
    ]),
    mouseenterBtn(){
      this.$emit('mouseenterBtn')
    },
    isIncludeEmoji(item){
      console.log(item)
      let reg = /[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/;
      if(reg.test(item.teamName)){
        return true
      }else{
        return false
      }
    },
    showMoadl(){
      this.showTeamBar =true
    },
    hideMoadl(){
      this.showTeamBar =false
    },
    createClipDesign(url){
      gio('track','video_clip_entry_button',{'clip_button_click':'视频剪辑按钮点击量'})
      if (!this.isLogin) {
          sessionStorage.setItem('forward', JSON.stringify({
            forward_module_name:'左侧栏_视频剪辑',
            forward_page_name: '推荐页'
          }))
        this.setLogregShow(1)
        return
      }
      localStorage.nvsWidth = 960
      localStorage.nvsHeight = 540
      localStorage.nvsprojectid = ''
      localStorage.nvsprojectname = '未命名'
      localStorage.nvsxmlurl = ''
      localStorage.nvsxmlkeypath = ''
      window.open(url)
    },
     // 用户版本
    //  versionName(vipLevel) {
    //   // const { teamName, adminUserName, adminUserVipLevel, } = team
    //   let name = '';
    //   if (this.header.isProVip) {
    //     name = '企业专业版';
    //   } else {
    //     // 判断过期状态
    //     // if (this.isExpireTeam(team)) {
    //     //     name = '免费版'
    //     //   }
    //     name = VIP_VERSION[vipLevel] || '个人版';
    //   }
    //   return name;
    // },
    getNavGroupTitle(title) {
      const { teamName, adminUserName }  = this.teamInfo
      let name = ''
      try {
        if (teamName && teamName.trim()) {
          name = decodeURIComponent(teamName.trim())
        } else if (adminUserName) {
          name = `${decodeURIComponent(adminUserName.trim())}的团队`
        }
      } catch (error) {
        if (teamName && teamName.trim()) {
          name = decodeURIComponent(teamName.replace(/%/g, '%25').trim())
        } else if (adminUserName) {
          name = `${decodeURIComponent(adminUserName.replace(/%/g, '%25').trim())}的团队`
        }
      }
      
      return title === '团队' ? name : title
    },
    handleNavScroll(e) {
      const { scrollHeight, clientHeight, scrollTop } = e.target
      if (scrollTop + clientHeight >= (scrollHeight - 1)) {
        e.target.scrollTop = scrollHeight - clientHeight - 1
      } else if (scrollTop <=0) {
        e.target.scrollTop = 0
      }
    },
    handleNavWheel(e) {
      const { delta, deltaY } = e
      const computedDelta = deltaY !== undefined ? -deltaY : delta < 0 ? -20 : 20
      this.navAslideDom.scrollTop -= computedDelta
    },
    getTeamName(item,isSubstr) {
      let name = '';
      let { teamName, adminUserName,teamRoleEnum } = item;
      try {
        if (teamName && teamName.trim()) {
          name = decodeURIComponent(teamName.trim());
        }else if (adminUserName) {
          name = `${decodeURIComponent(adminUserName.trim())}的团队`;
        }
        //用户未创建团队且未加入其他团队
        if(this.canCreateFreeTeam){
          if(teamRoleEnum=='Administrator'){
            name = decodeURIComponent(adminUserName.trim());
          }
        }
      } catch (error) {
        if (teamName && teamName.trim()) {
          name = decodeURIComponent(teamName.replace(/%/g, '%25').trim());
        }else if (adminUserName) {
          name = `${decodeURIComponent(adminUserName.replace(/%/g, '%25').trim())}的团队`;
        }
        //用户未创建团队且未加入其他团队
        if(this.canCreateFreeTeam){
          if(teamRoleEnum=='Administrator'){
            name = decodeURIComponent(adminUserName.replace(/%/g, '%25').trim());
          }
        }
      }
      if(isSubstr){
        name= name.substr(0, 1)
      } 
      return name;
    },
    createTeam () {
      this.showTeamBar =false
      this.$emit('createModal')
    },
     // 判断是否过期团队
     isExpireTeam(teamInfo) {
      return teamInfo.adminUserMemberExpire - Date.now() <= 0;
    },
    //开通会员
    goVip(){
      this.app.showrightsAndBuyPop({ index: 0, isBuy: true},'buy')
    },
    //切换团队
    changeTeam(item){
      if (this.teamInfo.teamId === item.teamId) return;
      const data = {
        teamId: item.teamId,
        gw:true
      };
      this.$http.post('/team/fuse/team/update/activeTeam', data).then((res) => {
        // -1参数错误;-2未登录;0操作错误;1操作成功
        const code = res.body.code;
        if (code === 200) {
          this.showTeamBar =false
          this.teamInfo.teamId = item.teamId;
          sessionStorage.setItem('toggleTeam', item.teamId);
          this.$emit('getUserInfo');
          this.$emit('setTeamId', item.teamId);
          localStorage.removeItem(`isWxworkLogin_${this.userInfo.userId}`);
        } else if(code===10002) {
          this.setLogregShow(1)
        } else {
          this.$message(`团队切换失败，错误码：${code}`);
        }
      });
    }
  },
}
</script>

<style lang="less" scoped>
@import 'changeTeam.less';
</style>
