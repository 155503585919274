<template></template>
<style></style>
<script>
  import axios from 'axios'

  export default {
    data () {
      return {
        token: "",
      }
    },
    props: {
      id: {
        type: Number,
        default: 0
      },
      file: null,
      isUploadStart: { // 是否开始上传
        type: Boolean,
        default: false
      },
      isTransformStart: { // 是否开始解析
        type: Boolean,
        default: false
      },
      stopTransform: { // 是否停止解析
        type: Boolean,
        default: false
      },
      parserConfig: {
        type: Object,
        default: function () {
          return {
            splitLayer: true, // 是否分隔图层
            editableText: true, // 是否字体可编辑
          }
        }
      },
      type: {
        type: Number,
        defaule: 0, // 0 我的设计  1 团队模板
      },
      fid:{ // 文件夹 ID
        default: 0,
        type: Number
      },
    },
    watch: {
      isUploadStart(value) {
        if(value) {
          this.uploadPSD();
        }
      },
      isTransformStart(value) {
        if(value) {
          this.isTransformFinish()
        }
      }
    },
    mounted() {
      if (this.isUploadStart) {
        this.uploadPSD();
      }
    },
    methods: {
      uploadStart() {
        this.$emit('upload-start', this.id)
      },
      uploadSuccess() {
        this.$emit('upload-success', {id: this.id})
      },
      uploadFail() {
        this.$emit('upload-fail', this.id)
      },
      progress(e) {
        this.$emit('change-progress', {id: this.id, progress: Math.round((e.loaded * 100) / e.total)})
        if (isNaN(Math.round((e.loaded * 100) / e.total))) {
          this.uploadFail()
        }
      },
      transformSuccess() {
        this.$emit('transform-success', this.id)
      },
      transformFail() {
        this.$emit('transform-fail', this.id)
      },
      // 预上传
      async uploadPSD (file = this.file) {
        const {
          splitLayer,
          editableText,
        } = this.parserConfig || {}
        let fd = {
          split_layer: Number(splitLayer),
          text_edit: Number(editableText),
          in_team: this.type,
          fid: this.fid,
          apidomainv2: true
        }
        this.uploadStart();
        try {
          let {body: {code, data: {token, uploadPms} = {}}} = await this.$http.post('/team/enterprise/template/preUploadPsd', fd)
          if (code == 200) {
            this.uploading(uploadPms, file)
            this.token = token
            return;
          }
          if (code == -110) {
            this.$message('权限不足，无法上传')
            this.uploadFail()
            await sleep(2000)
            window.location.replace('/designtools/team')
            return;
          }
          if (+code === -102) {
            this.$emit('login-fail')
            return;
          }
        } catch {
          this.uploadFail()
        }
      },   
      // 上传
      async uploading (uploadPms, file) {
        let fd = new FormData
        Object.keys(uploadPms.paramMap).forEach((key) => {
          fd.append(key, uploadPms.paramMap[key])
        })
        fd.append('file', file)
        try {
          await this.$http.post(uploadPms.url.replace(/^(https|http)/, 'ul'), fd, {
              onUploadProgress: this.progress
            })
          this.reportUpload()
        } catch (err) {
          this.uploadFail()
        }
      },

      // 上报上传结果
      async reportUpload () {
        try {
          let {body: {code}} = await this.$http.post('/team/enterprise/template/reportUploadPsdState', {token: this.token, gw: true})
          // 获取psd文件的转换信息
          if (code === 200) {
            this.uploadSuccess()
            return;
          }
          this.uploadFail();
        } catch {
          this.uploadFail();
        }
      },
      
      // 获取解析状态
      async isTransformFinish () {
        await sleep(1000)
        try {
          let {body: {code, data}} = await this.$http.post('/team/enterprise/template/getPsdAnalysisState', {token: this.token, gw: true})
          if (code !== 200 ) {
            this.transformFail();
            return;
          }
          if (data === 2) {
            this.transformSuccess()
            return;
          }
          if (data === 1 || data === 0) {
            if (this.stopTransform) {
              return;
            }
            this.isTransformFinish()
            return;
          }
          this.transformFail();
        } catch (err) {
          this.transformFail();
        }
      },
    },
  }

  function sleep (delay = 0) {
    return new Promise(resolve => setTimeout(resolve, delay))
  }

</script>
