<template>
  <div
    class="pop-right-dialog-content"
    :class="{'close': isClose, 'show': isShow}"
    :style="messageList.length==0?'overflow-y: inherit;':'overflow-y: scroll;'"
    v-click-outside="closeModal"
  >
    <div class="popHead">
      <ul class="tabList">
        <li class="tabItem" :class="item.id === avtiveId? 'tabItem activeItem':'tabItem'" v-for="item in messageTabList" :key="item.id" @click="hanledItem(item.id)">
          {{ item.text }}
          <span class="active_bar" v-if="item.id === avtiveId"></span>
        </li>
      </ul>
     <div class="readAll" @click="setEnterpriseNoticeRead(null)" v-if='unReadCount>0'>全部已读</div>
    </div>
    <div class="tab_readCon" v-if="totalCount>0">
      <div :class="readState==1?'read_active':''" @click="tabChange(1)">未读({{ unReadCount }})</div>
      <div :class="readState==0?'read_active':''" @click="tabChange(0)">全部({{totalCount}})</div>
    </div>
    <ul @scroll="onScroll($event)" class="meassage_list" v-if='(unReadCount>0&&readState==1)||(totalCount>0&&readState==0)'>
      <li v-for="item in messageList" :key="item.noticeId">
        <div class="messageCenterPop-list">
          <p class="list-head"  @click="messageClicked(item.noticeId)">
            <span class="no-read" v-if="item.hasRead === 0"></span>
            <span class="list-head-title">
              {{ item.subject || item.title }}
            </span>
          </p>
          <span class="list-date">{{ getNoticeSendTime(item) }}</span>
          <div
            class="list-main"
            @click="messageClicked(item.noticeId, $event)"
            v-html="item.content"
          ></div>
        </div>
      </li>
    </ul>
    <div v-if="messageList.length==0" class="nomessageCon">
      <img :src="require('@/base/empty-glass.png')" height="160"/>
      <p>暂无消息通知</p>
    </div>
    <div class="mes_close" @click="closeModal">
      <img src="../img/message_close.svg"/>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'MessageCenterPop',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean
    },
    isProVip: {
      type: Boolean,
      default: false
    },
    messageCountInfo: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
  },
  data() {
    return {
      messageList: [],
      messageTabList:[{id:0,text:'全部消息'},{id:2,text:'团队消息'},{id:1,text:'系统消息'}],
      showList: {
        title: '',
        date: '',
        main: ''
      },
      pageNum: 1,
      avtiveId:0,
      isClose: false,
      showTime: 0,
      totalCount:0,
      unReadCount:0,
      readState:1,// 0 全部 1未读 2 已读
    }
  },
  computed:{
    ...mapGetters({
      teamId: 'getTeamId',
    }),
  },
  watch: {
    pageNum (v) {
      if(v===1||this.messageList.length>=this.totalCount) return
        this.getNoticeMethod()
    },
    isShow (v) {
      if (v) {
        this.showTime = Date.now()
      }
    },
    isLogin (v) {
      if (v) {
        this.getNoticeMethod()
      }
    },
    isProVip() {
      this.getNoticeMethod()
    },
    teamId(v){
      if(v){
        this.avtiveId = 0
      }
    },
    messageCountInfo:{
      handler(v){
        this.totalCount = v.kindTotalCount
        this.unReadCount = v.kindUnReadCount
      },
      immediate:true
    }
  },
  beforeMount () {
    this.getNoticeMethod()
  },
  methods: {
    onScroll(event) {
      var offsetHeight = event.currentTarget.offsetHeight,
          scrollHeight = event.target.scrollHeight,
          scrollTop = event.target.scrollTop,
          scrollBottom = offsetHeight + scrollTop
      if (scrollBottom === scrollHeight || scrollBottom === scrollHeight + 2) {
        this.pageNum++
      }
    },
    getNoticeMethod() {
      if (!this.isLogin) return
      this.getUserNotice()
    },
    hanledItem(itemId){
      this.avtiveId =itemId
      this.readState = 1
      this.getUserNotice()
    },
    tabChange(readState){
      this.readState = readState
      this.getUserNotice()
    },
    // 获取主站用户消息列表
    getUserNotice() {
      this.$http.get('/mbox/notice/getNoticeList', {params: {readState: this.readState, pageNo: this.pageNum,noticeKind: this.avtiveId }, data: { gw: true, template: true, nottrans: true },}).then(res => {
        // if (res.body.code) return  // 无通知记录(code与noticeList不共存)
        if (res.body.code == -401) {
          this.$message('请登录')
          this.$emit('set-logreg-show', 1)
          return
        }
        const nList = res.body.data&&res.body.data.list
        let arr = []
        for (let i in nList) {
          if (nList[i].noticeType === 0) {
            arr.push(nList[i])
          }
          if (nList[i].noticeType === 3  || nList[i].noticeType === 4) {
            let message = nList[i]
            if (message.templateNotice) {
              let templateNotice = message.templateNotice
              if (templateNotice.mainTitle) {
                message.subject = this.getPlainText(templateNotice.mainTitle)
              }
              message.content = `${this.getPlainText(templateNotice.subTitle)}` || ''
              if (templateNotice.content) {
                message.content += `${this.getPlainText(templateNotice.content)}`
              }
              if (templateNotice.button) {
                message.content += `<a href="${templateNotice.button.url ? templateNotice.button.url : `/messagecenter?id=${message.noticeId}`}" target="_blank">${ templateNotice.button.text ? templateNotice.button.text : '点击查看'}</a>`
              }
            }
            arr.push(message)
          }
        }
        this.messageList = arr
        this.$emit('getNoticeCount',this.avtiveId)
      }, res => {
        console.log('error')
      })
    },
    // 获取专业版用户消息列表
    async getEnterpriseNotice() {
      const params = {
        page_no: this.pageNum,
        page_size: 10,
        gw: true
      }
      try {
        const res = await this.$http.post('/team/enterprise/notice/getEnterpriseNotice', params) || {}
        if (res.body.code === 200) {
          const { recordData } = res.body.data
          recordData.map((item) => {
            item.noticeId = item.id
            item.hasRead = item.readState
          })
          if (this.pageNum > 1) {
            this.messageList = [...this.messageList, ...recordData]
          } else {
            this.messageList = recordData
          }
        } else {
          this.messageList = []
          this.$message('获取消息列表失败')
        }
      } catch (error) {
        this.messageList = []
        this.$message('获取消息列表失败')
      }
    },
    // 获取消息的发送时间
    getNoticeSendTime(notice) {
      const time = notice.sendTime
      return dayjs(time).format('YYYY-MM-DD')
    },
    // 点击消息
    messageClicked(noticeId='', event) {
      this.setEnterpriseNoticeRead(noticeId);
      if (event && event.target.tagName === 'A') return
      window.open(`/messagecenter?id=${noticeId}`)
    },
    getPlainText(str) {
      if(str){
        return str.replace(/<[^>]*>|/g, "")
      }else{
        return str
      }
    },
    closeModal () {
      // 点击空白处关闭：防止在外部打开后立即关闭
      if (Date.now() - this.showTime < 100 || !this.isShow) return

      this.isClose = true
      this.$emit('close')
      //  动画执行完之后清除isClose的值
      setTimeout(() => {
        this.isClose = false
      }, 600);
    },
    // 企业消息读取状态更改
    async setEnterpriseNoticeRead(noticeId) {
      // if (!this.isProVip) return;
      const params = { 
        noticeId: noticeId,
        gw: true
      }
      const res = await this.$http.post('/mbox/notice/setNoticeAsHasRead', params) || {}
      if (res.body.code !== 200) {
        console.log('setEnterpriseNoticeRead',res.body.code)
        return
      }
      // 重新获取消息,防止企业消息红点不刷新
      this.getUserNotice()
      this.$emit('setReadSuccess')
    },
    // //获取用户消息数量
    // async getUserNoticeCount(){
    //   const params = { 
    //     gw: true,
    //     noticeKind: this.avtiveId
    //   }
    //   const res = await this.$http.post('/mbox/notice/getUserNoticeCount', params) || {}
    //   if (res.body.code == 200) {
    //     this.totalCount = res.body.data.kindTotalCount
    //     this.unReadCount = res.body.data.kindUnReadCount
    //   }
    // }
  }
}
</script>

<style lang="less">
.messageCenterPop-list .list-main {
  * {
    box-sizing: border-box;
    font-size: 12px !important;
  }
  a {
    color: #0773fc!important;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

<style lang="less" scoped>
  @import "messageCenterPop.less";
</style>
