import Vue from 'vue';
import Router from 'vue-router';
import index from '../pages/homepage/homepage.vue';

Vue.use(Router);

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition;
  } else {
    const position = {};
    // new navigation.
    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash;
    }
    // check if any matched route config has meta that requires scrolling to top
    if (to.matched.some((m) => m.meta.scrollToTop)) {
      // cords will be used if no selector is provided,
      // or if the selector didn't match any element.
      position.x = 0;
      position.y = 0;
    }
    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    return position;
  }
};
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: index, // 首页访问率高，不做懒加载
    },
    {
      path: '/index.html',
      redirect: '/',
    },
    {
      path: '/index',
      redirect: '/',
    },
    {
      path: '/newcustomera',
      redirect: '/', // 兼容
    },
    {
      path: '/startdesign',
      redirect: '/',
    },
    {
      path: '/list',
      component: () =>
        import(
          /* webpackChunkName: "list" */ '../pages/list/list.vue' // 订单中心
        ),
      children: [
        {
          path: '/list',
          redirect: '/list/vip',
        },
        {
          path: '/list/print',
          component: () =>
            import(
              /* webpackChunkName: "orderManage" */ '../pages/order/orderManage.vue'
            ),
        },
        {
          path: '/list/vm-amount-io',
          component: () =>
            import(
              /* webpackChunkName: "vmAmountIO" */ '../pages/list/vmAmountIO.vue' // 订单中心
            ),
        },
        {
          path: '/list/vip',
          component: () =>
            import(
              /* webpackChunkName: "vipOrder" */ '../pages/list/vipOrder.vue' // 订单中心
            ),
        },
        {
          path: '/list/seat',
          component: () =>
            import(
              /* webpackChunkName: "vipSeatOrder" */ '../pages/list/vipSeatOrder.vue' // 订单中心
            ),
        },
        {
          path: '/list/transfer',
          component: () =>
            import(
              /* webpackChunkName: "transferOrder" */ '../pages/list/transferOrder.vue' // 订单中心
            ),
        },
        {
          path: '/list/invoice',
          component: () =>
            import(
              /* webpackChunkName: "applyInvoice" */ '../pages/list/applyInvoice.vue' // 订单中心
            ),
        },
        {
          path: '/list/invoicehistory',
          component: () =>
            import(
              /* webpackChunkName: "invoiceHistory" */ '../pages/list/invoiceHistory.vue' // 订单中心
            ),
        },
      ],
    },
    {
      path: '/promotion',
      component: () =>
        import(
          /* webpackChunkName: "priceCompany" */ '../pages/promotion/index.vue' // 推广页页
        ),
    },
    // {
    //   path: '/invitation',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "priceCompany" */ '../pages/invitation/index.vue'  // 推广页页
    //       )
    // },
    // {
    //   path: '/invatationactive',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "priceCompany" */ '../pages/invatationActive/index.vue'  // 推广页页
    //       )
    // },
    {
      path: '/soogif',
      component: () =>
        import(
          /* webpackChunkName: "soogif" */ '../pages/soogif/soogif.vue' // gif 图合成
        ),
    },
    {
      path: '/enterprise',
      component: () =>
        import(
          /* webpackChunkName: "enterprise" */ '../pages/price/enterprise.vue' // 会员介绍页
        ),
    },
    {
      path: '/price',
      redirect: '/price/vip',
    },
    {
      path: '/price/vip',
      component: () =>
        import(
          /* webpackChunkName: "priceVip" */ '../pages/price/vip.vue' // 会员介绍页
        )
        /*
        props: (route) => ({
          showReCharge: route.query.recharge === 'true'
        })
        */
    },
    {
      path: '/price/recommend_landing',
      component: () =>
        import(
          /* webpackChunkName: "recommendLanding" */ '../pages/price/recommend_landing.vue' // 推荐落地页
        )
    },
    {
      path: '/price/vip_new',
      component: () =>
        import(
          /* webpackChunkName: "priceVip" */ '../pages/price/vip_new.vue' // 会员介绍页
        ),
        props: (route) => ({
          showReCharge: route.query.recharge === 'true'
        })
    },
    {
      path: '/price/company',
      redirect: '/price/vip',
      // component: () =>
      //   import(
      //     /* webpackChunkName: "priceCompany" */ '../pages/price/price_company.vue'  // 会员介绍页
      //   )
    },
    {
      path: '/price/personal',
      redirect: '/price/vip',
      // component: () =>
      //   import(/* webpackChunkName: "pricePersonal" */ '../pages/price/price_personal.vue') // 会员介绍页
    },
    {
      path: '/price/companypro',
      component: () =>
        import(
          /* webpackChunkName: "priceCompanyPro" */ '../pages/price/price_companyPro.vue' // 大企业会员介绍页
        ),
    },
    {
      path: '/vipsuccess',
      component: () =>
        import(
          /* webpackChunkName: "vipSuccess" */ '../pages/price/vipSuccess.vue' // 支付成功页
        ),
    },
    {
      path: '/memberactivity',
      component: () =>
        import(
          /* webpackChunkName: "memberActivity" */ '../pages/memberActivity/memberActivity.vue' // 会员口令活动页
        ),
    },
    {
      path: '/signactivity',
      component: () =>
        import(
          /* webpackChunkName: "signActivity" */ '../pages/signActivity/signActivity.vue' // 签到活动页
        ),
    },
    {
      path: '/ordermanage',
      redirect: '/list/print',
    },
    {
      path: '/repay',
      component: () =>
        import(
          /* webpackChunkName :"repay"*/ '../pages/order/repay.vue' // 订单重新支付页
        ),
    },
    {
      path: '/accountcenter',
      component: () =>
        import(
          /* webpackChunkName: "accountCenter" */ '../pages/accountCenter/accountCenter.vue' // 账号中心
        ),
    },
    {
      path: '/validate',
      component: () =>
        import(
          /* webpackChunkName: "safetyValidate" */ '../pages/accountCenter/safetyValidate/safetyValidate.vue' // 身份验证
        ),
    },
    {
      path: '/userquan',
      component: () =>
        import(
          /* webpackChunkName: "userQuan" */ '../pages/accountCenter/userQuan/userQuan.vue' // 优惠券
        ),
    },
    {
      path: '/bindmail',
      component: () =>
        import(
          /* webpackChunkName: "bindMail" */ '../pages/accountCenter/bindMail/bindMail.vue' // 绑定邮箱
        ),
    },
    {
      path: '/bindmailsuccess',
      component: () =>
        import(
          /* webpackChunkName: "bindMailSuccess" */ '../pages/callBack/bindMailSuccess/bindMailSuccess.vue' // 激活邮箱成功or失败页
        ),
    },
    {
      path: '/bindphone',
      component: () =>
        import(
          /* webpackChunkName: "bindPhone" */ '../pages/accountCenter/bindPhone/bindPhone.vue' // 绑定手机
        ),
    },
    {
      path: '/designerInfo',
      component: () =>
        import(
          /* webpackChunkName: "designerInfo" */ '../pages/accountCenter/designerInfo/designerInfo.vue' // 设计师主页
        ),
    },
    {
      path: '/changepw',
      component: () =>
        import(
          /* webpackChunkName: "changePW" */ '../pages/accountCenter/changePW/changePW.vue' // 修改密码
        ),
    },
    {
      path: '/invitefriend',
      component: () =>
        import(
          /* webpackChunkName: "inviteFriend" */ '../pages/accountCenter/inviteFriend/inviteFriend.vue' // 邀请好友页面
        ),
    },
    {
      path: '/unsubscribe',
      component: () =>
        import(
          /* webpackChunkName: 'unSubscribe' */ '../pages/accountCenter/unSubscribe/unSubscribe.vue' // 注销账号页面
        ),
    },
    {
      path: '/vipdetail',
      redirect: '/list/vip',
    },
    {
      path: '/landingpagefordingding',
      component: () =>
        import(
          /* webpackChunkName: "forDingDing" */ '../pages/landingPage/forDingDing/forDingDing.vue' // 客户端落地页  钉钉
        ),
    },
    {
      path: '/landingpageforweixin',
      component: () =>
        import(
          /* webpackChunkName: "forWeixin" */ '../pages/landingPage/forWeixin/forWeixin.vue' // 客户端落地页  微信
        ),
    },
    {
      path: '/picture',
      component: () =>
        import(
          /* webpackChunkName: "forPictureEditor" */ '../pages/landingPage/forPictureEditor/forPictureEditor.vue' // 客户端落地页  图片编辑器
        ),
    },
    {
      path: '/templatebatch',
      component: () =>
        import(
          /* webpackChunkName: "forPictureEditor" */ '../pages/landingPage/forBatchTemplate/index.vue' // 客户端落地页  批量模板设计落地页
        ),
    },
    {
      path: '/eliminate',
      component: () =>
        import(
          /* webpackChunkName: "forPictureEliminate" */ '../pages/landingPage/forPictureEliminate/index.vue' // 客户端落地页 图片消除
        ),
    },
    {
      path: '/intelligentfilm/',
      component: () =>
        import(
          /* webpackChunkName: "intelligentFilm" */ '../pages/intelligentFilm/intelligentFilm.vue' // 客户端落地页  智能成片
        ),
    },
    {
      path: '/intelligentfilm/design',
      component: () =>
        import(
          /* webpackChunkName: "intelligentFilm" */ '../pages/intelligentFilm/design.vue' // 智能成片 编辑页面
        ),
    },
    {
      path: '/intelligentfilm/video',
      component: () =>
        import(
          /* webpackChunkName: "intelligentFilm" */ '../pages/intelligentFilm/videoList.vue' // 智能成片 项目视频
        ),
    },
    {
      path:'/enterpriseSaasLandPage',
      component: () =>
        import(
          /* webpackChunkName: 'enterpriseSaasLandPage'  */ '../pages/landingPage/enterpriseSaasLandPage/index.vue'
          ),
    },
    {
      path:'/flagshipLandPage',
      component: () =>
        import(
          /* webpackChunkName: 'flagshipLandPage'  */ '../pages/landingPage/flagshipLandPage/index.vue'
          ),
    },
    {
      path: '/landingpage', // 客户端落地页
      component: () =>
        import(
          /* webpackChunkName: "landingPage" */ '../pages/landingPageNew/landingPage.vue'
        ),
      children: [
        {
          path: '/landingpage/miniprogram',
          component: () =>
            import(
              /* webpackChunkName: "miniProgram" */ '../pages/landingPageNew/miniProgram/miniProgram.vue'
            ),
        },
        {
          path: '/landingpage/pc',
          component: () =>
            import(
              /* webpackChunkName: "pc" */ '../pages/landingPageNew/pc/pc.vue'
            ),
        },
        {
          path: '/landingpage/app',
          component: () =>
            import(
              /* webpackChunkName: "app2" */ '../pages/landingPageNew/app2/app2.vue'
            ),
        },
      ],
    },
    {
      path: '/clientpage', // 客户端
      component: () =>
        import(
          /* webpackChunkName: "clientpage" */ '../pages/clientPage/clientPage.vue'
        ),
    },
    {
      path: '/logreg',
      component: () =>
        import(
          /* webpackChunkName: "logreg" */ '../components/logreg/logreg.vue' // 登录注册页
        ),
      props: (route) => {
        return {
          redirect: route.query.redirect,
        };
      },
      beforeEnter(to, from, next) {
        if (!to.query.redirect) {
          to.query.redirect = from.path;
          next(to);
        } else {
          next();
        }
      },
    },
    {
      path: '/resetpwdbyemail',
      component: () =>
        import(
          /* webpackChunkName: "resetPwdByEmail" */ '../components/logreg/resetPwdByEmail.vue' // 邮箱验证方式重设密码
        ),
    },
    {
      path: '/messagecenter',
      component: () =>
        import(
          /* webpackChunkName: "messageCenter" */ '../pages/messageCenter/messageCenter.vue' // 消息中心
        ),
    },
    {
      path: '/help',
      component: () =>
        import(/* webpackChunkName: "help" */ '../pages/aboutUs/help.vue'),
      children: [
        {
          path: '/help/aboutus',
          component: () =>
            import(
              /* webpackChunkName: "aboutUs" */ '../pages/aboutUs/aboutus/aboutus.vue'
            ),
        },
        {
          path: '/help/useragreement',
          component: () =>
            import(
              /* webpackChunkName: "serviceProvision" */ '../pages/aboutUs/useragreement/useragreement.vue'
            ),
        },
        {
          path: '/help/serviceprovision',
          component: () =>
            import(
              /* webpackChunkName: "serviceProvision" */ '../pages/aboutUs/serviceprovision/serviceprovision.vue'
            ),
        },
        {
          path: '/help/privacypolicy',
          component: () =>
            import(
              /* webpackChunkName: "privacyPolicy" */ '../pages/aboutUs/privacypolicy/privacypolicy.vue'
            ),
        },
        {
          path: '/help/disclaimerofliability',
          component: () =>
            import(
              /* webpackChunkName: "disclaimerOfLiability" */ '../pages/aboutUs/disclaimerofliability/disclaimerofliability.vue'
            ),
        },
        {
          path: '/help/termsofuse',
          component: () =>
            import(
              /* webpackChunkName: "termsOfUse" */ '../pages/aboutUs/termsofuse/termsofuse.vue'
            ),
        },
        {
          path: '/help/joinus',
          component: () =>
            import(
              /* webpackChunkName: "joinUs" */ '../pages/aboutUs/joinus/joinus.vue'
            ),
        },
        {
          path: '/help/friendshiplink',
          component: () =>
            import(
              /* webpackChunkName: "friendshipLink" */ '../pages/aboutUs/friendshiplink/friendshiplink.vue'
            ),
        },
        {
          path: '/help/contactus',
          component: () =>
            import(
              /* webpackChunkName: "contactUs" */ '../pages/aboutUs/contactus/contactus.vue'
            ),
        },
      ],
    },
    {
      path: '/usetutorial',
      component: () =>
        import(
          /* webpackChunkName: "useTutorial" */ '../pages/useTutorial/useTutorial.vue' // 使用帮助
        ),
    },
    {
      path: '/customdesign',
      component: () =>
        import(
          /* webpackChunkName: "customDesign" */ '../pages/customDesign/customDesign.vue' // 定制设计
        ),
    },
    {
      path: '/designerecruit',
      component: () =>
        import(
          /* webpackChunkName: "designerRecruit" */ '../pages/designerRecruit/designerRecruit.vue' // 设计师招募
        ),
    },
    {
      path: '/payback',
      component: () =>
        import(
          /* webpackChunkName: "payBack" */ '../pages/paycallback/payBack.vue' // 充值成功页
        ),
      children: [
        {
          path: '/payback/template',
          component: () =>
            import(
              /* webpackChunkName: "payBackTemp" */ '../pages/paycallback/comp/payBackTemp.vue' // 购买模板成功页
            ),
        },
        {
          path: '/payback/print',
          component: () =>
            import(
              /* webpackChunkName: "payBackPrint" */ '../pages/paycallback/comp/payBackPrint.vue' // 下单成功页
            ),
        },
      ],
    },
    {
      path: '/activecenter',
      component: () =>
        import(
          /* webpackChunkName: "activeCenter" */ '../pages/activecenter/activeCenter.vue' // 活动中心
        ),
      children: [
        {
          path: '/activecenter',
          component: () =>
            import(
              /* webpackChunkName: "activeCenterList" */ '../pages/activecenter/comp/list.vue'
            ),
        },
        {
          path: '/activecenter/list',
          component: () =>
            import(
              /* webpackChunkName: "activeCenterList" */ '../pages/activecenter/comp/list.vue'
            ),
        },
        {
          path: '/activecenter/active',
          component: () =>
            import(
              /* webpackChunkName: "activeCenterActive" */ '../pages/activecenter/comp/active.vue'
            ),
        },
        {
          path: '/activecenter/share',
          component: () =>
            import(
              /* webpackChunkName: "activeCenterShare" */ '../pages/activecenter/comp/share.vue'
            ),
        },
      ],
    },
    {
      path: '/api-guide',
      component: () =>
        import(
          /* webpackChunkName: "apiGuide" */ '../pages/apiGuide/apiGuide.vue' // apiJSSDK文档
        ),
    },
    {
      path: '/apides',
      redirect:'/terms/introduce',
    },
    {
      path: '/terms/introduce',
      component: () =>
        import(
          /* webpackChunkName: "api" */ '../pages/openplatform/index.vue'
        ),
      meta: {title: '创客贴-开放平台'}
    },
    {
      path: '/event/recruit',
      redirect: '/designerecruit',
      component: () =>
        import(
          /* webpackChunkName: "recruit" */ '../pages/recruit/recruit.vue' //签约设计师
        ),
    },
    {
      path: '/userTemRequirement',
      component: () =>
        import(
          /* webpackChunkName: "userTemRequirement" */ '../pages/userTemRequirement/userTemRequirement.vue' //用户模板需求
        ),
    },
    {
      path: '/koutu',
      component: () =>
        import(
          // 抠图
          /* webpackChunkName: "koutu" */ '../pages/koutu/koutu.vue'
        ),
    },
    {
      path: '/customactivity',
      component: () =>
        import(
          // 定制活动
          /* webpackChunkName: "customActivity" */ '../pages/activities/customActivity/customActivity.vue'
        ),
    },
    {
      path: '/double11activity',
      component: () =>
        import(
          // 双11活动
          /* webpackChunkName: "double11Activity2020" */ '../pages/activities/double11Activity2020/index.vue'
        ),
    },
    {
      path: '/double11For2021',
      component: () =>
        import(
          // 双11活动
          /* webpackChunkName: "double11Activity2021" */ '../pages/activities/double11Activity2021/index.vue'
        ),
    },
    {
      path: '/auth_record',
      component: () =>
        import(
          //授权记录
          /* webpackChunkName: "authorizationRecord" */ '../pages/authorizationRecord/authorizationRecord.vue'
        ),
      children: [
        {
          path: '/auth_record/design',
          component: () =>
            import(
              /* webpackChunkName: "designRecord" */ '../pages/authorizationRecord/designRecord.vue'
            ),
        },
        {
          path: '/auth_record/vip',
          component: () =>
            import(
              /* webpackChunkName: "vipRecord" */ '../pages/authorizationRecord/vipRecord.vue'
            ),
        },
      ],
    },
    {
      path: '/auth_record/design_record',
      component: () =>
        import(
          //授权记录
          /* webpackChunkName: "authorizationRecord" */ '../pages/authorizationRecord/newAuthorizationRecord.vue'
        ),
    },
    {
      path: '/terms',
      // 协议/常见问题页面
      component: () =>
        import(/* webpackChunkName: "terms" */ '../pages/terms/terms.vue'),
      children: [
        {
          path: '/terms/vip',
          component: () =>
            // 企业会员促销活动 2019.6
            import(
              /* webpackChunkName: "vip_serveic" */ '../pages/terms/agreement_1_vip_service/vip_serveic.vue'
            ),
        },
        {
          path: '/terms/skuvip',
          component: () =>
            // 企业会员促销活动 2019.6
            import(
              /* webpackChunkName: "vip_serveic_sku" */ '../pages/terms/agreement_1_vip_service/vip_serveic_sku.vue'
            ),
        },
        {
          path: '/terms/vipauth',
          component: () =>
            // 企业会员促销活动 2019.6
            import(
              /* webpackChunkName: "vip_auth" */ '../pages/terms/agreement_1_vip_auth/index.vue'
            ),
        },
        {
          path: '/terms/faq-cc0',
          component: () =>
            import(
              /* webpackChunkName: "cc0_image" */ '../pages/terms/agreement_2_cc0_image/cc0_image.vue'
            ),
        },
        {
          path: '/terms/cc0',
          component: () =>
            import(
              /* webpackChunkName: "cc0" */ '../pages/terms/agreement_3_cc01.0_text/cc01.0_text.vue'
            ),
        },
        {
          path: '/terms/cr-auth',
          component: () =>
            import(
              /* webpackChunkName: "img_empower" */ '../pages/terms/agreement_4_img_empower/img_empower.vue'
            ),
        },
        {
          path: '/terms/faq',
          component: () =>
            import(
              /* webpackChunkName: "faq" */ '../pages/terms/agreement_5_problem/problem.vue'
            ),
        },
        {
          path: '/agreement/break-rule',
          component: () =>
            import(
              /* webpackChunkName: "faq" */ '../pages/terms/agreement_5_problem/break_rule.vue'
            ),
        },
        {
          path: '/terms/faq-invoice',
          component: () =>
            import(
              /* webpackChunkName: "faqInvoice" */ '../pages/terms/problem/problem_1_fapiao/problem_1_fapiao.vue'
            ),
        },
        {
          path: '/terms/faq-copyright',
          component: () =>
            import(
              /* webpackChunkName: "faqCopyright" */ '../pages/terms/problem/problem_2_copyright/problem_2_copyright.vue'
            ),
        },
        {
          path: '/terms/faq-auth',
          component: () =>
            import(
              /* webpackChunkName: "faqAuth" */ '../pages/terms/problem/problem_3_empower/problem_3_empower.vue'
            ),
        },
      ],
    },
    {
      path: '/activationcode',
      component: () =>
        import(
          /* webpackChunkName: 'activationCode'  */ '../pages/activationCode/activationCode.vue'
        ),
    },
    {
      path: '/incompatible',
      component: () =>
        import(
          /* webpackChunkName: 'incompatible'  */ '../pages/incompatible/incompatible.vue'
        ),
    },
    {
      path: '/double12',
      component: () =>
        import(
          /* webpackChunkName: 'double12'  */ '../pages/activities/double12/double12.vue'
        ),
    },
    {
      path: '/screen-marketing',
      component: () =>
        import(
          /* webpackChunkName: 'screenMarketing'  */ '../pages/screenMarketing/screenMarketing.vue'
        ),
    },
    {
      path: '/video-cooperation',
      component: () =>
        import(
          /* webpackChunkName: 'videoCooperation'  */ '../pages/videoCooperation/videoCooperation.vue'
        ),
    },
    {
      path: '/rebuy',
      component: () =>
        import(/* webpackChunkName: 'rebuy'  */ '../pages/rebuypage/index.vue'),
    },
    {
      path: '/7thAnniversary',
      component: () =>
        import(
          /* webpackChunkName: 'rebuy'  */ '../pages/activities/seventhAnniversary/index.vue'
        ),
    },
    {
      path: '/price/pay',
      redirect: ({ query }) => {
        let path = '/price/company';
        if (query.p === '4') {
          path = '/price/personal';
        }
        return path;
      },
    },
    {
      path:'/doubleDan',
      component: () =>
        import(
          /* webpackChunkName: 'rebuy'  */ '../pages/activities/doubleDan/index.vue'
        ),
    },
    {
      path:'/activity618',
      component: () =>
        import(
          /* webpackChunkName: 'rebuy'  */ '../pages/activities/activity618/index.vue'
        ),
    },
    {
      path: '/difference',
      component: () =>
        import(
          /* webpackChunkName: "difference" */ '../pages/price/difference.vue' // 补差价页面
        )
    },
    {
      path:'/newYear',
      component: () =>
        import(
          /* webpackChunkName: 'newYear'  */ '../pages/activities/newYear/index.vue'
        ),
    },
  ],
  scrollBehavior,
});

// 只要通过百度广告链接进入网站，站内路由跳转都会携带bd_vid参数，以供新开标签页使用
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title;
  }
  if (document.referrer || to.query.bd_vid) {
    next();
    return;
  }
  if (from.query.bd_vid) {
    let toQuery = JSON.parse(JSON.stringify(to.query));
    toQuery.bd_vid = from.query.bd_vid;
    next({
      path: to.path,
      query: toQuery,
    });
  } else {
    next();
  }
});

export default router;
