import * as types from '../mutation-types';
import {GET_CAPACITY_TIP_MODAL_VISIBLE} from "../mutation-types";

const state = {
  capacityTipModalVisible: false,
};

const getters = {
  getCapacityTipModalVisible: (state) => state.capacityTipModalVisible,
};

const mutations = {
  [types.GET_CAPACITY_TIP_MODAL_VISIBLE](state, v) {
    state.capacityTipModalVisible = v;
  },
};

const actions = {
  setCapacityTipModalVisible({ commit }, v) {
    commit(types.GET_CAPACITY_TIP_MODAL_VISIBLE, v);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
