var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"name":"modal1"}},[_c('lt-modal',{attrs:{"simple":"","footer-hide":true},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"preview-upload",style:({
          width: _vm.wrapWidth + 'px',
          height: _vm.wrapHeight + 'px',
        })},[_c('div',{class:`preview-img-wrap ${ _vm.fileInfo.base64 ? '' : 'animation' }`,style:({ width: _vm.wrapWidth - _vm.mainWidth - 80 + 'px' })},[(_vm.fileInfo.base64)?_c('div',{staticClass:"preview-img",style:({
              width: _vm.wrapWidth - _vm.mainWidth - 80 - 64 + 'px',
              backgroundImage: `url(${_vm.fileInfo.base64})`
            })}):_vm._e()]),_c('div',{staticClass:"upload",style:({ width: _vm.mainWidth + 'px', alignItems: _vm.status === 'uploadSuccess' ? 'flex-start' : 'center' })},[(_vm.status === 'uploading')?_c('div',{staticClass:"uploading"},[_c('div',{staticClass:"upload-title"},[_vm._v("正在上传你的图片 "),_c('span',[_vm._v(_vm._s(_vm.uploadProcess)+"%")])]),_c('div',{staticClass:"upload-process"},[_c('div',{staticClass:"upload-process-bar",style:({ width: _vm.uploadProcess + '%' })})]),_c('div',{staticClass:"upload-cancel",on:{"click":_vm.cancelUpload}},[_vm._v("取消上传")])]):_vm._e(),(_vm.status === 'uploadFail')?_c('div',{staticClass:"upload-fail"},[_c('div',{staticClass:"fail-msg"},[_c('img',{staticClass:"fail-msg-icon",attrs:{"src":require('./img/error.svg'),"alt":"错误"}}),_vm._v(" "+_vm._s(+_vm.errorCode === 87014 ? '图片违规' : '图片上传失败')+" ")]),_c('div',{staticClass:"fail-desc"},[_vm._v(_vm._s(_vm.errTip[_vm.errorCode]))]),([87014, 0, 100, 101].includes(+_vm.errorCode))?_c('div',{staticClass:"fail-btn",on:{"click":_vm.reUploadFile}},[_vm._v("重新上传")]):_vm._e(),(+_vm.errorCode === -431 && +_vm.teamInfo.adminUserVipLevel === 0)?_c('div',{staticClass:"fail-btn",on:{"click":_vm.buyNow}},[_vm._v("扩充存储权限")]):_vm._e()]):_vm._e(),(_vm.status === 'uploadSuccess')?_c('div',{staticClass:"upload-success"},[_c('div',{staticClass:"file-name"},[_vm._v(_vm._s(_vm.fileInfo.name))]),(_vm.fileInfo.rect)?_c('div',{staticClass:"file-size"},[_vm._v(" "+_vm._s(_vm.fileInfo.rect.w)+" * "+_vm._s(_vm.fileInfo.rect.h)+" px")]):_vm._e(),_c('div',{staticClass:"file-edit",on:{"click":_vm.openCreateImage}},[_vm._v("上传并编辑图片")]),_c('QuickScenes',{attrs:{"mid":_vm.mid,"teamId":_vm.teamId}},[_c('div',{staticClass:"file-design"},[_vm._v("在设计中使用")])])],1):_vm._e()])])])],1),_c('UploadImgFunc',{ref:"uploadImgFuncRef",on:{"upload-success":_vm.uploadSuccess,"upload-fail":_vm.uploadFail}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }