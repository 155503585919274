<template>
  <div class="loading-three-colomns-container" :style="styles">
    <span class="column"></span>
    <span class="column"></span>
    <span class="column"></span>
  </div>
</template>

<script>
  export default {
    name: 'loadingThreeColomns',
    props: {
      width: {
        type: [Number, String],
        default: 38
      }
    },
    computed: {
      styles () {
        let width = this.width.toString().replace('px', '')
        return {
          width: width + 'px',
          height: (0.632 * width) + 'px'
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .loading-three-colomns-container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    .column {
      width: 6px;
      border-radius: 3px;
      animation: stretch .7s cubic-bezier(.42, 0, .58, 1)  infinite;
      &:nth-child(1) {
        background-color: #0773FC;
        animation-delay: .1s;
      }
      &:nth-child(2) {
        background-color: #388EFC;
        animation-delay: .2s;
        margin: 0 25%;
      }
      &:nth-child(3) {
        animation-delay: .3s;
        background-color: #98C3FA;
      }
    }
  }

  @keyframes stretch {
    0% {
      height: 58.3%;
    }
    50% {
      height: 58.3%;
    }
    75% {
      height: 100%;
    }
    100% {
      height: 58.3%;
    }
  }
</style>
