import * as types from '../mutation-types'

const state = {
  shoppingData: [],
  iconList: [],
  shoppingCatAnimationFlag: false,
  carAllFlag: false,
}

const getters = {
  getShoppingData: state => state.shoppingData,
  getAddIconList: state => state.iconList,
  getShoppingCatAnimationFlag: state => state.shoppingCatAnimationFlag,
  getCarAllFlag: state => state.carAllFlag,
}

const mutations = {
  [types.SET_SHOPPINGCAT_DATE] (state, v) {
    state.shoppingData = v
  },
  [types.ADD_SHOPPING_DATA] (state, v) {
    state.shoppingData.unshift(v)
  },
  [types.ADD_ICONLIST] (state, v) {
    state.iconList.unshift(v)
  },
  [types.SET_ICONLIST] (state, v) {
    state.iconList = v
  },
  [types.SET_SHOPPINGANIMATIOIN] (state, v) {
    state.shoppingCatAnimationFlag = v
  },
  [types.SET_CARALLFLAG] (state, v) {
    state.carAllFlag = v
  },
}

const actions = {
  setShoppingData ({commit}, v) {
    commit(types.SET_SHOPPINGCAT_DATE, v)
  },
  addShoppingData ({commit}, v) {
    commit(types.ADD_SHOPPING_DATA, v)
  },
  setIconList ({commit}, v) {
    commit(types.SET_ICONLIST, v)
  },
  addIconList ({commit}, v) {
    commit(types.ADD_ICONLIST, v)
  },
  setShoppingCatAnimationFlag ({commit}, v) {
    commit(types.SET_SHOPPINGANIMATIOIN, v)
  },
  setCarAllFlag ({commit}, v) {
    commit(types.SET_CARALLFLAG, v)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}