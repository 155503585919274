<template>
  <div class="quick-wrapper" @mouseenter="showQuick" @mouseleave="hideQuick">
    <div>
      <slot></slot>
    </div>
    <div v-show="visible" class="quick">
      <div class="quick-main">
        <div class="quick-search">
         <img class="quick-search-icon" :src="require('./img/search.svg')" alt="搜索场景尺寸创建空白画布" />
         <input
           name="quick-search-input"
           class="quick-search-input"
           placeholder="你想创建哪种设计？"
           autocomplete="off"
           v-model="searchValue"
           type="text"
           :maxlength="32"
         />
         <div v-if="searchValue" class="quick-search-clear" @click="onClear" />
        </div>
        <template v-if="!showCustomSize">
         <ul class="quick-scenes" v-if="filterScenes.length">
           <li class="quick-scenes-item" @click="createCustomSize">
             <div class="quick-scenes-item-icon"/>
             <div class="quick-scenes-item-name">自定义大小</div>
             <img class="quick-scenes-item-custom" :src="require('./img/add.svg')" alt="自定义">
           </li>
           <li class="quick-scenes-item" v-for="(item, index) in filterScenes" :key="index" @click="goDesign(item)">
             <img class="quick-scenes-item-icon" :src="item.iconUrl || defaultImg"  @error="imgError" />
             <div class="quick-scenes-item-name">{{ item.title }}</div>
             <div class="quick-scenes-item-desc">{{ item.width }} * {{ item.height }}</div>
           </li>
         </ul>
         <div v-else class="quick-scenes-empty">
           <div class="quick-scenes-empty-tip" v-if="searchValue">
             没有关于“{{ searchValue.length > 8 ? searchValue.slice(0, 8) + '...' : searchValue }}”的搜索结果
           </div>
           <div class="quick-scenes-empty-link" @click="createCustomSize">
             创建自定义大小
           </div>
         </div>
        </template>
        <template v-else>
         <CustomSize type="large" @on-create="startDesign" />
         <template v-if="recommendedScenes.length">
           <div class="recommended-size">推荐尺寸</div>
           <ul class="quick-scenes" style="max-height: 180px">
             <li class="quick-scenes-item" v-for="(item, index) in recommendedScenes" :key="index" @click="goDesign(item)">
               <img class="quick-scenes-item-icon" :src="item.iconUrl || defaultImg" @error="imgError" />
               <div class="quick-scenes-item-name">{{ item.title }}</div>
               <div class="quick-scenes-item-desc-t">{{ item.width }} * {{ item.height }}</div>
             </li>
           </ul>
         </template>
       </template>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSize from "./custom-size.vue";
import mixin from "./mixin";
export default {
  name: "quickDesign",
  components: {
    CustomSize
  },
  mixins: [mixin],
  props: {
    mid: [String, Number],
    isLogin: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      name: 'imgHandle'
    }
  }
}
</script>

<style scoped lang="less">
.quick-wrapper {
  position: relative;
  user-select:none
}
.quick {
  position: absolute;
  top: 45px;
  left: 0;
  width: 360px;
  background-color: #fff;
  box-shadow: 0 6px 24px 4px rgba(27, 35, 55, 0.12);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .quick-main {
    flex: 1;
    padding: 0 0 10px;
    .quick-search {
      width: 100%;
      height: 40px;
      position: relative;
      border-bottom: 1px solid #EBEEF5;
      &-input {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        padding-left: 48px;
        padding-right: 38px;
        border: none;
        outline: none;
        color: #1B2337;
        &::placeholder {
          color: #8693AB;
        }
      }
      &-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
      &-clear {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        background: url("./img/clear.svg") no-repeat;
        &:hover {
          background: url("./img/clear-hover.svg") no-repeat;
        }
      }
    }
    .quick-scenes {
      max-height: 340px;
      overflow-y: auto;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &-item {
        cursor: pointer;
        padding: 0 24px;
        height: 40px;
        display: flex;
        align-items: center;
        &:hover {
          background: #F5F7FD;
          .quick-scenes-item-desc {
            display: block;
          }
        }
        &-icon {
          width: 24px;
          height: 24px;
          //background: #FEE9E9;
          margin-right: 8px;
        }
        &-name {
          flex: 1;
          font-weight: 400;
          font-size: 14px;
          color: #1B2337;
        }
        &-desc {
          display: none;
          font-weight: 400;
          font-size: 12px;
          color: #8693AB;
        }
        &-desc-t {
          font-weight: 400;
          font-size: 12px;
          color: #8693AB;
        }
        &-custom {
          width: 14px;
          height: 14px;
        }
      }
      &-empty {
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-tip {
          height: 22px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #505A71;
          margin-bottom: 15px;
        }
        &-link {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          border-bottom: 1px solid #0773FC;
          color: #0773FC;
          cursor: pointer;
        }
      }
    }
    .recommended-size {
      padding: 16px 24px 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #8693AB;
    }
  }
}
</style>