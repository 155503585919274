/**
 * Created by Chuanfeng on 2017/7/5.
 */

import * as types from '../mutation-types'

const state = {
  userInfo: {},
  isLogin: undefined,
  isVip: 1, // 1：普通用户； 2：会员用户
  teamId: 0, // 用户当前使用中的团队ID(0表示个人版;其他数值则表示团队ID)
  designTester: 0,
  teams: [],
  teamIsExpired: false,
  copyrightPrivilege: false,   //是否享受版权特权
  isPaidUser: false,
  designerType: 0, // 	设计师类型(1:api设计师；2:素材设计师；3模板设计师)
}

const getters = {
  getUserInfo: state => state.userInfo,
  getIsLogin: state => state.isLogin,
  getIsVip: state => state.isVip,
  getTeamId: state => state.teamId,
  getTeams: state => state.teams,
  getDesignTester: state => state.designTester,
  getTeamIsExpired: state => state.teamIsExpired,
  getCopyrightPrivilege: state => state.copyrightPrivilege,
  getIsPaidUser: state => state.isPaidUser,
  getDesignerType: state => state.designerType,
}

const actions = {
  setUserInfo({ commit }, userInfo) {
    commit(types.SET_USERINFO, userInfo)
  },
  setIsLogin({ commit }, isLogin) {
    commit(types.SET_ISLOGIN, isLogin)
    if (!isLogin) {
      commit(types.SET_USERINFO, null)
    }
  },
  setTeamId({ commit }, teamId) {
    commit(types.SET_TEAMID, teamId)
  },
  setDesignTester({ commit }, designTester) {
    commit('setDesignTester', designTester)
  },
  setTeams({ commit }, teams) {
    commit('setTeams', teams)
  },
  setTeamIsExpired({ commit }, teamIsExpired) {
    commit('setTeamIsExpired', teamIsExpired)
  },
  setCopyrightPrivilege({ commit }, copyrightPrivilege) {
    commit('setCopyrightPrivilege', copyrightPrivilege)
  },
  setIsPaidUser({ commit }, isPaidUser) {
    commit('setIsPaidUser', isPaidUser)
  },
  setDesignerType({ commit }, designerType) {
    commit('setDesignerType', designerType)
  },
}

const mutations = {
  [types.SET_USERINFO](state, userInfo) {
    if (userInfo === null) {
      state.userInfo = {}
      state.userInfo.LoginTimeOut = true
      state.isLogin = false
      state.isVip = 1
      state.teamId = 0
    } else {
      state.userInfo = userInfo
      state.isLogin = true
      state.isVip = userInfo.mem || userInfo.curTid > 0 ? 2 : 1
      state.teamId = userInfo.curTid
    }
  },
  [types.SET_ISLOGIN](state, isLogin) {
    state.isLogin = isLogin
  },
  [types.SET_TEAMID](state, teamId) {
    state.teamId = teamId
  },
  setDesignTester(state, designTester) {
    state.designTester = designTester
  },
  setTeams({ commit }, teams) {
    state.teams = teams
  },
  setTeamIsExpired({ commit }, teamIsExpired) {
    state.teamIsExpired = teamIsExpired
  },
  setCopyrightPrivilege({ commit }, copyrightPrivilege) {
    state.copyrightPrivilege = copyrightPrivilege
  },
  setIsPaidUser({ commit }, isPaidUser) {
    state.isPaidUser = isPaidUser
  },
  setDesignerType({ commit }, designerType) {
    state.designerType = designerType
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
