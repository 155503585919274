import * as types from '../mutation-types'

const state = {
  show: false
}

const getters = {
  getTeamExpiredPopShow: state => state.show
}

const mutations = {
  [types.SET_TEAM_EXPIRED_POP_SHOW](state, show) {
    state.show = !!show
  }
}

const actions = {
  setTeamExpiredPopShow({ commit }, show) {
    commit(types.SET_TEAM_EXPIRED_POP_SHOW, !!show)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
