/**
 * Created by Chuanfeng on 2018/3/1.
 */

import * as types from '../mutation-types'

const state = {
  tariffPackages: []
}

const getters = {
  getTariffPackages: state => state.tariffPackages
}

const mutations = {
  [types.SET_TARIFF_PACKAGES] (state, tariffPackages) {
    state.tariffPackages = tariffPackages
  }
}

const actions = {
  setTariffPackages ({commit}, tariffPackages) {
    commit(types.SET_TARIFF_PACKAGES, tariffPackages)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}