import { render, staticRenderFns } from "./taskCenter.vue?vue&type=template&id=bcdd787a&scoped=true&"
import script from "./taskCenter.vue?vue&type=script&lang=js&"
export * from "./taskCenter.vue?vue&type=script&lang=js&"
import style0 from "./taskCenter.vue?vue&type=style&index=0&id=bcdd787a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcdd787a",
  null
  
)

export default component.exports