<template v-if="modalShow">
  <LtModal
    v-model="modalShow"
    :z-index="1001"
    :simple="false"
    :footer-hide="true"
    class="prod-vip-box"
    title="更多功能权益"
    @on-visible-change="getModalVisible"
  >
    <div class="pro-vip-modal">
      <div class="text">
        当前功能为仅旗舰版用户可定制权益，可联系专属顾问咨询
      </div>
      <div class="qrcode_box">
        <img :src="activityPicUrl" alt="" class="qrcode" />
      </div>
      <p class="desc">扫码咨询顾问获得更多权益</p>
    </div>
  </LtModal>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'proVipModal',
    props: {
      vipContactSalesFrom: {
        type: Object,
        default: () => {},
      },
      proVipVisible: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters({
        isLogin: 'getIsLogin',
      }),
    },
    watch: {
      proVipVisible(v) {
        this.modalShow = v;
        if (v) {
          window.gio('track', 'vip_contact_sales', {
            forward_module_name: this.vipContactSalesFrom.forward_module_name,
            forward_page_name: this.vipContactSalesFrom.forward_page_name,
            popup_name: '联系销售弹框',
            is_login: this.isLogin ? '是' : '否',
          });
        }
      },
    },
    data() {
      return {
        modalShow: false,
        activityPicUrl: '',
      };
    },
    mounted() {
      this.modalShow = this.proVipVisible;
      this.getActiveBanner();
    },
    methods: {
      getModalVisible(state) {
        if (!state) {
          this.$emit('close');
        }
      },
      // 获取二维码
      async getActiveBanner() {
        try {
          const {
            body: { code, data },
          } = await this.$http.get('/hatchery/adposition', {
            data: {
              gw: true,
              requestMethod: 'GET',
              template: true,
              nottrans: true,
            },
            params: {
              platform: 0,
              alias: 'adv_web_enterprise_contact',
              gw: true,
            },
          });
          if (code === 200) {
            this.activityPicUrl = data && data[0].picUrl;
          }
        } catch (e) {
          console.error('error \n' + e);
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .prod-vip-box/deep/.lt-modal-header {
    border-bottom: none;
  }
  .prod-vip-box/deep/.lt-modal-body {
    padding-top: 4px;
  }
  .pro-vip-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text {
      color: #1b2337;
      font-size: 14px;
      line-height: 22px;
    }
    .qrcode_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 12px 0;
      width: 164px;
      height: 164px;
      border-radius: 4px;
      background: #f8f8fb;
    }
    .qrcode {
      width: 140px;
      height: 140px;
    }
    .desc {
      color: #8693ab;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }
  }
</style>
