export default {
  '/': '品牌首页',
  '/designtools/designindex': '推荐页',
  '/templatecenter': '模板中心页',
  '/packing/template': '包装模版页',
  '/packing/boxtype': '包装盒型页',
  '/designtools/solution': '模板中心页',
  '/solutiontopics': '专题页',
  '/designtools/startdesign': '创建设计页',
  '/designtools/self/design': '我的设计页',
  '/design': '设计页',
  '/koutu': '抠图编辑器',
  '/pic/image': '图片编辑编辑器',
  '/studio/vs-editor': '视频剪辑编辑器',
  '/team/template': '团队协作页',
  '/team/material': '团队协作页',
  '/team/brandManage': '团队协作页',
  '/team/member': '团队协作页',
  '/price/vip': '会员页',
  '/designerecruit': '设计师招募页',
  '/doubleDan': '双旦会员促销',
  '/activity/designcompetition': '虎年设计大赛',
  '/usetutorial': '教程页',
  '/image': '正版图库页',
  '/picture': '图片编辑介绍页',
  '/clientpage': '客户端下载页',
  '/print/customservice': '定制设计页',
  '/screen-marketing': '屏幕营销页',
  '/video-cooperation': '视频协作',
  '/print/printcenter': '印刷中心页',
  '/apides': '开放平台页'
}