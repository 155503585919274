export default [
  "高***山领取了新手礼包",
  "彭***里领取了新手礼包",
  "谢***海领取了新手礼包",
  "马***宇领取了新手礼包",
  "林***莽领取了新手礼包",
  "黄***辉领取了新手礼包",
  "章***夫领取了新手礼包",
  "范***江领取了新手礼包",
  "林***雄领取了新手礼包",
  "谭***山领取了新手礼包",
  "朱***亮领取了新手礼包",
  "李***光领取了新手礼包",
  "甘***生领取了新手礼包",
  "张***祖领取了新手礼包",
  "马***祖领取了新手礼包",
  "程***先领取了新手礼包",
  "宗***先领取了新手礼包",
  "年***嗣领取了新手礼包",
  "汤***箕领取了新手礼包",
  "吕***祖领取了新手礼包",
  "何***宗领取了新手礼包",
  "孙***祖领取了新手礼包",
  "马***国领取了新手礼包",
  "节***国领取了新手礼包",
  "冯***国领取了新手礼包",
  "爱***民领取了新手礼包",
  "于***忠领取了新手礼包",
  "马***良领取了新手礼包",
  "胡***善领取了新手礼包",
  "李***仁领取了新手礼包",
  "余***勤领取了新手礼包",
  "吴***俭领取了新手礼包",
  "杨***义领取了新手礼包",
  "李***信领取了新手礼包",
  "王***茂领取了新手礼包",
  "李***诚领取了新手礼包",
  "杨***勇领取了新手礼包",
  "高***德领取了新手礼包",
  "刁***贵领取了新手礼包",
  "汤***祖领取了新手礼包",
  "何***宗领取了新手礼包",
  "冷***友领取了新手礼包",
  "安***孙领取了新手礼包",
  "贾***善领取了新手礼包",
  "蔡***霖领取了新手礼包",
  "关***仁领取了新手礼包",
  "郑***义领取了新手礼包",
  "贾***怡领取了新手礼包",
  "孙***民领取了新手礼包",
  "赵***华领取了新手礼包",
  "赵***荣领取了新手礼包",
  "赵***茂领取了新手礼包",
  "钱***祥领取了新手礼包",
  "钱***高领取了新手礼包",
  "钱***禄领取了新手礼包",
  "孙***康领取了新手礼包",
  "孙***吉领取了新手礼包",
  "孙***达领取了新手礼包",
  "李***贵领取了新手礼包",
  "李***福领取了新手礼包",
  "王***久领取了新手礼包",
  "刘***生领取了新手礼包",
  "刘***瑞领取了新手礼包",
  "关***和领取了新手礼包",
  "王***兴领取了新手礼包",
  "李***泰领取了新手礼包",
  "罗***发领取了新手礼包",
  "刘***时领取了新手礼包",
  "刘***超领取了新手礼包",
  "刘***胜领取了新手礼包",
  "张***柱领取了新手礼包",
  "张***远领取了新手礼包",
  "张***才领取了新手礼包",
  "吕***榜领取了新手礼包",
  "吕***达领取了新手礼包",
  "吴***栋领取了新手礼包",
  "吴***梁领取了新手礼包",
  "吴***功领取了新手礼包",
  "李***江领取了新手礼包",
  "张***山领取了新手礼包",
  "云***可领取了新手礼包",
  "郎***剑领取了新手礼包",
  "余***茂领取了新手礼包",
  "凌***昼领取了新手礼包",
  "吕***冉领取了新手礼包",
  "涂***毅领取了新手礼包",
  "魏***博领取了新手礼包",
  "周***大领取了新手礼包",
  "凌***帆领取了新手礼包",
  "文***治领取了新手礼包",
  "柯***杜领取了新手礼包",
  "李***汉领取了新手礼包",
  "严***滕领取了新手礼包",
  "李***妙领取了新手礼包",
  "元***振领取了新手礼包",
  "杨***察领取了新手礼包",
  "连***淦领取了新手礼包",
  "黎***旺领取了新手礼包",
  "张***焕领取了新手礼包",
  "卢***新领取了新手礼包",
];
