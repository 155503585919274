/**
 * Created by Chuanfeng on 2017/12/5.
 */

import * as types from '../mutation-types'

const state = {
  show: false
}

const getters = {
  getRechargeShow: state => state.show
}

const mutations = {
  [types.SET_RECHARGE_SHOW] (state, show) {
    state.show = !!show
  }
}

const actions = {
  setRechargeShow ({commit}, show) {
    commit(types.SET_RECHARGE_SHOW, !!show)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}