<template>
  <a-modal
    :visible="capacityTipModalVisible"
    :width="398"
    :footer="null"
    :title="null"
    :body-style="{ padding: 0 }"
    :z-index="1001"
    @cancel="close"
  >
    <div class="capacity">
      <div class="capacity-header">
        <div class="capacity-header-title">温馨提示</div>
        <!--        <my-icon-->
        <!--          type="iconCPM-close"-->
        <!--          class="capacity-header-close-btn"-->
        <!--          @click="close"-->
        <!--        />-->
      </div>
      <div class="capacity-content">
        您当前所在团队「{{ teamName }}」容量已满，无法{{ operationText }}！可
        <span class="clear-space" @click="clearSpace">清理空间</span>
        或扩展容量后继续{{ operationText }}。
      </div>
      <div class="capacity-footer">
        <a-button type="primary" class="capacity-btn" @click="confirm">
          扩展容量
        </a-button>
        <a-button class="capacity-btn" @click="close(true)">取消</a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'CapacityTipModal',
    data() {
      return {
        operationText: '上传',
      };
    },
    props: {
      teamInfo: {
        type: Object,
        default: () => {},
      },
      isLogin: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      visible(val) {
        if (val) {
          window.gio?.('track', 'popup_show', {
            forward_module_name: 'up_capacityup',
            forward_page_name: 'sjbatch',
            popup_name: '上传_容量已满提示框',
            page_url: location.href,
            is_login: this.isLogin,
          });
        }
      },
    },
    computed: {
      ...mapGetters({
        capacityTipModalVisible: 'getCapacityTipModalVisible',
      }),
      teamName() {
        const teamName = this.teamInfo.teamName;
        return teamName ? decodeURIComponent(teamName) : '';
      },
    },
    methods: {
      ...mapActions({
        setCapacityTipModalVisible: 'setCapacityTipModalVisible',
      }),
      close(isDom = false) {
        if (isDom) {
          this.buttonClickReport('取消按钮');
        }
        this.setCapacityTipModalVisible(false);
      },
      confirm() {
        this.buttonClickReport('扩展容量按钮');
        this.close();
        const pageName = 'sjbatch';
        const point = {
          source: {
            moduleName: 'capacityup',
            pageName,
          },
          standardOrEnterprise: 'enterprise',  
        };
        if (
          [200, 199, 4, 1, 9, 125, 130, 210, 0].includes(
            this.teamInfo.adminUserVipLevel,
          )
        ) {
          return this.$emit('rights-buy-pop', point);
        }

        this.$emit('open-svip', {
          forward_page_name: pageName,
          forward_module_name: 'up_capacityup',
        });
      },
      clearSpace() {
        this.buttonClickReport('清理空间');
        window.open('https://support.qq.com/products/55374/faqs/155193');
      },
      buttonClickReport(button_name) {
        window.gio?.('track', 'button_click', {
          forward_module_name: 'up_capacityup',
          forward_page_name: 'sjbatch',
          popup_name: '上传_容量已满提示框',
          page_url: location.href,
          is_login: this.isLogin,
          button_name,
        });
      },
    },
  };
</script>

<style scoped lang="less">
  .capacity {
    padding: 24px;
    &-header {
      height: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      &-title {
        color: #1b2337;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        margin-right: auto;
      }
      &-close-btn {
        font-size: 16px;
      }
    }
    &-content {
      color: #1b2337;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 24px;
    }
    &-footer {
      text-align: right;
    }
    &-btn {
      width: 104px;
      height: 40px;
      margin-left: 16px;
    }
  }
  .clear-space {
    color: #0773fc;
    cursor: pointer;
  }
</style>
<style>
  .ant-modal-content {
    border-radius: 8px;
  }
</style>
