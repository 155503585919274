/*
 * 登录注册组件显示状态
 * create by Chuanfeng on 2017/8/25
 */
import * as types from '../mutation-types'

const state = {
  show: 0, // 1：登录；2：注册；3：找回密码
  location: 0, // 1: 右上角注册按钮, 2: 点击模板弹窗注册, 3: 个人/企业权益页点击购买弹窗注册, 4: 设计工具页左侧 5: 新客首页-页面按钮, 6: 新客首页-挽留弹窗
}

const getters = {
  getLogregShow: state => state.show,
  getLogregShowLocation: state => state.location
}

const mutations = {
  [types.SET_LOGREG_SHOW] (state, show) {
    state.show = show
  },
  [types.SET_LOGREG_SHOW_LOCATION](state, location) {
    state.location = location
  }
}

const actions = {
  setLogregShow({commit}, show) {
    commit(types.SET_LOGREG_SHOW, show)
  },
  setLogregShowLocation({ commit }, location) {
    commit(types.SET_LOGREG_SHOW_LOCATION, +location)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

